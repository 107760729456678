import {
  modalController,
} from '@ionic/vue';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword.vue';

const forgotPasswordFormModal = function (useremail, vm) {
  return modalController
    .create({
      component: ForgotPassword,
      componentProps: {
        useremail,
      },
      cssClass: 'fullscreen',
    })
    .then((modalElement) => {
      modalElement.present();
      modalElement.onDidDismiss().then((returnObj) => {
        if (returnObj && returnObj.autoLogin) {
          vm.accountDetail = {
            useremail: returnObj.forgotPasswordForm.useremail,
            userpassword: returnObj.forgotPasswordForm.password,
          };
          vm.loginNow();
        }
      });
    });
};

export { forgotPasswordFormModal };
