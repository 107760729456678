<template>
  <div class="app-menu-tabs">
    <div
      class="menu-item"
      :class="{ active: activeState.parent === 'observation' }"
    >
      <ion-item @click="onToggleObservation">
        <font-awesome-icon
          slot="start"
          icon="binoculars"
        />
        <ion-label>
          Observations
        </ion-label>
      </ion-item>
    </div>
    <div
      v-if="!showFavorite && showObservation"
      class="menu-airport-list"
    >
      <MenuSubitem
        v-for="(item, index) in observationsMenuItems"
        :key="index"
        :menu-item="item"
        :active-state-key="activeState.key"
        @sub-item-clicked="onMenuSubItemClicked"
      />
    </div>
    <div
      class="menu-item"
      :class="{ active: activeState.parent === 'forecast' }"
    >
      <ion-item @click="onToggleForecast">
        <ion-icon
          slot="start"
          :icon="trendingUp"
        />
        <ion-label>
          Forecasts
        </ion-label>
      </ion-item>
    </div>
    <div
      v-if="!showFavorite && showForecasts"
      class="menu-airport-list"
    >
      <MenuSubitem
        v-for="(item, index) in forecastMenuItems"
        :key="index"
        :menu-item="item"
        :active-state-key="activeState.key"
        @sub-item-clicked="onMenuSubItemClicked"
      />
    </div>
  </div>
</template>
<script>
import { IonItem, IonIcon, IonLabel } from '@ionic/vue';
import { trendingUp } from 'ionicons/icons';
import AppConst from '../constants/app.constant';
import AirportTabs from '../constants/airportTabs.constant';
import Emitter from '../bus';
import EventConst from '../constants/event.constant';
import utilityService from '../services/utility.service';
import { getSettingFromLocalstorage } from '../services/localstorage/getSettingFromLocalstorage';
import MenuSubitem from './MenuSubitem.vue';
import router from '../router';
import { routerPushInterface } from '../services/router.interface';

export default {
  components: {
    IonItem,
    IonIcon,
    IonLabel,
    MenuSubitem,
  },
  props: {
    showFavorite: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['collapse-fav', 'go-to-view'],
  data() {
    return {
      activeState: {
        parent: '',
        key: '',
      },
      trendingUp,
      showObservation: false,
      showForecasts: false,
      airportDetails: null,
    };
  },
  computed: {
    forecastMenuItems() {
      return AirportTabs.forecastTabs.map((item) => ({ ...item, icon: this[item.icon] }));
    },
    observationsMenuItems() {
      return [...AirportTabs.observationsTabs];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === 'app.dashboard') {
          const currentRouteActionKey = router.currentRoute.value.query.actionKey;
          const findInObervation = this.observationsMenuItems.find(
            (item) => item.key === currentRouteActionKey,
          );
          const findInForecast = this.forecastMenuItems.find(
            (item) => item.key === currentRouteActionKey,
          );
          if (findInObervation || findInForecast) {
            if (findInObervation) {
              this.showObservation = true;
              this.showForecasts = false;
              this.activeState = {
                parent: 'observation',
                key: findInObervation.key,
              };
            }
            if (findInForecast) {
              this.showObservation = false;
              this.showForecasts = true;
              this.activeState = {
                parent: 'forecast',
                key: findInForecast.key,
              };
            }
          } else {
            this.activeState = {
              parent: '',
              key: '',
            };
          }
        } else if (to.name === 'app.weather') {
          this.showObservation = false;
          this.showForecasts = true;
          this.activeState = {
            parent: 'forecast',
            key: 'weather',
          };
        } else {
          this.showForecasts = false;
          this.showObservation = false;
          this.activeState = {
            parent: '',
            key: '',
          };
        }
      },
    },
  },
  methods: {
    onToggleObservation() {
      this.showForecasts = false;
      this.showObservation = !this.showObservation;
      this.$emit('collapse-fav');
    },
    onToggleForecast() {
      this.showForecasts = !this.showForecasts;
      this.showObservation = false;
      this.$emit('collapse-fav');
    },
    onMenuSubItemClicked(item) {
      if (item.goToView === 'dashboard') {
        const myDashboardSettings = getSettingFromLocalstorage(
          AppConst.localstorageConf.meteogramSettingKey,
        );
        myDashboardSettings.actionKey = item.key;
        utilityService.setSettingInLocalstorage(
          myDashboardSettings,
          AppConst.localstorageConf.meteogramSettingKey,
        );
      }
      if (
        item.goToView === 'dashboard'
        && router.currentRoute.value.name === AppConst.stateMap.dashboard.key
      ) {
        const newQueryParam = { ...router.currentRoute.value.query, actionKey: item.key };
        routerPushInterface({
          query: {
            ...newQueryParam,
          },
        });
        setTimeout(() => {
          Emitter.emit(`${EventConst.refreshViewRootscope}${AppConst.stateMap.dashboard.key}`);
        }, 10);
      } else if (item.goToView) {
        this.$emit('go-to-view', item.goToView);
      }
    },
  },
};
</script>

<style scoped>
.menu-airport-list,
.menu-airport-list ion-item {
  background: #1C1C1C;
  --background: #1C1C1C;
}
.bn-menu .menu-airport-list .item:hover {
  background-color: transparent;
}
</style>
