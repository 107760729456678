import d3 from '../../globalexports/d3.export';

/**
 *
 * @param {*} parentSelector
 * @param {*} refCanvasConfig
 * @param {*} canvasConfig
 * @param {*} canvasId
 * @param {*} projection
 * @param {*} d3Path
 */
const configureCanvas = (
  parentSelector,
  refCanvasConfig,
  canvasConfig,
  canvasId,
  d3Path,
  projection,
) => {
  const canvasContainer = d3.select(parentSelector);
  if (!canvasContainer.empty()) {
    refCanvasConfig.canvas = canvasContainer
      .append('canvas')
      .attr('id', `canvas-${canvasId}`)
      .attr('width', canvasConfig.width)
      .attr('height', canvasConfig.height)
      .style('width', canvasConfig.style.width)
      .style('height', canvasConfig.style.height);
    if (
      refCanvasConfig.canvas
      && refCanvasConfig.canvas.node()
      && refCanvasConfig.canvas.node().getContext('2d')
    ) {
      refCanvasConfig.canvas
        .node()
        .getContext('2d')
        .setTransform(canvasConfig.ratio, 0, 0, canvasConfig.ratio, 0, 0);
      refCanvasConfig.ctx = refCanvasConfig.canvas.node().getContext('2d');
      if (d3Path) {
        refCanvasConfig.path = d3Path().context(refCanvasConfig.ctx).projection(projection);
      }
    }
  }
};
export default configureCanvas;
