<template>
  <div v-if="showAnySubscriptionOption">
    <div
      v-if="showPaypalSubscriptionSection || showInAppSubscriptionSection"
      class="custom-divider"
    >
      Subscription Options
    </div>
    <PaypalSubscription
      v-if="showPaypalSubscriptionSection"
      @buy-product="onBuyProduct"
    />
    <IapSubscription
      v-if="showInAppSubscriptionSection"
      @buy-product="onBuyProduct"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import { showPaypalSubscriptionOptions } from '../services/subscription/showPaypalSubscriptionOptions';
import { getIsActiveFsUser } from '../services/user/getIsActiveFsUser';
import { getIsActiveApplePayUser } from '../services/user/getIsActiveApplePayUser';
import { getIsUserPaypalSubscriptionActive } from '../services/user/getIsUserPaypalSubscriptionActive';
import IapSubscription from './InAppPurchase/IapSubscription.vue';
import PaypalSubscription from './PaypalSubscription/PaypalSubscription.vue';

export default {
  components: {
    IapSubscription,
    PaypalSubscription,
  },
  props: {
    needSubscription: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['buy-product'],
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
      isInAppPurchaseActive:
        SUBSCRIPTION_GETTERS_ENUM.GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE,
    }),
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    showPaypalSubscriptionSection() {
      const status = this.userDetails
        && showPaypalSubscriptionOptions(
          this.userDetails,
          this.userPaypalSubscription,
          this.needSubscription,
        );
      return status;
    },
    showInAppSubscriptionSection() {
      if (this.isInAppPurchaseActive) {
        if (
          !getIsActiveApplePayUser(this.userDetails)
          && (!this.showPaypalSubscriptionSection)
        ) {
          return true;
        }
      }
      return false;
    },
    showAnySubscriptionOption() {
      if (
        getIsActiveFsUser(this.userDetails) === true
        || getIsActiveApplePayUser(this.userDetails) === true
        || getIsUserPaypalSubscriptionActive(this.userPaypalSubscription) === true) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onBuyProduct(product) {
      this.$emit('buy-product', product);
    },
  },
};
</script>
