<template>
  <IonFooter>
    <IonToolbar color="dark">
      <div class="app-verison">
        <div>{{ deviceAppNameVersion }} / {{ deviceBundleVersion }}</div>
        <div>v{{ codeVersion }} / {{ codeBundleVersion }}</div>
      </div>
    </IonToolbar>
  </IonFooter>
</template>
<script>
import {
  IonToolbar,
  IonFooter,
} from '@ionic/vue';
import { getAppInfo } from '../services/capacitor/getApp.info';
import AppConst from '../constants/app.constant';

export default {
  components: {
    IonToolbar,
    IonFooter,
  },
  data() {
    return {
      deviceInfo: null,
    };
  },
  computed: {
    deviceBundleVersion() {
      return this.deviceInfo && this.deviceInfo.appBuild;
    },
    deviceAppNameVersion() {
      return this.deviceInfo && `${this.deviceInfo.appName} v${this.deviceInfo.appVersion}`;
    },
    codeVersion() {
      return AppConst.application.version;
    },
    codeBundleVersion() {
      return AppConst.application.bundleVersion;
    },
  },
  mounted() {
    getAppInfo().then(({ appBuild, appVersion, appName }) => {
      this.deviceInfo = {
        appBuild: appBuild || this.codeBundleVersion,
        appVersion: appVersion || this.codeVersion,
        appName: appName || AppConst.application.name,
      };
    });
  },
};
</script>
<style scoped>
.app-verison {
  text-align: center;
  color: silver;
  padding: 4px;
}
</style>
