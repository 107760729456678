import addDays from 'date-fns/addDays';

const getCycleTimeFromAdjustedtime = (adjustedTime, cycles) => {
  // NOTE: we don't support decimal cycles!
  if (!cycles) {
    adjustedTime.setUTCMinutes(0);
    adjustedTime.setUTCSeconds(0);
    return adjustedTime;
  }
  const hours = adjustedTime.getUTCHours();

  let adjustedCycleTime = new Date(adjustedTime);
  let cycleValue = cycles.find((item) => item >= hours);

  // the idea is, it should step forward from adjusted time 'til it sees a matching cycle
  // so if time now is 09-20 0400Z
  // and "s" is 8 and cycle is [0, 12]
  // then it would start at 09-19 2000Z and move forward
  // first match would be 09-20 0000Z (0 cycle)
  if (!cycleValue) {
    adjustedCycleTime = addDays(adjustedCycleTime, 1);
    [cycleValue] = cycles;
  }
  // NOTE: we are returning time in Local time zone not UTC!
  adjustedCycleTime.setUTCHours(cycleValue);
  adjustedCycleTime.setUTCMinutes(0);
  adjustedCycleTime.setUTCSeconds(0);
  return adjustedCycleTime;
};

export default getCycleTimeFromAdjustedtime;
