import {
  writeText,
} from '../../../../utils/canvas';
import bnd3Service from '../../../../services/bnd3.service';
import utilityService from '../../../../services/utility.service';
import weatherImgService from '../../../../services/weather.service';

const drawTravelRouteDots = (
  ctx,
  path,
  projection,
  airport,
  scaleThresholdForRouteIcaoID,
  settings,
) => {
  const colorValue = bnd3Service.getFlightDotColor(airport.frl);
  const useWhiteImage = utilityService.useWhiteImage(colorValue);
  const latLongCord = [airport.airportDetails.lng, airport.airportDetails.lat];
  ctx.beginPath();
  path({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: latLongCord,
    },
  });
  ctx.fillStyle = colorValue;
  ctx.fill();
  const latLongProj = projection(latLongCord);
  // Getting image from a pre loaded map
  // so we dont have to wait for image onload
  ctx.strokeStyle = 'transparent';
  if (airport.weatherRepresentation) {
    const imageObject = (useWhiteImage ? weatherImgService.imageMap[
      airport.weatherRepresentation.filename
    ]
      : weatherImgService.imageMapBlack[airport.weatherRepresentation.filename]);
    ctx.drawImage(
      imageObject,
      latLongProj[0] - 10,
      latLongProj[1] - 10,
      20,
      20,
    );
  } else {
    ctx.drawImage(
      weatherImgService.imageMap.none,
      latLongProj[0] - 10,
      latLongProj[1] - 10,
      20,
      20,
    );
  }
  if (projection.scale() > scaleThresholdForRouteIcaoID || (airport.icao_id === settings
    .start || airport.icao_id === settings.mid1 || airport.icao_id === settings.mid2
        || airport.icao_id === settings.end)) {
    writeText(ctx, airport.icao_id, latLongProj[0] - 16, latLongProj[1] - 20);
  }
};

export { drawTravelRouteDots };
