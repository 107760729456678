const MENU_ACTIONS_ENUM = {
  ENABLE_SWIPE_GESTURE: 'ENABLE_SWIPE_GESTURE',
  DISABLE_SWIPE_GESTURE: 'DISABLE_SWIPE_GESTURE',
};

Object.freeze(MENU_ACTIONS_ENUM);

const MENU_GETTERS_ENUM = {
  GET_SWIPE_GESTURE: 'GET_SWIPE_GESTURE',
};

Object.freeze(MENU_GETTERS_ENUM);

const MENU_MUTATIONS_ENUM = {
  ENABLE_SWIPE_GESTURE: 'ENABLE_SWIPE_GESTURE',
  DISABLE_SWIPE_GESTURE: 'DISABLE_SWIPE_GESTURE',
};

Object.freeze(MENU_MUTATIONS_ENUM);

export {
  MENU_ACTIONS_ENUM,
  MENU_MUTATIONS_ENUM,
  MENU_GETTERS_ENUM,
};
