import {
  CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { getPoolConfig } from './getPoolConfig';

/**
 *
 * @returns CognitoUserPool
 */
export const getCognitoUserPool = () => new CognitoUserPool(getPoolConfig());
