import AccountConst from '../../../constants/account.constant';
import {
  getCognitoUserAttribute,
} from '../core/getCognitoUserAttribute';

export const getAttributesForUserRegisteration = (
  useremail,
  rCode,
  givenName,
  familyName,
  tu,
) => {
  const attributeEmail = getCognitoUserAttribute(
    'email',
    useremail,
  );
  const attributeAppInstallDate = getCognitoUserAttribute(
    AccountConst.customAttribute.appinstalldate,
    tu,
  );
  const attributeTrialYN = getCognitoUserAttribute(AccountConst.customAttribute.trialYN, 'Y');
  const attributeGivenName = getCognitoUserAttribute('given_name', givenName);
  const attributeFamilyName = getCognitoUserAttribute('family_name', familyName);

  const attributeList = [
    attributeEmail,
    attributeAppInstallDate,
  ];
  attributeList.push(attributeTrialYN);
  if (givenName && familyName) {
    attributeList.push(attributeGivenName);
    attributeList.push(attributeFamilyName);
  }
  if (rCode) {
    const attributeRcode = getCognitoUserAttribute('AccountConst.customAttribute.rCode', rCode);
    attributeList.push(attributeRcode);
  }
  return attributeList;
};
