import {
  drawImageOnCanvas,
} from '../../../../utils/canvas';
import { getStormImage } from '../../stormTracks/utils/getStormImage';

const drawStormIcon = (projection, ctx, storm, width = 22, height = 22) => {
  const latLongCord = [storm.fcst[0][1], storm.fcst[0][0]];
  const latLongProj = projection(latLongCord);
  drawImageOnCanvas(
    ctx,
    width,
    height,
    latLongProj[0],
    latLongProj[1],
    getStormImage(storm.icon),
  );
};

export { drawStormIcon };
