<template>
  <div
    :class="customClass"
    class="paypal-button-container"
  >
    <div :id="paypalButtonId" />
  </div>
</template>
<script>
import { loadingController } from '@ionic/vue';
import { mapGetters } from 'vuex';
import reportError from '../../services/error-handler/reportError';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import AppConst from '../../constants/app.constant';
import AccountConst from '../../constants/account.constant';
import { getPaypalSubscriptionDetails } from '../../services/paypal/getPaypalSubscriptionDetails';
import { setUserAttributeInStore } from '../../services/user/setUserAttributeInStore';
import { openSubscribedSuccessfullyAlert } from '../../alert/subscribed-successfully.alert';
import { setPayPalOrderRecord } from '../../services/aws/user/setAttributes/setPayPalOrderRecord';
import { setT4Cognito } from '../../services/aws/user/setAttributes/setT4Cognito';
import { getExtraDaysForEarlyPurchase } from '../../services/user/getExtraDaysForEarlyPurchase';
import { processSubscriptionByT4 } from '../../services/aws/user/processSubscriptionByT4';

export default {
  props: {
    productId: {
      type: String,
      required: true,
    },
    doStyling: {
      type: Boolean,
      default: true,
    },
    earlyPurchase: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['paypal-product-purchased'],
  data() {
    return {
      loading: null,
      newSubscriptionId: null,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
    }),
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    paypalButtonId() {
      return `paypal-button-container-${this.productId}`;
    },
    customClass() {
      return this.doStyling ? 'paypal-do-styling' : '';
    },
  },
  mounted() {
    const vm = this;
    if (window.paypal) {
      window.paypal
        .Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'subscribe',
          },
          onError(err) {
            reportError({
              message: 'PayPal Payment Error',
              code: 'PayPal Payment Error',
              err,
              plan_id: vm.productId,
            });
          },
          onCancel(data) {
            vm.tryOnCancelIfPaymentWasDone();
            reportError({
              message: 'PayPal Payment Canceled by user',
              code: 'PayPal Payment Canceled by user',
              data,
              plan_id: vm.productId,
            });
          },
          async createSubscription(data, actions) {
            const createdSubscriptionId = await actions.subscription.create({
              /* Creates the subscription */
              plan_id: vm.productId,
            });
            await setT4Cognito(createdSubscriptionId);
            vm.newSubscriptionId = createdSubscriptionId;
            return createdSubscriptionId;
          },
          onApprove(data) {
            vm.processSubscriptionComplete(data);
          },
        })
        .render(`#paypal-button-container-${vm.productId}`); // Renders the PayPal button
    }
  },
  methods: {
    async presentLoading() {
      this.loading = await loadingController.create({
        message: AppConst.processingText,
        duration: 10000,
      });

      await this.loading.present();
    },
    hideLoading() {
      this.loading.dismiss();
    },
    finallyOnSubscriptionData(subscriptionData) {
      this.$emit('paypal-product-purchased');
      setUserAttributeInStore({
        key: AccountConst.customAttribute.t3,
        value: subscriptionData,
      });
      setUserAttributeInStore({
        key: 't3Object',
        value: subscriptionData,
      });
      // You can add optional success message for the subscriber here
      setPayPalOrderRecord(subscriptionData).then(() => {
        this.hideLoading();
        openSubscribedSuccessfullyAlert();
      });
    },
    async tryOnCancelIfPaymentWasDone() {
      const t3DataForT4 = await processSubscriptionByT4(this.newSubscriptionId);
      if (t3DataForT4) {
        this.presentLoading();
        this.finallyOnSubscriptionData(t3DataForT4);
      }
    },
    processSubscriptionComplete(data) {
      const { billingToken, orderID, subscriptionID } = data;
      const subscriptionData = {
        bt: billingToken,
        oi: orderID,
        si: subscriptionID,
      };
      this.presentLoading();
      getPaypalSubscriptionDetails(subscriptionID).then((subscriptionDetail) => {
        const { billing_info, plan_id } = subscriptionDetail;
        subscriptionData.lp = billing_info.last_payment.time;
        subscriptionData.nb = billing_info.next_billing_time;
        subscriptionData.pi = plan_id;
        if (this.earlyPurchase) {
          subscriptionData.ed = getExtraDaysForEarlyPurchase();
        }
        this.finallyOnSubscriptionData(subscriptionData);
      });
    },
  },
};
</script>
<style scoped>
.paypal-do-styling.paypal-button-container {
  padding: 20px;
  width: 320px;
  background: #fff;
  border-radius: 40px;
  margin: auto;
  margin-top: 20px;
}
</style>
