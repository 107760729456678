/**
 *
 * @param {Object} subscriptionDetail
 * @returns
 */
const getUserPaypalNextBillingDate = (userDetails) => {
  if (userDetails && userDetails.t3Object) {
    return userDetails.t3Object.nb;
  }
  return null;
};

const getNextLatestBillingDateBetweenPaypalAndFS = (userDetails) => {
  if (userDetails) {
    let {
      'custom:fs': fs,
      'custom:t2': iap,
      t3Object: { nb } = {},
    } = userDetails;
    fs = fs ? new Date(fs) : null;
    iap = iap ? new Date(iap) : null;
    nb = nb ? new Date(nb) : null;
    const latest = Math.max(fs, nb, iap);
    const next_date = new Date(latest);
    return next_date;
  }
  return null;
};

/**
 * Use it to get extended subscription days
 * Note: We used to provide some extra days but now we dont want to give any extra days
 * @returns
 */
const getUsersExtraDays = () => 0;

export {
  getUserPaypalNextBillingDate,
  getUsersExtraDays,
  getNextLatestBillingDateBetweenPaypalAndFS,
};
