<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-title>Check your network connection</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="contents">
      <div class="noNetworkContainer">
        <font-awesome-icon icon="wifi" />
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';

export default {
  name: 'NoNetwork',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
};
</script>
<style scoped>
.noNetworkContainer {
  display: flex;
  font-size: 100px;
  color: silver;
  height: 100%;
}
.noNetworkContainer i {
  margin: auto;
}
.noNetworkContainer svg {
  margin: auto;
}
</style>
