<template>
  <ion-item @click="deleteMyAccount()">
    <ion-label>Delete My Account</ion-label>
    <ion-icon
      slot="end"
      :icon="arrowForward"
      mode="ios"
    />
  </ion-item>
</template>
<script>
import {
  IonItem,
  IonIcon,
  IonLabel,
  alertController,
} from '@ionic/vue';
import { arrowForward } from 'ionicons/icons';
import AccountConst from '../constants/account.constant';
import reportError from '../services/error-handler/reportError';

export default {
  components: {
    IonItem,
    IonIcon,
    IonLabel,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      arrowForward,
      showingAlert: false,
    };
  },
  computed: {
    userEmail() {
      if (this.user && this.user.email) {
        return this.user.email;
      }
      return null;
    },
  },
  methods: {
    logToSentry() {
      const userDetails = this.user;
      const sentryLogData = {
        message: `User ${this.userEmail} requested to delete his account.`,
        email: this.userEmail,
        userDetails,
      };
      const extraTag = {
        username: this.userEmail,
      };
      reportError(sentryLogData, extraTag);
    },
    deleteAccountFeedback() {
      alertController
        .create({
          message: AccountConst.deleteAccountConfirmedFeedback,
          buttons: [
            {
              text: 'Ok',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    logDeleteMyAccount() {
      this.logToSentry();
      this.deleteAccountFeedback();
    },
    deleteMyAccount() {
      const vm = this;
      if (!vm.showingAlert) {
        vm.showingAlert = true;
        setTimeout(() => {
          vm.showingAlert = false;
        }, 1000);
        alertController
          .create({
            header: 'Confirm!',
            message: AccountConst.deleteAccountConfirmMessage,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: 'Yes',
                handler() {
                  vm.logDeleteMyAccount();
                },
              },
            ],
          })
          .then((alert) => {
            alert.present();
          });
      }
    },
  },
};
</script>
