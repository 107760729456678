import _forEach from 'lodash/forEach';
import bnChartDataService from '../chartData.service';

const processMosRecords = (mapMosData) => {
  const records = {
    lamp: [],
    mav: [],
  };
  const allPoints = [];
  const routeAirports = [];
  if (mapMosData && mapMosData.constructor === Array) {
    _forEach(mapMosData.slice().reverse(), (item, index, object) => {
      if (routeAirports.indexOf(item.icao_id) > -1) {
        mapMosData.splice(object.length - 1 - index, 1);
      }
    });
    const mapMosAirports = [];
    const mapMosIcaoIds = [];
    const mapMosIcaoIdsMap = {};
    _forEach(mapMosData, (airport) => {
      if (airport.lamp) {
        mapMosAirports.push({
          icao_id: airport.icao_id,
          lat: airport.lat,
          lng: airport.lng,
        });
        mapMosIcaoIdsMap[airport.icao_id] = true;
      }
      mapMosIcaoIds.push(airport.icao_id);
      bnChartDataService.processMapMosRecords(airport);
      records.lamp.push(airport.lamp);
      records.mav.push(airport.mav);
      allPoints.push([airport.lng, airport.lat, airport.icao_id]);
    });
    return {
      mapMosAirports,
      mapMosIcaoIds,
      mapMosIcaoIdsMap,
      mapMosDataObj: records,
      allPoints,
    };
  }
};

export { processMosRecords };
