import {
  configureCanvas,
  getHiDPICanvasConfig,
} from '../../../../utils/canvas';
import {
  clearGridViewCanvas,
} from './clearGridViewCanvas';
import {
  gridCanvasConfig,
} from './gridCanvasConfig';
import d3 from '../../../../globalexports/d3.export';

const appendCanvasAndConfigure = (
  parentSelector,
  refCanvasConfig,
  canvasConfig,
  canvasId,
) => {
  configureCanvas(
    parentSelector,
    refCanvasConfig,
    canvasConfig,
    canvasId,
  );
};

/**
 *
 * @param {Number} width
 * @param {Number} height
 */
export const initGridCanvas = (width, height, left, top) => {
  clearGridViewCanvas();
  const canvasConfig = getHiDPICanvasConfig(width, height);
  appendCanvasAndConfigure(
    '#gridview-canvas-images',
    gridCanvasConfig,
    canvasConfig,
    'weather-images',
  );
  d3.select('#gridview-canvas-images')
    .style('padding-top', `${top}px`)
    .style('padding-left', `${left}px`);
};
