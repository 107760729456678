import { createStore } from 'vuex';
import { aeroplaneLoader } from './modules/aeroplane-loader';
import { personalMinimums } from './modules/personal-minimums';
import { messageLoader } from './modules/message-loader';
import { subscription } from './modules/subscription';
import { menu } from './modules/menu';
import { user } from './modules/user';
import { mrms } from './modules/mrms';
import { layerUpdateTime } from './modules/layer-update-time';
import { connectivity } from './modules/connectivity';

const store = createStore({
  modules: {
    aeroplaneLoader,
    personalMinimums,
    messageLoader,
    subscription,
    menu,
    user,
    mrms,
    layerUpdateTime,
    connectivity,
  },
  strict: true,
});

export { store };
