import utilityService from '../../../services/utility.service';
import { getDateFormatDay } from '../utils/getDateFormatDay';
import { getSunMoonTime } from '../utils/getSunMoonTime';
import { getWeatherIcon } from '../utils/getWeatherIcon';
import { getWindDisplay } from '../utils/getWindDisplay';
import { getPrecipitation } from '../utils/getPrecipitation';
import { getTemperatureValue } from '../utils/getTemperatureValue';

const getGovWeatherDayNightObject = (weatherData, i, settings) => {
  let dayNightObject = null;
  if (weatherData.properties.periods[i]) {
    const startTime = new Date(weatherData.properties.periods[i].startTime);
    dayNightObject = {
      displayKey: weatherData.properties.periods[i].name,
      startTime,
      displayDay: startTime.toLocaleString('en', { weekday: 'short' }),
      displayDate: getDateFormatDay(settings)(startTime),
      temperature: getTemperatureValue(weatherData.properties.periods[i].temperature, settings),
      windDisplay: getWindDisplay(weatherData.properties.periods[i]),
      windIcon: `wi-from-${weatherData.properties.periods[i].windDirection
        ? weatherData.properties.periods[i].windDirection.toLowerCase()
        : ''}`,
      pop: getPrecipitation(weatherData.properties.periods[i]),
      weather: weatherData.properties.periods[i].shortForecast,
      weatherType: weatherData.properties.periods[i].icon
        ? getWeatherIcon(weatherData.properties.periods[i].icon)
        : null,
      text: weatherData.properties.periods[i].detailedForecast,
      hasSlider: true,
    };
    dayNightObject.sunMoonCalc = utilityService.getSunMoonCalculation(
      dayNightObject.startTime,
      settings.airportDetails.lat,
      settings.airportDetails.lng,
    );
    dayNightObject.sunrise = getSunMoonTime(settings)(dayNightObject.sunMoonCalc
      .todaysSunlightTime.sunrise);
    dayNightObject.sunset = getSunMoonTime(settings)(dayNightObject.sunMoonCalc
      .todaysSunlightTime.sunset);
  }
  return dayNightObject;
};

export { getGovWeatherDayNightObject };
