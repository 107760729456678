import { getCognitoSyncManager } from '../core/getCognitoSyncManager';

const getCognitoSyncDataset = () => new Promise((resolve) => {
  const client = getCognitoSyncManager();
  const bnDataSetName = 'bnUserPreferences';
  client.openOrCreateDataset(bnDataSetName, (err, dataset) => {
    if (!err) {
      resolve(dataset);
    } else {
      resolve(null);
    }
  });
});

export { getCognitoSyncDataset };
