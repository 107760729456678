const validateAloftData = (aloftData) => {
  if (aloftData
        && aloftData[0] && aloftData[0].altitudes
        && Object.keys(aloftData[0].altitudes)
        && Object.keys(aloftData[0].altitudes).length) {
    return true;
  } if (aloftData && aloftData.airports) {
    return true;
  }
  return false;
};

export {
  validateAloftData,
};
