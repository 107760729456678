/* eslint-disable max-classes-per-file */
const loadImage = (path) => new Promise((resolve, reject) => {
  const image = new Image();
  image.onload = () => resolve();
  image.onerror = (event) => reject(event.target.src);
  image.src = path;
});

export class FailedImagesRequestError extends Error {
  constructor(urls) {
    super('some of the images load failed');
    this.name = 'FailedImagesRequest';
    this.failedImageUrls = urls;
    this.statusCode = 404;
  }
}

class ImagePreLoader {
  constructor(imageLocations, progressfn) {
    this.progressfn = progressfn;
    this.imageLocations = imageLocations;
    this.progress = 0;
    this.totalImageCount = this.imageLocations.length;
  }

  updateProgress() {
    const progress = Math.ceil((this.progress / this.totalImageCount) * 100);
    this.progressfn?.(progress);
  }

  async request() {
    const requestList = this.imageLocations.map(async (location) => {
      const path = Array.isArray(location) ? location[0] : location;
      return loadImage(path).finally(() => {
        this.progress += 1;
        this.updateProgress();
      });
    });
    const list = await Promise.allSettled(requestList);
    const failedRequest = list.filter((item) => item.status === 'rejected');
    if (failedRequest.length) {
      const failedImageUrls = failedRequest.map((item) => item.reason);
      throw new FailedImagesRequestError(failedImageUrls);
    }
    return this.imageLocations;
  }
}

export default ImagePreLoader;
