import { Device } from '@capacitor/device';
import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  SUBSCRIPTION_ACTIONS_ENUM,
} from '../../store/modules/subscription/subscription.enum';

/**
 *
 * @returns Promise
 */
const setIsInAppPurchaseActive = async () => {
  const deviceInfo = await Device.getInfo();
  if (deviceInfo.platform === 'ios') {
    store.dispatch(
      `${STORE_NAMESPACE.SUBSCRIPTION}/${SUBSCRIPTION_ACTIONS_ENUM.SET_IF_IAP_SUBSCRIPTION_IS_ACTIVE}`,
      true,
    );
  }
};
export {
  setIsInAppPurchaseActive,
};
