const drawMainLandBorder = (ctx, path, land) => {
  // This is not used as it is causing overlapped dirty border
  // Drawing main land borders
  ctx.beginPath();
  path(land);
  ctx.lineWidth = 1;
  ctx.strokeStyle = '#fff';
  ctx.stroke();
};

export { drawMainLandBorder };
