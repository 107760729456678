import AccountConst from '../../../../constants/account.constant';
import { getCognitoUserAttribute } from '../../core/getCognitoUserAttribute';
import { updateUserAttributes } from '../../core/updateUserAttributes';

const setT1T2Cognito = async (t1, t2) => {
  const attributeList = [
    getCognitoUserAttribute(AccountConst.customAttribute.t1, t1),
    getCognitoUserAttribute(AccountConst.customAttribute.t2, t2),
  ];
  return updateUserAttributes(attributeList);
};
export { setT1T2Cognito };
