import reportError from '../../error-handler/reportError';
import { getCurrentUser } from '../user/getCurrentUser';

const updateUserAttributes = (attributeList) => new Promise((resolve) => {
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      cognitoUser.getSession((err, session) => {
        if (!err && session && session.isValid()) {
          cognitoUser.updateAttributes(attributeList, (err2) => {
            if (err2) {
              resolve(false);
              reportError(err2);
              return;
            }
            resolve(true);
          });
        }
      });
    } else {
      resolve(false);
    }
  });
});

export { updateUserAttributes };
