import {
  MESSAGE_LOADER_ACTIONS_ENUM,
  MESSAGE_LOADER_MUTATIONS_ENUM,
} from './message-loader.eum';

export const messageLoaderActions = {
  [MESSAGE_LOADER_ACTIONS_ENUM.SET_LOADING_MESSAGE]({ commit }, payload) {
    commit(MESSAGE_LOADER_MUTATIONS_ENUM.SET_LOADING_MESSAGE, payload);
  },
};
