import AppConst from '../../constants/app.constant';
import {
  getKeyValueFromLocalStorage,
} from '../localstorage';
import { routerReplaceInterface } from '../router.interface';

const fallbackRoute = 'app.gridview';

const navigateToRouteDifferentFromNow = () => {
  const lastViewedState = getKeyValueFromLocalStorage(
    AppConst.localstorageConf.lastViewedStateKey,
  );
  routerReplaceInterface({
    name: lastViewedState === fallbackRoute ? AppConst.defaultHomeViewState : fallbackRoute,
  });
};

export { navigateToRouteDifferentFromNow };
