<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div
    class="app-slider"
    :class="{
      secondarySlider: hideBottomAxis
    }"
  >
    <PlayPauseButton
      v-show="!hideBottomAxis"
      @playSlider="onPlaySlider"
      @pauseSlider="onPauseSlider"
    />
    <div class="slider-svg-container">
      <div class="no-radar-data">
        {{ radarDataNotAvailable }}
      </div>
    </div>
  </div>
</template>
<script>
import _debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import bnd3Service from '../../services/bnd3.service';
import GlobeConst from '../../constants/globe.constant';
import capacitorService from '../../services/capacitor.service';
import utilityService from '../../services/utility.service';
import ConfigDeviceSettings from '../../config/device.config';
import AppConst from '../../constants/app.constant';
import d3 from '../../globalexports/d3.export';
import PlayPauseButton from '../PlayPauseButton.vue';
import ChartConst from '../../constants/chart.constant';
import EventConst from '../../constants/event.constant';
import { MRMS_GETTERS_ENUM } from '../../store/modules/mrms/mrms.enum';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';

export default {
  name: 'RadarSlider',
  components: {
    PlayPauseButton,
  },
  emits: [EventConst.sliderEvent.changing],
  data() {
    return {
      parentElement: null,
      settings: null,
      sliderPinned: null,
      radarDataNotAvailable: GlobeConst.radarDataNotAvailable,
      sliderMainObj: GlobeConst.radarSlidesCount,
      showSlider: null,
      sliderIdxStart: GlobeConst.radarSlidesCount,
      currentSliderIdx: 0,
      sliderNotchRadius: 10,
      textHeightRef: 20,
      enableAlternateSlider:
        ConfigDeviceSettings.settings.enableAlternateSlider,
      hideBottomAxis: utilityService.hideBottomAxis(),
      hideStepButtons: ConfigDeviceSettings.settings.hideSliderStepButtons,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.MRMS, {
      rasterMetaData: MRMS_GETTERS_ENUM.GET_MRMS_METADATA,
    }),
  },
  watch: {
    rasterMetaData() {
      this.drawSlider();
    },
  },
  mounted() {
    const vm = this;
    vm.parentElement = d3.select(`#${vm.$el.id}`);
    vm.$nextTick(() => {
      window.addEventListener('resize', vm.handleResizeEvent);
    });
  },
  beforeUnmount() {
    const vm = this;
    window.removeEventListener('resize', vm.handleResizeEvent);
  },
  methods: {
    onPlaySlider() {
      this.playSlider();
    },
    onPauseSlider() {
      this.pauseSlider();
    },
    getSliderLabel(radarTime) {
      const vm = this;
      if (vm.dateFormat) {
        return (
          ChartConst.flightSliderMainPrefix
          + vm.dateFormat(radarTime)
        );
      }
    },
    drawSlider() {
      const vm = this;
      vm.hideBottomAxis = utilityService.hideBottomAxis();
      let windowRefValue = window.innerWidth;
      if (vm.hideBottomAxis) {
        windowRefValue = window.innerHeight;
        d3.select('#bn-radar-slider').style(
          'width',
          `calc(100vh - ${utilityService.getHeaderHeight()}px)`,
        );
      } else {
        d3.select('#bn-radar-slider').style('width', '');
      }
      const margin = {
        left: 20,
        right: 20,
      };
      const buttonWidth = 34;
      const buttonsSliderPad = 23;
      const buttonsPadding = 5;
      const buttonsPaddingBorder = 6;
      if (vm.hideBottomAxis) {
        /// this is right always in vertical mode
        margin.left = buttonsSliderPad;
        margin.right = buttonsSliderPad + utilityService.getHeaderHeight();
      } else {
        margin.right += buttonWidth + buttonsPadding + buttonsPaddingBorder;
      }
      let width = windowRefValue
        - (utilityService.is_small_device()
          ? 0
          : parseFloat(AppConst.sideMenuWidth));
      if (!vm.hideBottomAxis) {
        width
          -= utilityService.getSafeAreaPadding().left
          - utilityService.getSafeAreaPadding().right;
      }
      // Limit max width of radar slider by using max width between notches
      // width = Math.min(
      //   width,
      //   GlobeConst.radarSlidesCount * GlobeConst.maxWidthBetweenRadarSlides,
      // );
      const height = 70;
      const range = [0, vm.sliderMainObj - 1];
      const step = 1; // change the step and if null, it"ll switch back to a normal slider
      let handle;
      let etdTextHandle;
      let textHeightRef;
      // append svg
      vm.parentElement.select('div.slider-svg-container svg').remove();
      vm.parentElement
        .select('div.slider-svg-container .no-radar-data')
        .remove();
      const svg = vm.parentElement
        .select('.slider-svg-container')
        .append('svg')
        .style('width', `${width}px`)
        .style('height', `${height}px`);
      const slider = svg
        .append('g')
        .classed('slider', true)
        .attr('transform', `translate(${margin.left}, ${height / 2 + 0})`);
      // using clamp here to avoid slider exceeding the range limits
      const xScale = d3
        .scaleLinear()
        .domain(range)
        .range([0, width - margin.left - margin.right])
        .clamp(true);
      // array useful for step sliders
      const rangeValues = d3
        .range(range[0], range[1], step || 1)
        .concat(range[1]);
      xScale.clamp(true);

      const dragged = function (value, isDragEnd, isInitializing) {
        const x = xScale.invert(value);
        let index = null;
        let midPoint;
        let cx;
        let xVal;
        let i;
        let isChangeDetected = false;
        if (step) {
          // if step has a value, compute the midpoint based on range values and
          // reposition the slider based on the mouse position
          i = 0;
          while (i < rangeValues.length - 1) {
            if (x >= rangeValues[i] && x <= rangeValues[i + 1]) {
              index = i;
              break;
            }
            i += 1;
          }
          midPoint = (rangeValues[index] + rangeValues[index + 1]) / 2;
          if (x < midPoint) {
            cx = xScale(rangeValues[index]);
            xVal = rangeValues[index];
          } else {
            cx = xScale(rangeValues[index + 1]);
            xVal = rangeValues[index + 1];
          }
        } else {
          // if step is null or 0, return the drag value as is
          cx = xScale(x);
          xVal = x.toFixed(3);
        }
        if (xVal !== vm.currentSliderIdx) {
          isChangeDetected = true;
        }
        vm.currentSliderIdx = xVal;
        if (
          !vm.hideBottomAxis
          && !vm.enableAlternateSlider
          && vm.rasterMetaData
          && vm.rasterMetaData[xVal]
        ) {
          etdTextHandle.text(
            vm.getSliderLabel(new Date(vm.rasterMetaData[xVal].refDate)),
          );
        }
        // use xVal as drag value
        let flightRuleWidth = bnd3Service.getSelectionWidth(
          handle.select('.flight-ruleset-handle'),
        );
        const flightRuleHeight = bnd3Service.getSelectionHeight(
          handle.select('.flight-ruleset-handle'),
        );
        let flightEtdWidth = bnd3Service.getSelectionWidth(etdTextHandle);
        const flightEtdHeight = bnd3Service.getSelectionHeight(etdTextHandle);
        if (vm.hideBottomAxis) {
          flightEtdWidth = flightEtdHeight;
          flightRuleWidth = flightRuleHeight;
        }
        flightEtdWidth = Math.max(flightEtdWidth, 140);
        handle.attr('transform', `translate(${cx}, 0)`).attr('stroke', '#000');
        handle
          .select('.flight-ruleset-handle')
          .attr(
            'transform',
            `translate(${-flightRuleWidth / 2}, ${textHeightRef
              + vm.sliderNotchRadius})`,
          );
        etdTextHandle.attr('transform', () => {
          let xValue = cx - flightEtdWidth / 2;
          if (xValue < 0) {
            xValue = -10;
          } else if (xValue + flightEtdWidth > xScale(vm.sliderMainObj - 1)) {
            xValue = xScale(vm.sliderMainObj - 1) - flightEtdWidth;
          }
          return `translate(${xValue}, ${-(textHeightRef + 0)})`;
        });
        if (!isInitializing && !isChangeDetected && !isDragEnd) {
          return;
        }
        vm.$emit(EventConst.sliderEvent.changing, {
          idx: xVal,
          isDragEnd,
          isInitializing,
        });
      };
      vm.dragSliderToIdx = function (toIdx) {
        window.requestAnimationFrame(() => {
          dragged(xScale(toIdx));
        });
      };
      // drag behavior initialization
      const drag = d3
        .drag()
        .on('drag', (event) => {
          let xRef = event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(event.y + 56);
            }
          }
          window.requestAnimationFrame(() => {
            dragged(xRef);
          });
          // console.log("draging");
        })
        .on('end', (event) => {
          let xRef = event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(event.y + 56);
            }
          }
          window.requestAnimationFrame(() => {
            dragged(xRef, true);
          });
          etdTextHandle.text('');
        });
      // regular
      // vm is the main bar with a stroke (applied through CSS)
      slider
        .append('line')
        .attr('class', 'track regular')
        .attr('x1', xScale.range()[0])
        .attr('x2', xScale.range()[1]);

      // drag handle
      handle = slider.append('g');
      textHeightRef = vm.textHeightRef;
      handle
        .append('circle')
        .classed('radar-slider-handle handle', true)
        .attr('r', vm.sliderNotchRadius);
      etdTextHandle = slider
        .append('text')
        .classed('flight-etd-handle', true)
        .text(vm.hideBottomAxis || vm.enableAlternateSlider ? '' : '--');
      handle.append('text').classed('flight-ruleset-handle', true);
      // this is the bar on top of above tracks with stroke =
      // transparent and on which the drag behaviour is actually called
      // try removing above 2 tracks and play around with the CSS
      // for this track overlay, you"ll see the difference
      slider
        .append('rect')
        .attr('class', 'track track-overlay  regular')
        .attr('height', '50')
        .attr('width', xScale.range()[1] - xScale.range()[0] + 40)
        .attr('transform', 'translate(-20, -25)')
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .call(drag);
      // initial transition
      dragged(xScale(vm.sliderIdxStart), false, true);
      // PlayerControls defined here

      vm.playSlider = function () {
        // debugger
        vm.pauseSlider();
        const delayTimer = AppConst.fixedCycleTime / vm.sliderMainObj;
        vm.sliderInterval = setInterval(() => {
          if (vm.currentSliderIdx === vm.sliderMainObj - 1) {
            vm.currentSliderIdx = 0;
          } else {
            vm.currentSliderIdx += 1;
          }
          dragged(xScale(vm.currentSliderIdx), true, false, true);
        }, delayTimer);
      };
      vm.pauseSlider = function () {
        clearInterval(vm.sliderInterval);
      };
    },
    initDrawing() {
      this.drawSlider();
    },
    refresh(settings, allDataForInterval, dateFormat) {
      const vm = this;
      vm.dateFormat = dateFormat;
      vm.settings = settings;
      vm.sliderPinned = true;
      vm.flightSliderInfo = allDataForInterval.flightSliderInfo;
      // setTimeout(() => {
      //   vm.initDrawing();
      // });
    },
    resetToLastSlide() {
      if (this.dragSliderToIdx) {
        this.dragSliderToIdx(this.sliderMainObj - 1);
      }
    },
    handleResizeEvent: _debounce(function () {
      this.hideBottomAxis = utilityService.hideBottomAxis();
      this.initDrawing();
    }, ChartConst.radarSliderResizeDebounce),
  },
};
</script>
<style scoped>
#bn-radar-slider .slider-svg-container {
  text-align: center;
}
.no-radar-data {
  color: #fff;
  font-weight: bold;
  margin-top: 15px;
}
</style>
