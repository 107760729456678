import {
  drawImageOnCanvas,
} from '../../../../utils/canvas';

const imageObj = new Image();
imageObj.src = 'static/img/locate.svg?cb=212';

/**
 *
 * @param {Canvas.context} ctx
 * @param {Canvas.PATH} path
 * @param {GeoJSON POINT} stormPoint
 * @param {Boolean} isStartPoint
 */
const drawUserLocation = (projection, ctx, currentLocation) => {
  const latLngProj = projection(currentLocation);
  drawImageOnCanvas(
    ctx,
    32,
    32,
    latLngProj[0],
    latLngProj[1],
    imageObj,
  );
};

export { drawUserLocation };
