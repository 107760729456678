import { getCurrentUser } from '../user/getCurrentUser';
import { getCognitoSyncDataset } from './getCognitoSyncDataset';
import { synchronizeDataset } from './synchronizeDataset';

const putRecord = (recordname, newValue) => new Promise((resolve) => {
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      getCognitoSyncDataset().then((dataset) => {
        if (dataset) {
          if (typeof newValue !== 'string') {
            newValue = newValue.toString();
          }
          dataset.put(recordname, newValue, (
            err2,
            value,
          ) => {
            if (!err2) {
              synchronizeDataset();
              resolve(value);
            }
          });
        }
      });
    }
  });
});

export { putRecord };
