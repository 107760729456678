import {
  replaceAll,
} from '../../utils/replaceAll.polyfill';

/**
 *
 * @param {String} inputString
 * @param {Object} replacebleConfig
 * @returns
 */
const replaceKeysByConfig = (inputString, replacebleConfig) => {
  const allKeys = Object.keys(replacebleConfig);
  return allKeys.reduce(
    (accumalator, configKey) => replaceAll(
      accumalator,
      `{${configKey}}`,
      replacebleConfig[configKey],
    ),
    inputString,
  );
};

export { replaceKeysByConfig };
