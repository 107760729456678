import {
  store,
} from '../../store/store';

/**
 *
 * @returns Promise
 */
const getIsConnectedToInternet = () => store.state.connectivity.isConnectedToInternet;

export {
  getIsConnectedToInternet,
};
