import { aeroplaneLoaderState } from './aeroplane-loader.state';
import { aeroplaneLoaderActions } from './aeroplane-loader.action';
import { aeroplaneLoaderMutations } from './aeroplane-loader.mutation';
import { aeroplaneLoaderGetters } from './aeroplane-loader.getter';

export const aeroplaneLoader = {
  namespaced: true,
  state: aeroplaneLoaderState,
  mutations: aeroplaneLoaderMutations,
  actions: aeroplaneLoaderActions,
  getters: aeroplaneLoaderGetters,
};
