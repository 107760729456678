const USER_ACTIONS_ENUM = {
  SET_USER_DETAILS: 'SER_USER_DETAILS',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_USER_ATTRIBUTE: 'SET_USER_ATTRIBUTE',
  GET_USER_LOCATION: 'GET_USER_LOCATION',
  CLEAR_USER_STORE_ON_LOGOUT: 'CLEAR_USER_STORE_ON_LOGOUT',
};

Object.freeze(USER_ACTIONS_ENUM);

const USER_GETTERS_ENUM = {
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  GET_IS_LOGGED_IN: 'GET_IS_LOGGED_IN',
  GET_USER_LOCATION: 'GET_USER_LOCATION',
};

Object.freeze(USER_GETTERS_ENUM);

const USER_MUTATIONS_ENUM = {
  SET_USER_DETAILS: 'SER_USER_DETAILS',
  SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
  SET_USER_ATTRIBUTE: 'SET_USER_ATTRIBUTE',
  SET_USER_LOCATION: 'SET_USER_LOCATION',
  CLEAR_USER_STORE_ON_LOGOUT: 'CLEAR_USER_STORE_ON_LOGOUT',
};

Object.freeze(USER_MUTATIONS_ENUM);

export {
  USER_ACTIONS_ENUM,
  USER_MUTATIONS_ENUM,
  USER_GETTERS_ENUM,
};
