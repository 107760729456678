import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import CapacitorConst from '../../constants/capacitor.constant';
import AppConst from '../../constants/app.constant';

const getAppInfo = async () => {
  const deviceInfo = await Device.getInfo();
  if (deviceInfo.platform !== CapacitorConst.platform.web) {
    const appInfo = await App.getInfo();
    return {
      appBuild: appInfo.build,
      appVersion: appInfo.version,
      appName: appInfo.name,
    };
  }
  return {
    appBuild: AppConst.application.bundleVersion,
    appVersion: AppConst.application.version,
    appName: AppConst.application.name,
  };
};
export { getAppInfo };
