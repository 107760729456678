import AppConst from '../../../constants/app.constant';

const getUserName = () => {
  const localStorageKeys = Object.keys(localStorage);
  let username = null;
  if (localStorageKeys) {
    localStorageKeys.forEach((item) => {
      if (item.match(new RegExp(AppConst.usernameKeyCognito))) {
        username = localStorage.getItem(item);
      }
    });
  }
  return username;
};

export default getUserName;
