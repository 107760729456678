<template>
  <div
    ref="parentRef"
    class="scale-to-fit-parent"
  >
    <div class="scale-to-fit-child">
      <slot
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import utilityService from '../../services/utility.service';
import AppConst from '../../constants/app.constant';

const width = ref(0);
const height = ref(0);
const parentRef = ref(null);
const resizeObserver = ref(null);

const applyWidthHeight = () => {
  const footerHeight = utilityService.getFooterHeight(true)
    + utilityService.iPhoneXFooterExtraOffset();
  width.value = window.innerWidth
    - (utilityService.is_small_device() ? 0 : parseFloat(AppConst.sideMenuWidth));
  height.value = window.innerHeight - utilityService.getHeaderHeight() - footerHeight;
};

onMounted(() => {
  resizeObserver.value = new ResizeObserver(applyWidthHeight);
  resizeObserver.value.observe(parentRef.value);
  applyWidthHeight();
});

onUnmounted(() => {
  resizeObserver.value.disconnect();
});
</script>

<style scoped>
.scale-to-fit-parent {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
}

.scale-to-fit-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
