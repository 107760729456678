import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  SUBSCRIPTION_ACTIONS_ENUM,
} from '../../store/modules/subscription/subscription.enum';

const getPaypalSubscriptionDetailsFromStore = () => store.state.subscription.paypalSubscription;

/**
 *
 * @param {String} subscriptionId
 * @returns
 */
const getPaypalSubscriptionDetails = async (subscriptionId, isForT4 = false) => {
  await store.dispatch(
    `${STORE_NAMESPACE.SUBSCRIPTION}/${SUBSCRIPTION_ACTIONS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION}`,
    {
      subscriptionId,
      isForT4,
    },
  );
  return getPaypalSubscriptionDetailsFromStore();
};

export {
  getPaypalSubscriptionDetails,
  getPaypalSubscriptionDetailsFromStore,
};
