<template>
  <div>
    <ion-header v-if="showHeader">
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button
            class="back-button"
            fill="solid"
            color="secondary"
            @click="onGoBackToAccount()"
          >
            Account
            <ion-icon
              slot="start"
              mode="ios"
              :icon="arrowBack"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>
          Purchase Plan
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-list>
      <ion-item>
        <ion-label>Plan</ion-label>
        <ion-label
          slot="end"
          class="right-align"
        >
          {{ productToBuy.title }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Price</ion-label>
        <ion-label
          slot="end"
          class="right-align"
        >
          {{ productToBuy.price }}
        </ion-label>
      </ion-item>
    </ion-list>
    <PaypalButton
      v-if="isPaypalProduct"
      class="paypal-single-button"
      :product-id="productToBuy.productId"
      @paypal-product-purchased="onPaypalProductPurchased"
    />
    <IapPlaceOrderButton
      v-else
      :product-to-buy="productToBuy"
      @iap-product-purchased="onIapProductPurchased"
    />
  </div>
</template>
<script>
import {
  IonHeader,
  IonList,
  IonItem,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLabel,
} from '@ionic/vue';
import {
  arrowBack,
} from 'ionicons/icons';
import PaypalButton from './PaypalSubscription/PaypalButton.vue';
import IapPlaceOrderButton from './InAppPurchase/IapPlaceOrderButton.vue';

export default {
  components: {
    IonHeader,
    IonList,
    IonItem,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonLabel,
    PaypalButton,
    IapPlaceOrderButton,
  },
  props: {
    productToBuy: {
      type: Object,
      default: null,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['go-back-to-account'],
  data() {
    return {
      arrowBack,
    };
  },
  computed: {
    isPaypalProduct() {
      return this.productToBuy.isPaypalProduct;
    },
  },
  methods: {
    onGoBackToAccount() {
      this.$emit('go-back-to-account');
    },
    onPaypalProductPurchased() {
      this.onGoBackToAccount();
    },
    onIapProductPurchased() {
      this.onGoBackToAccount();
    },
  },
};
</script>
