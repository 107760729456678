<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div
    class="ion-page"
    @click="hideSuggestion($event)"
  >
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button
            fill="solid"
            color="secondary"
            @click="closeModal(false)"
          >
            Close
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            :disabled="invalidRouteObj.isValidRoute == false"
            fill="solid"
            color="secondary"
            @click="closeModal(true)"
          >
            Apply
          </ion-button>
        </ion-buttons>
        <ion-title>Route Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="contents">
      <div
        class="selector-container"
        :class="{
          showdelete: routesObject.length > 2,
          isMobileModeSelector: modeOfSelectorIsPhone
        }"
      >
        <ion-list class="airport-selector-list">
          <div
            v-for="(item, $index) in routesObject"
            :key="$index"
          >
            <ion-item
              class="airport-selector-input"
              @click.stop="airportFocusedMobile(item, $index)"
            >
              <ion-label class="selector-label">
                <span v-if="$index == 0">Departure</span>
                <span
                  v-if="$index != 0 && $index < routesObject.length - 1"
                >Midpoint {{ $index }}</span>
                <span
                  v-if="$index == routesObject.length - 1"
                >Destination</span>
              </ion-label>
              <ion-label v-if="modeOfSelectorIsPhone">
                <label v-if="item.value">{{ item.value.toUpperCase() }}</label>
                <span v-if="!item.value"> Airport name/ICAO code</span>
              </ion-label>
              <ion-input
                v-else
                v-model.trim="item.value"
                class="uppercase"
                :readonly="modeOfSelectorIsPhone"
                debounce="300"
                placeholder="Airport name/ICAO code"
                @ionChange="changingAirport(item)"
                @click.stop="airportFocused(item, $index)"
              />
              <ion-icon
                v-if="$index == 0 || $index == routesObject.length - 1"
                v-show="item.valid == false"
                slot="end"
                class="invalid-value"
                :icon="closeCircle"
              />
              <ion-icon
                v-if="$index == 0 || $index == routesObject.length - 1"
                v-show="item.valid == true"
                slot="end"
                class="valid-value"
                :icon="checkmarkCircle"
              />

              <ion-icon
                v-if="$index > 0 && $index < routesObject.length - 1"
                v-show="
                  item.valid == false && item.value && item.value.length > 0
                "
                slot="end"
                class="invalid-value"
                :icon="closeCircle"
              />
              <ion-icon
                v-if="$index > 0 && $index < routesObject.length - 1"
                v-show="
                  item.valid == true && item.value && item.value.length > 0
                "
                slot="end"
                class="valid-value"
                :icon="checkmarkCircle"
              />

              <ion-icon
                v-show="item.value && item.value.length"
                slot="end"
                :icon="trash"
                @click.stop="removeRouteItem(item)"
              />
              <!-- There are START slot icons -->
              <ion-icon
                v-if="$index == 0"
                slot="start"
                class="routemainicon starting"
                :icon="location"
              />
              <ion-icon
                v-else-if="$index == routesObject.length - 1"
                slot="start"
                class="routemainicon ending"
                :icon="location"
              />
              <ion-icon
                v-else
                slot="start"
                class="routemainicon"
                :icon="radioButtonOn"
              />
            </ion-item>
            <airport-filtered-results
              v-if="focusedOn.idx === $index"
              :search-str="focusedOn.value"
              :filtered-results="filteredResults"
              @selectedAirportItem="selectedItem"
            />
          </div>
        </ion-list>
      </div>
      <div
        v-if="!invalidRouteObj.isValidRoute"
        class="alert alert-danger shake"
      >
        <div v-if="invalidRouteObj.bnMessage == 1">
          <p>
            <strong>Departure -> Midpoint 1</strong> distance must be less than
            <strong>Departure -> Midpoint 2</strong> distance
          </p>
          <p>
            <strong>Departure -> Midpoint 2</strong> distance must be less than
            <strong>Departure -> Destination</strong> distance
          </p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 2">
          <p>
            <strong>Departure -> Midpoint 1</strong> distance must be less than
            <strong>Departure -> Midpoint 2</strong> distance
          </p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 3">
          <p>
            <strong>Departure -> Midpoint 2</strong> distance must be less than
            <strong>Departure -> Destination</strong> distance
          </p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 4">
          <p>
            <strong>Departure -> Midpoint 1</strong> distance must be less than
            <strong>Departure -> Destination</strong> distance
          </p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 5">
          <p>
            <strong>Departure -> Midpoint 2</strong> distance must be less than
            <strong>Departure -> Destination</strong> distance
          </p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 6">
          <p><strong>Departure</strong> is a required field</p>
          <p><strong>Destination</strong> is a required field</p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 7">
          <p><strong>Departure</strong> is a required field</p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 8">
          <p><strong>Destination</strong> is a required field</p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 9">
          <p><strong>Midpoint 1</strong> is a not valid airport</p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 10">
          <p><strong>Midpoint 2</strong> is a not valid airport</p>
        </div>
        <div v-if="invalidRouteObj.bnMessage == 11">
          <p><strong>Airport</strong> is a required field</p>
        </div>
        <div class="multipleMessages">
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(1) > -1">
            <p>
              <strong>Departure</strong> and <strong>Destination</strong> can
              not be same
            </p>
          </div>
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(2) > -1">
            <p>
              <strong>Departure</strong> and <strong>Midpoint 1</strong> can not
              be same
            </p>
          </div>
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(3) > -1">
            <p>
              <strong>Departure</strong> and <strong>Midpoint 2</strong> can not
              be same
            </p>
          </div>
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(4) > -1">
            <p>
              <strong>Midpoint 1</strong> and <strong>Midpoint 2</strong> can
              not be same
            </p>
          </div>
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(5) > -1">
            <p>
              <strong>Midpoint 1</strong> and <strong>Destination</strong> can
              not be same
            </p>
          </div>
          <div v-if="invalidRouteObj.dirtyReasons.indexOf(6) > -1">
            <p>
              <strong>Midpoint 2</strong> and <strong>Destination</strong> can
              not be same
            </p>
          </div>
        </div>
      </div>

      <div class="align-center">
        <ion-button
          fill="solid"
          color="secondary"
          @click="swapAirports()"
        >
          <span class="pl-1">Reverse Route</span>
          <font-awesome-icon
            slot="start"
            icon="exchange-alt"
            class="exchange-alt roate90"
          />
        </ion-button>
      </div>
      <div v-if="viewName === 'app.routeprofile'">
        <ion-radio-group
          v-model="showHeadWindOnly"
        >
          <div class="custom-divider">
            Winds Aloft
          </div>
          <ion-item>
            <ion-label>Headwinds/Tailwinds</ion-label>
            <ion-radio
              slot="end"
              :value="true"
            />
          </ion-item>
          <ion-item>
            <ion-label>Winds Barbs (North Up)</ion-label>
            <ion-radio
              slot="end"
              :value="false"
            />
          </ion-item>
        </ion-radio-group>
        <ion-radio-group
          v-model="maximumAltitudeAllowed"
        >
          <div class="custom-divider">
            Maximum Altitude
          </div>
          <ion-item
            v-for="option in altitudeOptions"
            :key="option.feet"
          >
            <ion-label>
              {{ option.text }}
            </ion-label>
            <ion-radio
              slot="end"
              :value="option.feet"
            />
          </ion-item>
        </ion-radio-group>
      </div>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonLabel,
  IonIcon,
  IonList,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonInput,
  modalController,
} from '@ionic/vue';
import {
  closeCircle,
  radioButtonOn,
  checkmarkCircle,
  location,
  time,
  trash,
} from 'ionicons/icons';
import ChartConst from '../constants/chart.constant';
import AirportSelector from './AirportSelector.vue';
import AirportFilteredResults from './AirportFilteredResults.vue';
import utilityService from '../services/utility.service';
import searchHistoryService from '../services/searchHistory.service';
import applicationDataSource from '../datasource/applicationdata.datasource';
import capacitorService from '../services/capacitor.service';
import * as AltitudeOptionsConst from '../constants/altitude.constant';

export default {
  name: 'RouteForm',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonButton,
    IonItem,
    IonList,
    IonButtons,
    IonRadio,
    IonRadioGroup,
    IonInput,
    AirportFilteredResults,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      closeCircle,
      checkmarkCircle,
      radioButtonOn,
      location,
      time,
      trash,
      start: null,
      mid1: null,
      mid2: null,
      viewName: '',
      end: null,
      maximumAltitudeAllowed: '',
      invalidRouteObj: {
        isValidRoute: true,
        dirtyReasons: [],
      },
      modeOfSelectorIsPhone: utilityService.isModalFullScreen(),
      isIos: capacitorService.isIos,
      showRecentSearch: false,
      showHeadWindOnly: true,
      productsList: [
        ChartConst.products.twelvehours,
        ChartConst.products.lamp,
        ChartConst.products.mav,
      ],
      altitudeOptions: AltitudeOptionsConst.routeProfile,
      filteredOptionsStart: [],
      filteredOptionsMid1: [],
      filteredOptionsMid2: [],
      filteredOptionsEnd: [],
      focusedOn: {},
      showingSelectorModal: false,
      filteredResults: [],
      suggestions: applicationDataSource.airportsListing,
      routesObject: [
        { value: '', valid: true },
        { value: '', valid: true },
        { value: '', valid: true },
        { value: '', valid: true },
      ],
    };
  },
  mounted() {
    const vm = this;
    vm.viewName = vm.settings.viewName;
    searchHistoryService.loadAirportHistorySearch();
    vm.start = vm.settings.start;
    vm.end = vm.settings.end;
    vm.mid1 = vm.settings.mid1;
    vm.mid2 = vm.settings.mid2;
    // vm.positionDots();
    vm.populateRouteObj(
      vm.settings.start,
      vm.settings.mid1,
      vm.settings.mid2,
      vm.settings.end,
    );
    vm.showHeadWindOnly = vm.settings.showHeadWindOnly;
    vm.maximumAltitudeAllowed = vm.settings.maximumAltitudeAllowed;
    vm.watchAirportChanges();
  },
  methods: {
    openAirportSelector() {
      const vm = this;
      if (vm.showingSelectorModal) {
        return;
      }
      vm.showingSelectorModal = true;
      modalController
        .create({
          component: AirportSelector,
          backdropDismiss: false,
          componentProps: {
            selectorInput: vm.focusedOn.value,
          },
        })
        .then((modalElement) => {
          modalElement.present();
          modalElement.onDidDismiss().then((res) => {
            const formData = res.data;
            if (formData && formData.icao_id) {
              vm.focusedOn.value = formData.icao_id;
            }
            vm.filteredResults = [];
            vm.changingAirport(vm.focusedOn);
          });
          window.setTimeout(() => {
            vm.showingSelectorModal = false;
          }, 500);
        });
    },
    airportFocusedMobile(item, idx) {
      this.focusedOn = item;
      this.focusedOn.idx = idx;
      if (this.modeOfSelectorIsPhone) {
        this.openAirportSelector();
      }
    },
    airportFocused(item, idx) {
      const vm = this;
      this.focusedOn = item;
      this.focusedOn.idx = idx;
      if (!vm.modeOfSelectorIsPhone) {
        vm.showRecentSearch = true;
        vm.filteredResults = searchHistoryService.airportHistorySearches.slice(
          0,
          10,
        );
      }
    },
    hideSuggestion(e) {
      const vm = this;
      const isChild = this.$el.contains(e.target);
      if (isChild && e.target.tagName === 'ION-INPUT') {
        return;
      }
      vm.filteredResults = [];
    },
    changingAirport(item) {
      const vm = this;
      // item.value = item.value
      //     ? item.value.toUpperCase()
      //     : "";
      if (!item.value || item.value.length < 2 || !vm.focusedOn.value) {
        vm.watchAirportChanges();
        return;
      }
      const filteredData = vm.suggestions.filter((option) => {
        if (!option.icao_id) {
          return false;
        }
        return (
          option.icao_id.toLowerCase().indexOf(item.value.toLowerCase()) > -1
          || option.faa_facility_name
            .toLowerCase()
            .indexOf(item.value.toLowerCase()) > -1
        );
      });
      if (item.value.length === 4) {
        const matchedResult = utilityService.getMatchedAirportObject(
          item.value.toUpperCase(),
        );
        if (matchedResult) {
          item.valid = true;
        }
      } else {
        item.valid = false;
      }
      vm.filteredResults = filteredData;
      vm.watchAirportChanges();
    },
    removeRouteItem(item) {
      const vm = this;
      item.value = '';
      vm.watchAirportChanges();
    },
    selectedItem(item) {
      const vm = this;
      vm.focusedOn.value = item.icao_id;
      vm.focusedOn.valid = true;
      vm.filteredResults = [];
      vm.focusedOn = {};
      vm.watchAirportChanges();
    },
    closeModal(isSave) {
      const vm = this;
      let returnData = false;
      const formSetting = {};
      if (isSave) {
        if (vm.routesObject[0].value) {
          formSetting.start = vm.routesObject[0].value.toUpperCase();
        }
        if (vm.routesObject[1].value) {
          formSetting.mid1 = vm.routesObject[1].value.toUpperCase();
        }
        if (vm.routesObject[2].value) {
          formSetting.mid2 = vm.routesObject[2].value.toUpperCase();
        }
        if (vm.routesObject[3].value) {
          formSetting.end = vm.routesObject[3].value.toUpperCase();
        }
        formSetting.maximumAltitudeAllowed = parseInt(
          vm.maximumAltitudeAllowed,
          10,
        );
        if (!formSetting.maximumAltitudeAllowed) {
          formSetting.maximumAltitudeAllowed = AltitudeOptionsConst.MSL12K.feet;
        }
        formSetting.showHeadWindOnly = vm.showHeadWindOnly;
        returnData = formSetting;
      }
      modalController.dismiss(returnData);
    },
    swapItems(arr, indexA, indexB) {
      const temp = arr[indexA];
      arr[indexA] = arr[indexB];
      arr[indexB] = temp;
    },
    swapAirports() {
      const vm = this;
      switch (vm.routesObject.length) {
        case 2:
          vm.swapItems(vm.routesObject, 0, 1);
          break;
        case 3:
          vm.swapItems(vm.routesObject, 0, 2);
          break;
        case 4:
          vm.swapItems(vm.routesObject, 0, 3);
          vm.swapItems(vm.routesObject, 1, 2);
          break;
        default:
      }
      vm.watchAirportChanges();
    },
    highlight(airport) {
      const vm = this;
      const text = `${airport.icao_id} ${airport.faa_facility_name}`;
      if (!vm.focusedOn.value) {
        return text;
      }
      vm.focusedOn.value = utilityService.sanitizeSearchString(
        vm.focusedOn.value,
      );
      vm.focusedOn.value = vm.focusedOn.value.replace(/\[/g, '');
      return text.replace(
        new RegExp(vm.focusedOn.value, 'gi'),
        '<span class="boldedText">$&</span>',
      );
    },
    reEvaluateRouteValidity() {
      const vm = this;
      /// 0:start, 1: mid1, 2: mid2, 3: end
      switch (vm.invalidRouteObj.bnMessage) {
        case 1:
          vm.routesObject[1].valid = false;
          vm.routesObject[2].valid = false;
          break;
        case 2:
        case 4:
          vm.routesObject[1].valid = false;
          break;
        case 3:
        case 5:
          vm.routesObject[2].valid = false;
          break;
        case 6:
          vm.routesObject[0].valid = false;
          vm.routesObject[3].valid = false;
          break;
        case 7:
          vm.routesObject[0].valid = false;
          break;
        case 8:
          vm.routesObject[3].valid = false;
          break;
        case 9:
          vm.routesObject[1].valid = false;
          break;
        case 10:
          vm.routesObject[2].valid = false;
          break;
        default:
      }
      if (vm.invalidRouteObj.isValidRoute === false) {
        if (vm.invalidRouteObj.dirtyReasons.indexOf(1) > -1) {
          vm.routesObject[3].valid = false;
        }
        if (vm.invalidRouteObj.dirtyReasons.indexOf(2) > -1) {
          vm.routesObject[1].valid = false;
        }
        if (vm.invalidRouteObj.dirtyReasons.indexOf(3) > -1) {
          vm.routesObject[2].valid = false;
        }
        if (vm.invalidRouteObj.dirtyReasons.indexOf(4) > -1) {
          vm.routesObject[2].valid = false;
        }
        if (vm.invalidRouteObj.dirtyReasons.indexOf(5) > -1) {
          vm.routesObject[3].valid = false;
        }
        if (vm.invalidRouteObj.dirtyReasons.indexOf(6) > -1) {
          vm.routesObject[3].valid = false;
        }
      }
    },
    watchAirportChanges() {
      const vm = this;
      vm.routesObject[0].valid = true;
      vm.routesObject[1].valid = true;
      vm.routesObject[2].valid = true;
      vm.routesObject[3].valid = true;
      const start = vm.routesObject[0].value;
      const end = vm.routesObject[vm.routesObject.length - 1].value;
      let mid1;
      let mid2;
      if (vm.routesObject.length === 4) {
        mid1 = vm.routesObject[1].value;
        mid2 = vm.routesObject[2].value;
      } else if (vm.routesObject.length === 3) {
        mid1 = vm.routesObject[1].value;
      }
      vm.invalidRouteObj = utilityService.validateRoute(
        {
          selectedDepartureAirport: { icao_id: start },
          selectedMid1Airport: { icao_id: mid1 },
          selectedMid2Airport: { icao_id: mid2 },
          selectedDestinationAirport: { icao_id: end },
        },
        {},
        false,
      );
      vm.reEvaluateRouteValidity();
    },
    populateRouteObj(start, mid1, mid2, end) {
      const vm = this;
      vm.routesObject[0].value = start;
      vm.routesObject[1].value = mid1;
      vm.routesObject[2].value = mid2;
      vm.routesObject[3].value = end;
    },
  },
};
</script>
<style scoped>
.routedoticon {
  position: absolute;
  top: 23px;
  padding-left: 5px;
}
.plt-capacitor.plt-ios .routedoticon {
  top: 26.5px;
}
.deleteicon {
  /* display: none; */
  cursor: pointer;
}
.addwaypoint {
  margin-left: 10px;
}
.moredot-container {
  position: absolute;
  color: #fff;
  z-index: 1;
  /* top: 38px; */
  left: 23px;
}
.moredot-container i {
  display: block;
}
.moredot-container i.middledot {
  display: block;
  /* padding: 30px 0px; */
}
ion-icon.moredots.pushdown {
  top: 28px;
}
ion-icon.moredots.pushup {
  top: -16px;
}
.selector-container ion-icon {
  font-size: 20px;
}
</style>
