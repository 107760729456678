<template>
  <div class="app-metar">
    <div
      v-if="data && data.error"
      v-html="data.error"
    />
    <div
      v-if="data && !data.error"
      class="airport-tab-content"
    >
      <h2>METARs from the past hour</h2>
      <div>
        <div
          v-for="(item, $index) in data"
          :key="$index"
          class="metar-record"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as LayerUpdateTimeService from '../../services/layerUpdateTime/layerUpdateTime.service';

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        LayerUpdateTimeService.setCurrentScreenRenderTime();
      },
    },
  },
};
</script>
<style scoped>
h2 {
  font-weight: bold;
}
pre,
.metar-record{
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace !important;
    margin-bottom: 10px;
}
</style>
