<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div>
    <div
      id="gridviewNoDataMessage"
      class="no-data"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <span />
    </div>
    <div
      id="gridview-canvas-images"
      class="gridview-canvas"
    />
    <div
      id="gridViewChartContainer"
      class="svg-container"
    />
  </div>
</template>
<script>
import GridViewChartService from './service/gridViewChart.service';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';

export default {
  name: 'GridviewChart',
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      travelRoute: null,
      fullData: null,
      softDraw: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResizeEvent);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent);
  },
  methods: {
    handleResizeEvent() {
      showAeroplane();
    },
    initDrawing() {
      GridViewChartService.chartApi.refresh(
        this.travelRoute,
        this.fullData,
        this.softDraw,
        this.settings,
      );
      hideAeroplane();
    },
    refresh(travelRoute, fullData, softDraw) {
      this.travelRoute = travelRoute;
      this.fullData = fullData;
      this.softDraw = softDraw;
      this.initDrawing();
    },
  },
};
</script>
<style scoped>
.gridview-canvas {
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  position: absolute;
}
</style>
