import AccountConst from '../../constants/account.constant';

/**
   * User is free trial if he does not following attributes
   * t1, t3, fs, p1,
   * And user has following attribute
   * trialYN
*/
const getIsFreeTrialUser = (userDetails) => {
  if (userDetails
    && !userDetails[AccountConst.customAttribute.fs]
    && !userDetails[AccountConst.customAttribute.t1]
    && !userDetails[AccountConst.customAttribute.t3]
    && !userDetails[AccountConst.customAttribute.p1]
    && (userDetails[AccountConst.customAttribute.trialYN] === 'Y'
      || userDetails[AccountConst.customAttribute.trialYN] === 'N')) {
    return true;
  }
  return false;
};

export {
  getIsFreeTrialUser,
};
