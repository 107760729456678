<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div
    id="sliderRoute"
    class="app-slider"
    :class="{ secondarySlider: hideBottomAxis }"
  >
    <PlayPauseButton
      v-show="!hideBottomAxis"
      @playSlider="onPlaySlider"
      @pauseSlider="onPauseSlider"
    />
    <div class="slider-svg-container" />
  </div>
</template>
<script>
import { play, pause } from 'ionicons/icons';
import _debounce from 'lodash/debounce';
import bnd3Service from '../../services/bnd3.service';
import capacitorService from '../../services/capacitor.service';
import utilityService from '../../services/utility.service';
import ConfigApplicationSettings from '../../config/application.config';
import AppConst from '../../constants/app.constant';
import Emitter from '../../bus';
import d3 from '../../globalexports/d3.export';
import ChartConst from '../../constants/chart.constant';
import avwxService from '../../views/Imagery/Imagery.service';
import EventConst from '../../constants/event.constant';
import PlayPauseButton from '../PlayPauseButton.vue';

export default {
  name: 'ImagerySlider',
  components: {
    PlayPauseButton,
  },
  emits: [EventConst.sliderEvent.changing],
  data() {
    return {
      play,
      pause,
      parentElement: null,
      settings: null,
      allDataForInterval: null,
      sliderMainObj: null,
      dateFormat: null,
      sliderPinned: null,
      departTime: '',
      showSlider: null,
      sliderIdxStart: 0,
      currentSliderIdx: 10000,
      isPlaying: false,
      sliderNotchRadius: 10,
      textHeightRef: 20,
      sliderInterval: null,
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  mounted() {
    const vm = this;
    vm.parentElement = d3.select(`#${vm.$el.id}`);
    vm.$nextTick(() => {
      window.addEventListener('resize', vm.handleResizeEvent);
    });
    Emitter.on(EventConst.appPaused, vm.appPausedHandle);
  },
  beforeUnmount() {
    const vm = this;
    if (vm.pauseSlider) {
      vm.pauseSlider();
    }
    console.log('removing old resize event');
    window.removeEventListener('resize', vm.handleResizeEvent);
    Emitter.off(EventConst.appPaused, vm.pauseSlider);
  },
  methods: {
    onPlaySlider() {
      this.playSlider();
    },
    onPauseSlider() {
      this.pauseSlider();
    },
    appPausedHandle() {
      if (this.pauseSlider) {
        this.pauseSlider();
      }
    },
    getSliderLabel(xVal) {
      if (
        avwxService.selectedSubtab
        && avwxService.selectedSubtab.vData
        && xVal >= 0
      ) {
        return avwxService.getImageryValidInfo(
          avwxService.selectedSubtab.vData[xVal],
        );
      }
      return '';
    },
    drawSlider() {
      const vm = this;
      vm.hideBottomAxis = utilityService.hideBottomAxis();
      let windowRefValue = window.innerWidth;
      if (vm.hideBottomAxis) {
        windowRefValue = window.innerHeight;
        d3.select('#imageryslidermobilenav').style(
          'width',
          `calc(100vh - ${utilityService.getHeaderHeight()}px)`,
        );
      } else {
        d3.select('#imageryslidermobilenav').style('width', '');
      }
      const sliderShadow = (window.innerWidth * 1) / 100;
      let buttonWidth;
      const buttonsSliderPad = 23;
      const buttonsPadding = 5;
      const buttonsPaddingBorder = 6;
      const margin = {};

      if (vm.hideBottomAxis) {
        /// this is right always in vertical mode
        margin.left = buttonsSliderPad;
        margin.right = buttonsSliderPad + utilityService.getHeaderHeight();
      } else {
        buttonWidth = 34; // document.getElementById("refbutton").clientWidth;
        margin.left = buttonsSliderPad + buttonsPaddingBorder;
        margin.right = buttonsSliderPad
          + buttonsPadding
          + buttonWidth
          + buttonsPaddingBorder;
        if (!vm.sliderPinned) {
          margin.right += sliderShadow + sliderShadow;
        }
        margin.right += utilityService.is_small_device() ? 0 : 10;
      }
      let width = windowRefValue
        - (utilityService.is_small_device()
          ? 0
          : parseFloat(AppConst.sideMenuWidth));
      if (!vm.hideBottomAxis) {
        width
          -= utilityService.getSafeAreaPadding().left
          - utilityService.getSafeAreaPadding().right;
      }
      if (!ConfigApplicationSettings.imageryViewUseSubnav) {
        // for imagery if accord view then reduce width by width of accordion menu
        width -= AppConst.imageryAccordWidth;
      }
      const height = 70;
      const range = [0, vm.sliderMainObj.length - 1];
      const step = 1; // change the step and if null, it"ll switch back to a normal slider
      let handle;
      let etdTextHandle;
      let textHeightRef;
      // append svg
      vm.parentElement.select('div.slider-svg-container svg').remove();
      const svg = vm.parentElement
        .select('.slider-svg-container')
        .append('svg')
        .style('width', `${width}px`)
        .style('height', `${height}px`);
      const slider = svg
        .append('g')
        .classed('slider', true)
        .attr('transform', `translate(${margin.left}, ${height / 2 + 0})`);
      // using clamp here to avoid slider exceeding the range limits
      const xScale = d3
        .scaleLinear()
        .domain(range)
        .range([0, width - margin.left - margin.right])
        .clamp(true);
      // array useful for step sliders
      const rangeValues = d3
        .range(range[0], range[1], step || 1)
        .concat(range[1]);
      xScale.clamp(true);

      const dragged = function (value, isDragEnd, isInitializing, isFromPlayer) {
        if (!vm || !vm.sliderMainObj || vm.sliderMainObj.length === 1) {
          return;
        }
        if (!isFromPlayer && vm.pauseSlider) {
          vm.pauseSlider();
        }
        const x = xScale.invert(value);
        let index = null;
        let midPoint;
        let cx;
        let xVal;
        let i;
        let isChangeDetected = false;
        if (step) {
          // if step has a value, compute the midpoint based on range values
          // and reposition the slider based on the mouse position
          i = 0;
          while (i < rangeValues.length - 1) {
            if (x >= rangeValues[i] && x <= rangeValues[i + 1]) {
              index = i;
              break;
            }
            i += 1;
          }
          midPoint = (rangeValues[index] + rangeValues[index + 1]) / 2;
          if (x < midPoint) {
            cx = xScale(rangeValues[index]);
            xVal = rangeValues[index];
          } else {
            cx = xScale(rangeValues[index + 1]);
            xVal = rangeValues[index + 1];
          }
        } else {
          // if step is null or 0, return the drag value as is
          cx = xScale(x);
          xVal = x.toFixed(3);
        }
        // console.log("Before", vm.currentSliderIdx);
        if (xVal !== vm.currentSliderIdx) {
          isChangeDetected = true;
        }
        vm.currentSliderIdx = xVal;
        // console.log("After", vm.currentSliderIdx);
        const ruleColor = '#1C1C1C';
        const sliderLabel = vm.getSliderLabel(xVal);
        if (!vm.hideBottomAxis) {
          etdTextHandle.text(isInitializing ? '' : sliderLabel);
        }
        // use xVal as drag value
        let flightRuleWidth = bnd3Service.getSelectionWidth(
          handle.select('.flight-ruleset-handle'),
        );
        const flightRuleHeight = bnd3Service.getSelectionHeight(
          handle.select('.flight-ruleset-handle'),
        );
        let flightEtdWidth = bnd3Service.getSelectionWidth(etdTextHandle);
        const flightEtdHeight = bnd3Service.getSelectionHeight(etdTextHandle);
        if (vm.hideBottomAxis) {
          flightEtdWidth = flightEtdHeight;
          flightRuleWidth = flightRuleHeight;
        }
        handle
          .attr('transform', `translate(${cx}, 0)`)
          .attr('stroke', ruleColor);
        handle
          .select('.flight-ruleset-handle')
          .attr(
            'transform',
            `translate(${-flightRuleWidth / 2}, ${textHeightRef
              + vm.sliderNotchRadius})`,
          );
        if (!vm.hideBottomAxis) {
          etdTextHandle.attr('transform', () => {
            let xValue = cx - flightEtdWidth / 2;
            if (xValue < 0) {
              xValue = -10;
            } else if (
              xValue + flightEtdWidth
              > xScale(vm.sliderMainObj.length - 1)
            ) {
              xValue = xScale(vm.sliderMainObj.length - 1) - flightEtdWidth;
            }
            return `translate(${xValue}, ${-(textHeightRef + 0)})`;
          });
        }
        if (!isInitializing && !isChangeDetected && !isDragEnd) {
          return;
        }
        vm.departTime = sliderLabel;
        vm.$emit(EventConst.sliderEvent.changing, {
          idx: xVal,
          cx,
          departTime: vm.sliderMainObj[xVal],
          departTimeDisplay: sliderLabel,
          sliderLabel,
          isDragEnd,
          isInitializing,
          leftMargin: margin.left,
          rightMargin: margin.right,
          sliderWidth: width,
        });
      };
      // drag behavior initialization
      const drag = d3
        .drag()
        .on('drag', (event) => {
          let xRef = event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(event.y + 56);
            }
          }
          dragged(xRef);
          // console.log("draging");
        })
        .on('end', (event) => {
          let xRef = event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(event.y + 56);
            }
          }
          dragged(xRef, true);
          etdTextHandle.text('');
        });

      // vm is the main bar with a stroke (applied through CSS)
      slider
        .append('line')
        .attr('class', 'track regular')
        .attr('x1', xScale.range()[0])
        .attr('x2', xScale.range()[1]);
      // vm is a bar (steelblue) that"s inside the main
      // "track" to make it look like a rect with a border
      // var trackInset = d3.select(slider.node().appendChild(
      // track.node().cloneNode())).attr("class", "track-inset");

      const tickWidth = 10;
      const ticks = slider
        .append('g')
        .attr('class', 'ticks')
        .attr('transform', 'translate(0, 0)')
        .selectAll('#slider-ticks')
        .data(vm.sliderMainObj)
        .enter();
      ticks
        .append('rect')
        .attr('class', 'tick')
        .attr('fill', '#fff')
        .attr('width', tickWidth)
        .attr('height', '10')
        .attr(
          'transform',
          (...args) => `translate(${xScale(args[1]) - tickWidth / 2},  ${-5})`,
        )
        .attr('rx', 5)
        .attr('ry', 5);
      // drag handle
      handle = slider.append('g');
      textHeightRef = vm.textHeightRef;
      handle
        .append('circle')
        .classed('imagery-slider-handle handle', true)
        .attr('r', vm.sliderNotchRadius);
      etdTextHandle = slider
        .append('text')
        .classed('flight-etd-handle', true)
        .text(vm.hideBottomAxis ? '' : 'dummytext');
      handle.append('text').classed('flight-ruleset-handle', true);
      // this is the bar on top of above tracks with stroke = transparent
      // and on which the drag behaviour is actually called
      // try removing above 2 tracks and play around with the CSS for this
      // track overlay, you"ll see the difference
      slider
        .append('rect')
        .attr('class', 'track track-overlay  regular')
        .attr('height', '50')
        .attr('width', xScale.range()[1] - xScale.range()[0] + 40)
        .attr('transform', 'translate(-20, -25)')
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .call(drag);
      // initial transition
      dragged(xScale(vm.sliderIdxStart), false, true);
      // PlayerControls defined here
      vm.playSlider = function () {
        vm.pauseSlider();
        let delayTimer;
        if (avwxService.selectedTab && avwxService.selectedTab.d) {
          delayTimer = avwxService.selectedTab.d;
        } else {
          delayTimer = avwxService.getStepSpeed(vm.sliderMainObj.length);
        }
        vm.sliderInterval = setInterval(() => {
          if (vm.currentSliderIdx === vm.sliderMainObj.length - 1) {
            vm.currentSliderIdx = 0;
          } else {
            vm.currentSliderIdx += 1;
          }
          dragged(xScale(vm.currentSliderIdx), true, false, true);
        }, delayTimer);
        vm.isPlaying = true;
      };
      vm.pauseSlider = function () {
        clearInterval(vm.sliderInterval);
        vm.isPlaying = false;
        etdTextHandle.text('');
      };
    },
    initDrawing() {
      const vm = this;
      if (vm.settings) {
        vm.sliderIdxStart = vm.settings.currentVisibleImage;
        vm.drawSlider();
      }
    },
    refresh(settings, allDataForInterval, sliderMainObj, dateFormat) {
      const vm = this;
      if (vm.pauseSlider) {
        vm.pauseSlider();
        vm.isPlaying = false;
      }
      vm.settings = settings;
      vm.sliderPinned = true;
      vm.allDataForInterval = allDataForInterval;
      vm.sliderMainObj = sliderMainObj;
      vm.dateFormat = dateFormat;
      if (sliderMainObj && sliderMainObj.length > 1) {
        setTimeout(() => {
          vm.initDrawing();
        });
      }
    },
    playPauseSlider() {
      const vm = this;
      if (vm.playSlider && vm.pauseSlider) {
        if (vm.isPlaying) {
          vm.pauseSlider();
        } else {
          vm.playSlider();
        }
      }
    },
    handleResizeEvent: _debounce(function () {
      this.hideBottomAxis = utilityService.hideBottomAxis();
      this.initDrawing();
    }, ChartConst.resizeDebounce),
  },
};
</script>
