<template>
  <ion-loading
    :is-open="showLoading"
    :spinner="loadingSpinner"
    :message="loadingMessageText"
  />
</template>
<script>
import { IonLoading } from '@ionic/vue';

export default {
  components: {
    IonLoading,
  },
  props: {
    loadingMessage: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hideSpinner() {
      return this.loadingMessage ? this.loadingMessage.hideSpinner : false;
    },
    message() {
      return this.loadingMessage && this.loadingMessage.message;
    },
    showLoading() {
      return this.loadingMessage;
    },
    loadingMessageText() {
      return this.message;
    },
    loadingSpinner() {
      return this.hideSpinner ? 'hide' : 'crescent';
    },
  },
};
</script>
