import WeatherConst from '../../../constants/weather.constant';
import utilityService from '../../../services/utility.service';
import { getDateFormatDay } from '../utils/getDateFormatDay';
import { getSunMoonTime } from '../utils/getSunMoonTime';
import { getWeatherIcon } from '../utils/getWeatherIcon';
import { getTemperatureValue } from '../utils/getTemperatureValue';

const getMapClickDayNightObject = (weatherData, i, settings) => {
  let dayNightObject = null;
  if (weatherData.time.startPeriodName[i]) {
    const startTime = new Date(weatherData.time.startValidTime[i]);
    dayNightObject = {
      displayKey: weatherData.time.startPeriodName[i],
      startValidTime: weatherData.time.startValidTime[i],
      startTime,
      displayDay: startTime.toLocaleString('en', { weekday: 'short' }),
      displayDate: getDateFormatDay(settings)(startTime),
      temperature: getTemperatureValue(weatherData.data.temperature[i], settings),
      pop: (weatherData.data.pop[i] !== null && weatherData.data.pop[i] >= 0)
        ? weatherData.data.pop[i]
        : WeatherConst.defaultPopIfNotAvailable,
      weather: weatherData.data.weather[i],
      weatherType: weatherData.data.iconLink[i]
        ? getWeatherIcon(weatherData.data.iconLink[i])
        : null,
      text: weatherData.data.text[i],
      hasSlider: true,
    };
    dayNightObject.sunMoonCalc = utilityService.getSunMoonCalculation(
      dayNightObject.startTime,
      settings.airportDetails.lat,
      settings.airportDetails.lng,
    );
    dayNightObject.sunrise = getSunMoonTime(settings)(dayNightObject.sunMoonCalc
      .todaysSunlightTime.sunrise);
    dayNightObject.sunset = getSunMoonTime(settings)(dayNightObject.sunMoonCalc
      .todaysSunlightTime.sunset);
  }
  return dayNightObject;
};

export { getMapClickDayNightObject };
