/**
 *
 * @param {Object} ctx
 * @param {Number} width
 * @param {Number} height
 * @param {Number} x
 * @param {Number} y
 * @param {Object} imageObject
 */
const drawImageOnCanvas = (
  ctx,
  width,
  height,
  x,
  y,
  imageObject,
) => {
  ctx.drawImage(
    imageObject,
    x - (width / 2),
    y - (height / 2),
    width,
    height,
  );
};
export default drawImageOnCanvas;
