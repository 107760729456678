<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <ion-button
    v-show="showButton"
    fill="solid"
    color="secondary"
    @click="toggleMenu"
  >
    <ion-icon :icon="menu" />
  </ion-button>
</template>
<script>
import {
  IonButton,
  IonIcon,
} from '@ionic/vue';
import { menu } from 'ionicons/icons';
import menuService from '../services/menu.service';
import { isShowingSideMenu } from '../services/utils/isShowingSideMenu';

export default {
  name: 'MenuButton',
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      menu,
      showButton: true,
    };
  },
  mounted() {
    this.handleResizeEvent();
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResizeEvent);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent);
  },
  methods: {
    toggleMenu() {
      menuService.toggleMenu();
    },
    handleResizeEvent() {
      if (isShowingSideMenu()) {
        this.showButton = false;
      } else {
        this.showButton = true;
      }
    },
  },
};
</script>
