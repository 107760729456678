import WeatherConst from '../../../constants/weather.constant';

const getPrecipitation = (weatherItem) => {
  const reg = /\d+/g;
  const precipitaion = weatherItem.icon.match(reg);
  if (precipitaion) {
    return Math.max.apply(null, precipitaion);
  }
  return WeatherConst.defaultPopIfNotAvailable;
};

export { getPrecipitation };
