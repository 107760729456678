import {
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';

/**
 *
 * @param {String} Name
 * @param {*} Value
 * @returns CognitoUserAttribute
 */
export const getCognitoUserAttribute = (Name, Value) => new CognitoUserAttribute({
  Name,
  Value,
});
