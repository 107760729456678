import ConfigDeviceSettings from '../../../config/device.config';
import { userAuthFailed } from '../../auth/userAuthFailed';
import { getCurrentUser } from './getCurrentUser';
import { initCognitoSync } from '../cognitoSync/initCognitoSync';
import { reLoginAws } from '../login/reLoginAws';
import { getIsConnectedToInternet } from '../../connectivity/getIsConnectedToInternet';

const successAuthOnResume = (cognitoUser) => {
  cognitoUser.getSession((err1, session) => {
    initCognitoSync(session.idToken.jwtToken);
  });
};

const resumeAppAuthCheck = async () => {
  if (ConfigDeviceSettings.settings.enableLoginFlow && getIsConnectedToInternet()) {
    const cognitoUser = await getCurrentUser();
    if (cognitoUser !== null) {
      successAuthOnResume(cognitoUser);
    } else {
      const resRedo = await reLoginAws();
      if (resRedo !== undefined && resRedo !== null && !resRedo.message) {
        successAuthOnResume(await getCurrentUser());
      } else {
        userAuthFailed();
      }
    }
  }
};

export { resumeAppAuthCheck };
