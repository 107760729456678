import AccountConst from '../../constants/account.constant';
import { getIsUserT2Expired } from './getIsUserT2Expired';

const getIsActiveApplePayUser = (user) => {
  if (user) {
    const t2Value = user[AccountConst.customAttribute.t2];
    if (t2Value && !getIsUserT2Expired(t2Value)) {
      return true;
    }
  }
  return false;
};

export { getIsActiveApplePayUser };
