<template>
  <ion-menu
    content-id="main-content"
    type="reveal"
    :swipe-gesture="swipeGesture"
    @ionWillOpen="menuWillOpenHandle()"
    @ionDidClose="menuDidCloseHandle"
  >
    <ion-header class="menu-header">
      <ion-toolbar color="dark">
        <img
          src="static/img/horizontal-logo.svg"
          class="main-menu-logo"
        >
      </ion-toolbar>
    </ion-header>
    <ion-content class="bn-menu ">
      <ion-list class="bn-menu-list menu-items">
        <ion-item @click="showFavorite = !showFavorite">
          <ion-icon
            slot="start"
            :icon="star"
          />
          <ion-label>
            Favorites
          </ion-label>
        </ion-item>
        <favorites-component
          v-show="showFavorite"
          @favItemClicked="onCollabseFav"
        />
        <MenuAirport
          :show-favorite="showFavorite"
          @goToView="onGoToView"
          @collapseFav="onCollabseFav"
        />
        <ion-menu-toggle auto-hide="false">
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.routeprofile' }"
          >
            <ion-item @click="goToView('routeprofile')">
              <font-awesome-icon
                slot="start"
                icon="route"
              />
              <ion-label>
                Route Profile
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.gridview' }"
          >
            <ion-item @click="goToView('gridview')">
              <font-awesome-icon
                slot="start"
                icon="hashtag"
              />
              <ion-label>
                Grid View
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.mapview' }"
          >
            <ion-item @click="goToView('mapview')">
              <ion-icon
                slot="start"
                :icon="globe"
              />
              <ion-label>
                Map View
              </ion-label>
            </ion-item>
          </div>
          <div
            v-if="deviceSettings.enableLeafletMap"
            class="menu-item"
            :class="{ active: activeItem == 'app.leafletmapview' }"
          >
            <ion-item @click="goToView('leafletmapview')">
              <ion-icon
                slot="start"
                mode="md"
                :icon="logoBuffer"
              />
              <ion-label>
                Leaflet map View
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.imagery' }"
          >
            <ion-item @click="goToView('imagery')">
              <ion-icon
                slot="start"
                :icon="images"
              />
              <ion-label>
                Imagery
              </ion-label>
            </ion-item>
          </div>
          <div
            v-if="deviceSettings.enablePersonalMinimums"
            class="menu-item"
            :class="{ active: activeItem == 'app.personalminimums' }"
          >
            <ion-item @click="goToView('personalminimums')">
              <ion-icon
                slot="start"
                :icon="optionsOutline"
              />
              <ion-label>
                Personal Minimums
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.settings' }"
          >
            <ion-item @click="goToView('settings')">
              <ion-icon
                slot="start"
                :icon="settings"
              />
              <ion-label>
                Preferences
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.account' }"
          >
            <ion-item
              @click="goToView('account')"
            >
              <font-awesome-icon
                slot="start"
                icon="user"
              />
              <ion-label>
                Account
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.aboutus' }"
          >
            <ion-item @click="goToView('aboutus')">
              <ion-icon
                slot="start"
                :icon="informationCircle"
              />
              <ion-label>
                Support
              </ion-label>
            </ion-item>
          </div>
          <div
            class="menu-item"
            :class="{ active: activeItem == 'app.developer' }"
          >
            <ion-item
              v-show="enableDeveloperMode"
              @click="goToView('developer')"
            >
              <ion-icon
                slot="start"
                :icon="bug"
              />
              <ion-label>
                Developer
              </ion-label>
            </ion-item>
          </div>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>
import {
  IonMenu,
  IonContent,
  IonHeader,
  IonList,
  IonItem,
  IonToolbar,
  IonIcon,
  IonLabel,
  IonMenuToggle,
} from '@ionic/vue';
import {
  star,
  calendar,
  globe,
  logoBuffer,
  images,
  videocam,
  informationCircle,
  bug,
  settings,
  optionsOutline,
} from 'ionicons/icons';
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { MENU_GETTERS_ENUM } from '../store/modules/menu/menu.enum';
import FavoritesComponent from './Favorites/Favorites.vue';
import MenuAirport from './MenuAirport.vue';
import SideMenuService from '../services/menu.service';
import { routerReplaceInterface } from '../services/router.interface';

export default {
  components: {
    IonMenuToggle,
    IonMenu,
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonList,
    IonItem,
    FavoritesComponent,
    MenuAirport,
  },
  props: {
    activeItem: {
      type: String,
      default: '',
    },
    deviceSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      star,
      calendar,
      globe,
      logoBuffer,
      images,
      videocam,
      informationCircle,
      bug,
      settings,
      optionsOutline,
      showFavorite: false,
      lockMenuItem: false,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.MENU, {
      swipeGesture: MENU_GETTERS_ENUM.GET_SWIPE_GESTURE,
    }),
    enableDeveloperMode() {
      return this.deviceSettings.enableDeveloperMode;
    },
  },
  methods: {
    menuDidCloseHandle() {
      SideMenuService.menuClosed();
    },
    menuWillOpenHandle() {
      SideMenuService.menuOpened();
    },
    onCollabseFav() {
      this.showFavorite = false;
    },
    navigateToRoute(view) {
      SideMenuService.closeMenu();
      const currentRouteName = this.$router.currentRoute.value.name;
      if (currentRouteName && currentRouteName === `app.${view}`) {
        return;
      }
      routerReplaceInterface(`/app/${view}`);
    },
    goToView(view) {
      if (this.lockMenuItem === false) {
        this.lockMenuItem = true;
        setTimeout(() => {
          this.lockMenuItem = false;
        }, 500);
        this.navigateToRoute(view);
      }
    },
    onGoToView(view) {
      this.navigateToRoute(view);
    },
  },
};
</script>
