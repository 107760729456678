const AEROPLANE_LOADER_ACTIONS_ENUM = {
  SET_SHOW_AEROPLANE_TRUE: 'SET_SHOW_AEROPLANE_TRUE',
  SET_SHOW_AEROPLANE_FALSE: 'SET_SHOW_AEROPLANE_FALSE',
};

Object.freeze(AEROPLANE_LOADER_ACTIONS_ENUM);

const AEROPLANE_LOADER_GETTERS_ENUM = {
  GET_SHOW_AEROPLANE: 'GET_SHOW_AEROPLANE',
};

Object.freeze(AEROPLANE_LOADER_GETTERS_ENUM);

const AEROPLANE_LOADER_MUTATIONS_ENUM = {
  SET_SHOW_AEROPLANE_TRUE: 'SET_SHOW_AEROPLANE_TRUE',
  SET_SHOW_AEROPLANE_FALSE: 'SET_SHOW_AEROPLANE_FALSE',
};

Object.freeze(AEROPLANE_LOADER_MUTATIONS_ENUM);

export {
  AEROPLANE_LOADER_ACTIONS_ENUM,
  AEROPLANE_LOADER_MUTATIONS_ENUM,
  AEROPLANE_LOADER_GETTERS_ENUM,
};
