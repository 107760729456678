import {
  writeText,
} from '../../../../utils/canvas';

const drawStormCellTop = (projection, ctx, storm, extraPadding) => {
  if (storm && storm.extra) {
    ctx.lineWidth = 4;
    const { extra } = storm;
    const cellTop25Value = (Math.floor(extra.ct25 / 100)).toString().padStart(3, '0');
    const latLongCord = [storm.fcst[0][1], storm.fcst[0][0]];
    const latLongProj = projection(latLongCord);
    const baseVerticalPad = 28;
    writeText(ctx, cellTop25Value, latLongProj[0] - 10, latLongProj[1] + (
      extraPadding ? baseVerticalPad : baseVerticalPad - 2));
    ctx.lineWidth = 1;
  }
};

export { drawStormCellTop };
