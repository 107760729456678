import AccountConst from '../../../constants/account.constant';
import { getPaypalSubscriptionDetails } from '../../paypal/getPaypalSubscriptionDetails';
import { getIsUserPaypalSubscriptionActive } from '../../user/getIsUserPaypalSubscriptionActive';
import { setPayPalOrderRecord } from './setAttributes/setPayPalOrderRecord';
import { processSubscriptionByT4 } from './processSubscriptionByT4';

const processUserAttributes = async (attributeList) => {
  // const user = secureLsService.getInfo('temp');
  attributeList[AccountConst.customAttribute.appinstalldate] = new Date(
    parseInt(attributeList[AccountConst.customAttribute.appinstalldate], 10),
  );
  // debugger
  // attributeList[AccountConst.customAttribute.t3] = JSON.stringify({
  //   bt: 'BA-8DG086220E032963D',
  //   oi: '7WS70078B13627242',
  //   si: 'I-1JF4VMHWKWCB',
  //   lp: '2021-10-24T11:06:11Z',
  //   nb: '2021-11-24T10:00:00Z',
  //   pi: 'P-47V73064BS590392TMCSW3FA',
  // });
  if (
    !attributeList[AccountConst.customAttribute.t3]
    && attributeList[AccountConst.customAttribute.t4]
  ) {
    const t3DataForT4 = await processSubscriptionByT4(
      attributeList[AccountConst.customAttribute.t4],
    );
    if (t3DataForT4) {
      await setPayPalOrderRecord(t3DataForT4);
      attributeList[AccountConst.customAttribute.t3] = JSON.stringify(t3DataForT4);
    }
  }

  if (attributeList[AccountConst.customAttribute.t3]) {
    const t3Object = JSON.parse(attributeList[AccountConst.customAttribute.t3]);
    const subscriptionDetail = await getPaypalSubscriptionDetails(t3Object.si);
    if (subscriptionDetail) {
      const { billing_info } = subscriptionDetail;
      const isActiveSubscription = getIsUserPaypalSubscriptionActive(subscriptionDetail);
      if (
        isActiveSubscription
        && billing_info
        && (t3Object.lp !== billing_info.last_payment.time
          || t3Object.nb !== billing_info.next_billing_time)
      ) {
        // update values of lp and nb if any change detected
        t3Object.lp = billing_info.last_payment.time;
        t3Object.nb = billing_info.next_billing_time;
        setPayPalOrderRecord(t3Object);
      }
    }
    attributeList.t3Object = t3Object;
  }
  // const dataTemp = {
  //   bt: 'BA-0M718502KW488815S',
  //   oi: '4MG9560189472352E',
  //   si: 'I-N7B2MK6AVNS6',
  //   lp: '2021-06-02T02:06:55Z',
  //   nb: '2022-06-01T10:00:00Z',
  //   pi: 'P-630441013F946584DMCSW5BQ',
  //   np: '2022-06-01T10:00:00Z',
  // };
  // setPayPalOrderRecord(dataTemp);
  return attributeList;
};

export { processUserAttributes };
