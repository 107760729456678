<template>
  <slot
    v-if="loaded"
    :image-natural-height="naturalHeight"
    :image-natural-width="naturalWidth"
  />
</template>

<script setup>
import { ref, watchEffect } from 'vue';

const props = defineProps({
  url: { type: String, required: true },
});

const naturalHeight = ref(0);
const naturalWidth = ref(0);
const loaded = ref(false);

watchEffect(() => {
  const image = new Image();
  image.onload = () => {
    loaded.value = true;
    naturalHeight.value = image.naturalHeight;
    naturalWidth.value = image.naturalWidth;
  };
  image.src = props.url;
});
</script>
