<template>
  <div>
    <div
      v-if="imageryPlaceholderText === imageryNoLongerValidKey"
    >
      This image is not available at this time. Please contact
      <a
        href
        @click="sendSupportEmail"
      >support@weatherspork.com</a> to have it added to the image
      library.
    </div>
    <div v-else>
      {{ imageryPlaceholderText }}
    </div>
  </div>
</template>
<script>
import AppConst from '../constants/app.constant';

export default {
  props: {
    imageryPlaceholderText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageryNoLongerValidKey: AppConst.imageryNoLongerValid,
    };
  },
  methods: {
    sendSupportEmail(event) {
      event.preventDefault();
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
  },
};
</script>
