import ChartConst from '../../constants/chart.constant';
import ConfigDeviceSettings from '../../config/device.config';
import bnChartDataProcessService from '../chartDataProcess.service';

const formatValue = (value) => bnChartDataProcessService.parseYValueInterface(value);

/**
 * 1: < 5
 * 2: 5 - 9
 * 3: 10 - 19
 * 4: 20 - 29
 * 5: 30 - 50
 * 6: 51 - 60
 * 7: > 60
 * @param {number} vis
 * @returns
 */
const getVisValueBasedOnRange = (vis) => {
  if (vis < 5) {
    return 1;
  }
  if (vis <= 9) {
    return 2;
  }
  if (vis <= 19) {
    return 3;
  }
  if (vis <= 29) {
    return 4;
  }
  if (vis <= 50) {
    return 5;
  }
  if (vis <= 60) {
    return 6;
  }
  return 7;
};

const getVisAndCigMapped = (cig, vis) => {
  const res = {};
  const versionToUse = ConfigDeviceSettings.settings.apiVersionKey;
  if (versionToUse === 3) {
    res.cig_mapped = formatValue(parseInt(cig, 10) * 100);
    const visCalculatedValue = formatValue(parseInt(vis, 10) / 10);
    res.vis_mapped = visCalculatedValue;
    res.vis = vis > 0 ? getVisValueBasedOnRange(vis) : null;
  } else {
    res.cig_mapped = formatValue(ChartConst.mavDefsMap.cig[cig]);
    res.vis_mapped = formatValue(ChartConst.mavDefsMap.vis[vis]);
  }
  return res;
};

export { getVisAndCigMapped };
