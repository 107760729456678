export default {
  api: {
    allPaypalPlans: 'api/billing/plans',
    subscriptionDetail: 'api/billing/subscriptions/{SUBSCRIPTION_ID}',
    cancelSubscription: 'api/billing/subscriptions/{SUBSCRIPTION_ID}/cancel',
  },
  alert: {
    cancelPaypalMembership: {
      messageBeforeYouGo: 'Hey before you go can we talk about any feedback or improvements you might like to see in our app?',
      message: 'Are you sure you want to cancel automatic renewal of your membership? You will be able to use the app until {EXPIRY_DATE}.',
    },
  },
  toasts: {
    subscribed: {
      cancelled: {
        header: 'Subscription Cancelled',
        position: 'bottom',
        duration: 3000,
      },
    },
  },
};
