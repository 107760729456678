<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <div class="ion-page">
    <SubscriptionExpireSoonHeader />
    <ion-content>
      <div class="subscription-expire-soon">
        <SubscriptionExpireSoonInfo />
        <PaypalButton
          v-if="annualPlanProductId"
          class="paypal-single-button"
          :early-purchase="true"
          :product-id="annualPlanProductId"
          @paypal-product-purchased="onPaypalProductPurchased"
        />
        <br>
        <ion-button
          class="back-button"
          fill="solid"
          color="dark"
          @click="remindMeLater()"
        >
          Remind Me Later
        </ion-button>
      </div>
    </ion-content>
    <AppVersion />
  </div>
</template>
<script>
import {
  IonContent,
  IonButton,
  modalController,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import AppVersion from '../AppVersion.vue';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import PaypalButton from '../PaypalSubscription/PaypalButton.vue';
import SubscriptionExpireSoonInfo from './SubscriptionExpireSoon.info.vue';
import SubscriptionExpireSoonHeader from './SubscriptionExpireSoon.header.vue';
import { routerReplaceInterface } from '../../services/router.interface';
import utilityService from '../../services/utility.service';

export default {
  name: 'SubscriptionExpireSoon',
  components: {
    IonContent,
    IonButton,
    PaypalButton,
    AppVersion,
    SubscriptionExpireSoonInfo,
    SubscriptionExpireSoonHeader,
  },
  data() {
    return {
      productToBuy: {},
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      annualPaypalPlan: SUBSCRIPTION_GETTERS_ENUM.GET_ANNUAL_PAYPAL_PLAN,
    }),
    annualPlanProductId() {
      return this.annualPaypalPlan?.productId;
    },
  },
  mounted() {
    utilityService.setSubscriptionExpireReminderInLocalStorage(new Date().toDateString());
  },
  methods: {
    buyProduct(product) {
      this.subscriptionExpireSoonSwiper.slideNext();
      this.productToBuy = product;
    },
    onPaypalProductPurchased() {
      this.goBackToAccount();
    },
    goBackToAccount() {
      utilityService.removeSubscriptionExpireReminderFromLocalStorage();
      modalController.dismiss();
      routerReplaceInterface({
        name: 'app.account',
      });
    },
    remindMeLater() {
      modalController.dismiss();
    },
  },
};
</script>
<style scoped>
.subscription-expire-soon {
  margin: 25px;
  text-align: center;
}
</style>
