<template>
  <ion-buttons
    slot="end"
  >
    <ion-button
      id="refbutton"
      fill="solid"
      color="secondary"
      class="slider-btn play-button custom-button"
      @click.stop="playPauseSlider($event)"
    >
      <ion-icon
        v-show="!isPlaying"
        mode="ios"
        :icon="play"
      />
      <ion-icon
        v-show="isPlaying"
        mode="ios"
        :icon="pause"
      />
    </ion-button>
  </ion-buttons>
</template>
<script>
import { IonButton, IonButtons, IonIcon } from '@ionic/vue';
import { play, pause } from 'ionicons/icons';

export default {
  emits: ['playSlider', 'pauseSlider'],
  data() {
    return { play, pause, isPlaying: false };
  },
  computed: {
    IonButton,
    IonButtons,
    IonIcon,
  },
  methods: {
    playPauseSlider() {
      this.isPlaying = !this.isPlaying;
      this.$emit(this.isPlaying ? 'playSlider' : 'pauseSlider');
    },
  },
};
</script>
