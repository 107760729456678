import { Network } from '@capacitor/network';
import {
  CONNECTIVITY_ACTIONS_ENUM,
  CONNECTIVITY_MUTATIONS_ENUM,
} from './connectivity.eum';
import capacitorService from '../../../services/capacitor.service';
import EventConst from '../../../constants/event.constant';
import Emitter from '../../../bus';

export const connectivityActions = {
  async [CONNECTIVITY_ACTIONS_ENUM.SET_NETWORK_STATUS]({ commit }) {
    const status = await Network.getStatus();
    if (capacitorService.isCapacitorMobileApp) {
      commit(CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET, status.connected);
      return status.connected;
    }
    commit(CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET, navigator.onLine);
  },

  [CONNECTIVITY_ACTIONS_ENUM.START_NETWORK_WATCHING]({ commit }, payload) {
    const isOnline = () => {
      console.log('online');
      commit(CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET, true);
      Emitter.emit(EventConst.networkBackOnline);
    };

    const isOffline = () => {
      console.log('offline');
      commit(CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET, false);
    };
    if (capacitorService.isCapacitorMobileApp) {
      Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed', status.connected);
        if (status.connected) {
          isOnline();
        } else {
          isOffline();
        }
      });
    } else {
      window.addEventListener('online', () => {
        isOnline();
      }, false);

      window.addEventListener('offline', () => {
        isOffline();
      }, false);
    }
    commit(CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET, payload);
  },
};
