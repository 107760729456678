import _debounce from 'lodash/debounce';
import router from '../router';

const routerPushInterface = (input) => {
  router.push(input);
};

const routerReplaceInterface = _debounce((input) => {
  console.log('routerReplaceInterface @@@@@@@@@@', input);
  router.replace(input);
}, 100);

export { routerPushInterface, routerReplaceInterface };
