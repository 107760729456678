import { STORM_TYPE } from './stormType.enum';

const imageMap = {};
Object.keys(STORM_TYPE).forEach((type) => {
  const img = new Image();
  img.src = `static/img/${type.toLowerCase()}.png`;
  imageMap[type] = img;
});

const getStormImage = (icon) => imageMap[icon];

export { getStormImage };
