<template>
  <IonList class="app-subscription">
    <PaypalProduct
      v-for="product in products"
      :key="product.id"
      :product="product"
      @buy-product="onBuyProduct"
    />
  </IonList>
</template>
<script>
import {
  IonList,
} from '@ionic/vue';
import { checkmarkCircle } from 'ionicons/icons';
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';
import PaypalProduct from './PaypalProduct.vue';

export default {
  name: 'Subscription',
  components: {
    IonList,
    PaypalProduct,
  },
  emits: ['buy-product'],
  data() {
    return {
      checkmarkCircle,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      products: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_PAYPAL_PLANS,
      userPaypalSubscription: SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
    }),
  },
  methods: {
    onBuyProduct(product) {
      this.$emit('buy-product', product);
    },
  },
};
</script>
