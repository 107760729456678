<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div
    id="flight-slider"
    class="app-slider"
    :class="{
      secondarySlider: hideBottomAxis
    }"
  >
    <div class="slider-svg-container" />
  </div>
</template>
<script>
import _debounce from 'lodash/debounce';
import bnd3Service from '../../services/bnd3.service';
import capacitorService from '../../services/capacitor.service';
import utilityService from '../../services/utility.service';
import AppConst from '../../constants/app.constant';
import d3 from '../../globalexports/d3.export';
import ChartConst from '../../constants/chart.constant';
import EventConst from '../../constants/event.constant';

export default {
  name: 'FlightSlider',
  emits: [EventConst.sliderEvent.changing],
  data() {
    return {
      settings: null,
      parentElement: null,
      sliderPinned: null,
      sliderMainObj: 100,
      showSlider: null,
      sliderIdxStart: 0,
      currentSliderIdx: 0,
      sliderNotchRadius: 10,
      textHeightRef: 20,
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  mounted() {
    const vm = this;
    vm.parentElement = d3.select(`#${vm.$el.id}`);
    vm.$nextTick(() => {
      window.addEventListener('resize', vm.handleResizeEvent);
    });
  },
  beforeUnmount() {
    const vm = this;
    window.removeEventListener('resize', vm.handleResizeEvent);
  },
  methods: {
    getSliderLabel(arrivalTime, dateFormat) {
      return ChartConst.flightSliderMainPrefix + dateFormat(arrivalTime);
    },
    getFlightCoverageDetails(xVal) {
      const vm = this;
      let dateFormat;
      if (vm.settings.timeZone === AppConst.timeZones.local.key) {
        dateFormat = d3.timeFormat(
          ChartConst.xAxis.formatLocal
            + ChartConst.GraphConfig.xAxis.timePostFix.local,
        );
      } else {
        dateFormat = d3.utcFormat(
          ChartConst.xAxis.format
            + ChartConst.GraphConfig.xAxis.timePostFix.zulu,
        );
      }
      const flightCovered = (xVal * vm.flightSliderInfo.totalDistanceToTravel) / 100;
      const travelTime = Math.ceil(flightCovered) / vm.settings.groundSpeed;
      let arrivalTime;
      if (!d3.select('.etd-handle-circle').empty()) {
        arrivalTime = utilityService.addHours(
          new Date(d3.select('.etd-handle-circle').attr('realEtdStartTime')),
          travelTime,
        );
      }
      return {
        distanceCoveredInNauticleMiles: flightCovered,
        arrivalTimeDisplayText:
          ChartConst.flightSliderMainPrefix + dateFormat(arrivalTime),
        arrivalTime,
        travelTimeElapsed: travelTime,
        dateFormat,
      };
    },
    drawSlider() {
      const vm = this;
      vm.hideBottomAxis = utilityService.hideBottomAxis();
      let windowRefValue = window.innerWidth;
      if (vm.hideBottomAxis) {
        windowRefValue = window.innerHeight;
        d3.select('#bn-flight-slider').style(
          'width',
          `calc(100vh - ${utilityService.getHeaderHeight()}px)`,
        );
      } else {
        d3.select('#bn-flight-slider').style('width', '');
      }
      const margin = {
        left: 20,
        right: 20,
      };
      const buttonsSliderPad = 23;
      if (vm.hideBottomAxis) {
        /// this is right always in vertical mode
        margin.left = buttonsSliderPad;
        margin.right = buttonsSliderPad + utilityService.getHeaderHeight();
      }
      let width = windowRefValue
        - (utilityService.is_small_device()
          ? 0
          : parseFloat(AppConst.sideMenuWidth));
      if (!vm.hideBottomAxis) {
        width
          -= utilityService.getSafeAreaPadding().left
          - utilityService.getSafeAreaPadding().right;
      }
      const height = 70;
      const range = [0, vm.sliderMainObj - 1];
      const step = 1; // change the step and if null, it"ll switch back to a normal slider
      let handle;
      let etdTextHandle;
      // append svg
      vm.parentElement.select('div.slider-svg-container svg').remove();
      const svg = vm.parentElement
        .select('.slider-svg-container')
        .append('svg')
        .style('width', `${width}px`)
        .style('height', `${height}px`);
      const slider = svg
        .append('g')
        .classed('slider', true)
        .attr('transform', `translate(${margin.left}, ${height / 2 + 0})`);
      // using clamp here to avoid slider exceeding the range limits
      const xScale = d3
        .scaleLinear()
        .domain(range)
        .range([0, width - margin.left - margin.right])
        .clamp(true);
      // array useful for step sliders
      const rangeValues = d3
        .range(range[0], range[1], step || 1)
        .concat(range[1]);
      xScale.clamp(true);

      function dragged(value, isDragEnd, isInitializing) {
        const x = xScale.invert(value);
        let index = null;
        let midPoint;
        let cx;
        let xVal;
        let i;
        let isChangeDetected = false;
        if (step) {
          // if step has a value, compute the midpoint based on range values and
          // reposition the slider based on the mouse position
          i = 0;
          while (i < rangeValues.length - 1) {
            if (x >= rangeValues[i] && x <= rangeValues[i + 1]) {
              index = i;
              break;
            }
            i += 1;
          }
          midPoint = (rangeValues[index] + rangeValues[index + 1]) / 2;
          if (x < midPoint) {
            cx = xScale(rangeValues[index]);
            xVal = rangeValues[index];
          } else {
            cx = xScale(rangeValues[index + 1]);
            xVal = rangeValues[index + 1];
          }
        } else {
          // if step is null or 0, return the drag value as is
          cx = xScale(x);
          xVal = x.toFixed(3);
        }
        if (xVal !== vm.currentSliderIdx) {
          isChangeDetected = true;
        }
        vm.currentSliderIdx = xVal;
        const flightCoverageDetails = vm.getFlightCoverageDetails(xVal);
        // handle.select(".flight-ruleset-handle").text("Covered: "
        // + flightCoverageDetails.distanceCoveredInNauticleMiles.toFixed(0) + "nm");
        if (!vm.hideBottomAxis) {
          etdTextHandle.text(
            vm.getSliderLabel(
              flightCoverageDetails.arrivalTime,
              flightCoverageDetails.dateFormat,
            ),
          );
        }
        // use xVal as drag value
        let flightRuleWidth = bnd3Service.getSelectionWidth(
          handle.select('.flight-ruleset-handle'),
        );
        const flightRuleHeight = bnd3Service.getSelectionHeight(
          handle.select('.flight-ruleset-handle'),
        );
        let flightEtdWidth = bnd3Service.getSelectionWidth(etdTextHandle);
        const flightEtdHeight = bnd3Service.getSelectionHeight(etdTextHandle);
        if (vm.hideBottomAxis) {
          flightEtdWidth = flightEtdHeight;
          flightRuleWidth = flightRuleHeight;
        }
        handle.attr('transform', `translate(${cx}, 0)`).attr('stroke', '#000');
        handle
          .select('.flight-ruleset-handle')
          .attr(
            'transform',
            `translate(${-flightRuleWidth / 2}, ${vm.textHeightRef
              + vm.sliderNotchRadius})`,
          );
        etdTextHandle.attr('transform', () => {
          let xValue = cx - flightEtdWidth / 2;
          if (xValue < 0) {
            xValue = -10;
          } else if (xValue + flightEtdWidth > xScale(vm.sliderMainObj - 1)) {
            xValue = xScale(vm.sliderMainObj - 1) - flightEtdWidth;
          }
          return `translate(${xValue}, ${-(vm.textHeightRef + 0)})`;
        });
        if (!isInitializing && !isChangeDetected && !isDragEnd) {
          return;
        }
        vm.$emit(EventConst.sliderEvent.changing, {
          idx: xVal,
          isDragEnd,
          isInitializing,
          ...flightCoverageDetails,
        });
      }
      // drag behavior initialization
      const drag = d3
        .drag()
        .on('drag', (d3Event) => {
          let xRef = d3Event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (d3Event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(d3Event.y + 56);
            }
          }
          window.requestAnimationFrame(() => {
            dragged(xRef);
          });
          // console.log("draging");
        })
        .on('end', (d3Event) => {
          let xRef = d3Event.x;
          if (capacitorService.isIos && vm.hideBottomAxis) {
            if (d3Event.y > -56) {
              xRef = 0;
            } else {
              xRef = Math.abs(d3Event.y + 56);
            }
          }
          window.requestAnimationFrame(() => {
            dragged(xRef, true);
          });
          etdTextHandle.text('');
        });
      // regular
      // vm is the main bar with a stroke (applied through CSS)
      slider
        .append('line')
        .attr('class', 'track regular')
        .attr('x1', xScale.range()[0])
        .attr('x2', xScale.range()[1])
        .style('stroke', '#fff');

      // drag handle
      handle = slider.append('g');
      handle
        .append('circle')
        .classed('flight-slider-handle handle', true)
        .attr('r', vm.sliderNotchRadius);
      etdTextHandle = slider
        .append('text')
        .classed('flight-etd-handle', true)
        .text(vm.hideBottomAxis ? '' : 'dummytext');
      handle.append('text').classed('flight-ruleset-handle', true);
      // this is the bar on top of above tracks with stroke = transparent
      // and on which the drag behaviour is actually called
      // try removing above 2 tracks and play around with the CSS for this
      // track overlay, you"ll see the difference
      slider
        .append('rect')
        .attr('class', 'track track-overlay  regular')
        .attr('height', '50')
        .attr('width', xScale.range()[1] - xScale.range()[0] + 40)
        .attr('transform', 'translate(-20, -25)')
        .style('fill', 'transparent')
        .style('cursor', 'pointer')
        .call(drag);
      // initial transition
      dragged(xScale(vm.sliderIdxStart), false, true);
      // PlayerControls defined here
      vm.resetSliderToStart = () => {
        dragged(xScale(0), true, false);
      };
    },
    initDrawing() {
      const vm = this;
      vm.drawSlider();
    },
    refresh(settings, allDataForInterval) {
      const vm = this;
      vm.settings = settings;
      vm.sliderPinned = true;
      vm.flightSliderInfo = allDataForInterval.flightSliderInfo;
      setTimeout(() => {
        vm.initDrawing();
      });
    },
    handleResizeEvent: _debounce(function () {
      if (this.settings) {
        this.hideBottomAxis = utilityService.hideBottomAxis();
        this.initDrawing();
      }
    }, ChartConst.resizeDebounce),
    resetPositionToStart() {
      if (this.resetSliderToStart) {
        this.resetSliderToStart();
      }
    },
  },
};
</script>
