<template>
  <div>
    <BackGroundRefresh />
    <div class="sentinel-item">
      Sentinel Item
    </div>
  </div>
</template>
<script>
import BackGroundRefresh from './BackGroundRefresh.vue';

export default {
  components: {
    BackGroundRefresh,
  },
};
</script>
<style scoped>
.sentinel-item {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
</style>
