<template>
  <ion-page>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
        </ion-buttons>
        <ion-title>Support</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="version-container">
        <div id="backdoor">
          <img
            class="logo-about"
            src="static/img/logo.png"
          >
        </div>
        <div class="about-version-section">
          <div v-if="deviceInfo">
            {{ deviceAppNameVersion }}
            <br>
            <p class="about-buildnumber">
              {{ deviceBundleVersion }}
            </p>
            <i>
              <p class="about-buildnumber">
                {{ codeVersion }} / {{ codeBundleVersion }}
              </p>
            </i>
          </div>
        </div>
      </div>
      <ion-grid class="about-us">
        <ion-row>
          <ion-col>
            <h4>About WeatherSpork</h4>
            <div>
              <p>
                <b>WeatherSpork</b> is the strategic all-purpose weather planning
                application for aviators. WeatherSpork includes visualization tools
                such as a <b>Meteogram</b>, a
                vertical depiction of the weather over the next three days at an
                airport. The <b>Route Profile</b> view is also a vertical
                depiction that lets you quickly see the impact of wind and
                hazardous weather along your proposed route of flight. Lastly,
                the <b>Grid view</b> coupled with the "ETD Finder" lets you
                choose the most favorable time to fly.
              </p>
              <EulaContent />
              <h4>Need some help?</h4>
              <p>
                For support with your account or to make comments or suggestions
                about this app, please send an email to
                <a
                  href="mailto:support@weatherspork.com"
                >support@weatherspork.com</a>.
              </p>
            </div>
            <a
              target="_blank"
              href="https://weatherspork.com/privacy-policy/"
            >Privacy Policy</a>
            <p><i>OTU: {{ lsOtuOn }}</i></p>
            <p>&copy; 2009-{{ currentYear }} BuserNet Consulting LLC</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonGrid,
  IonButtons,
  IonCol,
  alertController,
} from '@ionic/vue';
import { getKeyValueFromLocalStorage } from '../services/localstorage/getKeyValueFromLocalStorage';
import capacitorService from '../services/capacitor.service';
import AppConst from '../constants/app.constant';
import EulaContent from '../components/EulaContent.vue';
import { routerPushInterface } from '../services/router.interface';
import { getAppInfo } from '../services/capacitor/getApp.info';

export default {
  name: 'Aboutus',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonGrid,
    IonButtons,
    IonCol,
    EulaContent,
  },
  data() {
    return {
      deviceInfo: null,
      mouseTimer: null,
      lsOtuOn: getKeyValueFromLocalStorage(AppConst.localstorageConf.lastOtuOn),
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    deviceBundleVersion() {
      return this.deviceInfo.appBuild;
    },
    deviceAppNameVersion() {
      return `${this.deviceInfo.appName} v${this.deviceInfo.appVersion}`;
    },
    codeVersion() {
      return AppConst.application.version;
    },
    codeBundleVersion() {
      return AppConst.application.bundleVersion;
    },
  },
  mounted() {
    const div = document.getElementById('backdoor');
    if (div) {
      if (capacitorService.isCapacitorMobileApp) {
        div.addEventListener('touchstart', this.mouseDown, false);
        div.addEventListener('touchend', this.mouseUp, false);
      } else {
        div.addEventListener('mousedown', this.mouseDown);
        document.body.addEventListener('mouseup', this.mouseUp);
      }
    }
    getAppInfo().then(({ appBuild, appVersion, appName }) => {
      this.deviceInfo = {
        appBuild: appBuild || this.codeBundleVersion,
        appVersion: appVersion || this.codeVersion,
        appName: appName || AppConst.application.name,
      };
    });
  },
  methods: {
    mouseUp() {
      if (this.mouseTimer) {
        window.clearTimeout(this.mouseTimer); // cancel timer when mouse button is released
      }
    },
    mouseDown() {
      this.mouseUp();
      // set timeout to fire in 2 seconds when the user presses mouse button down
      this.mouseTimer = window.setTimeout(this.openbackDoorgate, 500);
    },
    openbackDoorgate() {
      alertController
        .create({
          header: 'Passcode!',
          backdropDismiss: 'false',
          inputs: [
            {
              name: 'dc',
              type: 'string',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Alohomora',
              handler(data) {
                if (data.dc === (AppConst.dc1 + AppConst.dc2) || data.dc === '5000') {
                  routerPushInterface({ name: 'app.developer' });
                }
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
  },
};
</script>
<style scoped>
.version-container {
  display: flex;
  margin: 10px;
  margin-bottom: 0px;
}
.about-version-section {
  flex: 1;
  margin: auto;
  margin-left: 10px;
}
.logo-about {
  pointer-events: none;
}
.poweredby {
  display: inline-block;
}
</style>
