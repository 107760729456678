import _forEach from 'lodash/forEach';
import AccountConst from '../../constants/account.constant';

const clearLocalStorage = () => {
  const localStorageKeys = Object.keys(localStorage);
  _forEach(localStorageKeys, (item) => {
    if (AccountConst.signoutExcludedKeys.indexOf(item) < 0) {
      localStorage.removeItem(item);
    }
  });
};

export { clearLocalStorage };
