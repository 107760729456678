/**
 *
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 */
const drawStrokedText = (ctx, text, x, y) => {
  ctx.miterLimit = 2; // default is 10
  ctx.font = '12px Sans-serif';
  ctx.strokeStyle = 'black';
  // ctx.lineWidth = 4;
  ctx.strokeText(text, x, y);
  ctx.fillStyle = 'white';
  ctx.fillText(text, x, y);
};
export default drawStrokedText;
