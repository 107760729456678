const CONNECTIVITY_ACTIONS_ENUM = {
  SET_NETWORK_STATUS: 'SET_NETWORK_STATUS',
  START_NETWORK_WATCHING: 'START_NETWORK_WATCHING',
};

Object.freeze(CONNECTIVITY_ACTIONS_ENUM);

const CONNECTIVITY_GETTERS_ENUM = {
  GET_IS_CONNECTED_TO_INTERNET: 'GET_IS_CONNECTED_TO_INTERNET',
};

Object.freeze(CONNECTIVITY_GETTERS_ENUM);

const CONNECTIVITY_MUTATIONS_ENUM = {
  SET_IS_CONNECTED_TO_INTERNET: 'SET_IS_CONNECTED_TO_INTERNET',
};

Object.freeze(CONNECTIVITY_MUTATIONS_ENUM);

export {
  CONNECTIVITY_ACTIONS_ENUM,
  CONNECTIVITY_MUTATIONS_ENUM,
  CONNECTIVITY_GETTERS_ENUM,
};
