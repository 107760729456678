/**
 * Replace all the occerencess of $find by $replace in $originalString
 * If replaceAll native method is supported then that will be used
 * @param  {originalString} input - Raw string.
 * @param  {find} input - Target key word or regex that need to be replaced.
 * @param  {replace} input - Replacement key word
 * @return {String}       Output string
 */
const replaceAll = (
  originalString,
  find,
  replace,
) => {
  if (originalString.replaceAll) {
    return originalString.replaceAll(
      find,
      replace,
    );
  }
  find = find.replace('[', '');
  return originalString.replace(
    new RegExp(find, 'g'),
    replace,
  );
};

export { replaceAll };
