<template>
  <div>
    <ion-item
      v-for="(productItem, $index) in products"
      :key="$index"
    >
      <ion-label>
        <h2 class="makebold">
          {{ productItem.title }}
        </h2>
      </ion-label>
      <!-- <ion-badge slot="end">{{productItem.price}}</ion-badge> -->
      <ion-button
        slot="end"
        fill="solid"
        color="primary"
        @click="onBuyProduct(productItem)"
      >
        {{ productItem.price }}
      </ion-button>
    </ion-item>
  </div>
</template>
<script>
import {
  IonItem,
  IonButton,
  IonLabel,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';

export default {
  components: {
    IonItem,
    IonButton,
    IonLabel,
  },
  emits: ['buy-product'],
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      products: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_IAP_PRODUCTS,
      userPaypalSubscription: SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
    }),
  },
  methods: {
    onBuyProduct(product) {
      this.$emit('buy-product', product);
    },
  },
};
</script>
