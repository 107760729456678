import AccountConst from '../../../../constants/account.constant';
import { getCognitoUserAttribute } from '../../core/getCognitoUserAttribute';
import { updateUserAttributes } from '../../core/updateUserAttributes';

const setEulaAgreed = async (version) => {
  const attributeList = [];
  const attributeEulaAgreed = getCognitoUserAttribute(
    AccountConst.customAttribute.eulaVersionAgreed,
    version.toString(),
  );
  const attributeEulaAgreedOn = getCognitoUserAttribute(
    AccountConst.customAttribute.eulaVersionAgreedOn,
    Date.now().toString(),
  );
  attributeList.push(attributeEulaAgreed);
  attributeList.push(attributeEulaAgreedOn);
  return updateUserAttributes(attributeList);
};

export { setEulaAgreed };
