const MRMS_ACTIONS_ENUM = {
  GET_MRMS_METADATA_REMOTE: 'GET_MRMS_METADATA_REMOTE',
};

Object.freeze(MRMS_ACTIONS_ENUM);

const MRMS_GETTERS_ENUM = {
  GET_MRMS_METADATA: 'GET_MRMS_METADATA',
};

Object.freeze(MRMS_GETTERS_ENUM);

const MRMS_MUTATIONS_ENUM = {
  SET_MRMS_METADATA: 'SET_MRMS_METADATA',
};

Object.freeze(MRMS_MUTATIONS_ENUM);

export {
  MRMS_ACTIONS_ENUM,
  MRMS_MUTATIONS_ENUM,
  MRMS_GETTERS_ENUM,
};
