import {
  drawImageOnCanvas,
  clearCanvas,
} from '../../../../utils/canvas';
import {
  gridCanvasConfig,
} from './gridCanvasConfig';
import weatherImgService from '../../../../services/weather.service';
import utilityService from '../../../../services/utility.service';
import bnChartDataProcessService from '../../../../services/chartDataProcess.service';
import bnd3Service from '../../../../services/bnd3.service';
import ChartConst from '../../../../constants/chart.constant';
import {
  roundOfNumber,
} from '../../../../services/utils/roundOfNumber';

/**
 *
 * @param {Forecast} p
 */
const showWeatherImage = (p) => p.frl !== 3 || (p.weatherRepresentation && p.weatherRepresentation
  .levelOfWeather <= ChartConst.gridLevelOfWeatherThreshold);

/**
 *
 * @param {Number} squareWidth
 * @param {Number} squareHeight
 * @param {Number} airportSqaureWidth
 * @param {Object} chartData
 */
export const drawWeathersImages = (
  squareWidth,
  squareHeight,
  airportSqaureWidth,
  chartData,
) => {
  clearCanvas(gridCanvasConfig.ctx, gridCanvasConfig.canvas);
  const imageWidth = (squareWidth / 3);
  const imageHeight = Math.min(imageWidth * 2, (squareHeight / 2));
  chartData.forEach((airport, airportIndex) => {
    airport.forecast.forEach((forecastRecord, forecastRecordIndex) => {
      let weatherImage = '';
      const y = (airportIndex * squareHeight) + ((squareHeight / 2));
      const x = forecastRecordIndex === 0
        ? -100
        : roundOfNumber(
          (forecastRecordIndex * squareWidth)
          + (airportSqaureWidth - squareWidth)
          + ((squareWidth / 2)),
        );
      const colorValue = (forecastRecordIndex === 0)
        ? ChartConst.gridView.airportRectColor
        : bnd3Service.getFlightDotColor(forecastRecord.frl);
      const useWhiteImage = utilityService.useWhiteImage(colorValue);
      if (!forecastRecord.sunMoonPositionData) {
        forecastRecord.sunMoonPositionData = utilityService.getSunMoonCalculation(
          forecastRecord.forecast_ts_local,
          airport.lat,
          airport.lng,
        );
        bnChartDataProcessService.appendWeatherStripData(
          forecastRecord,
          'forecast_ts_local',
        );
      }
      if (forecastRecord.weatherRepresentation && showWeatherImage(forecastRecord)) {
        // Keep it blank if weatherRepresentation is empty
        weatherImage = forecastRecord.weatherRepresentation.filename;
      }
      if (!forecastRecord.weatherRepresentation && forecastRecord.frl !== null) {
        weatherImage = 'none';
      }
      if (weatherImgService.imageMap[weatherImage]) {
        drawImageOnCanvas(
          gridCanvasConfig.ctx,
          imageWidth,
          imageHeight,
          x,
          y,
          (useWhiteImage ? weatherImgService.imageMap[weatherImage]
            : weatherImgService.imageMapBlack[weatherImage]),
        );
      }
    });
  });
};
