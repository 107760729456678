export default {
  cb: {
    key: 'cb',
    label: 'Cell base',
    unit: 'feet',
  },
  ch: {
    key: 'ch',
    label: 'Centroid height',
    unit: 'feet',
  },
  ct: {
    key: 'ct',
    label: 'Cell top',
    unit: 'feet',
    zoomFiltrerMap: [40000, 30000, 25000, 1000],
  },
  cvil: {
    key: 'cvil',
    label: 'Cell based VIL',
    unit: 'kg/m^2',
  },
  mr: {
    key: 'mr',
    label: 'Max ref',
    unit: 'dBz',
  },
  mrh: {
    key: 'mrh',
    label: 'Max ref height',
    unit: 'feet',
  },
  ph: {
    key: 'ph',
    label: 'Probability of Hail',
    unit: '%',
  },
  psh: {
    key: 'psh',
    label: 'Probability of Severe Hail',
    unit: '%',
  },
};
