import bnd3Service from '../../../../services/bnd3.service';
import {
  writeText,
} from '../../../../utils/canvas';

const drawMetarDot = (ctx, path, airport, projection, scaleThresholdForRouteIcaoID) => {
  const colorValue = bnd3Service.getFlightDotColor(airport.frl);
  const latLongCord = [airport.lng, airport.lat];
  ctx.beginPath();
  path({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: latLongCord,
    },
  });
  ctx.fillStyle = colorValue;
  ctx.fill();
  if (projection.scale() > scaleThresholdForRouteIcaoID) {
    const latLongProj = projection(latLongCord);
    writeText(ctx, airport.icao_id, latLongProj[0] - 16, latLongProj[1] - 12);
  }
};
export { drawMetarDot };
