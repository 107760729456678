import {
  MENU_ACTIONS_ENUM,
  MENU_MUTATIONS_ENUM,
} from './menu.enum';

export const menuActions = {
  [MENU_ACTIONS_ENUM.ENABLE_SWIPE_GESTURE]({ commit }) {
    commit(MENU_MUTATIONS_ENUM.ENABLE_SWIPE_GESTURE);
  },
  [MENU_ACTIONS_ENUM.DISABLE_SWIPE_GESTURE]({ commit }) {
    commit(MENU_MUTATIONS_ENUM.DISABLE_SWIPE_GESTURE);
  },
};
