import InAppPurchasesConst from '../../constants/inapppurchases.constant';
import utilityService from '../utility.service';

const getAppleIapSubscriptionExpiryDate = (productId) => {
  if (productId) {
    const duration = InAppPurchasesConst.productDurationMapping[productId];
    const expiryDate = utilityService.addMonth(new Date(), duration);
    return expiryDate.toLocaleDateString('lt-LT'); // Lithuania "YYYY-MM-DD"
  }
};

export { getAppleIapSubscriptionExpiryDate };
