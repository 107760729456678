import {
  getUserFsValue,
} from './getUserFsValue';
import {
  getIsUserFsExpired,
} from './getIsUserFsExpired';

/**
 *
 * @param {Object} user
 * @returns
 */
const getIsActiveFsUser = (user) => {
  if (user) {
    const fsValue = getUserFsValue(user);
    if (fsValue && !getIsUserFsExpired(fsValue)) {
      return true;
    }
  }
  return false;
};

export {
  getIsActiveFsUser,
};
