<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <div class="ion-page">
    <NeedSubscriptionHeader
      :current-slide="currentSlide"
      @slide-prev="slidePrev"
    />
    <ion-content>
      <div class="swiper-container need-subscription-swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <NeedSubscriptionExpires @goBackToAccount="goBackToAccount" @nextSlide="nextSlide" />
          </div>
          <div class="swiper-slide">
            <AccountSubscriptionOptions
              :need-subscription="true"
              @buyProduct="buyProduct"
            />
          </div>
          <div class="swiper-slide center-align">
            <!-- Product to buy Slide -->
            <SubscriptionProduct
              :show-header="false"
              :product-to-buy="productToBuy"
              @goBackToAccount="goBackToAccount"
            />
          </div>
        </div>
      </div>
    </ion-content>
    <AppVersion />
  </div>
</template>
<script>
import {
  IonContent,
  modalController,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import Swiper from 'swiper';
import AppVersion from '../AppVersion.vue';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import NeedSubscriptionExpires from './NeedSubscription.expires.vue';
import NeedSubscriptionHeader from './NeedSubscription.header.vue';
import AccountSubscriptionOptions from '../AccountSubscriptionOptions.vue';
import SubscriptionProduct from '../SubscriptionProduct.vue';
import { routerReplaceInterface } from '../../services/router.interface';

export default {
  name: 'NeedSubscription',
  components: {
    IonContent,
    SubscriptionProduct,
    NeedSubscriptionExpires,
    NeedSubscriptionHeader,
    AppVersion,
    AccountSubscriptionOptions,
  },
  data() {
    return {
      currentSlide: 0,
      productToBuy: {},
      isIapMode: false,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      isInAppPurchaseActive:
        SUBSCRIPTION_GETTERS_ENUM.GET_IF_IAP_SUBSCRIPTION_IS_ACTIVE,
    }),
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResizeEvent);
    });
    this.initializeSwiper();
  },
  methods: {
    handleResizeEvent: _debounce(function () {
      if (this.needSubscriptionSwiper && this.needSubscriptionSwiper.update) {
        this.needSubscriptionSwiper.update();
      }
    }, 100),
    initializeSwiper() {
      this.needSubscriptionSwiper = new Swiper('.need-subscription-swiper', {
        allowTouchMove: false,
      });
      setTimeout(() => {
        if (this.needSubscriptionSwiper && this.needSubscriptionSwiper.update) {
          this.needSubscriptionSwiper.update();
        }
      }, 1000);
    },
    buyProduct(product) {
      this.needSubscriptionSwiper.slideNext();
      this.productToBuy = product;
    },
    setCurrentSlideIndex() {
      this.currentSlide = this.needSubscriptionSwiper.activeIndex;
    },
    nextSlide() {
      this.needSubscriptionSwiper.slideNext();
      this.setCurrentSlideIndex();
    },
    slidePrev() {
      this.needSubscriptionSwiper.slidePrev();
      this.setCurrentSlideIndex();
    },
    goBackToAccount() {
      modalController.dismiss();
      routerReplaceInterface({
        name: 'app.account',
      });
    },
  },
};
</script>
<style scoped>
ion-content {
  --background: #1C1C1C;
}
.need-subscription-swiper {
  height: 100%;
}
</style>
