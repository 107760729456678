export const LAYER_UPDATE_TIME_MUTATION_ENUM = Object.freeze({
  CHANGE_TRACK_TIME: 'CHANGE_TRACK_TIME',
  SET_CURRENT_SCREEN_RENDER_TIME: 'SET_CURRENT_SCREEN_RENDER_TIME',
  CLEAR_CURRENT_SCREEN_RENDER_TIME: 'CLEAR_CURRENT_SCREEN_RENDER_TIME',
  MARK_URL_PENDING: 'MARK_URL_PENDING',
  MARK_URL_FAIL: 'MARK_URL_FAIL',
  RETRY_URL: 'RETRY_URL',
});

export const LAYER_UPDATE_TIME_GETTER_ENUM = Object.freeze({
  GET_METEOGRAM_LAYER_UPDATE_TIME: 'GET_METEOGRAM_LAYER_UPDATE_TIME',
  GET_TAF_LAYER_UPDATE_TIME: 'GET_TAF_LAYER_UPDATE_TIME',
  GET_FCST_DISCUSSION_LAYER_UPDATE_TIME: 'GET_FCST_DISCUSSION_LAYER_UPDATE_TIME',
  GET_METARS_LAYER_UPDATE_TIME: 'GET_METARS_LAYER_UPDATE_TIME',
  GET_ROUTE_PROFILE_LAYER_UPDATE_TIME: 'GET_ROUTE_PROFILE_LAYER_UPDATE_TIME',
  GET_GRID_VIEW_LAYER_UPDATE_TIME: 'GET_GRID_VIEW_LAYER_UPDATE_TIME',
  GET_MAP_VIEW_LAYER_UPDATE_TIME: 'GET_MAP_VIEW_LAYER_UPDATE_TIME',
  GET_WEATHER_LAYER_UPDATE_TIME: 'GET_WEATHER_LAYER_UPDATE_TIME',

  GET_MAP_VIEW_MARKER_STATUS: 'GET_MAP_VIEW_MARKER_STATUS',
  GET_GRID_VIEW_MARKER_STATUS: 'GET_GRID_VIEW_MARKER_STATUS',
  GET_METEOGRAM_MARKER_STATUS: 'GET_METEOGRAM_MARKER_STATUS',
  GET_ROUTE_PROFILE_MARKER_STATUS: 'GET_ROUTE_PROFILE_MARKER_STATUS',
  GET_TAF_MARKER_STATUS: 'GET_TAF_MARKER_STATUS',
  GET_FCST_DISCUSSION_MARKER_STATUS: 'GET_FCST_DISCUSSION_MARKER_STATUS',
  GET_METARS_MARKER_STATUS: 'GET_METARS_MARKER_STATUS',
  GET_WEATHER_MARKER_STATUS: 'GET_WEATHER_MARKER_STATUS',

  GET_CURRENT_SCREEN_RENDER_TIME_MAP_VIEW: 'GET_CURRENT_SCREEN_RENDER_TIME_MAP_VIEW',
  GET_CURRENT_SCREEN_RENDER_TIME_GRID_VIEW: 'GET_CURRENT_SCREEN_RENDER_TIME_GRID_VIEW',
  GET_CURRENT_SCREEN_RENDER_TIME_METEOGRAM: 'GET_CURRENT_SCREEN_RENDER_TIME_METEOGRAM',
  GET_CURRENT_SCREEN_RENDER_TIME_ROUTE_PROFILE: 'GET_CURRENT_SCREEN_RENDER_TIME_ROUTE_PROFILE',
  GET_CURRENT_SCREEN_RENDER_TIME_TAF: 'GET_CURRENT_SCREEN_RENDER_TIME_TAF',
  GET_CURRENT_SCREEN_RENDER_TIME_FCST: 'GET_CURRENT_SCREEN_RENDER_TIME_FCST',
  GET_CURRENT_SCREEN_RENDER_TIME_METARS: 'GET_CURRENT_SCREEN_RENDER_TIME_METARS',
  GET_CURRENT_SCREEN_RENDER_TIME_WEATHER: 'GET_CURRENT_SCREEN_RENDER_TIME_WEATHER',
});
