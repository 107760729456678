import AWS from 'aws-sdk';
import AccountConst from '../../../constants/account.constant';

export const configureAws = () => {
  const {
    identityPoolId,
  } = AccountConst.awsCognito;

  AWS.config.region = AccountConst.awsCognito.region;
  AWS.config.correctClockSkew = true;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
  });
};
