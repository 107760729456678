import { appendUserNameToMessage } from '../utils/appendUserNameToMessage';
import AppConst from '../../../constants/app.constant';
import {
  getKeyValueFromLocalStorage,
} from '../../localstorage/getKeyValueFromLocalStorage';
import {
  setKeyValueInLocalStorage,
} from '../../localstorage/setKeyValueInLocalStorage';
import {
  getAttributesForUserRegisteration,
} from './getAttributesForUserRegisteration';
import { getCognitoUserPool } from '../core/getCognitoUserPool';
import { checkIfPasswordInvalid } from '../utils/checkIfPasswordInvalid';
import reportError from '../../error-handler/reportError';
import { getTuValue } from '../utils/getTuValue';

const registerUser = (
  useremail,
  username,
  passcode,
  rCode,
  givenName,
  familyName,
) => new Promise((resolve) => {
  const tu = getTuValue();
  const attributeList = getAttributesForUserRegisteration(
    useremail,
    rCode,
    givenName,
    familyName,
    getTuValue(),
  );
  getCognitoUserPool().signUp(username, passcode, attributeList, null, (err, result) => {
    if (err) {
      err.message = appendUserNameToMessage(username, err.message);
      err.emailTried = username;
      resolve(err);
      if (
        !err.code
            || (!err.code.match(new RegExp(AppConst.exceptionsCodes.UserNotFoundException))
            && !checkIfPasswordInvalid(err)
              && !err.code.match(new RegExp(AppConst.exceptionsCodes.UsernameExistsException)))
      ) {
        reportError(err);
      }
      return;
    }
    if (!getKeyValueFromLocalStorage(AppConst.localstorageConf.tu)) {
      setKeyValueInLocalStorage(AppConst.localstorageConf.tu, tu);
    }
    resolve(result);
  });
});

export { registerUser };
