<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <div>
    <div class="logo-img-container">
      <img
        class="logo"
        src="static/img/logo.png"
      >
    </div>
    <div class="main-heading">
      Your WeatherSpork membership is expiring soon! Renew now to avoid any
      lapse in your service. Your expiration date is
      {{ subscriptionExpiryDate }}.
      <br>
      <br>
      <div class="account-expired">
        Account: {{ userEmail }}
      </div>
    </div>
    <br>
    <div>
      <b>Note:</b> Effective April 2021 AvWxWorkshops have launched a competing product
      and terminated the marketing relationship with WeatherSpork.
    </div>
    <br>
    <div>
      If you have any questions please contact us at
      <a
        href
        @click="sendSupportEmail"
      >support@weatherspork.com</a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppConst from '../../constants/app.constant';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import { getUserFsValue } from '../../services/user/getUserFsValue';

export default {
  name: 'SubscriptionExpireSoonInfo',
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    fsExpiryDate() {
      const d = getUserFsValue(this.userDetails);
      return new Date(d).toLocaleDateString(AppConst.buserPreferredDatedFormatLocale, { dateStyle: 'medium' });
    },
    subscriptionExpiryDate() {
      return this.fsExpiryDate;
    },
    userEmail() {
      return this.userDetails ? this.userDetails.email : '';
    },
  },
  methods: {
    sendSupportEmail(event) {
      event.preventDefault();
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
  },
};
</script>
<style scoped>
.account-expired {
  font-size: 12px;
}
.logo-img-container {
  width: 70px;
  margin: auto;
  margin-bottom: 10px;
}
.logo-img-container img {
  border-radius: 20px;
}
</style>
