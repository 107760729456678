import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  MESSAGE_LOADER_ACTIONS_ENUM,
} from '../../store/modules/message-loader/message-loader.eum';

export const showFeedback = (message, hideSpinner) => {
  store.dispatch(`${STORE_NAMESPACE.MESSAGE_LOADER}/${MESSAGE_LOADER_ACTIONS_ENUM.SET_LOADING_MESSAGE}`, {
    message,
    hideSpinner,
  });
};
