import AppConst from '../constants/app.constant';
import { routerReplaceInterface } from './router.interface';
import utilityService from './utility.service';
import { getKeyValueFromLocalStorage } from './localstorage';

const navigateToLastStateOrDefault = () => {
  const lastViewedState = utilityService.getCorrectLastViewedStateKey(
    getKeyValueFromLocalStorage(
      AppConst.localstorageConf.lastViewedStateKey,
    ),
  );
  if (lastViewedState) {
    // If last state is saved then go there else default is dashboard state
    try {
      routerReplaceInterface({
        name: lastViewedState,
      });
    } catch (ex) {
      // If it is no match error
      // then send user to defaultHomeViewState to make sure it does not blanks out
      if (ex.message.indexOf('No match for') > -1) {
        routerReplaceInterface({
          name: AppConst.defaultHomeViewState,
        });
      }
    }
  } else {
    routerReplaceInterface({
      name: AppConst.defaultHomeViewState,
    });
  }
};

export { navigateToLastStateOrDefault };
