<template>
  <div class="apple-iap-buy-button">
    <ion-button
      fill="solid"
      color="primary"
      @click="onBuyIapProduct()"
    >
      Buy Subscription
    </ion-button>
  </div>
</template>
<script>
import {
  IonButton,
  loadingController,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { sendSlackNotification } from '../../services/sendSlackNotifications';
import reportError from '../../services/error-handler/reportError';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import AccountConst from '../../constants/account.constant';
import AppConst from '../../constants/app.constant';
import inAppPurchaseService from '../../services/inAppPurchase.service';
import { setUserAttributeInStore } from '../../services/user/setUserAttributeInStore';
import { getAppleIapSubscriptionExpiryDate } from '../../services/iap/getAppleIapSubscriptionExpiryDate';

export default {
  components: {
    IonButton,
  },
  props: {
    productToBuy: {
      type: Object,
      default: null,
    },
  },
  emits: ['iap-product-purchased'],
  data() {
    return {
      loading: null,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
  },
  methods: {
    hideLoading() {
      if (this.loading) {
        this.loading.dismiss();
      }
    },
    async presentLoading() {
      this.loading = await loadingController.create({
        message: AppConst.processingText,
      });
      this.loading.present();
    },
    onBuyIapProduct() {
      const vm = this;
      try {
        vm.presentLoading();
        const productID = vm.productToBuy.id;
        const expiryDateOfPurchase = getAppleIapSubscriptionExpiryDate(productID);
        inAppPurchaseService
          .buySubscription(productID, expiryDateOfPurchase, vm.userDetails)
          .then((data) => {
            if (data.status) {
              setUserAttributeInStore({
                key: AccountConst.customAttribute.t1,
                value: productID,
              });
              setUserAttributeInStore({
                key: AccountConst.customAttribute.t2,
                value: expiryDateOfPurchase,
              });
              vm.$emit('iap-product-purchased');
            }
          }).finally(() => {
            vm.hideLoading();
          });
      } catch (ex) {
        vm.hideLoading();
        console.log('onBuyIapProduct', ex);
        reportError(ex);
        sendSlackNotification(ex);
      }
    },
  },
};
</script>
<style scoped>
.apple-iap-buy-button {
  text-align: center;
  margin: 30px;
}
</style>
