import AppConst from '../../../constants/app.constant';

const getWeatherSource = (weatherData, settings) => {
  let weatherSource;
  switch (settings.apiToUse) {
    case AppConst.possibleWeatherApi.govWeather:
      weatherSource = weatherData.properties.periods;
      break;
    case AppConst.possibleWeatherApi.mapClick:
      weatherSource = (weatherData && weatherData.time)
        ? weatherData.time.startPeriodName
        : [];
      break;
    default:
  }
  return weatherSource;
};

export { getWeatherSource };
