import { routerReplaceInterface } from '../router.interface';
import { doSignoutTask } from '../user/doSignoutTask';
import { setIsLoggedIn } from '../user/setIsLoggedIn';

const userAuthFailed = () => {
  setIsLoggedIn(false);
  doSignoutTask();
  routerReplaceInterface({
    name: 'app.login',
  });
};

export { userAuthFailed };
