// eslint-disable-next-line import/no-extraneous-dependencies
import * as LiveUpdates from '@capacitor/live-updates';
import AccountConst from '../constants/account.constant';
import CapacitorConst from '../constants/capacitor.constant';
import capacitorService from './capacitor.service';

const startLiveUpdateSync = async (user) => {
  try {
    if (
      capacitorService.isCapacitorMobileApp
      && user
      && user[AccountConst.customAttribute.channel]
    ) {
      // Dynamically set the channel and max versions
      await LiveUpdates.setConfig({
        channel: user[AccountConst.customAttribute.channel], // could be beta, production, etc.
        autoUpdateMethod: 'background',
        appId: CapacitorConst.AppflowAppId,
      });
      await LiveUpdates.sync();
    }
  } catch (ex) {
    console.log(ex);
  }
};

export { startLiveUpdateSync };
