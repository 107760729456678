<template>
  <div>
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
        </ion-buttons>
        <ion-title>
          Account
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <div
      v-if="!user"
      class="account-loading"
    >
      <ion-spinner name="crescent" />
      <span>Fetching Account Details</span>
    </div>
    <ion-list>
      <!-- User NAME -->
      <ion-item v-if="userName">
        <ion-label>Name</ion-label>
        <ion-label
          slot="end"
          class="right-align"
        >
          {{ userName }}
        </ion-label>
      </ion-item>
      <!-- User EMAIL -->
      <ion-item v-if="userEmail">
        <ion-label>Email</ion-label>
        <ion-label
          slot="end"
          class="right-align"
        >
          {{ userEmail }}
        </ion-label>
      </ion-item>
      <!-- User MEMBERSHIP -->
      <AccountMembership />
      <!-- User ACCOUNT BUTTONS -->
      <div v-if="user">
        <div class="custom-divider">
          Account Options
        </div>
        <ion-item @click="changePassword()">
          <ion-label>Change Password</ion-label>
          <ion-icon
            slot="end"
            :icon="arrowForward"
            mode="ios"
          />
        </ion-item>
        <DeleteMyAccount
          v-if="isIos"
          :user="user"
        />
      </div>
    </ion-list>
  </div>
</template>
<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonList,
  IonItem,
  IonIcon,
  IonSpinner,
  IonLabel,
} from '@ionic/vue';
import { arrowForward } from 'ionicons/icons';
import AccountMembership from './AccountMembership.vue';
import DeleteMyAccount from './DeleteMyAccount.vue';
import { changePasswordFormModal } from '../modal/changePasswordForm.modal';
import capacitorService from '../services/capacitor.service';

export default {
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonList,
    IonItem,
    IonSpinner,
    IonIcon,
    IonLabel,
    AccountMembership,
    DeleteMyAccount,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      arrowForward,
      showingAlert: false,
      isIos: capacitorService.isIos,
    };
  },
  computed: {
    userName() {
      if (this.user && this.user.given_name) {
        return `${this.user.given_name} ${this.user.family_name || ''}`;
      }
      return null;
    },
    userEmail() {
      if (this.user && this.user.email) {
        return this.user.email;
      }
      return null;
    },
  },
  methods: {
    changePassword() {
      changePasswordFormModal();
    },
  },
};
</script>
<style scoped>
.account-loading {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}
</style>
