import {
  CONNECTIVITY_MUTATIONS_ENUM,
} from './connectivity.eum';
import { showNoNetworkModal } from '../../../services/connectivity/showNoNetworkModal';
import { removeNoNetworkModal } from '../../../services/connectivity/removeNoNetworkModal';
// import { recheckAsyncAuthFlow } from '../../../services/connectivity/recheckAsyncAuthFlow';

export const connectivityMutations = {
  [CONNECTIVITY_MUTATIONS_ENUM.SET_IS_CONNECTED_TO_INTERNET](state, connected) {
    state.isConnectedToInternet = connected;
    console.log(connected, 'connected');
    if (connected === false) {
      showNoNetworkModal(connected);
    } else {
      // recheckAsyncAuthFlow();
      removeNoNetworkModal(connected);
    }
  },
};
