import { getIconSize } from '../utils/getIconSize';
import { getWeatherSource } from './getWeatherSource';
import { getDayNightObject } from './getDayNightObject';
import AppConst from '../../../constants/app.constant';

const processWeatherData = (weatherData, settings) => {
  const weeklyData = [];
  let week;
  const incrementBy = 2;
  let i = 0;
  const weatherSource = getWeatherSource(weatherData, settings);
  let firstRecordRef;
  while (i < weatherSource.length) {
    week = {};
    firstRecordRef = null;
    if (i === 0) {
      if (settings.apiToUse === AppConst.possibleWeatherApi.mapClick) {
        firstRecordRef = weatherSource[i];
      } else if (settings.apiToUse === AppConst.possibleWeatherApi.govWeather) {
        firstRecordRef = weatherSource[i].name;
      }
    }
    if (i === 0 && (weatherSource[i].isDaytime === false || (firstRecordRef && firstRecordRef
      .toLowerCase().indexOf('night') > -1))) {
      week = null;
      i += 1;
    } else {
      week.day = getDayNightObject(weatherData, i, settings, true);
      week.night = getDayNightObject(weatherData, i, settings, false);
      week.showSecondary = false;
      week.hasSlider = true;
      i += incrementBy;
    }
    if (week) {
      weeklyData.push(week);
    }
  }
  return {
    weeklyData,
    iconSize: getIconSize(weeklyData.length),
    serviceUnavailable: (weeklyData.length === 0),
  };
};

export { processWeatherData };
