const isValidStorm = (storm) => {
  /**
     * Storm should have fcst
     * Storm should have hail. This will filter out storm with hailProb -999%
     */
  if (storm.fcst && storm.fcst.length >= 2 && storm.hail) {
    return true;
  }
  return false;
};

export { isValidStorm };
