const ignoreErrorsList = [
  'InvalidSignatureException',
  'rootScope:inprog',
  'setLastSyncCount',
  'firebug',
  'this._positions[0]',
  'Network error',
  'Network Error',
  'Synchronize failed: exceeded maximum retry count',
  "evaluating 'airportList.length'",
  'Rate exceeded',
  'Username/client id combination not found',
  'Refresh Token has expired',
  'Password attempts exceeded',
  'Incorrect username or password',
  'Missing required key',
  'Missing credentials in config',
  'The network connection was lost',
  'not allowed by Access-Control-Allow-Origin',
  '_startPos.add',
  'Could not load "elevation"',
  'Could not load credentials from constructor',
  'timeout of 20000ms exceeded',
  'Request timed out',
  'Loading chunk',
  'animate is not a function',
  'timeout of',
  'isProxied',
  'setLastModifiedDate',
  'Could not load "util"',
  'supported-color-schemes',
  'Ignored attempt to cancel',
  'ASSERT',
  'The Internet connection appears to be offline',
  'User is disabled',
  '$emit("targetNotFound"',
  'o is undefined',
  'classList',
];

const ignoredApiRequest = [
  '/radar',
  'api.weather.gov/gridpoints',
  '&t4=true',
];

export { ignoreErrorsList, ignoredApiRequest };
