<template>
  <IonItem>
    <ion-label>
      <h2 class="makebold">
        {{ product.title }}
      </h2>
      <p>{{ product.description }}</p>
    </ion-label>
    <ion-button
      slot="end"
      disabled="true"
      fill="solid"
      color="primary"
    >
      {{ product.price }}
    </ion-button>
    <ion-icon
      slot="start"
      :icon="checkmarkCircle"
      color="success"
    />
  </IonItem>
</template>
<script>
import { IonItem, IonIcon, IonLabel } from '@ionic/vue';
import { checkmarkCircle } from 'ionicons/icons';

export default {
  name: 'Subscription',
  components: {
    IonItem,
    IonIcon,
    IonLabel,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkmarkCircle,
    };
  },
};
</script>
