<template>
  <ion-buttons
    v-show="layerFor"
    slot="end"
  >
    <ion-button
      fill="solid"
      color="secondary"
      @click="openLayerUpdateTimeModalHandler"
    >
      <ion-spinner
        v-if="!getRenderTime()"
        color="light"
        name="circles"
      />
      <ion-icon
        v-else
        slot="icon-only"
        :icon="getMarkerStaus().icon"
        :class="getMarkerStaus().class"
      />
      <!-- <div
        v-else
        class="dataTime"
      >
        <span class="text">Data </span>
        <span class="time">{{ getRenderTime() }}</span>
      </div> -->
    </ion-button>
  </ion-buttons>
</template>
<script>
import {
  IonButton, IonSpinner, IonButtons, IonIcon,
} from '@ionic/vue';
import { checkmarkCircleOutline, warningOutline } from 'ionicons/icons';
import { mapGetters, mapMutations } from 'vuex';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import {
  LAYER_UPDATE_TIME_GETTER_ENUM,
  LAYER_UPDATE_TIME_MUTATION_ENUM,
} from '../../store/modules/layer-update-time/layer-update-time.enum';
import openLayerUpdateTimeModal from '../../modal/layer-update-time.modal';
import {
  MarkerStatus,
  ViewKeysMap,
} from '../../constants/layerUpdateTime.constant';

export default {
  components: {
    IonButton,
    IonSpinner,
    IonButtons,
    IonIcon,
  },
  props: {
    layerFor: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.LAYER_UPDATE_TIME, {
      getMapViewMarkerStatus:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_MAP_VIEW_MARKER_STATUS,
      getMetogramMarkerStatus: LAYER_UPDATE_TIME_GETTER_ENUM.GET_METEOGRAM_MARKER_STATUS,
      getTafMarkerStatus: LAYER_UPDATE_TIME_GETTER_ENUM.GET_TAF_MARKER_STATUS,
      getFcstdiscussionMarkerStatus:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_FCST_DISCUSSION_MARKER_STATUS,
      getMetarsMarkerStatus:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_METARS_MARKER_STATUS,
      getGridViewMarkerStatus: LAYER_UPDATE_TIME_GETTER_ENUM.GET_GRID_VIEW_MARKER_STATUS,
      getRouteProfileMarkerStatus:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_ROUTE_PROFILE_MARKER_STATUS,
      getWeatherMarkerStatus:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_WEATHER_MARKER_STATUS,
    }),
    ...mapGetters(STORE_NAMESPACE.LAYER_UPDATE_TIME, {
      getMapViewCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_MAP_VIEW,
      getFcstdiscussionCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_FCST,
      getMetarsCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_METARS,
      getMetogramCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_METEOGRAM,
      getTafCurrentRenderTime: LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_TAF,
      getGridViewCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_GRID_VIEW,
      getRouteProfileCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_ROUTE_PROFILE,
      getWeatherCurrentRenderTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_WEATHER,
    }),
  },
  unmounted() {
    this.clearCurrentScreenRenderTime();
  },
  methods: {
    openLayerUpdateTimeModalHandler() {
      openLayerUpdateTimeModal(this.layerFor);
    },
    ...mapMutations(STORE_NAMESPACE.LAYER_UPDATE_TIME, {
      clearCurrentScreenRenderTime:
        LAYER_UPDATE_TIME_MUTATION_ENUM.CLEAR_CURRENT_SCREEN_RENDER_TIME,
    }),
    getRenderTime() {
      const map = {
        [ViewKeysMap.MAPVIEW]: this.getMapViewCurrentRenderTime,
        [ViewKeysMap.METEOGRAM]: this.getMetogramCurrentRenderTime,
        [ViewKeysMap.TAFS]: this.getTafCurrentRenderTime,
        [ViewKeysMap.FCSTDISCUSSION]: this.getFcstdiscussionCurrentRenderTime,
        [ViewKeysMap.METARS]: this.getMetarsCurrentRenderTime,
        [ViewKeysMap.GRIDVIEW]: this.getGridViewCurrentRenderTime,
        [ViewKeysMap.ROUTEPROFILE]: this.getRouteProfileCurrentRenderTime,
        [ViewKeysMap.WEATHER]: this.getWeatherCurrentRenderTime,
      };
      return map[this.layerFor];
    },
    getMarkerStaus() {
      const map = {
        [ViewKeysMap.MAPVIEW]: this.getMapViewMarkerStatus,
        [ViewKeysMap.METEOGRAM]: this.getMetogramMarkerStatus,
        [ViewKeysMap.TAFS]: this.getTafMarkerStatus,
        [ViewKeysMap.FCSTDISCUSSION]: this.getFcstdiscussionMarkerStatus,
        [ViewKeysMap.METARS]: this.getMetarsMarkerStatus,
        [ViewKeysMap.GRIDVIEW]: this.getGridViewMarkerStatus,
        [ViewKeysMap.ROUTEPROFILE]: this.getRouteProfileMarkerStatus,
        [ViewKeysMap.WEATHER]: this.getWeatherMarkerStatus,
      };
      const markerStatus = map[this.layerFor];
      switch (markerStatus) {
        case MarkerStatus.UPDATED:
          return { icon: checkmarkCircleOutline, class: 'success' };
        case MarkerStatus.OUTDATED:
          return { icon: warningOutline, class: 'warning' };
        default:
          return { icon: warningOutline, class: 'warning' };
      }
    },
  },
};
</script>
<style scoped>
.dataTime {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dataTime .text {
  font-size: 12px;
}

.dataTime .time {
  font-size: 13px;
  position: relative;
}

.success {
  color: #2dd36f;
}

.warning {
  color: #ffc409;
}
</style>
