import Url from 'url-parse';
import { store } from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import { LAYER_UPDATE_TIME_MUTATION_ENUM } from '../../store/modules/layer-update-time/layer-update-time.enum';
import layerUpdateTimeConstant from '../../constants/layerUpdateTime.constant';

const findTerm = (url) => {
  const { pathname = '', query = '' } = new Url(url);
  const term = Object.values(layerUpdateTimeConstant).find((lut) => pathname.includes(lut.search));
  return { term, urlString: `${pathname}${query}` };
};

export const setTimeForLayer = (url) => {
  const { term, urlString } = findTerm(url);
  if (!term) {
    return;
  }
  store.commit(`${STORE_NAMESPACE.LAYER_UPDATE_TIME}/${LAYER_UPDATE_TIME_MUTATION_ENUM.CHANGE_TRACK_TIME}`, { search: term.search, urlString, retry: term.retry });
};

export const setLayerToPendingState = (url) => {
  const { term, urlString } = findTerm(url);
  if (!term) {
    return;
  }
  store.commit(`${STORE_NAMESPACE.LAYER_UPDATE_TIME}/${LAYER_UPDATE_TIME_MUTATION_ENUM.MARK_URL_PENDING}`, { search: term.search, urlString, retry: term.retry });
};

export const setLayerToFailState = (url) => {
  const { term, urlString } = findTerm(url);
  if (!term) {
    return;
  }
  store.commit(`${STORE_NAMESPACE.LAYER_UPDATE_TIME}/${LAYER_UPDATE_TIME_MUTATION_ENUM.MARK_URL_FAIL}`, { search: term.search, urlString, retry: term.retry });
};
