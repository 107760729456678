import AppConst from '../../../constants/app.constant';

const checkIfPasswordInvalid = (data) => {
  if (data && data.code && data.code === AppConst.exceptionsCodes.InvalidParameterException
    && data.message.indexOf("Value at 'password' failed to satisfy constraint") > -1) {
    return true;
  }
  return false;
};

export { checkIfPasswordInvalid };
