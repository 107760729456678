<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="quickactions">
    <ion-list v-if="metLayersSelector">
      <ion-item-divider class="item-divider">
        <ion-label>
          LAYERS
        </ion-label>
      </ion-item-divider>
      <ion-item
        v-if="showWindAloftLayerSelector"
        class="maplayer-item map-layers-toggle"
      >
        <ion-label>{{ metLayersSelector[0].name }}</ion-label>
        <ion-toggle
          v-model="metLayersSelector[0].checked"
          @ionChange="
            metLayerSelectChange('windlayer');
          "
        />
      </ion-item>
      <ion-item
        v-if="showMapLayerSelectorRoute"
        class="maplayer-item map-layers-toggle"
      >
        <ion-label>Winds Aloft</ion-label>
        <ion-toggle
          v-model="metLayersSelector[1].checked"
          @ionChange="
            metLayerSelectChange('windlayer');
          "
        />
      </ion-item>
      <ion-item class="maplayer-item map-layers-toggle">
        <ion-label>{{ metLayersSelector[3].name }}</ion-label>
        <ion-toggle
          v-model="metLayersSelector[3].checked"
          @ionChange="
            metLayerSelectChange();
          "
        />
      </ion-item>
      <ion-item class="maplayer-item map-layers-toggle">
        <ion-label>{{ metLayersSelector[4].name }}</ion-label>
        <ion-toggle
          v-model="metLayersSelector[4].checked"
          @ionChange="metLayerSelectChange();"
        />
      </ion-item>
      <ion-item
        v-for="(layer, keyidx) in metLayerSelectors"
        :key="keyidx"
        class="maplayer-item indentleft map-layers-toggle"
      >
        <ion-label>{{ layer.name }}</ion-label>
        <ion-toggle
          v-model="layer.checked"
          @ionChange="
            metLayerSelectChange();
          "
        />
      </ion-item>
      <ion-item
        v-for="(layer, keyidx) in mosMetarLayerSelectors"
        :key="keyidx"
        class="maplayer-item map-layers-toggle"
        :class="layer.name === 'Storm Track' ? 'indentleft':'' "
      >
        <ion-label>{{ layer.name }}</ion-label>
        <ion-toggle
          v-model="layer.checked"
          @ionChange="
            metLayerSelectChange();
          "
        />
      </ion-item>
      <ion-item
        v-for="(layer, keyidx) in radarLayerSelectors"
        :key="keyidx"
        class="maplayer-item map-layers-toggle"
        :class="layer.name === 'Storm Track' ? 'indentleft':'' "
      >
        <ion-label>{{ layer.name }}</ion-label>
        <ion-toggle
          v-model="layer.checked"
          @ionChange="
            metLayerSelectChange();
          "
        />
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import {
  IonItemDivider,
  IonList,
  IonItem,
  IonLabel,
  IonToggle,
} from '@ionic/vue';
import AppConst from '../../constants/app.constant';
import ConfigApplicationSettings from '../../config/application.config';
import ConfigDeviceSettings from '../../config/device.config';
import bnd3Service from '../../services/bnd3.service';
import bnChartDataProcessService from '../../services/chartDataProcess.service';
import router from '../../router';
import chartConstant from '../../constants/chart.constant';

export default {
  name: 'QuickChanges',
  components: {
    IonItemDivider,
    IonList,
    IonItem,
    IonLabel,
    IonToggle,
  },
  data() {
    return {
      formSetting: {},
      mapLayerTypes: AppConst.mapLayerTypes,
      mapStyle: ConfigApplicationSettings.settings.mapStyle,
      enableRadarFeature: ConfigDeviceSettings.settings.enableRadarFeature,
      metLayersSelector: null,
      showMapLayerGeoJsonSelector: false,
      showWindAloftLayerSelector: false,
      showMapLayerSelectorRoute: false,
      showMosMetarLayerSelector: false,
    };
  },
  computed: {
    metLayerSelectors() {
      if (this.metLayersSelector[4].checked) {
        return this.metLayersSelector.slice(5).filter((item) => item.mets);
      }
      return [];
    },
    mosMetarLayerSelectors() {
      if (this.metLayersSelector && this.showMosMetarLayerSelector) {
        return this.metLayersSelector.filter((item) => item.ruleset);
      }
      return [];
    },
    radarLayerSelectors() {
      if (this.enableRadarFeature && this.metLayersSelector && this.showMosMetarLayerSelector) {
        const radarLayer = this.metLayersSelector.filter((item) => item.radar);
        const mainRadarLayer = radarLayer.find(
          (item) => item.name === chartConstant.radarsLayerKey,
        );
        return mainRadarLayer.checked ? radarLayer : [mainRadarLayer];
      }
      return [];
    },
  },
  mounted() {
    this.metLayersSelector = bnChartDataProcessService.getMetsForLayer();
    this.showMapLayerSelectorRoute = this.checkIfshowWindAloftLayerSelectorRoute();
    this.showMosMetarLayerSelector = this.checkIfshowMosMetarLayerSelector();
    this.showMapLayerGeoJsonSelector = this.checkIfshowMapLayerGeoJsonSelector();
    this.showWindAloftLayerSelector = this.checkIfshowWindAloftLayerSelector();
  },
  methods: {
    savePreferences() {
      ConfigApplicationSettings.setapplicationConfig();
    },
    metLayerSelectChange(mapLayerChanged) {
      if (mapLayerChanged === 'metars') {
        bnChartDataProcessService.setSuppressedMetFromCharts(
          this.metLayersSelector,
        );
      } else {
        bnChartDataProcessService.setSuppressedMetFromCharts(
          this.metLayersSelector,
        );
        if (mapLayerChanged === 'windlayer') {
          bnd3Service.aloftSuppress();
        } else {
          bnd3Service.chartMetSuppress(this.metLayersSelector);
        }
      }
    },
    checkIfshowMapLayerSelector() {
      if (router.currentRoute.value.name === 'app.leafletmapview') {
        return true;
      }
      return false;
    },
    checkIfshowMosMetarLayerSelector() {
      if (
        router.currentRoute.value.name === 'app.leafletmapview'
        || router.currentRoute.value.name === 'app.mapview'
      ) {
        return true;
      }
      return false;
    },
    checkIfshowMapLayerGeoJsonSelector() {
      if (router.currentRoute.value.name === 'app.leafletmapview') {
        return true;
      }
      return false;
    },
    checkIfshowWindAloftLayerSelector() {
      if (router.currentRoute.value.name === 'app.dashboard') {
        return true;
      }
      return false;
    },
    checkIfshowWindAloftLayerSelectorRoute() {
      if (router.currentRoute.value.name === 'app.routeprofile') {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.item-divider ion-label {
  margin: 0;
}
.maplayer-item {
  --min-height: 30px;
}
.list-ios {
  margin: 0;
}
.map-layers-radio ion-label {
  margin: 6px 8px 6px 0;
}
.map-layers-toggle ion-label {
  margin: 8px 8px 8px 0;
}
</style>
