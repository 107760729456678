import {
  writeText,
} from '../../../../utils/canvas';
import { setLineDash } from '../utils/setLineDash';

const getDashArrayForMet = (airmet) => airmet.hazardConfig.strokeDashArray || [0, 0];

const drawAviationMetPolygon = (ctx, path, airmet, projection) => {
  ctx.beginPath();
  path(airmet);
  ctx.strokeStyle = airmet.hazardConfig.strokeColor;
  ctx.lineWidth = 1.5;
  ctx.globalAlpha = 1;
  setLineDash(ctx, getDashArrayForMet(airmet));
  ctx.stroke();
  // Fill only polygons
  if (airmet.geometry.type === 'Polygon') {
    ctx.fillStyle = airmet.hazardConfig.strokeColor;
    ctx.globalAlpha = 0.2;
    ctx.fill();
  } else if (airmet.geometry.type === 'LineString') {
    const latLongProj = projection(airmet.geometry.coordinates[0]);
    writeText(
      ctx,
      `${airmet.properties.hazard}@${airmet.properties.level}`,
      latLongProj[0] - 5,
      latLongProj[1] - 5,
    );
  }
};

export { drawAviationMetPolygon };
