import { LAYER_UPDATE_TIME_MUTATION_ENUM } from './layer-update-time.enum';
import { RequestStatus } from '../../../constants/layerUpdateTime.constant';

export const layerUpdateTimeMutation = {
  [LAYER_UPDATE_TIME_MUTATION_ENUM.CHANGE_TRACK_TIME](state, payload) {
    const key = { ...state.trackTime[payload.search] };
    key.urlStrings.delete(payload.urlString);
    if (key.urlStrings.size === 0 && key.status !== RequestStatus.FAILED) {
      key.status = RequestStatus.SUCCESS;
      key.time = new Date();
    }
    state.trackTime[payload.search] = key;
    state.retry = state.retry.filter((item) => item === payload.retry);
  },
  [LAYER_UPDATE_TIME_MUTATION_ENUM.MARK_URL_PENDING](state, payload) {
    const key = { ...state.trackTime[payload.search] };
    const urlStrings = key.urlStrings || new Set();
    urlStrings.add(payload.urlString);
    if ((state.retry.length > 0 && state.retry.includes(payload.retry))
    || state.retry.length === 0) {
      key.status = RequestStatus.PENDING;
    }
    key.urlStrings = urlStrings;
    state.trackTime[payload.search] = key;
  },
  [LAYER_UPDATE_TIME_MUTATION_ENUM.MARK_URL_FAIL](state, payload) {
    const key = { ...state.trackTime[payload.search] };
    key.status = RequestStatus.FAILED;
    key.urlStrings.delete(payload.urlString);
    state.trackTime[payload.search] = key;
    state.retry = state.retry.filter((item) => item === payload.retry);
  },
  [LAYER_UPDATE_TIME_MUTATION_ENUM.SET_CURRENT_SCREEN_RENDER_TIME](state) {
    state.currentScreenRenderTime = Date.now();
  },
  [LAYER_UPDATE_TIME_MUTATION_ENUM.CLEAR_CURRENT_SCREEN_RENDER_TIME](state) {
    state.currentScreenRenderTime = null;
    state.retry = [];
  },
  [LAYER_UPDATE_TIME_MUTATION_ENUM.RETRY_URL](state, payload) {
    state.retry.push(payload.search);
  },
};
