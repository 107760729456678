import ConfigDeviceSettings from '../../config/device.config';
import utilityService from '../utility.service';
import { getServiceHostPreference } from '../utils/getServiceHostPreference';

const getPaypalConfig = () => {
  const paypalConfig = {
    dev: {
      clientId: 'AfTD1z6GD1gShW5d1GXsxbmNU9gcvKEJeh58-EOzeEninxiqOADnPu8a05mVVT8uo0sg7omrLP2UbrQm',
      serviceUrl: `${getServiceHostPreference()}/iap-dev/`,
    },
    prod: {
      clientId: 'AXvbiinS95SUyQDk3adBPPHqCF-4QQIZbeWQFCdKjStZT6mxLD4QR8QAQZ1698ix0xFY4efdqSbU1wiI',
      serviceUrl: `${getServiceHostPreference()}/iap/`,
    },
  };

  if (utilityService.isCordovaApplication()) {
    if (ConfigDeviceSettings.settings.enableDeveloperMode) {
      return paypalConfig.dev;
    }
    return paypalConfig.prod;
  }
  if (window.location.hostname === 'app.weatherspork.com') {
    return paypalConfig.prod;
  }
  return paypalConfig.dev;
};

export { getPaypalConfig };
