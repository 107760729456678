/*
 adding the new altitude require to add the "alt value"
 for that altitude which is mapped inside
 def.constant.js "altServiceOptions"
*/
export const MSL6K = { text: ' 6K (MSL)', feet: 6000 };
export const MSL12K = { text: '12K (MSL)', feet: 12000 };
export const FL20K = { text: 'FL200', feet: 20000 };
export const FL28K = { text: 'FL280', feet: 28000 };
export const FL45K = { text: 'FL450', feet: 45000 };

export const meteogram = [MSL6K, MSL12K, FL20K, FL28K];
export const routeProfile = [MSL6K, MSL12K, FL20K, FL28K, FL45K];
