import AppConst from '../../../constants/app.constant';
import d3 from '../../../globalexports/d3.export';

const dayFormat = '%-d %b';

const getDateFormatDay = (settings) => {
  let dateFormat;
  if (settings.timeZone === AppConst.timeZones.local.key) {
    dateFormat = d3.timeFormat(dayFormat);
  } else {
    dateFormat = d3.utcFormat(dayFormat);
  }
  return dateFormat;
};

export { getDateFormatDay };
