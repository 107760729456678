import WeatherConst from '../../../constants/weather.constant';

const getWeatherIcon = (iconLink) => {
  let i = 0;
  let weatherType;
  let matchFound;
  while (i < WeatherConst.weatherType.length) {
    weatherType = WeatherConst.weatherType[i];
    matchFound = false;
    if (iconLink.match(new RegExp(weatherType.regex))) {
      matchFound = true;
      break;
    }
    if (matchFound) {
      break;
    }
    i += 1;
  }
  return weatherType;
};

export { getWeatherIcon };
