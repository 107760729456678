import * as d3 from 'd3';
// Override prototypes
d3.selection.prototype.dblTap = function (callback) {
  let last = 0;
  return this.each((...args) => {
    d3.select(args[2][args[1]]).on('touchstart', (d3Event) => {
      if (d3Event.timeStamp - last < 500) {
        return callback(d3Event);
      }
      last = d3Event.timeStamp;
    });
  });
};
export default d3;
