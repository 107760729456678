import { STORM_TYPE } from './stormType.enum';

const getStormIconName = (storm) => {
  let icon = null;
  if (storm.hail) {
    const { probSevere, probHail } = storm.hail;
    if (probSevere > 0 || probHail >= 80) {
      icon = STORM_TYPE.HAIL;
    }
  }
  if (storm.tvs) {
    icon = STORM_TYPE.TORNADO;
  }
  if (storm.meso) {
    icon = STORM_TYPE.MESOCYCLONE;
  }
  return icon;
};

export { getStormIconName };
