/**
 *
 * @param {Canvas.context} ctx
 * @param {Canvas.PATH} path
 * @param {GeoJSON LineString} lineStringPointsGeoJson
 */
const drawStormPath = (ctx, path, lineStringPointsGeoJson) => {
  ctx.beginPath();
  path(lineStringPointsGeoJson);
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#fff';
  ctx.stroke();
};

export { drawStormPath };
