import { getCognitoUserPool } from '../core/getCognitoUserPool';
import { refreshUserSession } from './refreshUserSession';

export const getCurrentUser = () => new Promise((resolve) => {
  const cognitoUser = getCognitoUserPool().getCurrentUser();
  if (cognitoUser != null) {
    cognitoUser.getSession((err1, oldSession) => {
      if (!err1) {
        const refreshToken = oldSession.getRefreshToken();
        if (refreshToken) {
          refreshUserSession(cognitoUser, refreshToken).then(() => {
            resolve(cognitoUser);
          });
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    });
  } else {
    resolve(null);
  }
});
