import Flatten from 'lodash/flatten';
import { LAYER_UPDATE_TIME_GETTER_ENUM } from './layer-update-time.enum';
import ConfigApplicationSettings from '../../../config/application.config';
import ConfigDeviceSettings from '../../../config/device.config';
import ChartConst from '../../../constants/chart.constant';
import layerUpdateTimeConstant, { MarkerStatus, RequestStatus } from '../../../constants/layerUpdateTime.constant';
import AppConst from '../../../constants/app.constant';
import d3 from '../../../globalexports/d3.export';
import utilityService from '../../../services/utility.service';

const {
  route,
  grib,
  taf,
  product,
  metar,
  mapViewMetar,
  mos,
  met,
  gAirMet,
  stormtrack,
  forecast,
  forecastSecondary,
  rala_00,
  rala_10,
  rala_20,
  rala_30,
  rala_40,
  rala_50,
  rala_data,
} = layerUpdateTimeConstant;

function formatter() {
  if (ConfigApplicationSettings.settings.timeZone === AppConst.timeZones.local.key) {
    return d3.timeFormat(ChartConst.xAxis.formatLocal + ChartConst.GraphConfig.xAxis
      .timePostFix.local);
  }
  return d3.utcFormat(ChartConst.xAxis.format + ChartConst.GraphConfig.xAxis
    .timePostFix.zulu);
}

const checkAnyRequestIs = (status, urls, state) => Flatten(urls)
  .some((url) => state.trackTime[url.search]?.status === status);

const groupUrlListItem = (urls, state) => {
  const { FAILED, SUCCESS, PENDING } = RequestStatus;
  let status;
  if (checkAnyRequestIs(FAILED, urls, state)) {
    status = FAILED;
  } else if (checkAnyRequestIs(PENDING, urls, state)) {
    status = PENDING;
  } else if (checkAnyRequestIs(SUCCESS, urls, state)) {
    status = SUCCESS;
  }
  const {
    name, search, info, retry,
  } = urls[0];
  return {
    name,
    // time: time ? formatter()(time) : undefined,
    url: search,
    status,
    info,
    retry,
  };
};

const getLayerUpdateTimeList = (selectedUrl = []) => (state) => selectedUrl
  .map((urls) => {
    if (Array.isArray(urls)) {
      return groupUrlListItem(urls, state);
    }
    const {
      // time,
      status,
    } = state.trackTime[urls.search] || {};
    return {
      name: urls.name,
      // time: time ? formatter()(time) : undefined,
      url: urls.search,
      status,
      info: urls.info,
      retry: urls.retry,
    };
  });

const isAllSuccess = (state, selectedUrl = [], ignoreForUnknown = []) => {
  /*
    some url eg map view metar is loaded later when zoom in
    and we want to show current time after every request completed
  */
  /* remove from ignore list if url have status */
  const ignoreList = ignoreForUnknown.filter((url) => !state.trackTime[url.search]?.status);
  /*
    grouping the urls and picking one because in
    weather screen forecast url is loaded with different url
    which means the group is considered successfully loaded when either of url is success

    whereas in case of map view rala group all the request should be loaded

    remove from selected url list if it is in ignore list
  */
  return getLayerUpdateTimeList(selectedUrl)(state)
    .filter((list) => !ignoreList.some((ignore) => ignore.search === list.url))
    .every((list) => list.status === RequestStatus.SUCCESS);
};

const getMarkerStatus = (selectedUrl = [], ignoreForUnknown = []) => (state) => {
  const isFailed = checkAnyRequestIs(RequestStatus.FAILED, selectedUrl, state);
  if (isFailed) {
    return MarkerStatus.OUTDATED;
  }

  const allSuccess = isAllSuccess(state, selectedUrl, ignoreForUnknown);
  if (allSuccess) {
    return MarkerStatus.UPDATED;
  }

  return MarkerStatus.PENDING;
};

const getCurrentScreenRenderTime = (selectedUrl = [], ignoreForUnknown = []) => (state) => {
  const isFailed = checkAnyRequestIs(RequestStatus.FAILED, selectedUrl, state);
  if (state.currentScreenRenderTime && isFailed) {
    return formatter()(state.currentScreenRenderTime);
  }

  const allSuccess = isAllSuccess(state, selectedUrl, ignoreForUnknown);
  if (state.currentScreenRenderTime && allSuccess) {
    return formatter()(state.currentScreenRenderTime);
  }

  return '';
};

const meteogramUrls = [route, grib];
const getMeteogramLayerTime = (state) => getLayerUpdateTimeList(meteogramUrls)(state);
const getMeteogramMarkerStatus = (state) => getMarkerStatus(meteogramUrls)(state);
const getMeteogramCurrentRenderTime = (state) => getCurrentScreenRenderTime(meteogramUrls)(state);

const routeProfileUrls = [route, grib];
const getRouteProfileLayerTime = (state) => getLayerUpdateTimeList(routeProfileUrls)(state);
const getRouteProfileMarkerStatus = (state) => getMarkerStatus(routeProfileUrls)(state);
// eslint-disable-next-line max-len
const getRouteProfileCurrentRenderTime = (state) => getCurrentScreenRenderTime(routeProfileUrls)(state);

const tafUrls = [taf];
const getTafLayerTime = (state) => getLayerUpdateTimeList(tafUrls)(state);
const getTafMarkerStatus = (state) => getMarkerStatus(tafUrls)(state);
const getTafCurrentRenderTime = (state) => getCurrentScreenRenderTime(tafUrls)(state);

const fcstUrls = [product];
const getFcstdiscussionLayerTime = (state) => getLayerUpdateTimeList(fcstUrls)(state);
const getFcstdiscussionMarkerStatus = (state) => getMarkerStatus(fcstUrls)(state);
const getFcstdiscussionCurrentRenderTime = (state) => getCurrentScreenRenderTime(fcstUrls)(state);

const metarsUrls = [metar];
const getMetarsLayerTime = (state) => getLayerUpdateTimeList(metarsUrls)(state);
const getMetarsMarkerStatus = (state) => getMarkerStatus(metarsUrls)(state);
const getMetarsCurrentRenderTime = (state) => getCurrentScreenRenderTime(metarsUrls)(state);

const gridViewUrls = [route];
const getGridViewProfileLayerTime = (state) => getLayerUpdateTimeList(gridViewUrls)(state);
const getGridViewMarkerStatus = (state) => getMarkerStatus(gridViewUrls)(state);
const getGridViewCurrentRenderTime = (state) => getCurrentScreenRenderTime(gridViewUrls)(state);

const getSuppressedMet = () => utilityService.getSuppressedMetFromLocalStorage();

const shouldShowRadar = () => {
  const filter = getSuppressedMet();
  return (
    filter.indexOf(ChartConst.RadarFilterKey) === -1
  );
};

const shouldShowStormTrack = () => {
  const filter = getSuppressedMet();
  return (
    filter.indexOf(ChartConst.RadarFilterKey) === -1
    && filter.indexOf(ChartConst.stormTrackFilterKey) === -1
  );
};

const mapViewIgnoreForUnkownWithOtherRequest = [mapViewMetar];
const mapViewUrls = () => {
  const ralaGroup = [rala_00, rala_10, rala_20, rala_30, rala_40, rala_50, rala_data];
  const urls = [route, mapViewMetar, mos, met, gAirMet];
  if (ConfigDeviceSettings.settings.enableRadarFeature) {
    if (shouldShowRadar()) {
      urls.push(ralaGroup);
    }
    if (shouldShowStormTrack()) {
      urls.push(stormtrack);
    }
  }
  return urls;
};

const getMapViewLayerTime = (state) => getLayerUpdateTimeList(mapViewUrls())(state);
const getMapViewMarkerStatus = (state) => getMarkerStatus(
  mapViewUrls(),
  mapViewIgnoreForUnkownWithOtherRequest,
)(state);
const getMapViewCurrentRenderTime = (state) => getCurrentScreenRenderTime(
  mapViewUrls(),
  mapViewIgnoreForUnkownWithOtherRequest,
)(state);

const weatherUrls = () => {
  const urls = [];
  switch (ConfigApplicationSettings.settings.weatherApiToUse) {
    case AppConst.possibleWeatherApi.govWeather:
      urls.push(forecast);
      break;
    case AppConst.possibleWeatherApi.mapClick:
      urls.push(forecastSecondary);
      break;
    default:
      break;
  }
  return urls;
};

const getWeatherLayerTime = (state) => getLayerUpdateTimeList(weatherUrls())(state);
const getWeatherMarkerStatus = (state) => getMarkerStatus(weatherUrls())(state);
const getWeatherCurrentRenderTime = (state) => getCurrentScreenRenderTime(weatherUrls())(state);

export const layerUpdateTimeGetter = {
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_METEOGRAM_LAYER_UPDATE_TIME]: getMeteogramLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_TAF_LAYER_UPDATE_TIME]: getTafLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_FCST_DISCUSSION_LAYER_UPDATE_TIME]: getFcstdiscussionLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_METARS_LAYER_UPDATE_TIME]: getMetarsLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_ROUTE_PROFILE_LAYER_UPDATE_TIME]: getRouteProfileLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_GRID_VIEW_LAYER_UPDATE_TIME]: getGridViewProfileLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_MAP_VIEW_LAYER_UPDATE_TIME]: getMapViewLayerTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_WEATHER_LAYER_UPDATE_TIME]: getWeatherLayerTime,

  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_MAP_VIEW_MARKER_STATUS]: getMapViewMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_GRID_VIEW_MARKER_STATUS]: getGridViewMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_METEOGRAM_MARKER_STATUS]: getMeteogramMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_ROUTE_PROFILE_MARKER_STATUS]: getRouteProfileMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_TAF_MARKER_STATUS]: getTafMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_FCST_DISCUSSION_MARKER_STATUS]: getFcstdiscussionMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_METARS_MARKER_STATUS]: getMetarsMarkerStatus,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_WEATHER_MARKER_STATUS]: getWeatherMarkerStatus,

  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_FCST]:
    getFcstdiscussionCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_GRID_VIEW]:
    getGridViewCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_MAP_VIEW]:
    getMapViewCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_METARS]:
    getMetarsCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_METEOGRAM]:
    getMeteogramCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_ROUTE_PROFILE]:
    getRouteProfileCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_TAF]: getTafCurrentRenderTime,
  [LAYER_UPDATE_TIME_GETTER_ENUM.GET_CURRENT_SCREEN_RENDER_TIME_WEATHER]:
    getWeatherCurrentRenderTime,
};
