import {
  mrmsState,
} from './mrms.state';
import {
  mrmsActions,
} from './mrms.action';
import {
  mrmsMutations,
} from './mrms.mutation';
import {
  mrmsGetters,
} from './mrms.getter';

export const mrms = {
  namespaced: true,
  state: mrmsState,
  mutations: mrmsMutations,
  actions: mrmsActions,
  getters: mrmsGetters,
};
