import { subscriptionState } from './subscription.state';
import { subscriptionActions } from './subscription.action';
import { subscriptionMutations } from './subscription.mutation';
import { subscriptionGetters } from './subscription.getter';

export const subscription = {
  namespaced: true,
  state: subscriptionState,
  mutations: subscriptionMutations,
  actions: subscriptionActions,
  getters: subscriptionGetters,
};
