// outdated data is in minutes
// outdated is not in use
export default {
  route: {
    search: 'service/route',
    name: 'Route',
    outdated: 10,
    retry: 'service/route',
  },
  grib: {
    search: 'api/grib/get-grib',
    name: 'Clouds and Winds Aloft',
    outdated: 10,
    retry: 'api/grib/get-grib',
  },
  taf: {
    search: 'api/taf',
    name: 'Taf',
    outdated: 10,
    retry: 'api/taf',
  },
  product: {
    search: 'product.php',
    name: 'Fcst discussion',
    outdated: 10,
    retry: 'product.php',
  },
  metar: {
    search: 'bnforecast/service/metar',
    name: 'METAR',
    outdated: 10,
    retry: 'bnforecast/service/metar',
  },
  mos: {
    search: 'api/all-mos',
    name: 'MOS',
    outdated: 10,
    retry: 'api/all-mos',
  },
  mapViewMetar: {
    search: 'service/all-metar',
    name: 'METAR',
    outdated: 10,
    retry: 'service/all-metar',
  },
  met: {
    search: 'service/met',
    name: 'MET',
    outdated: 10,
    retry: 'service/met',
  },
  gAirMet: {
    search: 'Gairmet',
    name: 'G-AIRMET',
    outdated: 10,
    retry: 'Gairmet',
  },
  stormtrack: {
    search: 'st/storm_data_final',
    name: 'Storm Track',
    outdated: 10,
    retry: 'st/storm_data_final',
  },
  forecast: {
    search: 'points',
    name: 'Forecast',
    outdated: 10,
    retry: 'points',
  },
  forecastSecondary: {
    search: 'api/forecastweathergov',
    name: 'Forecast',
    outdated: 10,
    retry: 'api/forecastweathergov',
  },
  rala_00: {
    search: 'rala/raster/low/rala_00',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_10: {
    search: 'rala/raster/low/rala_10',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_20: {
    search: 'rala/raster/low/rala_20',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_30: {
    search: 'rala/raster/low/rala_30',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_40: {
    search: 'rala/raster/low/rala_40',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_50: {
    search: 'rala/raster/low/rala_50',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
  rala_data: {
    search: 'raster-meta-data',
    name: 'MRMS',
    outdated: 10,
    retry: 'raster',
  },
};

export const MarkerStatus = {
  UPDATED: 'UPDATED',
  OUTDATED: 'OUTDATED',
  PENDING: 'PENDING',
};

export const RequestStatus = {
  PENDING: 'PENDING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
};

export const ViewKeysMap = {
  MAPVIEW: 'MAPVIEW',
  METEOGRAM: 'METEOGRAM',
  TAFS: 'TAFS',
  FCSTDISCUSSION: 'FCSTDISCUSSION',
  METARS: 'METARS',
  RADAR: 'RADAR',
  SKEW: 'SKEW',
  GRIDVIEW: 'GRIDVIEW',
  ROUTEPROFILE: 'ROUTEPROFILE',
  WEATHER: 'WEATHER',
};
