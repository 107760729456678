/**
 *
 * @param {Array[Lat, Lng]} start
 * @param {Array[Lat, Lng]} end
 * @returns
 */
const getDirectionFromAtoB = (start, end) => {
  const x = start[0];
  const y = start[1];
  const xR = end[0] - x;
  const yR = end[1] - y;
  const r = 90 - Math.atan2(-yR, xR) * (180 / Math.PI);
  if ((r) >= 0 && r <= 90) {
    return 'nw';
  }
  if ((r) > 90 && r <= 180) {
    return 'ne';
  }
  if ((r) > 180 && r <= 270) {
    return 'se';
  }
  return 'sw';
};

export { getDirectionFromAtoB };
