import d3 from '../../../../globalexports/d3.export';
import GlobeConst from '../../../../constants/globe.constant';

/*
   * Call this function to get globe back in bounds of given geojson
   * @function getInBounds
   * @param ctx
   * @param width
   * @param height
   * @param projection
   * @param geoJson
   */
const getInBounds = (globeCanvasConfig, width, height, projection, geoJson) => {
  // const point = d3.geoCentroid(geoJson);
  projection.fitExtent([
    [GlobeConst.boundingBoxPaddingOffset, GlobeConst.boundingBoxPaddingOffset],
    [width - GlobeConst.boundingBoxPaddingOffset, height - GlobeConst
      .boundingBoxPaddingOffset,
    ],
  ], geoJson);
  globeCanvasConfig.canvas.property('__zoom', d3.zoomIdentity.translate(...projection
    .translate()).scale(projection.scale()));
  // d3.select(ctx.canvas)
  //     .property("__zoom", d3.zoomIdentity.scale(projection.scale()));
  /**
     * Below commented code is for adding animation to fitting bound
     */
  // d3.transition()
  //     // .delay(250)
  //     .duration(1275)
  //     .tween("rotate", () => {
  //         var point = d3.geoCentroid(geoJson);
  //         var rotate = d3.interpolate(projection.rotate(), [-point[0], -point[1]]);
  //         return function (t) {
  //             projection.rotate(rotate(t)).fitExtent([
  //                 [GlobeConst.boundingBoxPaddingOffset, GlobeConst.boundingBoxPaddingOffset],
  //                 [width - GlobeConst.boundingBoxPaddingOffset,
  //                height - GlobeConst.boundingBoxPaddingOffset]
  //             ], geoJson);
  //             d3.select(ctx.canvas)
  //                 .property("__zoom", d3.zoomIdentity.scale(projection.scale()));
  //             callback();
  //             // country.attr("d", path);
  //         };
  //     }).transition()
  //     .on("end", () => {
  //         console.log("Transition Completed");
  //         callback(null, true);
  //     });
};
export { getInBounds };
