import { replaceAll } from '../utils/replaceAll.polyfill';
import { getUTCConfig } from './utils/getUTCConfig';

const processImageUrl = (image, adjustedTime, adjustedCycleTime) => {
  const utcConfig = getUTCConfig(adjustedTime, adjustedCycleTime);
  const allKeys = Object.keys(utcConfig.replacebleConfig);
  return allKeys.reduce(
    (accumalator, configKey) => replaceAll(accumalator, `{${configKey}}`, utcConfig.replacebleConfig[configKey]),
    image,
  );
};

export { processImageUrl };
