import applicationDataSource from '../../datasource/applicationdata.datasource';

const processNewMetar = (data) => {
  if (!data || !data[0]) {
    return null;
  }
  const points = [];
  const allMetarMap = data.reduce((acc, item) => {
    acc[item.s] = item.f;
    return acc;
  }, {});
  const metars = [];
  applicationDataSource.airportsListing.forEach((airport) => {
    if (allMetarMap[airport.icao_id]) {
      const metarAirport = {
        icao_id: airport.icao_id,
        frl: allMetarMap[airport.icao_id],
        lat: airport.lat,
        lng: airport.lng,
      };
      points.push([airport.lng, airport.lat, airport.icao_id]);
      metars.push(metarAirport);
    }
  });
  return {
    allMetarData: data,
    metars,
    points,
    allMetarMap,
  };
};

export { processNewMetar };
