import {
  modalController,
} from '@ionic/vue';
import LayerUpdate from '../components/layerUpdateTime/layerUpdateTime.vue';

export default async (layerFor) => {
  const modelElement = await modalController
    .create({
      component: LayerUpdate,
      componentProps: { layerFor },
      backdropDismiss: false,
      keyboardClose: false,
    });
  return modelElement.present();
};
