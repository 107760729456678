const getPriceOfPaypalProduct = (product) => `$${product.billing_cycles[0].pricing_scheme.fixed_price.value}`;
const getDurationOfPaypalProduct = (product) => product.billing_cycles[0].frequency.interval_unit;

const getPayPalProductsForState = (resProducts) => {
  let products = [];
  if (resProducts && resProducts.map) {
    products = resProducts.map((product) => {
      if (product && product.billing_cycles && product.billing_cycles[0]) {
        return {
          title: product.name,
          description: product.description,
          price: getPriceOfPaypalProduct(product),
          duration: getDurationOfPaypalProduct(product),
          productId: product.id,
          status: product.status,
          isPaypalProduct: true,
        };
      }
      return null;
    });
    return products.filter((product) => product);
  }
};

export { getPayPalProductsForState };
