import AWS from 'aws-sdk';
import { synchronizeDataset } from './synchronizeDataset';
import { setCredentialsForSync } from './setCredentialsForSync';

const removeInternalMetaData = () => {
  // remove this key to get latest sync data
  localStorage.removeItem('_internal._metadata');
};

const initCognitoSync = (jwtToken) => {
  removeInternalMetaData();
  setCredentialsForSync(jwtToken);
  AWS.config.credentials.get(() => {
    synchronizeDataset();
  });
};

export { initCognitoSync };
