<template>
  <ion-button
    fill="solid"
    color="secondary"
    class="custom-button footer-slider-label-button"
  >
    {{ label }}
    <ion-icon
      v-if="showIcon"
      slot="end"
      :icon="chevronUpOutline"
    />
  </ion-button>
</template>
<script>
import { chevronUpOutline } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/vue';

export default {
  name: 'FooterSliderLabel',
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chevronUpOutline,
    };
  },
};
</script>
