import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  CONNECTIVITY_ACTIONS_ENUM,
} from '../../store/modules/connectivity/connectivity.eum';
/**
 *
 * @returns Promise
 */
const setNetworkStatus = async () => {
  await store.dispatch(
    `${STORE_NAMESPACE.CONNECTIVITY}/${CONNECTIVITY_ACTIONS_ENUM.SET_NETWORK_STATUS}`,
  );
};
export {
  setNetworkStatus,
};
