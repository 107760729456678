import utilityService from '../utility.service';
import ChartConst from '../../constants/chart.constant';
import { processWeatherValue } from './processWeatherValue';

const meteoProcessData = function (data) {
  if (data && data.forecast) {
    data.forecast.forEach((row) => {
      // converting wsp from mph to knots
      row.wsp = (row.wsp !== null && row.wsp !== undefined)
        ? parseFloat(row.wsp) // / 1.15078
        : null;
      row.xwd = (row.xwd !== null && row.xwd !== undefined)
        ? parseFloat(row.xwd) // / 1.15078
        : null;
      row.wgs = (row.wgs !== null && row.wgs !== undefined)
        ? parseFloat(row.wgs) // / 1.15078
        : null;
      row.q06_mapped = ChartConst.mavDefsMap.q06[row.q06] !== undefined
        ? ChartConst.mavDefsMap.q06[row.q06]
        : null;
      row.tmp_fahrenheit = row.tmp;
      row.tmp_celsius = utilityService.fahrenheitToCelsius(row.tmp);
      row = processWeatherValue(row, 'forecast_ts_local');
    });
  }
  return data;
};

export { meteoProcessData };
