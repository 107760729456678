/**
 *
 * @param {*} ctx
 * @param {*} canvas
 */
const clearCanvas = (ctx, canvas) => {
  if (ctx) {
    ctx.clearRect(0, 0, canvas.attr('width'), canvas.attr('height'));
  }
};

export default clearCanvas;
