<template>
  <div v-if="showAeroplane">
    <div class="preloaderoverlay" />
    <div class="preloader">
      <img src="static/img/loader.gif">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { AEROPLANE_LOADER_GETTERS_ENUM } from '../store/modules/aeroplane-loader/aeroplane-loader.eum';

export default {
  name: 'AppLoader',
  computed: {
    ...mapGetters(STORE_NAMESPACE.AEROPLANE_LOADER, {
      showAeroplane: AEROPLANE_LOADER_GETTERS_ENUM.GET_SHOW_AEROPLANE,
    }),
  },
};
</script>
<style scoped>
.preloaderoverlay {
  z-index: 99;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 1;
}
.preloader {
  position: absolute;
  width: 64px;
  height: 64px;
  margin: auto;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
}
.preloader img {
  width: inherit;
  height: inherit;
}
</style>
