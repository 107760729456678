<template>
  <svg
    class="image-with-zoom-pan"
    :width="imageWidth"
    :height="imageHeight"
  />
</template>

<script setup>
import { watchEffect } from 'vue';
import d3 from '../../globalexports/d3.export';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  imageWidth: {
    type: Number,
    required: true,
  },
  imageHeight: {
    type: Number,
    required: true,
  },
});

watchEffect((onCleanup) => {
  let zoomItemG;
  function zoomed(event) {
    zoomItemG.attr('transform', event.transform.toString());
  }

  const zoom = d3
    .zoom()
    .scaleExtent([1, 14])
    .translateExtent([
      [0, 0],
      [props.imageWidth, props.imageHeight],
    ])
    .on('zoom', zoomed);
  const mainSVG = d3
    .select('svg.image-with-zoom-pan').append('g');

  zoomItemG = mainSVG.call(zoom).append('g');
  zoomItemG.append('image')
    .attr('width', props.imageWidth)
    .attr('height', props.imageHeight)
    .attr('xlink:href', props.url);

  onCleanup(() => {
    d3.select('svg.image-with-zoom-pan g').remove();
  });
});
</script>
