const MESSAGE_LOADER_ACTIONS_ENUM = {
  SET_LOADING_MESSAGE: 'SET_LOADING_MESSAGE',
};

Object.freeze(MESSAGE_LOADER_ACTIONS_ENUM);

const MESSAGE_LOADER_GETTERS_ENUM = {
  GET_LOADING_MESSAGE: 'GET_LOADING_MESSAGE',
};

Object.freeze(MESSAGE_LOADER_GETTERS_ENUM);

const MESSAGE_LOADER_MUTATIONS_ENUM = {
  SET_LOADING_MESSAGE: 'SET_LOADING_MESSAGE',
};

Object.freeze(MESSAGE_LOADER_MUTATIONS_ENUM);

export {
  MESSAGE_LOADER_ACTIONS_ENUM,
  MESSAGE_LOADER_MUTATIONS_ENUM,
  MESSAGE_LOADER_GETTERS_ENUM,
};
