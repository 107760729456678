import { getCurrentUser } from './getCurrentUser';
import { initCognitoSync } from '../cognitoSync/initCognitoSync';

const checkUserAuthenticated = () => new Promise((resolve) => {
  const resStatus = {
    status: false,
  };
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      cognitoUser.getSession((err1, session) => {
        initCognitoSync(session.idToken.jwtToken);
      });
      resStatus.status = true;
      resolve(resStatus);
    } else {
      resolve(resStatus);
    }
  });
});

export { checkUserAuthenticated };
