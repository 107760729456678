<template>
  <ion-list>
    <div>
      <div class="custom-divider">
        Membership
      </div>
      <div>
        <ion-item>
          <ion-label>Expiration date</ion-label>
          <ion-label
            slot="end"
            class="right-align expiryinfo"
          >
            {{ appleIapExpiryDate }}
          </ion-label>
        </ion-item>
        <AccountActiveMembershipProduct
          v-if="activeApplePayPlan"
          :product="activeApplePayPlan"
        />
      </div>
    </div>
  </ion-list>
</template>
<script>
import { IonList, IonItem, IonLabel } from '@ionic/vue';
import { mapGetters } from 'vuex';
import AppConst from '../constants/app.constant';
import AccountConst from '../constants/account.constant';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
import AccountActiveMembershipProduct from './AccountActiveMembershipProduct.vue';

export default {
  components: {
    IonList,
    IonItem,
    IonLabel,
    AccountActiveMembershipProduct,
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      user: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      products: SUBSCRIPTION_GETTERS_ENUM.GET_ALL_IAP_PRODUCTS,
    }),
    activeApplePayPlan() {
      if (this.products && this.user && this.user[AccountConst.customAttribute.t1]) {
        return this.products.find(
          (product) => product.id === this.user[AccountConst.customAttribute.t1],
        );
      }
      return null;
    },
    appleIapExpiryDate() {
      const d = this.user[AccountConst.customAttribute.t2];
      if (d) {
        return new Date(d).toLocaleDateString(AppConst.buserPreferredDatedFormatLocale, { dateStyle: 'medium' });
      }
      return undefined;
    },
  },
};
</script>
