import {
  USER_GETTERS_ENUM,
} from './user.enum';

export const userGetters = {
  [USER_GETTERS_ENUM.GET_USER_DETAILS](state) {
    return state.userDetails;
  },
  [USER_GETTERS_ENUM.GET_IS_LOGGED_IN](state) {
    return state.isLoggedIn;
  },
  [USER_GETTERS_ENUM.GET_USER_LOCATION](state) {
    return state.location;
  },
};
