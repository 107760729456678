import AccountConst from '../../../../constants/account.constant';
import AppConst from '../../../../constants/app.constant';
import { getCognitoUserAttribute } from '../../core/getCognitoUserAttribute';
import { updateUserAttributes } from '../../core/updateUserAttributes';
import { getAppInfo } from '../../../capacitor/getApp.info';
import { getVersionUsageValueToSet } from '../../utils/getVersionUsageValueToSet';
// import { deleteT1T2Cognito } from './deleteT1T2Cognito';

const serVersionUsage = async () => {
  const attributeList = [];
  const attributeVersionUsage = getCognitoUserAttribute(
    AccountConst.customAttribute.versionUsage,
    JSON.stringify(await getVersionUsageValueToSet()),
  );
  attributeList.push(attributeVersionUsage);
  return updateUserAttributes(attributeList);
};

const setAppVersionUsed = async () => {
  const { appVersion } = await getAppInfo();
  const attributeList = [];
  const attributeAppVersionUsed = getCognitoUserAttribute(
    AccountConst.customAttribute.appVersionUsed,
    AppConst.application.version,
  );
  // const attributeFsUsed = getCognitoUserAttribute(
  //   AccountConst.customAttribute.fs,
  //   '2021-12-30',
  // );
  // deleteT1T2Cognito();
  const attributeAppFlowVersionUsed = getCognitoUserAttribute(
    AccountConst.customAttribute.appFlowVersionUsed,
    `${appVersion || AppConst.application.version}/${AppConst.application.bundleVersion}`,
  );
  attributeList.push(attributeAppVersionUsed);
  attributeList.push(attributeAppFlowVersionUsed);
  // attributeList.push(attributeFsUsed);
  return updateUserAttributes(attributeList);
};

export { setAppVersionUsed, serVersionUsage };
