import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { getCognitoUserPool } from '../core/getCognitoUserPool';
import { appendUserNameToMessage } from '../utils/appendUserNameToMessage';
import { clearLocalStorage } from '../../localstorage/clearLocalStorage';
import secureLsService from '../../secureLs.service';
import { setIsLoggedIn } from '../../user/setIsLoggedIn';
import AppConst from '../../../constants/app.constant';
import reportError from '../../error-handler/reportError';
import { initCognitoSync } from '../cognitoSync/initCognitoSync';

const loginUser = (username, userpassword, soft) => new Promise((resolve) => {
  if (!soft) {
    clearLocalStorage();
  }
  const authenticationData = {
    Username: username,
    Password: userpassword,
  };
  const userData = {
    Username: username,
    Pool: getCognitoUserPool(),
  };
  const cognitoUser = new CognitoUser(userData);
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess(result) {
      setIsLoggedIn(true);
      initCognitoSync(result.idToken.jwtToken);
      secureLsService.setInfo(AppConst.localstorageConf.uc, authenticationData);
      secureLsService.setInfo(AppConst.localstorageConf.uc_copy, authenticationData);
      if (!soft) {
        resolve(result);
      } else {
        resolve(result);
      }
    },
    onFailure(err) {
      if (!soft) {
        err.message = appendUserNameToMessage(username, err.message);
        err.emailTried = username;
        if (!err.code || (!err.code.match(new RegExp(AppConst.exceptionsCodes
          .UserNotFoundException)) && !err.message.match(new RegExp(AppConst
          .exceptionsCodes.incorrectPassword)))) {
          // Log to sentry only if error is not
          // Invaild Password OR userNotFound
          reportError(err);
        }
      }
      resolve(err);
    },
  });
});

export { loginUser };
