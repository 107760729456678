<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button
            fill="solid"
            color="secondary"
            @click="closeModal(false)"
          >
            Close
          </ion-button>
        </ion-buttons>
        <ion-title>Forgot Password</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card class="welcome-account">
        <ion-card-content>
          <div>
            <form
              id="loginregisterformprimary"
              name="loginregisterformprimary"
              novalidate
            >
              <p
                v-show="forgotPasswordValidation"
                class="validationmessage"
              >
                {{ forgotPasswordValidation }}
              </p>
              <ion-item>
                <ion-label position="floating">
                  Your Email
                </ion-label>
                <ion-input
                  v-model.trim="forgotPasswordForm.useremail"
                  type="text"
                />
              </ion-item>
              <ion-item v-if="enableVerification">
                <ion-label position="floating">
                  Verification Code
                </ion-label>
                <ion-input
                  v-model.trim="forgotPasswordForm.verificationCode"
                  type="password"
                />
              </ion-item>
              <ion-item v-if="enableVerification">
                <ion-label position="floating">
                  New Password
                </ion-label>
                <ion-input
                  v-model="forgotPasswordForm.password"
                  type="password"
                />
              </ion-item>
              <p
                v-show="enableVerification"
                class="message"
              >
                Please enter verfication code sent to your email address
              </p>
              <ion-button
                v-if="enableVerification"
                shape="round"
                color="light"
                :disabled="!isForgotFormComplete"
                class="button-account-secondary"
                expand="full"
                @click="changePassword()"
              >
                Change Password
              </ion-button>
              <ion-button
                v-if="enableVerification"
                shape="round"
                color="light"
                class="button-account-secondary"
                expand="full"
                @click="requestVerification()"
              >
                Didn't receive verification code?
              </ion-button>
              <div class="clear-login-div" />
              <ion-button
                v-if="!enableVerification"
                shape="round"
                :disabled="!isForgotFormComplete"
                color="light"
                class="button-account-secondary"
                expand="full"
                @click="forgotPasswordInterface()"
              >
                Submit
              </ion-button>
            </form>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonLabel,
  IonCard,
  IonInput,
  IonCardContent,
  IonItem,
  modalController,
} from '@ionic/vue';
import AppConst from '../../constants/app.constant';
import { hideIonicLoading, showIonicLoading } from '../../services/loading.service';
import utilityService from '../../services/utility.service';
import AccountConst from '../../constants/account.constant';
import { confirmPassword } from '../../services/aws/user/confirmPassword';
import { forgotPassword } from '../../services/aws/user/forgotPassword';

export default {
  name: 'Forgotpassword',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonLabel,
    IonCard,
    IonInput,
    IonCardContent,
    IonItem,
  },
  props: {
    useremail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      forgotPasswordForm: {
        useremail: '',
        verificationCode: '',
        password: '',
      },
      enableVerification: false,
      forgotPasswordValidation: '',
    };
  },
  computed: {
    isForgotFormComplete() {
      if (!this.enableVerification) {
        return this.checkForgotLevel1FormValid();
      }
      return this.checkForgotLevel2FormValid();
    },
  },
  mounted() {
    this.forgotPasswordForm.useremail = this.useremail;
  },
  methods: {
    closeModal(obj) {
      modalController.dismiss(obj);
    },
    passwordChangedSuccess() {
      const vm = this;
      showIonicLoading(
        AccountConst.processingText.passwordChanged,
        true,
      );
      utilityService.deleteForgotPasswordState();
      setTimeout(() => {
        hideIonicLoading();
        vm.closeModal({
          autoLogin: true,
          forgotPasswordForm: vm.forgotPasswordForm,
        });
      }, AccountConst.durationOfPwdChangeSuccess);
    },
    changePassword() {
      if (this.forgotPasswordForm.password.length < 6) {
        this.forgotPasswordValidation = AccountConst.passwordMinLengthRequired;
        return;
      }
      showIonicLoading(
        AccountConst.processingText.pleaseWait,
        false,
      );
      confirmPassword(this.forgotPasswordForm)
        .then((data) => {
          if (!data.message) {
            this.passwordChangedSuccess();
          } else {
            this.forgotPasswordValidation = data.message;
            hideIonicLoading();
          }
        });
    },
    showVerficationCodeSent() {
      setTimeout(() => {
        showIonicLoading(
          AccountConst.processingText.verificationCodeSent,
          true,
        );
        setTimeout(() => {
          hideIonicLoading();
        }, 3000);
      }, 200);
    },
    requestVerificationComplete(data) {
      if (!data.message) {
        this.showVerficationCodeSent();
        utilityService.setForgotPasswordState(this.forgotPasswordForm);
        this.forgotPasswordValidation = '';
        this.enableVerification = true;
      } else if (
        data.code.match(
          new RegExp(AppConst.exceptionsCodes.UserNotFoundException),
        )
      ) {
        this.forgotPasswordValidation = 'User not found.';
      } else {
        this.forgotPasswordValidation = data.message;
      }
    },
    requestVerification() {
      if (this.forgotPasswordForm.useremail) {
        showIonicLoading(AccountConst.processingText.pleaseWait);
        forgotPassword(this.forgotPasswordForm.useremail).then((data) => {
          hideIonicLoading();
          this.requestVerificationComplete(data);
        });
      }
    },
    forgotPasswordInterface() {
      this.forgotPasswordValidation = null;
      if (this.forgotPasswordForm.useremail) {
        this.requestVerification();
      }
    },
    checkForgotLevel1FormValid() {
      if (this.forgotPasswordForm.useremail.length > 0) {
        return true;
      }
      return false;
    },
    checkForgotLevel2FormValid() {
      if (
        this.forgotPasswordForm.useremail.length > 0
        && this.forgotPasswordForm.verificationCode.length > 0
        && this.forgotPasswordForm.password.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
ion-content {
  --background: #1C1C1C;
  border-left: 5px solid #20232C;
  border-right: 5px solid #20232C;
  border-bottom: 5px solid #20232C;
}
ion-card-content {
  padding: 0;
}
ion-item {
  color: #fff;
  --background: transparent;
}
.message {
  margin: 12px 0;
}
</style>
