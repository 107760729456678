import AWS from 'aws-sdk';
import AccountConst from '../../../constants/account.constant';

const setCredentialsForSync = (jwtToken) => {
  const {
    cognitoEndpoint,
    identityPoolId,
    userPoolId,
  } = AccountConst.awsCognito;
  const logins = {};
  logins[`${cognitoEndpoint}/${userPoolId}`] = jwtToken;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
    Logins: logins,
  });
};

export { setCredentialsForSync };
