import httpService from '../../../services/http.service';
import AppConst from '../../../constants/app.constant';

const getFcstDiscussion = async (nws, icao_id) => {
  const errorResponse = {
    errorMessage: `Forecase Discussion not available for ${icao_id}`,
  };
  const url = AppConst.fcstTabData(nws);
  if (nws) {
    const { data } = await httpService.http_get_content(url);
    if (data) {
      const div = document.createElement('div');
      div.innerHTML = data;
      const pre = div.querySelector('pre');
      if (pre) {
        const span = pre.getElementsByTagName('span');
        if (span && span[0]) {
          pre.removeChild(pre.getElementsByTagName('span')[0]);
        }
        return pre.innerHTML;
      }
      return errorResponse;
    }
    return errorResponse;
  }
  return errorResponse;
};

export {
  getFcstDiscussion,
};
