<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div>
    <div
      id="meteoNoDataMessage"
      class="no-data displaynone"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <span />
    </div>
    <div
      id="meteogramChartContainer"
      class="svg-container"
    />
  </div>
</template>
<script>
import _debounce from 'lodash/debounce';
import MeteogramChartService from './service/meteogramChart.service';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import ChartConst from '../../constants/chart.constant';

export default {
  name: 'MeteogramChart',
  props: {
    settings: {
      type: Object,
      required: true,
    },
    meteogramData: {
      type: Object,
      default: () => ({}),
    },
    sourceData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data: null,
      fullData: null,
      aloftData: null,
      noDataForAloftAvailable: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResizeEvent);
      window.addEventListener('resize', this.handleResizeEventSecondary);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent);
    window.removeEventListener('resize', this.handleResizeEventSecondary);
  },
  methods: {
    handleResizeEvent: _debounce(function () {
      if (this.settings && this.settings.actionKey === 'meteogram') {
        this.initDrawing();
      }
    }, ChartConst.resizeDebounce),
    handleResizeEventSecondary() {
      if (this.settings && this.settings.actionKey === 'meteogram') {
        showAeroplane();
      }
    },
    initDrawing() {
      showAeroplane();
      setTimeout(() => {
        window.requestAnimationFrame(() => {
          MeteogramChartService.chartApi.refresh(
            this.data,
            this.fullData,
            this.aloftData,
            this.noDataForAloftAvailable,
            this.settings,
          );
        });
        setTimeout(hideAeroplane, 500);
      }, 200);
    },
    refresh(data, fullData, aloftData, noDataForAloftAvailable) {
      this.data = data;
      this.fullData = fullData;
      this.aloftData = aloftData;
      this.noDataForAloftAvailable = noDataForAloftAvailable;
      this.initDrawing();
    },
  },
};
</script>
