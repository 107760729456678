import {
  PERSONAL_MINIMUMS_MUTATIONS_ENUM,
} from './personal-minimums.enum';

export const personalMinimumsMutations = {
  [PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_FILTER](state, payload) {
    state.personalMinimumsCurrentFilter = payload;
  },
  [PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_ENABELD](state, payload) {
    const personalMinimum = state.allPersonalMinimums.find((item) => item.id === payload.id);
    personalMinimum.isEnabled = payload.checked;
  },
  [PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_VALUE](state, payload) {
    const personalMinimum = state.allPersonalMinimums.find((item) => item.id === payload.id);
    personalMinimum.personalValue = payload.value;
  },
};
