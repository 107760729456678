import {
  modalController,
} from '@ionic/vue';
import ChangePassword from '../components/ChangePassword.vue';

const changePasswordFormModal = function () {
  return modalController
    .create({
      component: ChangePassword,
      cssClass: 'fullscreen',
    })
    .then((modalElement) => {
      modalElement.present();
      modalElement.onDidDismiss();
    });
};

export { changePasswordFormModal };
