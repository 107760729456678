import { alertController } from '@ionic/vue';

const showAlertForPurchase = (data) => {
  console.log('showing alert', data);
  alertController.create({
    header: data.title,
    backdropDismiss: false,
    message: data.template,
    buttons: ['OK'],
  }).then((alert) => {
    alert.present();
  });
};

const alertPurchaseFailed = () => {
  showAlertForPurchase({
    title: 'Subscription Purchase Error',
    template: 'We could not store your new subscription status on our server. '
        + 'No worries, you have not been charged. Please ensure you are '
        + 'connected to the Internet and try again.',
  });
};

const alertCanNotConnectToIap = () => {
  showAlertForPurchase({
    status: false,
    title: 'Subscription Purchase Error',
    template: 'We could not reach the Apple iTunes ordering server. Please ensure you are connected to the Internet and try again.',
  });
};

export { alertPurchaseFailed, alertCanNotConnectToIap };
