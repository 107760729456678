import { getZoomLevel } from '../../utils/getZoomLevel';
// import { getStormsOfRadarClosestToCenter } from './getStormsOfRadarClosestToCenter';
import { getStormFiltered } from './getStormFiltered';
import stormTrackConst from '../../../../constants/stormTrack.const';
import ConfigDeviceSettings from '../../../../config/device.config';

const getStormCtzoomFiltrerMap = () => {
  const { stormTrackCtThresholds } = ConfigDeviceSettings.settings;
  const valueConfig = stormTrackCtThresholds.split(',').map((item) => Number(item));
  if (valueConfig && valueConfig.length === 3) {
    valueConfig.push(1000);
    return valueConfig;
  }
  return stormTrackConst.ct.zoomFiltrerMap;
};

// so like if u show all USA, then show > FL300
// half way zoom, >250
// 3/4 zoom in, show all
const getStormsToRender = (stormTrackData, projection, width, height, scale) => {
  const zoomLevel = getZoomLevel(scale, projection);
  let radarSiteToUse = null;
  let storms = null;
  switch (zoomLevel) {
    case 1:
    case 2:
    case 3:
    case 4:
      storms = getStormFiltered(
        stormTrackData,
        getStormCtzoomFiltrerMap()[zoomLevel - 1],
      );
      radarSiteToUse = {
        isFilterMode: (zoomLevel !== 4),
        storms,
      };
      break;
    // case 4:
    //   radarSiteToUse = getStormsOfRadarClosestToCenter(
      // stormTrackData, projection, width, height);
    //   break;
    default:
      radarSiteToUse = null;
  }
  return radarSiteToUse;
};
export { getStormsToRender };
