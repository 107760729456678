import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  LAYER_UPDATE_TIME_MUTATION_ENUM,
} from '../../store/modules/layer-update-time/layer-update-time.enum';

export const setCurrentScreenRenderTime = () => store.commit(
  `${STORE_NAMESPACE.LAYER_UPDATE_TIME}/${LAYER_UPDATE_TIME_MUTATION_ENUM.SET_CURRENT_SCREEN_RENDER_TIME}`,
);
