/**
 * For a given hours string converts it in to hours and minutes
 * Hours string can contain fractional hours
 * e.g hours = 0.25 will be concerted to {hours: 0, minutes: 15}
 */
const hoursToHHmm = (hours = 0) => {
  const HHmm = hours.toString().split('.').map((x) => parseInt(x, 10));
  return {
    hours: HHmm[0],
    minutes: HHmm[1] ? Math.floor((HHmm[1] * 3) / 5) : undefined,
  };
};

export default hoursToHHmm;
