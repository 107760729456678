import { connectivityState } from './connectivity.state';
import { connectivityActions } from './connectivity.action';
import { connectivityMutations } from './connectivity.mutation';
import { connectivityGetters } from './connectivity.getter';

export const connectivity = {
  namespaced: true,
  state: connectivityState,
  mutations: connectivityMutations,
  actions: connectivityActions,
  getters: connectivityGetters,
};
