import secureLsService from '../../secureLs.service';
import { loginUser } from './loginUser';
import AppConst from '../../../constants/app.constant';

const reLoginAws = () => new Promise((resolve) => {
  const user = secureLsService.getInfo(AppConst.localstorageConf.uc_copy);
  if (user) {
    loginUser(user.Username, user.Password, true).then((redologindata) => {
      if (redologindata && !redologindata.message) {
        resolve(redologindata);
      } else {
        resolve(redologindata);
      }
    });
  } else {
    resolve(null);
  }
});

export { reLoginAws };
