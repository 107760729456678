export const getUTCDateParts = (date) => Intl.DateTimeFormat(undefined, {
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
  timeZone: 'UTC',
})
  .formatToParts(date)
  .reduce((obj, current) => {
    if (current.type !== 'literal') {
      obj[current.type] = current.value;
    }
    return obj;
  }, {});

export const getLocalDateParts = (date) => Intl.DateTimeFormat(undefined, {
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hourCycle: 'h23',
})
  .formatToParts(date)
  .reduce((obj, current) => {
    if (current.type !== 'literal') {
      obj[current.type] = current.value;
    }
    return obj;
  }, {});
