import utilityService from '../../../services/utility.service';
import { getWindGustInfo } from './getWindGustInfo';

const noValue = '--';

const getWindDisplay = (weatherItem) => {
  const windSpeedInfo = `${weatherItem.windSpeed} | ${getWindGustInfo(weatherItem)}`;
  const reg = /\d+/g;
  const matches = windSpeedInfo.match(reg);
  let i = 0;
  let windDisplayText = '';
  if (matches) {
    while (i < matches.length) {
      matches[i] = utilityService.mphToKnots(matches[i]);
      i += 1;
    }
    if (matches.length > 2) {
      // If contains more than 2 matches
      windDisplayText = `${Math.min.apply(null, matches)}-`;
      windDisplayText += Math.max.apply(null, matches);
    } else {
      windDisplayText = matches.join('-');
    }
  }
  return windDisplayText || noValue;
};

export { getWindDisplay };
