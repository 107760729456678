import {
  PERSONAL_MINIMUMS_GETTERS_ENUM,
} from './personal-minimums.enum';

export const personalMinimumsGetters = {
  [PERSONAL_MINIMUMS_GETTERS_ENUM.GET_FILTERED_PERSONAL_MINIMUMS](state) {
    return state.allPersonalMinimums.filter((item) => item.type === state
      .personalMinimumsCurrentFilter);
  },
  [PERSONAL_MINIMUMS_GETTERS_ENUM.GET_CURRENT_PERSONAL_MINIMUMS_FILTER](state) {
    return state.personalMinimumsCurrentFilter;
  },
};
