import { point as turfPoint } from '@turf/turf';
import { setPointRadius } from '../utils/setPointRadius';
import { getCenterOfViewPort } from '../../utils/getCenterOfViewPort';
import { drawDot } from './drawDot';

const shouldDrawCenterOfViewPort = false;

const drawCenterOfViewPort = (canvasConfig, projection, width, height) => {
  if (shouldDrawCenterOfViewPort) {
    setPointRadius(canvasConfig.path, 10);
    drawDot(
      canvasConfig.ctx,
      canvasConfig.path,
      turfPoint(getCenterOfViewPort(projection, width, height)),
      'r',
    );
  }
};

export { drawCenterOfViewPort };
