import AppConst from '../../../constants/app.constant';
import d3 from '../../../globalexports/d3.export';

const getSunMoonTime = (settings) => {
  let dateFormat;
  if (settings.timeZone === AppConst.timeZones.local.key) {
    dateFormat = d3.timeFormat('%H%M');
  } else {
    dateFormat = d3.utcFormat('%H%MZ');
  }
  return dateFormat;
};

export { getSunMoonTime };
