<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <ion-page>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
          <ion-button
            v-if="
              (!imageryViewUseSubnav && avwxServiceRef.selectedSubtab) ||
                (imageryViewUseSubnav &&
                  avwxServiceRef.selectedSubtab &&
                  currentSlideIndex === 3) ||
                settings.noLongerAvailable
            "
            id="btn-fav"
            fill="solid"
            color="secondary"
            @click="markItemAsFav(settings)"
          >
            <ion-icon
              v-show="isMarkedFavorite"
              class="markedfavorite"
              :icon="star"
            />
            <ion-icon
              v-show="!isMarkedFavorite"
              :icon="star"
            />
          </ion-button>
        </ion-buttons>
        <ion-title
          id="viewtitle"
          center
        >
          Imagery
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content
      v-if="isActiveRoute"
      force-overscroll="false"
      class="imagery-view"
    >
      <!-- <AltitudeSlider
        v-if="
          avwxServiceRef &&
            avwxServiceRef.selectedSubtab &&
            avwxServiceRef.selectedSubtab.altitudes &&
            currentSlideIndex === 3
        "
        :current-visible-image="currentVisibleImage"
        :slides-data="avwxServiceRef.selectedSubtab.altitudes"
        @altitudeRangeChanging="onAltitudeRangeChanging"
        @altitudeRangeChangingEnd="onAltitudeRangeChangingEnd"
      /> -->
      <div
        v-show="imageryViewUseSubnav"
        class="swiper-container imagery-swiper-container"
      >
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide imagery-swiper">
            <!-- <ion-searchbar
              v-model.trim="filterAvwx"
              debounce="300"
            /> -->
            <div class="mainlist">
              <ion-list v-if="filteredItems.length">
                <ion-item
                  v-for="(tab, tabindex) in filteredItems"
                  :key="tabindex"
                  class="item-icon-right"
                  @click="selectTab(tab)"
                >
                  <ion-label>
                    <span v-html="highlight(tab, filterAvwx)" />
                  </ion-label>
                  <ion-icon
                    v-if="tab.c"
                    slot="end"
                    :icon="chevronForward"
                    mode="ios"
                  />
                </ion-item>
              </ion-list>
            </div>
          </div>
          <div class="swiper-slide  imagery-swiper">
            <ion-toolbar color="medium">
              <ion-buttons slot="start">
                <ion-button
                  class="back-button"
                  fill="solid"
                  color="secondary"
                  @click="previousSlide()"
                >
                  Back
                  <ion-icon
                    slot="start"
                    mode="ios"
                    :icon="chevronBack"
                  />
                </ion-button>
              </ion-buttons>
              <ion-title
                v-if="
                  avwxServiceRef.selectedTab && avwxServiceRef.selectedTab.t
                "
                class="title_custom"
              >
                {{ avwxServiceRef.selectedTab.p }}
              </ion-title>
            </ion-toolbar>
            <div class="mainlist mainlistwithheader">
              <ion-list
                v-if="
                  avwxServiceRef.selectedTab && avwxServiceRef.selectedTab.c
                "
              >
                <div
                  v-for="(subtabgroup, subtabgroupindex) in avwxServiceRef
                    .selectedTab.c"
                  :key="subtabgroupindex"
                >
                  <ion-item
                    v-show="subtabgroup.t"
                    class="item-icon-right"
                    @click="selectSubtabGroup(subtabgroup)"
                  >
                    <ion-label>
                      <span v-html="highlight(subtabgroup, filterAvwx)" />
                    </ion-label>
                    <ion-icon
                      v-if="subtabgroup.c"
                      slot="end"
                      :icon="chevronForward"
                      mode="ios"
                    />
                  </ion-item>
                </div>
              </ion-list>
            </div>
          </div>
          <div class="swiper-slide imagery-swiper">
            <ion-toolbar
              v-show="avwxServiceRef.selectedSubtabGroup"
              color="medium"
            >
              <ion-buttons slot="start">
                <ion-button
                  fill="solid"
                  color="secondary"
                  class="back-button"
                  @click="previousSlide()"
                >
                  <ion-icon
                    slot="start"
                    mode="ios"
                    :icon="chevronBack"
                  />
                  Back
                </ion-button>
              </ion-buttons>
              <ion-title
                v-if="
                  avwxServiceRef.selectedSubtabGroup &&
                    avwxServiceRef.selectedSubtabGroup.t
                "
                class="title_custom"
              >
                {{
                  avwxServiceRef.selectedSubtabGroup.p ||
                    avwxServiceRef.selectedSubtabGroup.t
                }}
              </ion-title>
            </ion-toolbar>
            <div class="mainlist mainlistwithheader">
              <ion-list
                v-if="
                  avwxServiceRef.selectedSubtabGroup &&
                    avwxServiceRef.selectedSubtabGroup.c
                "
              >
                <ion-item
                  v-for="(subtab, subtabindex) in avwxServiceRef
                    .selectedSubtabGroup.c"
                  :key="subtabindex"
                  class="item-icon-right"
                  @click="selectSubtab(subtab)"
                >
                  <ion-label>
                    <span v-html="highlight(subtab, filterAvwx)" />
                  </ion-label>
                  <ion-icon
                    v-if="subtab.c"
                    slot="end"
                    :icon="chevronForward"
                    mode="ios"
                  />
                </ion-item>
              </ion-list>
            </div>
          </div>
          <div
            class="swiper-slide imagery-swiper"
            :class="{
              hideBottomContainer: hideBottomAxis,
              enableAlternateSlider: enableAlternateSlider
            }"
          >
            <div
              force-overscroll="false"
              class="imagery-swiper-content"
            >
              <ion-toolbar color="medium">
                <ion-buttons
                  v-if="!hideBottomAxis"
                  slot="start"
                >
                  <ion-button
                    class="back-button"
                    fill="solid"
                    color="secondary"
                    @click="previousSlide()"
                  >
                    <ion-icon
                      slot="start"
                      mode="ios"
                      :icon="chevronBack"
                    />Back
                  </ion-button>
                </ion-buttons>
                <ion-buttons
                  v-if="hideBottomAxis"
                  slot="end"
                  class=""
                >
                  <ion-button
                    class="back-button"
                    fill="solid"
                    color="secondary"
                    @click="previousSlide()"
                  >
                    <ion-icon
                      slot="start"
                      mode="ios"
                      :icon="chevronBack"
                    />
                  </ion-button>
                </ion-buttons>
                <ion-title
                  v-if="
                    avwxServiceRef.selectedSubtab &&
                      (avwxServiceRef.selectedSubtab.p ||
                        avwxServiceRef.selectedSubtab.t)
                  "
                  class="title_custom"
                >
                  {{
                    avwxServiceRef.selectedSubtab.p ||
                      avwxServiceRef.selectedSubtab.t
                  }}
                </ion-title>
              </ion-toolbar>
              <div
                class="imagery-content"
                :class="{
                  'imagery-content-withoutslider':
                    imageLocations && imageLocations.length <= 1
                }"
              >
                <div
                  v-show="isLoading && percentLoaded"
                  id="progress-radial-avwx-primary"
                  class="progress-imagery-secondary"
                />
                <div id="imagerysvgsubnav" />
                <div
                  v-show="
                    imageLocations &&
                      imageLocations.length &&
                      !isLoading &&
                      !isSuccessful
                  "
                  class="blackback"
                >
                  <div class="imagery-accordion-placeholder">
                    <font-awesome-icon
                      icon="exclamation-triangle"
                      class="failed-image"
                    />
                    {{ imageryLoadFailedPlaceholderText }}
                  </div>
                </div>
                <div
                  v-show="!avwxServiceRef.selectedSubtab"
                  class="imagery-accordion-placeholder"
                >
                  <ImageryPlaceholderText
                    :imagery-placeholder-text="imageryPlaceholderText"
                  />
                </div>
              </div>
              <AltitudeSlider
                v-if="
                  avwxServiceRef &&
                    avwxServiceRef.selectedSubtab &&
                    avwxServiceRef.selectedSubtab.altitudes &&
                    currentSlideIndex === 3
                "
                :current-altitude-slide-index="currentAltitudeSlideIndex"
                :current-visible-image="currentVisibleImage"
                :slides-data="avwxServiceRef.selectedSubtab.altitudes"
                @altitudeRangeChanging="onAltitudeRangeChanging"
                @altitudeRangeChangingEnd="onAltitudeRangeChangingEnd"
              />
              <imagery-slider
                v-show="
                  isSuccessful &&
                    avwxServiceRef.selectedSubtab &&
                    avwxServiceRef.selectedSubtab.i.length > 1
                "
                id="imageryslidermobilenav"
                ref="etdSliderApiMobileNav"
                :class="{ pinned: sliderPinned && !hideBottomAxis }"
                @changing="sliderChanging"
              />
              <div
                v-if="
                  (enableAlternateSlider || hideBottomAxis) &&
                    avwxServiceRef.validationInfo
                "
                id="floating-etd-button-primary"
                :style="floatingEtdStyle"
                class="floating-etd"
              >
                <FooterSliderLabelButton
                  :label="sliderLabel"
                  :show-icon="false"
                />
              </div>
            </div>
            <ion-footer v-if="!hideBottomAxis && !enableAlternateSlider">
              <ion-toolbar color="dark">
                <UTCClock />
                <ion-buttons slot="end">
                  <ion-button
                    fill="solid"
                    color="secondary"
                    :disabled="lastZoom <= 1"
                    @click="resetted()"
                  >
                    <font-awesome-icon
                      icon="expand-arrows-alt"
                      class="expand-arrows-alt fa-lg"
                    />
                  </ion-button>
                </ion-buttons>
                <ion-title v-if="avwxServiceRef.validationInfo">
                  <FooterSliderLabelButton
                    :label="sliderLabel"
                    :show-icon="false"
                  />
                </ion-title>
              </ion-toolbar>
            </ion-footer>
          </div>
        </div>
      </div>
      <!-- Imagery Subnav View -->

      <!-- Imagery Subnav View -->
      <!-- Imagery Full View -->
      <div
        v-show="!imageryViewUseSubnav"
        class="imagery-accord-list"
      >
        <!-- <ion-searchbar
          v-model.trim="filterAvwx"
          color="dark"
          debounce="300"
        /> -->
        <div class="main-list-accord">
          <ion-list v-if="avwxData">
            <div
              v-for="(tab, tabindex) in filteredItems"
              :key="tabindex"
              class="imagery-accord-list-container"
              :class="{
                'selected-imagery': avwxServiceRef.selectedSubtab == tab,
                haschild: tab.c
              }"
            >
              <ion-item
                v-if="tab.t"
                :title="tab.p.length > lengthToShowTooltip ? tab.p : ''"
                class="accord-item item-icon-left"
                @click="selectTab(tab)"
              >
                <ion-label>
                  <span v-html="highlight(tab, filterAvwx)" />
                </ion-label>
                <ion-icon
                  v-show="tab.c"
                  slot="start"
                  :icon="folder"
                />
                <ion-icon
                  v-show="!tab.c"
                  slot="start"
                  :icon="image"
                  class="padded"
                />
              </ion-item>
              <div v-if="tab.c || tab.i">
                <div
                  v-for="(subtabgroup, subtabgroupindex) in tab.c"
                  v-show="avwxServiceRef.selectedTab == tab"
                  :key="subtabgroupindex"
                  class="item-accordion-list"
                  :class="{
                    'selected-imagery':
                      avwxServiceRef.selectedSubtab == subtabgroup,
                    haschild: subtabgroup.c
                  }"
                >
                  <ion-item
                    :title="
                      subtabgroup.p.length > lengthToShowTooltip
                        ? subtabgroup.p
                        : ''
                    "
                    class="item-icon-left accord-item  level-1"
                    @click="selectSubtabGroup(subtabgroup)"
                  >
                    <ion-label>
                      <span v-html="highlight(subtabgroup, filterAvwx)" />
                    </ion-label>
                    <ion-icon
                      v-show="subtabgroup.c"
                      slot="start"
                      :icon="folder"
                    />
                    <ion-icon
                      v-show="!subtabgroup.c"
                      slot="start"
                      :icon="image"
                      class="padded"
                    />
                  </ion-item>
                  <div
                    v-for="(subtab, subtabindex) in subtabgroup.c"
                    v-show="avwxServiceRef.selectedSubtabGroup == subtabgroup"
                    :key="subtabindex"
                    class="item-accordion-list"
                    :class="{
                      'selected-imagery':
                        avwxServiceRef.selectedSubtab == subtab
                    }"
                  >
                    <ion-item
                      :title="
                        subtab.p.length > lengthToShowTooltip ? subtab.p : ''
                      "
                      class="item-icon-left accord-item level-2"
                      @click="selectSubtab(subtab)"
                    >
                      <ion-label>
                        <span v-html="highlight(subtab, filterAvwx)" />
                      </ion-label>
                      <ion-icon
                        slot="start"
                        :icon="image"
                      />
                    </ion-item>
                  </div>
                </div>
              </div>
            </div>
          </ion-list>
        </div>
      </div>
      <div
        v-show="!imageryViewUseSubnav"
        class="imagery-accord-images-container blackback"
        :class="{ enableAlternateSlider: enableAlternateSlider }"
      >
        <ion-header
          v-if="
            avwxServiceRef.selectedSubtab &&
              (avwxServiceRef.selectedSubtab.p ||
                avwxServiceRef.selectedSubtab.t)
          "
        >
          <ion-toolbar color="medium">
            <ion-title>
              {{
                avwxServiceRef.selectedSubtab.p ||
                  avwxServiceRef.selectedSubtab.t
              }}
            </ion-title>
          </ion-toolbar>
        </ion-header>
        <ion-content class="imagery-ion-content">
          <div
            class="imagery-content"
            :class="{
              'imagery-content-withoutslider':
                imageLocations && imageLocations.length <= 1
            }"
          >
            <div
              v-show="isLoading && percentLoaded"
              id="progress-radial-avwx-secondary"
              class="progress-imagery-secondary"
            />
            <div id="imagerysvg" />
            <div
              v-show="
                imageLocations &&
                  imageLocations.length &&
                  !isLoading &&
                  !isSuccessful
              "
              class="blackback"
            >
              <div class="imagery-accordion-placeholder">
                <font-awesome-icon
                  icon="exclamation-triangle"
                  class="failed-image"
                />
                {{ imageryLoadFailedPlaceholderText }}
              </div>
            </div>
            <ImageryPlaceholderText
              v-show="
                !avwxServiceRef.selectedSubtab ||
                  (avwxServiceRef.selectedSubtab &&
                    !avwxServiceRef.selectedSubtab.t)
              "
              class="imagery-accordion-placeholder"
              :imagery-placeholder-text="imageryPlaceholderText"
            />
          </div>
          <AltitudeSlider
            v-if="
              avwxServiceRef &&
                avwxServiceRef.selectedSubtab &&
                avwxServiceRef.selectedSubtab.altitudes &&
                currentSlideIndex === 3
            "
            :current-altitude-slide-index="currentAltitudeSlideIndex"
            :current-visible-image="currentVisibleImage"
            :slides-data="avwxServiceRef.selectedSubtab.altitudes"
            @altitudeRangeChanging="onAltitudeRangeChanging"
            @altitudeRangeChangingEnd="onAltitudeRangeChangingEnd"
          />
          <imagery-slider
            v-show="
              isSuccessful &&
                avwxServiceRef.selectedSubtab &&
                avwxServiceRef.selectedSubtab.i.length > 1
            "
            id="imageryslideraccordion"
            ref="etdSliderApiAccordion"
            class="pinned"
            @changing="sliderChanging"
          />
          <div
            v-if="
              (enableAlternateSlider || hideBottomAxis) &&
                avwxServiceRef.validationInfo
            "
            id="floating-etd-button-secondary"
            :style="floatingEtdStyle"
            class="floating-etd"
          >
            <FooterSliderLabelButton
              :label="sliderLabel"
              :show-icon="false"
            />
          </div>
        </ion-content>
        <ion-footer v-if="!enableAlternateSlider">
          <ion-toolbar color="dark">
            <UTCClock />
            <ion-buttons slot="end">
              <ion-button
                fill="solid"
                color="secondary"
                :disabled="lastZoom <= 1"
                @click="resetted()"
              >
                <font-awesome-icon
                  icon="expand-arrows-alt"
                  class="expand-arrows-alt fa-lg"
                />
              </ion-button>
            </ion-buttons>
            <ion-title v-if="avwxServiceRef.validationInfo">
              <FooterSliderLabelButton
                :label="sliderLabel"
                :show-icon="false"
              />
            </ion-title>
          </ion-toolbar>
        </ion-footer>
      </div>
      <!-- Imagery Full View -->
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/vue';
import {
  star,
  chevronForward,
  chevronBack,
  folder,
  image,
} from 'ionicons/icons';
import _debounce from 'lodash/debounce';
import AppConst from '../../constants/app.constant';
import EventConst from '../../constants/event.constant';
import utilityService from '../../services/utility.service';
import UTCClock from '../../components/UTCClock.vue';
import ImagerySlider from '../../components/ImagerySlider/ImagerySlider.vue';
import ImageryPlaceholderText from '../../components/ImageryPlaceholderText.vue';
import ConfigApplicationSettings from '../../config/application.config';
import ConfigDeviceSettings from '../../config/device.config';
import Emitter from '../../bus';
import d3 from '../../globalexports/d3.export';
import routeControllerService from '../../services/routeController.service';
import { getSettingFromLocalstorage } from '../../services/localstorage/getSettingFromLocalstorage';
import reportService from '../../services/report.service';
import avwxService from './Imagery.service';
import imageryUtility from './imageryUtility.service';
import FavoritesService from '../../components/Favorites/Favorites.service';
import FooterSliderLabelButton from '../../components/FooterSliderLabelButton.vue';
import AltitudeSlider from '../../components/AltitudeSlider.vue';
import router from '../../router';
import { routerPushInterface } from '../../services/router.interface';

export default {
  name: 'Imagery',
  components: {
    IonContent,
    IonHeader,
    IonFooter,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonLabel,
    ImagerySlider,
    FooterSliderLabelButton,
    ImageryPlaceholderText,
    AltitudeSlider,
    UTCClock,
  },
  data() {
    return {
      chevronForward,
      chevronBack,
      star,
      folder,
      image,
      settings: {},
      cacheBuster: '',
      avwxData: null,
      slideIndexs: [],
      currentVisibleImage: 0,
      currentSlideIndex: 0,
      currentAltitudeSlideIndex: {
        rangeValue: '0',
        rangeIndex: 0,
      },
      imageLocations: null,
      filterAvwx: '',
      lengthToShowTooltip: AppConst.lengthToShowTooltip,
      timeZoneShort: utilityService.getTimeZoneShort(),
      imageryLoadFailedPlaceholderText: '',
      imageryPlaceholderText: '',
      isLoading: false,
      isMarkedFavorite: false,
      avwxServiceRef: avwxService,
      isSuccessful: false,
      sliderLabel: '',
      imageryViewUseSubnav: true,
      showMobileView: ConfigApplicationSettings.showMobileView,
      floatingEtdStyle: {
        visibility: 'hidden',
        top: 0,
      },
      enableAlternateSlider:
        ConfigDeviceSettings.settings.enableAlternateSlider,
      lastZoom: null,
      sliderPinned: true,
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  computed: {
    isActiveRoute() {
      return this.$router.currentRoute.value.name === 'app.imagery';
    },
    filteredItems() {
      const vm = this;
      if (vm.avwxData) {
        return vm.avwxData.filter((item) => {
          if (
            item.t
            && (item.c || item.i)
            && item.p.toLowerCase().indexOf(vm.filterAvwx.toLowerCase()) > -1
          ) {
            return true;
          }
          return false;
        });
      }
      return [];
    },
  },
  methods: {
    refreshView() {
      this.loadAllAltitudeImages();
      imageryUtility.redrawOnResize(imageryUtility.imagerySwiper);
    },
    handleResizeEvent: _debounce(
      function () {
        this.refreshView();
      },
      500,
      false,
    ),
    ionViewDidEnter() {
      const vm = this;
      vm.imageryViewUseSubnav = ConfigApplicationSettings.imageryViewUseSubnav;
      vm.loadLastView();
      setTimeout(() => {
        imageryUtility.setVmContext(vm);
        vm.initView();
        reportService.bindReportMethod();
        Emitter.on(
          EventConst.refreshViewRootscope + AppConst.stateMap.imagery.key,
          () => {
            vm.initView();
          },
        );
        Emitter.on(
          EventConst.favorite.listRefreshed,
          vm.isMarkedFavoriteCheckForStar,
        );
      }, AppConst.mountDelay);
      imageryUtility.setImagerySwiper();
    },
    ionViewWillLeave() {
      const vm = this;
      Emitter.off(
        EventConst.refreshViewRootscope + AppConst.stateMap.imagery.key,
      );
      Emitter.off(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      reportService.unbindReportMethod();
      window.removeEventListener('resize', this.handleResizeEvent);
    },
    syncSettingsData() {
      const vm = this;
      utilityService.setSettingInLocalstorage(
        vm.settings,
        AppConst.localstorageConf.imagerySettingKey,
      );
    },
    swipedRight() {
      const vm = this;
      if (imageryUtility.getActiveIndex(imageryUtility.imagerySwiper) > 0) {
        vm.previousSlide();
      }
    },
    resetCachebuster() {
      const vm = this;
      vm.cacheBuster = `?bncachebuster=${Date.now()}`;
    },
    setCurrentSlideIndex() {
      const vm = this;
      if (imageryUtility.getActiveIndex(imageryUtility.imagerySwiper)) {
        vm.currentSlideIndex = imageryUtility.getActiveIndex(
          imageryUtility.imagerySwiper,
        );
      } else if (vm.slideIndexs && vm.slideIndexs.length) {
        vm.currentSlideIndex = vm.slideIndexs[vm.slideIndexs.length - 1] + 1;
      } else {
        vm.currentSlideIndex = 0;
      }
    },
    onAltitudeRangeChangingEnd(rangeData) {
      this.currentAltitudeSlideIndex = rangeData;
    },
    onAltitudeRangeChanging(rangeData) {
      this.currentAltitudeSlideIndex = rangeData;
    },
    markItemAsFav(item) {
      FavoritesService.markItemAsFav(item);
    },
    update(progress) {
      imageryUtility.attachedLoaderPrimary.value.attr(
        'd',
        imageryUtility.attachedLoaderPrimary.circle.endAngle(
          imageryUtility.attachedLoaderPrimary.endAngle * progress,
        ),
      );
      imageryUtility.attachedLoaderPrimary.numberText.text(
        imageryUtility.attachedLoaderPrimary.formatText(progress),
      );
      imageryUtility.attachedLoaderSecondary.value.attr(
        'd',
        imageryUtility.attachedLoaderSecondary.circle.endAngle(
          imageryUtility.attachedLoaderSecondary.endAngle * progress,
        ),
      );
      imageryUtility.attachedLoaderSecondary.numberText.text(
        imageryUtility.attachedLoaderSecondary.formatText(progress),
      );
    },
    previousSlide() {
      const vm = this;
      let slideTo = vm.slideIndexs.pop();
      if (vm.settings && vm.settings.noLongerAvailable) {
        vm.settings.noLongerAvailable = false;
        vm.slideIndexs = [];
        slideTo = 0;
      }
      // $ionicSlideBoxDelegate.slide(slideTo);
      imageryUtility.slideToWhichSlide(slideTo, imageryUtility.imagerySwiper);
      vm.setCurrentSlideIndex();
    },
    slideToTab() {
      const vm = this;
      imageryUtility.slideToWhichSlide(0, imageryUtility.imagerySwiper);
      vm.slideIndexs = [];
      vm.setCurrentSlideIndex();
    },
    slideToSubtabGroup() {
      const vm = this;
      imageryUtility.slideToWhichSlide(1, imageryUtility.imagerySwiper);
      vm.slideIndexs = [0];
      vm.setCurrentSlideIndex();
    },
    highlight(item, search) {
      let text = item.t;
      if (item.p) {
        text = item.p;
      }
      if (!search) {
        return text;
      }
      // remove reserved chracters of regex
      search = utilityService.sanitizeSearchString(search);
      return text.replace(
        new RegExp(search, 'gi'),
        '<span class="highlightedText">$&</span>',
      );
    },
    slideToSubtab() {
      const vm = this;
      imageryUtility.slideToWhichSlide(2, imageryUtility.imagerySwiper);
      if (vm.slideIndexs.length === 0) {
        vm.slideIndexs.push(0);
      } else {
        vm.slideIndexs.push(1);
      }
      vm.setCurrentSlideIndex();
    },
    slideToContent() {
      const vm = this;
      imageryUtility.slideToWhichSlide(3, imageryUtility.imagerySwiper);
      if (vm.slideIndexs.length === 0) {
        vm.slideIndexs.push(0);
      } else if (
        vm.slideIndexs[vm.slideIndexs.length - 1] === 1
        || vm.slideIndexs[vm.slideIndexs.length - 1] === 0
      ) {
        vm.slideIndexs.push(2);
      }
      vm.setCurrentSlideIndex();
    },
    isTabShown(tab) {
      return avwxService.selectedTab === tab;
    },
    isSubgroupTabShown(subgrouptab) {
      return avwxService.selectedSubtabGroup === subgrouptab;
    },
    selectTabAccord(tab) {
      avwxService.setSelectedTab(tab);
    },
    selectTab(tab) {
      const vm = this;
      if (
        avwxService.selectedTab
        && vm.isTabShown(tab)
        && !ConfigApplicationSettings.imageryViewUseSubnav
      ) {
        avwxService.selectedTab = {};
        vm.previousSlide();
        return;
      }
      tab.backText = AppConst.imageryDefaultBackText;
      avwxService.setSelectedTab(tab);
      // $ionicViewSwitcher.nextDirection("forward");
      if (tab && tab.c && tab.c[0] && tab.c[0].i) {
        tab.c.backText = AppConst.imageryDefaultBackText;
        tab.c.fallbackTitle = tab.t;
        avwxService.setSelectedSubtabGroup(tab);
        vm.slideToSubtab();
      } else if (tab.c) {
        vm.slideToSubtabGroup();
      } else {
        avwxService.setSelectedSubtab(tab);
        vm.selectSubtab(tab);
      }
      // $ionicScrollDelegate.resize();
    },
    selectSubtabGroup(subtabGroup) {
      const vm = this;
      if (subtabGroup && subtabGroup.i) {
        vm.selectSubtab(subtabGroup);
        // avwxCtrl.previousSlide();
        return;
      }
      if (
        avwxService.selectedSubtabGroup
        && vm.isSubgroupTabShown(subtabGroup)
        && !ConfigApplicationSettings.imageryViewUseSubnav
      ) {
        avwxService.selectedSubtabGroup = {};
        vm.previousSlide();
        return;
      }
      subtabGroup.backText = avwxService.selectedTab.t;
      avwxService.setSelectedSubtabGroup(subtabGroup);
      vm.slideToSubtab();
      // $ionicScrollDelegate.resize();
    },
    selectSubtab(subtab) {
      const vm = this;
      if (subtab && subtab.c) {
        vm.selectSubtabGroup(subtab);
      } else if (subtab && subtab.i) {
        vm.percentLoaded = 0;
        vm.isSuccessful = false;
        if (avwxService.selectedSubtabGroup) {
          subtab.backText = avwxService.selectedSubtabGroup.t
            || avwxService.selectedSubtabGroup.fallbackTitle;
        } else {
          subtab.backText = AppConst.imageryDefaultBackText;
        }
        avwxService.setSelectedSubtab(subtab);
        vm.settings = avwxService.setPathKey();
        vm.syncSettingsData();
        imageryUtility.loadImages();
        if (avwxService.selectedSubtab && avwxService.selectedSubtab.i) {
          if (avwxService.selectedSubtab
            && (avwxService.selectedSubtab.l === 'Last' || avwxService.selectedSubtab.l === 'Yes')) {
            // If value of l i.e. loop is last the show last image first
            vm.currentVisibleImage = avwxService.selectedSubtab.i.length - 1;
          } else {
            vm.currentVisibleImage = 0;
          }
          vm.slideToContent();
        }
      }
    },
    showHideImage() {
      const vm = this;
      if (vm.currentVisibleImage >= 0) {
        d3.selectAll('.accordImages').style('display', 'none');
        d3.selectAll('.accordImages image').style('display', 'none');
        d3.select(
          `#${imageryUtility.getSVGContainerID()}-accordImg${
            vm.currentVisibleImage
          }`,
        ).style('display', 'inline');
        const imagesContainers = d3.selectAll('.accordImages');
        imagesContainers.each(function () {
          const imagesContainer = d3.select(this);
          if (imagesContainer.selectAll('image').size() === 1) {
            imagesContainer.select(
              'image',
            ).style('display', 'inline');
          } else {
            const imagesContainerImage = imagesContainer.select(
              `#image-${vm.currentAltitudeSlideIndex.rangeValue}`,
            );
            imagesContainerImage.style('display', 'inline');
          }
        });
      }
    },
    startImageryRender(data) {
      const vm = this;
      setTimeout(() => {
        vm.setCurrentSlideIndex();
      });
      vm.imageryPlaceholderText = AppConst.imageryPlaceholderText;
      vm.imageryLoadFailedPlaceholderText = AppConst.imageryLoadFailedPlaceholderText;
      if (!vm.avwxData) {
        vm.avwxData = data.avwxData;
      }
      if (avwxService.selectedSubtab) {
        vm.selectSubtab(avwxService.selectedSubtab);
      }
      if (data.pathObj && !avwxService.selectedSubtab) {
        if (router.currentRoute.value.query.isFromFav) {
          vm.settings = {
            pathkey: router.currentRoute.value.query.pathkey,
            subtab: router.currentRoute.value.query.subtab,
            noLongerAvailable: true,
          };
          vm.imageryPlaceholderText = AppConst.imageryNoLongerValid;
          vm.syncSettingsData();
          imageryUtility.cleanSVG();
          if (data.slidesIndexes) {
            vm.slideIndexs = data.slidesIndexes;
          }
          vm.slideToContent();
        } else {
          vm.settings = {
            pathkey: '',
            subtab: '',
          };
          vm.syncSettingsData();
          imageryUtility.cleanSVG();
          if (data.slidesIndexes) {
            vm.slideIndexs = data.slidesIndexes;
          }
        }
      }
      if (data.slidesIndexes) {
        vm.slideIndexs = data.slidesIndexes;
      }
      vm.isMarkedFavoriteCheckForStar();
    },
    loadImageryData() {
      const vm = this;
      vm.imageryViewUseSubnav = ConfigApplicationSettings.imageryViewUseSubnav;
      avwxService.initView().then((data) => {
        vm.startImageryRender(data);
      });
    },
    loadAllAltitudeImages() {
      const vm = this;
      if (vm.currentVisibleImage >= 0) {
        const imagesContainer = d3.select(
          `#${imageryUtility.getSVGContainerID()}-accordImg${
            vm.currentVisibleImage
          }`,
        );
        imagesContainer.selectAll('image').each(function () {
          const dataHref = d3.select(this).attr('data-href');
          if (dataHref) {
            d3.select(this).attr('x-link:href', dataHref);
            d3.select(this).attr('data-href', null);
          }
        });
      }
    },
    sliderChanging(sliderEventData) {
      const vm = this;
      if (vm.imageLocations && vm.imageLocations.length > 1) {
        vm.sliderLabel = sliderEventData.sliderLabel;
        vm.currentVisibleImage = sliderEventData.idx;
        sliderEventData.imageryViewUseSubnav = vm.imageryViewUseSubnav;
        if (!sliderEventData.isInitializing) {
          routeControllerService.shiftEtdSliderLabel(vm, sliderEventData);
        }
        this.loadAllAltitudeImages();
        vm.showHideImage();
        if (sliderEventData.isDragEnd) {
          routeControllerService.hideFloatingSlider(vm);
        }
      }
    },
    initView() {
      const vm = this;
      vm.resetCachebuster();
      vm.loadImageryData();
      vm.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
    },
    isMarkedFavoriteCheckForStar() {
      const vm = this;
      FavoritesService.isMarkedFavoriteCheckForStar(vm, vm.settings);
    },
    loadLastView() {
      const queryKeys = Object.keys(router.currentRoute.value.query || {});
      if (queryKeys.length === 0) {
        //  This will prevent loading last view if navigation is coming from outside
        //  like bookmark or favorite
        const lastViewedState = getSettingFromLocalstorage(
          AppConst.localstorageConf.imagerySettingKey,
        );
        routerPushInterface({
          query: lastViewedState,
        });
      }
    },
  },
};
</script>
<style scoped>
.imagery-accord-list .sc-ion-label-md-h {
  margin: 0;
}
.imagery-accord-list ion-item {
  --min-height: 0;
}
.imager-slidebox ion-slide {
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
}
.imager-slidebox ion-slide ion-list {
  width: 100%;
}
.mainlist {
  height: calc(100vh - var(--header-height) - var(--ion-safe-area-top, 0px));
  width: 100%;
  overflow-y: auto;
  /* max-width: 300px; */
}
.mainlistwithheader {
  height: calc(
    100vh - var(--header-height) - var(--header-height) -
      var(--ion-safe-area-top, 0px)
  );
}
.main-list-accord {
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  overflow-x: hidden;
}
.main-list-accord ion-list {
  background: #363941;
}
.imagery-sidenav-slider {
  bottom: calc(var(--header-height) + var(--ion-safe-area-top, 0px)) !important;
}
.padded {
  /* padding-left: 29px; */
}
.imagery-swiper {
  flex-direction: column;
}
.imagery-swiper ion-list {
  width: 100%;
}
.imagery-swiper-content {
  height: calc(
    100vh - var(--header-height) - var(--header-height) -
      var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px)
  );
}
.enableAlternateSlider .imagery-swiper-content {
  height: calc(
    100vh - var(--header-height) - var(--ion-safe-area-top, 0px) -
      var(--ion-safe-area-bottom, 0px)
  );
}
.hideBottomContainer .imagery-swiper-content {
  height: calc(100vh - var(--header-height) - var(--ion-safe-area-top, 0px));
}
.hideBottomContainer ion-toolbar {
  width: calc(100vh - var(--header-height) - var(--ion-safe-area-top, 0px));
  padding: 0;
}
.hideBottomContainer .imagery-content {
  width: calc(
    100vw - var(--slider-vertical-width) - var(--header-height) -
      var(--ion-safe-area-left, 0px) - var(--ion-safe-area-right, 0px)
  );
}
.hideBottomContainer .imagery-content-withoutslider {
  width: calc(
    100vw - var(--header-height) - var(--ion-safe-area-left, 0px) -
      var(--ion-safe-area-right, 0px)
  );
}
.hideBottomContainer .imagery-content {
  height: calc(100vh - var(--header-height) - var(--ion-safe-area-top, 0px));
  margin-left: calc(var(--header-height) + var(--ion-safe-area-left, 0px));
}
.hideBottomContainer ion-toolbar {
  transform: translate(calc(-100% + 28px), -28px) rotate(270deg);
  transform-origin: center right;
  position: absolute;
}
html.plt-ios .hideBottomContainer ion-toolbar {
  transform: translate(
      calc(-100% + 22px + var(--ion-safe-area-left, 0px)),
      -22px
    )
    rotate(270deg);
}
.hideBottomContainer ion-toolbar ion-button {
  transform: rotate(90deg);
}
.hideBottomContainer ion-toolbar .title_custom {
  max-width: 75%;
}
.hideBottomContainer ion-toolbar ion-icon {
  margin: 0;
}
.imagery-content {
  height: calc(
    100vh - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px) -
      var(--header-height) - var(--header-height) - var(--header-height) -
      var(--slider-width)
  );
}
.imagery-content-withoutslider {
  height: calc(
    100vh - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px) -
      var(--header-height) - var(--header-height) - var(--header-height)
  );
}

.hideBottomContainer .imagery-content,
.enableAlternateSlider.hideBottomContainer .imagery-content {
  height: calc(
    100vh - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px) -
      var(--header-height)
  );
}

.enableAlternateSlider .imagery-content {
  height: calc(
    100vh - var(--ion-safe-area-top, 0px) - var(--ion-safe-area-bottom, 0px) -
      var(--header-height) - var(--header-height) - var(--slider-width)
  );
}
.hideBottomContainer #imagerysvgsubnav,
.hideBottomContainer .progress-imagery-secondary {
  margin-top: 0px;
}
.imagery-ion-content {
  --background: #000;
}
.imagery-accordion-placeholder {
  color: #fff;
}
ion-content.imagery-swiper-content {
  --background: #000;
}

.title_custom {
  /* padding: 0 84px */
  max-width: calc(100% - 84px - 84px);
  margin: auto;
  left: 0;
  right: 0;
}
ion-button.back-button {
  --padding-end: 11px;
}
.hideBottomContainer ion-button.back-button {
  --padding-end: 11px;
}
html.plt-ios .hideBottomContainer ion-button.back-button {
  --padding-end: 5px;
}
ion-button.back-button ion-icon {
  margin: 0;
}
.imagery-accord-list-container ion-icon {
  margin: 2px 8px 2px 0;
  color: #fff;
}
.swiper-slide.imagery-swiper .app-slider.pinned {
  bottom: auto;
}
</style>
