import { lineString as turfLineString } from '@turf/turf';
import d3 from '../../../globalexports/d3.export';
import { getBoundingBox } from './getBoundingBox';

const getCenterOfViewPort = (projection, width, height) => {
  const boundingBox = getBoundingBox(
    projection,
    width,
    height,
  );
  const boxObjectKeys = Object.keys(boundingBox);
  const box = boxObjectKeys.map((key) => boundingBox[key]);
  const lineStringPointsGeoJson2 = turfLineString(
    box,
  );
  const point = d3.geoCentroid(lineStringPointsGeoJson2);
  return point;
};

export { getCenterOfViewPort };
