import {
  MENU_MUTATIONS_ENUM,
} from './menu.enum';

export const menuMutations = {
  [MENU_MUTATIONS_ENUM.ENABLE_SWIPE_GESTURE](state) {
    state.swipeGesture = true;
  },
  [MENU_MUTATIONS_ENUM.DISABLE_SWIPE_GESTURE](state) {
    state.swipeGesture = false;
  },
};
