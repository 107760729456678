<template>
  <ion-page>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
        </ion-buttons>
        <ion-title>Personal Minimums</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="hasfooter">
      <ion-list>
        <ion-item
          v-for="(personalMinimum, key) in filteredPersonalMinimums"
          :key="key"
        >
          <ion-toggle
            color="primary"
            :checked="personalMinimum.isEnabled"
            @ionChange="
              setPersonalMinimumEnabled({
                id: personalMinimum.id,
                checked: $event.target.checked ? true : false
              })
            "
          />
          <ion-label style="margin-left: 10px">
            {{ personalMinimum.displayText }}
          </ion-label>
          {{ personalMinimum.personalValue }}
          <input
            :type="personalMinimum.inputType"
            :disabled="!personalMinimum.isEnabled"
            :value="personalMinimum.personalValue || personalMinimum.defaultValue"
            @change="setPersonalMinimumValue({
              id: personalMinimum.id,
              value: $event.target.value.trim(),
            })"
          >
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer v-if="!hideBottomAxis">
      <ion-toolbar color="dark">
        <UTCClock />
        <ion-title>
          <ion-segment :value="personalMinimumsCurrentFilter">
            <ion-segment-button
              value="minimums"
              @click="setPersonalMinimumFilter('minimums')"
            >
              <ion-label>Minimums</ion-label>
            </ion-segment-button>
            <ion-segment-button
              value="warning"
              @click="setPersonalMinimumFilter('warning')"
            >
              <ion-label>Warning</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-title>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonTitle,
  IonContent,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonButtons,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonList,
  IonItem,
  IonToggle,
} from '@ionic/vue';
import { mapGetters, mapActions } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import {
  PERSONAL_MINIMUMS_GETTERS_ENUM,
  PERSONAL_MINIMUMS_ACTIONS_ENUM,
} from '../store/modules/personal-minimums/personal-minimums.enum';

export default {
  name: 'PersonalMinimums',
  components: {
    IonPage,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonFooter,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonList,
    IonItem,
    IonToggle,
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.PERSONAL_MINIMUMS, {
      filteredPersonalMinimums:
        PERSONAL_MINIMUMS_GETTERS_ENUM.GET_FILTERED_PERSONAL_MINIMUMS,
      personalMinimumsCurrentFilter:
        PERSONAL_MINIMUMS_GETTERS_ENUM.GET_CURRENT_PERSONAL_MINIMUMS_FILTER,
    }),
  },
  methods: {
    ...mapActions(STORE_NAMESPACE.PERSONAL_MINIMUMS, {
      setPersonalMinimumFilter:
        PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_FILTER,
      setPersonalMinimumValue:
        PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_VALUE,
      setPersonalMinimumEnabled:
        PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_ENABELD,
    }),
  },
};
</script>
