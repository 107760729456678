import { getCurrentLocation } from '../../../services/capacitor/geoLocation.service';
import { USER_ACTIONS_ENUM, USER_MUTATIONS_ENUM } from './user.enum';

export const userActions = {
  [USER_ACTIONS_ENUM.SET_USER_DETAILS]({ commit }, payload) {
    return commit(USER_MUTATIONS_ENUM.SET_USER_DETAILS, payload);
  },
  [USER_ACTIONS_ENUM.SET_USER_ATTRIBUTE]({ commit }, payload) {
    return commit(USER_MUTATIONS_ENUM.SET_USER_ATTRIBUTE, payload);
  },
  async [USER_ACTIONS_ENUM.SET_IS_LOGGED_IN]({ commit }, payload) {
    commit(USER_MUTATIONS_ENUM.SET_IS_LOGGED_IN, payload);
  },
  async [USER_ACTIONS_ENUM.CLEAR_USER_STORE_ON_LOGOUT]({ commit }) {
    commit(USER_MUTATIONS_ENUM.CLEAR_USER_STORE_ON_LOGOUT);
  },
  async [USER_ACTIONS_ENUM.GET_USER_LOCATION]({ commit }, isFirstTimeAsk) {
    const coordinates = await getCurrentLocation(isFirstTimeAsk);
    commit(USER_MUTATIONS_ENUM.SET_USER_LOCATION, coordinates);
    return coordinates;
  },
};
