import reportError from '../../error-handler/reportError';
import { getCurrentUser } from './getCurrentUser';
import { processUserAttributes } from './processUserAttributes';

const getUserAttributes = () => new Promise((resolve) => {
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      cognitoUser.getUserAttributes((err, result) => {
        const attributeList = {};
        let i = 0;
        if (err) {
          resolve(err);
          reportError(err);
          return;
        }
        while (i < result.length) {
          attributeList[result[i].getName()] = result[i].getValue();
          i += 1;
        }
        processUserAttributes(attributeList).then((attributeData) => {
          resolve(attributeData);
        });
      });
    }
  });
});

export { getUserAttributes };
