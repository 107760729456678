<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <IonPage>
    <SentinelElement />
    <RouteHeader
      id="btn-route-selector-map"
      :settings="settings"
      :is-marked-favorite="isMarkedFavorite"
      :show-layer-selector="true"
      @showTour="showtour"
      @openRouteSelectModal="openRouteSelectModal"
    />
    <div>
      <MapTabs
        v-show="hideBottomAxis"
        :active-slider="activeSlider"
        class="hideBottomContainer app-mapview-hide-bottom"
        @setActiveSlider="setActiveSlider"
      />
    </div>
    <ion-content
      v-if="isActiveRoute"
      :class="{
        landscapeView: hideBottomAxis
      }"
      force-overscroll="false"
    >
      <AppLoader />
      <div id="map-d3-2d-map" />
      <div
        id="radarLoader-container"
        class="radarLoader-container"
      />
      <div class="scalepercent-container" />
      <ion-spinner
        class="spinner-globe"
        name="crescent"
      />
      <div
        id="map-canvas-globe"
        class="overlapping-canvas"
      />
      <div
        id="map-canvas-allradarsites"
        class="overlapping-canvas"
      />
      <div
        id="map-canvas-radar"
        class="overlapping-canvas"
      />
      <div
        id="map-canvas-features"
        class="overlapping-canvas"
      />
      <div
        id="map-canvas-stormtracks"
        class="overlapping-canvas"
      />
      <div class="map-canvas-control">
        <ion-buttons
          style="display: flex; flex-direction: column; row-gap: 10px;"
        >
          <ion-button
            id="btn-layer"
            fill="solid"
            color="secondary"
            @click="reboundMap()"
          >
            <ion-icon
              slot="icon-only"
              :icon="expand"
            />
          </ion-button>
          <locate @recenterToCurrentLocation="onRecenterToCurrentLocation" />
        </ion-buttons>
      </div>
      <div class="sliders globe-slider">
        <etd-slider
          v-show="activeSlider == 'etd'"
          id="bn-etd-slider-mapview"
          ref="etdSliderApi"
          class="pinned"
          :no-pin="true"
          :is-map-mode="true"
          :style="{ opacity: '1', 'pointer-events': 'all' }"
          @changing="etdSliderChanging"
        />
        <radar-slider
          v-if="enableRadarFeature"
          v-show="activeSlider == 'radar'"
          id="bn-radar-slider"
          ref="radarSliderApi"
          class="pinned"
          :style="{ opacity: '1', 'pointer-events': 'all' }"
          @changing="radarSliderChanging"
        />
        <flight-slider
          v-show="activeSlider == 'flight'"
          id="bn-flight-slider"
          ref="flightSliderApi"
          class="pinned"
          :style="{ opacity: '1', 'pointer-events': 'all' }"
          @changing="flightSliderChanging"
        />
      </div>

      <div
        v-show="hideBottomAxis"
        id="floating-etd-button"
        :style="floatingEtdStyle"
        class="floating-etd"
      >
        <FooterSliderLabelButton
          id="footer-slider-label-button-map"
          :label="departTime"
          :show-icon="false"
        />
      </div>
      <div id="globviewtooltip-container" />
    </ion-content>
    <MapTabs
      v-show="!hideBottomAxis"
      class="bottomTab"
      :active-slider="activeSlider"
      @setActiveSlider="setActiveSlider"
    />
    <v-tour
      name="viewTour"
      :steps="toursteps"
      :callbacks="tourCallbacks"
    />
  </IonPage>
</template>
<script>
import {
  IonContent,
  IonPage,
  IonButtons,
  IonButton,
  IonSpinner,
  IonIcon,
} from '@ionic/vue';
import {
  arrowForward,
  arrowBack,
  radioSharp,
  airplaneSharp,
  expand,
} from 'ionicons/icons';
import _debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import FooterSliderLabelButton from '../../components/FooterSliderLabelButton.vue';
import RouteHeader from '../../components/RouteHeader.vue';
import Locate from '../../components/Locate.vue';
import globeviewService from './Mapview.service';
import ChartConst from '../../constants/chart.constant';
import AppConst from '../../constants/app.constant';
import EventConst from '../../constants/event.constant';
import routeControllerService from '../../services/routeController.service';
import ConfigDeviceSettings from '../../config/device.config';
import reportService from '../../services/report.service';
import searchHistoryService from '../../services/searchHistory.service';
import etdService from '../../services/etd.service';
import routeProfileService from '../Routeprofile/Routeprofile.service';
import FavoritesService from '../../components/Favorites/Favorites.service';
import EtdSlider from '../../components/EtdSlider.vue';
import FlightSlider from '../../components/FlightSlider/FlightSlider.vue';
import RadarSlider from '../../components/RadarSlider/RadarSlider.vue';
import MapTabs from '../../components/MapTabs.vue';
import utilityService from '../../services/utility.service';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import { USER_GETTERS_ENUM } from '../../store/modules/user/user.enum';
import { MRMS_GETTERS_ENUM } from '../../store/modules/mrms/mrms.enum';
import Emitter from '../../bus';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import {
  loadMrmsDataRemote,
  clearTimer as mrmsRefresherTimer,
} from '../../services/mrms/loadMrmsDataRemote';

export default {
  name: 'Globe',
  components: {
    IonContent,
    IonPage,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    EtdSlider,
    FlightSlider,
    RadarSlider,
    RouteHeader,
    FooterSliderLabelButton,
    MapTabs,
    Locate,
  },
  data() {
    return {
      expand,
      arrowForward,
      arrowBack,
      radioSharp,
      airplaneSharp,
      settings: {},
      dontGetInBound: false,
      activeSlider: 'etd',
      showAdvanced: false,
      dateFormat: null,
      sliderMainObj: null,
      sliderMainObjSecondary: null,
      departTime: null,
      routeProfileData: null,
      showSlider: null,
      allDataForInterval: [],
      noDataFound: false,
      noDataFoundRouteMessage: ChartConst.noDataFoundRouteMessage,
      currentSliderIndex: null,
      runningCycle: false,
      isMarkedFavorite: false,
      toursteps: [],
      floatingEtdStyle: {
        visibility: 'hidden',
        top: 0,
      },
      enableAlternateSlider:
        ConfigDeviceSettings.settings.enableAlternateSlider,
      enableRadarFeature: ConfigDeviceSettings.settings.enableRadarFeature,
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userCurrentPosition: USER_GETTERS_ENUM.GET_USER_LOCATION,
    }),
    ...mapGetters(STORE_NAMESPACE.MRMS, {
      rasterMetaData: MRMS_GETTERS_ENUM.GET_MRMS_METADATA,
    }),
    isActiveRoute() {
      return this.$router.currentRoute.value.name === 'app.mapview';
    },
  },
  watch: {
    rasterMetaData() {
      globeviewService.resetRadarCanvasCollection();
    },
  },
  methods: {
    refreshView() {
      const vm = this;
      if (vm.settings) {
        globeviewService.initializeRequiredGlobeDependency();
        globeviewService.refreshGlobeAndGetInBound();
        vm.hideBottomAxis = utilityService.hideBottomAxis();
        if (vm.settings.sliderPinnedOldState) {
          vm.settings.sliderPinned = vm.settings.sliderPinnedOldState;
        } else if (vm.settings.sliderPinnedOldState === false) {
          vm.settings.sliderPinned = vm.settings.sliderPinnedOldState;
        }
        if (vm.hideBottomAxis) {
          vm.settings.sliderPinned = true;
        }
        vm.setTourSteps();
      }
    },
    handleResizeEvent: _debounce(function () {
      this.refreshView();
    }, ChartConst.resizeDebounce),
    ionViewDidEnter() {
      const vm = this;
      // This timeout makes sure that these is smooth transition of view
      vm.initGlobe();
      Emitter.on(
        EventConst.refreshViewRootscope + AppConst.stateMap.mapview.key,
        () => {
          vm.dontGetInBound = true;
          vm.initializeView();
        },
      );
      reportService.bindReportMethod(AppConst.dataMode.mapview);
      utilityService.changeTourElementPosition();
      vm.setTourSteps();
      vm.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
      Emitter.on(EventConst.tapped_outside, vm.tappedOutside);
      Emitter.on(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      Emitter.on(EventConst.tourEvent, vm.tourEventFire);
      Emitter.on(EventConst.metchanged, globeviewService.onMetChanged);
    },
    ionViewWillLeave() {
      const vm = this;
      mrmsRefresherTimer();
      utilityService.stopTour();
      window.removeEventListener('resize', this.handleResizeEvent);
      Emitter.off(EventConst.tapped_outside);
      Emitter.off(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      Emitter.off(
        EventConst.refreshViewRootscope + AppConst.stateMap.mapview.key,
      );
      Emitter.off(EventConst.tourEvent);
      Emitter.off(EventConst.metchanged);
      reportService.unbindReportMethod();
    },
    setActiveSlider(slider) {
      const vm = this;
      if (vm.activeSlider === slider) {
        return;
      }
      if (vm.activeSlider === 'radar' || slider === 'radar') {
        switch (slider) {
          case 'etd':
          case 'flight':
            vm.$refs.radarSliderApi.resetToLastSlide();
            vm.$refs.etdSliderApi.resetToPreviousSlide();
            break;
          case 'radar':
            vm.$refs.etdSliderApi.resetToFirstSlide();
            break;
          default:
        }
      }
      vm.activeSlider = slider;
      globeviewService.sliderTypeChanged(slider);
    },
    showtour() {
      if (!this.noDataFound) {
        this.$tours.viewTour.start();
      }
    },
    setDateFormat() {
      routeControllerService.setDateFormatRoute(this);
    },
    syncRouteData() {
      routeControllerService.syncRouteData(this.settings);
    },
    loadViewVairables() {
      routeControllerService.loadViewVairables(this);
    },
    loadRouteprofileSetting() {
      routeControllerService.loadRouteprofileSetting(this);
    },
    cleanUpSpace() {
      routeControllerService.cleanUpSpace(this);
    },
    togglePin(state) {
      routeControllerService.togglePin(state, this);
    },
    toggleSlider(event) {
      routeControllerService.toggleSlider(event, this);
    },
    loadSliderDatesDependency(data) {
      routeControllerService.loadSliderDatesDependency(data, this);
    },
    loadSliderDataAndProfileData(data) {
      routeControllerService.loadSliderDataAndProfileData(data, this);
    },
    getAllIntervalData() {
      routeControllerService.getAllIntervalDataRoute(this);
    },
    openRouteSelectModal() {
      routeControllerService.openRouteSelectModal(this);
    },
    openQuickChangesPopover(event) {
      routeControllerService.openQuickChangesPopover(this, event);
    },
    /* There is a last param here isOnEndSlide which is not added yet
            But if needed, it can be added here to use it
        */
    refreshChart(fullData, isComingFromSliderSlide, isDragEnd) {
      const vm = this;
      globeviewService.sourceData = fullData;
      globeviewService.settings = vm.settings;
      if (isComingFromSliderSlide) {
        globeviewService.refreshGlobeCallFromSlider(isDragEnd);
      } else {
        // If it is coming from loadRouteProfileData function
        // then only get in route inside bound
        globeviewService.refreshGlobeAndGetInBound(vm.dontGetInBound);
      }
    },
    loadRouteProfileData(data) {
      this.routeProfileData = data;
      this.refreshChart(data);
      etdService.setDepartureTime(this.settings.departureTime);
    },
    markItemAsFav(item) {
      FavoritesService.markItemAsFav(item);
    },
    initRouteProfile() {
      const vm = this;
      showAeroplane();
      utilityService.clearAuditFactory(true);
      vm.isMarkedFavorite = FavoritesService.isMarkedFavorite(vm.settings);
      vm.noDataFound = false;
      globeviewService.clearAllRadarCanvasHandle();
      routeProfileService
        .getRouteProfileData(
          vm.settings,
          AppConst.dataMode.mapview,
          routeControllerService,
          vm.openRouteSelectModal,
        )
        .then((data) => {
          if (data && data.isValidRoute !== false) {
            vm.loadSliderDataAndProfileData(data);
            searchHistoryService.loadAirportHistorySearch();
            const [firstDateObj] = vm.sliderMainObj;
            vm.settings.firstDateObj = firstDateObj;
            globeviewService.settings = vm.settings;
            loadMrmsDataRemote();
          } else {
            vm.departTime = ChartConst.departurePrefix
              + vm.dateFormat(vm.settings.departureTime);
            vm.sliderMainObj = [];
          }
          if (data === null) {
            vm.noDataFound = true;
          }
          hideAeroplane();
        });
    },
    tappedOutside() {
      this.showSlider = false;
    },
    tourEventFire() {
      if (!this.settings.sliderPinned) {
        this.showSlider = !this.showSlider;
      }
    },
    resetFlightSlider(newData) {
      globeviewService.flightCoverageDetails.distanceCoveredInNauticleMiles = 0;
      globeviewService.flightCoverageDetails.idx = 0;
      const arrivalTimeText = ChartConst.flightSliderMainPrefix
        + globeviewService.flightCoverageDetails.dateFormat(newData.departTime);
      globeviewService.flightCoverageDetails.arrivalTimeDisplayText = arrivalTimeText;
    },
    etdAndFlightSliderInterface(sliderEventData) {
      const vm = this;
      vm.routeProfileData.travelRoute = routeProfileService.getTravelRoute(
        vm.settings.departureTime,
        vm.settings,
      );
      vm.refreshChart(vm.routeProfileData, true, sliderEventData.isDragEnd);
    },
    etdSliderChanging(sliderEventData) {
      const vm = this;
      globeviewService.etdSliderDetails = sliderEventData;
      vm.departTime = sliderEventData.departTimeDisplay;
      vm.settings.departureTime = sliderEventData.departTime;
      if (!sliderEventData.isInitializing) {
        vm.resetFlightSlider(sliderEventData);
        routeControllerService.shiftEtdSliderLabel(vm, sliderEventData);
        routeControllerService.hideFloatingSlider(vm);
        etdService.saveDepartureTimeToLs();
      }
      vm.etdAndFlightSliderInterface(sliderEventData);
      etdService.setDepartureTime(sliderEventData.departTime);
      vm.$refs.flightSliderApi.resetPositionToStart();
      globeviewService.etdSliderChanging();
    },
    flightSliderChanging(sliderEventData) {
      const vm = this;
      globeviewService.flightCoverageDetails = sliderEventData;
      if (!sliderEventData.isInitializing) {
        let d = new Date(sliderEventData.arrivalTime);
        d = utilityService.roundTimeToNearest30Minutes(d);
        vm.settings.departureTime = d;
        vm.etdAndFlightSliderInterface(sliderEventData);
      }
      globeviewService.flightSliderChanging();
    },
    radarSliderChanging(sliderEventData) {
      globeviewService.radarSliderDetails = sliderEventData;
      globeviewService.showHideRadarForSlider();
    },
    initializeView() {
      routeControllerService.initializeView(this);
    },
    initGlobe() {
      const vm = this;
      globeviewService.loadGlobeStaticData().then(() => {
        vm.initializeView();
        globeviewService.settings = vm.settings;
        globeviewService.initializeRequiredGlobeDependency();
      });
    },
    isMarkedFavoriteCheckForStar() {
      const vm = this;
      FavoritesService.isMarkedFavoriteCheckForStar(vm, vm.settings);
    },
    setTourSteps() {
      const vm = this;
      utilityService.setTourSteps(vm, 'app.mapview');
    },
    reboundMap() {
      globeviewService.recenterMapToRoute();
    },
    onRecenterToCurrentLocation() {
      globeviewService.recenterMapToCurrentLocation();
    },
  },
};
</script>
<style>
#map {
  width: 100%;
  height: 100%;
}

#map-canvas-globe {
  background: #000;
  width: 100%;
  height: 100%;
}

#map-canvas-radar,
#map-canvas-features,
#map-canvas-stormtracks,
#map-canvas-allradarsites {
  pointer-events: none;
}

.overlapping-canvas,
.overlapping-canvas canvas {
  position: absolute;
}

#map-canvas-radar canvas {
  display: none;
}

#globviewtooltip {
  position: absolute;
  font: 12px/1.5 Helvetica Neue, Arial, Helvetica, sans-serif;
}
#globviewtooltip.bottom .mapview-bubble-container {
  transform: rotate(180deg);
  top: -18px;
}

#globviewtooltip p {
  margin: 0;
}

.popup-close-button {
  position: absolute;
  right: 10px;
  z-index: 1;
  color: #fff !important;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
}

.mapview-bubble-container {
  width: 40px;
  height: 20px;
  position: absolute;
  /* left: 50%; */
  /* margin-left: -20px; */
  overflow: hidden;
  pointer-events: none;
  margin-top: -1px;
}

.mapview-bubble {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  transform: rotate(45deg);
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  background: #20232c;
  color: #fff;
}

.isAirportTooltip .popup-close-button {
  right: 10px;
}

.map-canvas-control {
  position: absolute;
  z-index: 99999;
  top: 10px;
  right: 10px;
}

.map-images-renderer {
  position: absolute;
  top: 0;
  z-index: 99;
}

.map-images-renderer img {
  position: absolute;
  height: 20px;
  width: 20px;
  margin-left: -10px;
  margin-top: -10px;
  pointer-events: none;
}

.tab-plane-departure {
  font-size: 18px;
  margin-bottom: 2px;
}
.scalepercent-container,
.radarLoader-container {
  position: absolute;
  z-index: 99;
  padding: 4px;
  color: #fff;
  font-weight: bold;
  opacity: 0.6;
  margin: 8px;
}
.globe-slider .slider-arrow-top {
  display: none;
}
ion-spinner.spinner-globe {
  pointer-events: none;
  position: absolute;
  width: 50px;
  height: 50px;
  color: #fff;
  z-index: 1;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#map-d3-2d-map {
  background: #010d12;
}
.app-mapview-hide-bottom {
  width: calc(100vh - var(--header-height));
  transform: translate(calc(-100% + 22px), -22px) rotate(270deg);
  transform-origin: center right;
  position: absolute;
  height: var(--header-height);
  padding: 0;
}
.landscapeView .map-canvas-control {
  right: calc(
    10px + var(--slider-vertical-width) + var(--ion-safe-area-left, 0px)
  );
}
.landscapeView .flight-etd-handle {
  display: none;
}
.landscapeView .radarLoader-container {
  left: calc(10px + var(--slider-vertical-width));
}
.landscapeView .overlapping-canvas {
  left: var(--header-height);
}
.hideBottomContainer ion-buttons {
  transform: rotate(90deg);
}
#globviewtooltip-container {
  z-index: 2;
}
.segmentLabel {
  margin-left: 6px;
}
</style>
