import AppConst from '../../../constants/app.constant';
import utilityService from '../../../services/utility.service';

const noValue = '--';

function getTemperatureValue(temperature, settings) {
  let temperatureValue;
  if (settings.temperatureUnit === AppConst.temperatureUnits.fahrenheit.key && temperature
    !== null) {
    temperatureValue = parseFloat(temperature);
  } else {
    temperatureValue = utilityService.fahrenheitToCelsius(temperature);
  }
  if (temperatureValue !== null) {
    return temperatureValue.toFixed(0);
  }
  return noValue;
}

export { getTemperatureValue };
