import { userState } from './user.state';
import { userActions } from './user.action';
import { userMutations } from './user.mutation';
import { userGetters } from './user.getter';

export const user = {
  namespaced: true,
  state: userState,
  mutations: userMutations,
  actions: userActions,
  getters: userGetters,
};
