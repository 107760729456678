import AccountConst from '../../constants/account.constant';
import { putRecord } from '../aws/cognitoSync/putRecord';

function saveToCognitoSync(key, value) {
  if (AccountConst.allowedCognitoSyncKeys.indexOf(key) > -1) {
    putRecord(key, value);
  }
}

export const saveToLocalStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, value);
    saveToCognitoSync(key, value);
  }
};
