import { openNoConnectivityModalHandle } from '../../modal/no-connectivity.modal';
import d3 from '../../globalexports/d3.export';

let lockConnectivityModal = false;

const showNoNetworkModal = () => {
  if (lockConnectivityModal) {
    return;
  }
  lockConnectivityModal = true;
  // close any connectivity modal that is already open
  d3.selectAll('.connectivitymodal').remove();
  openNoConnectivityModalHandle();
  window.setTimeout(() => {
    lockConnectivityModal = false;
  }, 2000);
};

export { showNoNetworkModal };
