<template>
  <ion-label>
    {{ item.name }}
    <p> {{ item.info }} </p>
  </ion-label>
  <ion-spinner v-if="isPending" />
  <ion-icon
    v-else
    :icon="status.icon"
    :class="status.class"
  />
  <ion-button
    v-if="isFailed"
    fill="solid"
    color="secondary"
    @click="reloadUrl"
  >
    Reload
  </ion-button>
  <!-- <ion-label
    v-else
    class="time"
  >
    {{ item.time }}
  </ion-label> -->
</template>
<script>
import { IonLabel, IonSpinner, IonIcon } from '@ionic/vue';
import { checkmarkCircle, warning } from 'ionicons/icons';
import { mapMutations } from 'vuex';
import { RequestStatus } from '../../constants/layerUpdateTime.constant';
import Emitter from '../../bus';
import EventConst from '../../constants/event.constant';
import router from '../../router';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { LAYER_UPDATE_TIME_MUTATION_ENUM } from '../../store/modules/layer-update-time/layer-update-time.enum';

export default {
  components: {
    IonLabel,
    IonIcon,
    IonSpinner,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    isPending() {
      return this.item.status === RequestStatus.PENDING;
    },
    isFailed() {
      return this.item.status === RequestStatus.FAILED;
    },
    status() {
      switch (this.item.status) {
        case RequestStatus.SUCCESS:
          return { icon: checkmarkCircle, class: 'success' };
        case RequestStatus.FAILED:
          return { icon: warning, class: 'failed' };
        default:
          return {};
      }
    },
  },
  methods: {
    ...mapMutations(STORE_NAMESPACE.LAYER_UPDATE_TIME, {
      setRetry: LAYER_UPDATE_TIME_MUTATION_ENUM.RETRY_URL,
    }),
    reloadUrl() {
      this.setRetry({ search: this.item.retry });
      Emitter.emit(EventConst.refreshViewRootscope + router.currentRoute.value.name);
    },
  },
};
</script>
<style scoped>
.failed {
  color: #ffc409;
}

.success {
  color: #2dd36f;
}
.time {
  text-align: right;
}
</style>
