import {
  AEROPLANE_LOADER_MUTATIONS_ENUM,
} from './aeroplane-loader.eum';

export const aeroplaneLoaderMutations = {
  [AEROPLANE_LOADER_MUTATIONS_ENUM.SET_SHOW_AEROPLANE_TRUE](state) {
    state.showAeroplane = true;
  },
  [AEROPLANE_LOADER_MUTATIONS_ENUM.SET_SHOW_AEROPLANE_FALSE](state) {
    state.showAeroplane = false;
  },
};
