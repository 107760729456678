import differenceInMinutes from 'date-fns/differenceInMinutes';
import { getStormUTCInLocal } from './getStormUTCInLocal';
import { processStorm } from './processStorm';
import { filterStormDupes } from './filterStormDupes';
import ConfigDeviceSetting from '../../../../../config/device.config';
import AppConst from '../../../../../constants/app.constant';

/**
 *
 * @param {Array} allRadarSites
 * @returns {Array} storms
 */
const processAllRadarSites = (allRadarSites) => {
  const result = [];
  allRadarSites.forEach((radarSite) => {
    // KAMX - KTBW
    // if (false || radarSite.id === 'KAPX' || radarSite.id === 'KGRR') {
    const startStormLocal = getStormUTCInLocal(radarSite);
    const diff = differenceInMinutes(startStormLocal, new Date());
    if (diff >= AppConst.stormExpiryThresholdMinutes) {
      radarSite.st.forEach((stormDetected) => {
        const storm = processStorm(stormDetected, radarSite, startStormLocal);
        if (storm) {
          result.push(storm);
        }
      });
    }
    // }
  });
  if (ConfigDeviceSetting.settings.enableSTRemovedupe === true) {
    const filteredStorm = filterStormDupes(result);
    return filteredStorm;
  }
  console.log('No filtering of dupe', result.length);
  return result;
};

export { processAllRadarSites };
