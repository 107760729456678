import {
  store,
} from '../../store/store';

/**
 *
 * @returns Promise
 */
const getUserDetails = () => store.state.user.userDetails;

export {
  getUserDetails,
};
