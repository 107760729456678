import _debounce from 'lodash/debounce';
import AppConst from '../../constants/app.constant';
import router from '../../router';
import { routerReplaceInterface } from '../router.interface';
import reportError from '../error-handler/reportError';
import { navigateToRouteDifferentFromNow } from './navigateToRouteDifferentFromNow';

let reportedOnce = false;
const hasSentinalElement = () => !!document.querySelector('.sentinel-item');

const reloadRouteSentinel = () => {
  try {
    navigateToRouteDifferentFromNow();
  } catch (ex) {
    debugger;
    setTimeout(() => {
      console.log('reloadRouteSentinel 7');
      routerReplaceInterface({
        name: AppConst.defaultHomeViewState,
      });
    }, 1000);
  }
};

const simulateDoomScreen = function () {
  // setTimeout(() => {
  //   navigateToRouteDifferentFromNow();
  //   setTimeout(() => {
  //     navigateToRouteDifferentFromNow();
  //   }, 10);
  // }, 10);
};

const startSentinelWatcher = _debounce((toState) => {
  /**
   * If there is no to state then just discard it for
   */
  if (!toState || toState.name === undefined) {
    return;
  }
  const watcheDelay = 5000;
  setTimeout(() => {
    if (!hasSentinalElement()) {
      if (!reportedOnce) {
        try {
          console.log('Sentinel element not detected.');
          reportedOnce = true;
          reportError({
            message: 'Sentinel element not detected. Will do programatic navigation',
            code: 'Sentinel element not detected. Will do programatic navigation',
            routeCurrentName: router.currentRoute.value.name,
            name: 'Doom screen issue detected',
          });
        } catch (ezx) {
          console.log('Sentinel element not detected. ERROR CATCH');
          reportError({
            message: 'Sentinel element not detected. Will do programatic navigation. ERROR CATCH',
            code: 'Sentinel element not detected. Will do programatic navigation',
            name: 'Doom screen issue detected',
          });
        }
        console.log('EXECUTING FIX');
        console.log(router.currentRoute.value.name);
      }
      reloadRouteSentinel();
    }
  }, watcheDelay);
}, 1000);

export {
  startSentinelWatcher,
  simulateDoomScreen,
};
