import AppConst from '../../../../constants/app.constant';
import secureLsService from '../../../../services/secureLs.service';

const getPayPalPlansFromLs = () => {
  let paypalPlansFromLs = null;
  try {
    const dataPaypalPlansFromLs = secureLsService.getInfo(AppConst.localstorageConf.paypalPlans);
    if (dataPaypalPlansFromLs) {
      paypalPlansFromLs = dataPaypalPlansFromLs;
    }
    return paypalPlansFromLs;
  } catch (ex) {
    console.log('Error PaypalPlansFromLs: ', paypalPlansFromLs);
    return paypalPlansFromLs;
  }
};

export { getPayPalPlansFromLs };
