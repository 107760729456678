import { along as turfAlong } from '@turf/turf';
import { writeText, drawImageOnCanvas } from '../../../../utils/canvas';

const planeSvg = `<svg height="50px" id="mapview-plane" style="enable-background:new 0 0 50 50;" class="tets" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<path fill="#000" stroke="#fff" transform="rotate(ROTATION_VALUE 25 25)" fill-opacity="50%" d="m25.21488,3.93375c-0.44355,0 -0.84275,0.18332 -1.17933,0.51592c-0.33397,0.33267 -0.61055,0.80884 -0.84275,1.40377c-0.45922,1.18911 -0.74362,2.85964 -0.89755,4.86085c-0.15655,1.99729 -0.18263,4.32223 -0.11741,6.81118c-5.51835,2.26427 -16.7116,6.93857 -17.60916,7.98223c-1.19759,1.38937 -0.81143,2.98095 -0.32874,4.03902l18.39971,-3.74549c0.38616,4.88048 0.94192,9.7138 1.42461,13.50099c-1.80032,0.52703 -5.1609,1.56679 -5.85232,2.21255c-0.95496,0.88711 -0.95496,3.75718 -0.95496,3.75718l7.53,-0.61316c0.17743,1.23545 0.28701,1.95767 0.28701,1.95767l0.01304,0.06557l0.06002,0l0.13829,0l0.0574,0l0.01043,-0.06557c0,0 0.11218,-0.72222 0.28961,-1.95767l7.53164,0.61316c0,0 0,-2.87006 -0.95496,-3.75718c-0.69044,-0.64577 -4.05363,-1.68813 -5.85133,-2.21516c0.48009,-3.77545 1.03061,-8.58921 1.42198,-13.45404l18.18207,3.70115c0.48009,-1.05806 0.86881,-2.64965 -0.32617,-4.03902c-0.88969,-1.03062 -11.81147,-5.60054 -17.39409,-7.89352c0.06524,-2.52287 0.04175,-4.88024 -0.1148,-6.89989l0,-0.00476c-0.15655,-1.99844 -0.44094,-3.6683 -0.90277,-4.8561c-0.22699,-0.59493 -0.50356,-1.07111 -0.83754 ,-1.40377c-0.33658,-0.3326 -0.73578,-0.51592 -1.18194,-0.51592l0,0l-0.00001,0l0,0l0.00002,0.00001z"/>
</svg>
`;
const planeImageMap = {};
const planeImageObj = {
  img: new window.Image(),
  size: 50,
};

const renderPlaneAndTimeOnCanvas = (ctx, size, latLongProj, flightCoverageDetails, planeImage) => {
  writeText(ctx, flightCoverageDetails.arrivalTimeDisplayText, latLongProj[0] + 25, latLongProj[1]);
  drawImageOnCanvas(ctx, size, size, latLongProj[0], latLongProj[1], planeImage);
};

const drawPlaneForTravelRoute = (
  ctx,
  projection,
  lineStringPointsGeoJson,
  flightCoverageDetails,
) => {
  if (!flightCoverageDetails) {
    return;
  }
  const reachedAt = flightCoverageDetails.distanceCoveredInNauticleMiles * 1.852;
  const along = turfAlong(lineStringPointsGeoJson, reachedAt);
  const latLongProj = projection(along.geometry.coordinates);
  const p2 = projection(turfAlong(lineStringPointsGeoJson, reachedAt + 1).geometry.coordinates);
  const x = latLongProj[0];
  const y = latLongProj[1];
  const xR = p2[0] - x;
  const yR = p2[1] - y;
  const r = 90 - Math.atan2(-yR, xR) * (180 / Math.PI);
  if (planeImageMap[Math.ceil(r)]) {
    renderPlaneAndTimeOnCanvas(
      ctx,
      planeImageObj.size,
      latLongProj,
      flightCoverageDetails,
      planeImageMap[Math.ceil(r)],
    );
  } else {
    const newImageOfPlane = new window.Image();
    const encodedData = window.btoa(planeSvg.replace('ROTATION_VALUE', r));
    newImageOfPlane.src = `data:image/svg+xml;base64,${encodedData}`;
    newImageOfPlane.onload = () => {
      planeImageMap[Math.ceil(r)] = newImageOfPlane;
      renderPlaneAndTimeOnCanvas(
        ctx,
        planeImageObj.size,
        latLongProj,
        flightCoverageDetails,
        planeImageMap[Math.ceil(r)],
      );
    };
  }
};

export { drawPlaneForTravelRoute };
