import {
  CognitoUser,
} from 'amazon-cognito-identity-js';
import reportError from '../../error-handler/reportError';
import { getCognitoUserPool } from '../core/getCognitoUserPool';
import { appendUserNameToMessage } from '../utils/appendUserNameToMessage';

const confirmPassword = (data) => new Promise((resolve) => {
  const userData = {
    Username: data.useremail,
    Pool: getCognitoUserPool(),
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmPassword(data.verificationCode, data.password, {
    onSuccess() {
      resolve(true);
    },
    onFailure(err) {
      err.message = appendUserNameToMessage(data.useremail, err.message);
      err.emailTried = data.useremail;
      resolve(err);
      // eslint-disable-next-line prefer-regex-literals
      if (!err.code || (err.code && !err.code.match(new RegExp(
        'CodeMismatchException',
      // eslint-disable-next-line prefer-regex-literals
      )) && !err.code.match(new RegExp(
        'InvalidParameterException',
      )))) {
        reportError(err);
      }
    },
  });
});

export { confirmPassword };
