import utilityService from '../../utility.service';

const getEnvironment = () => {
  let environment = '';
  if (window.cordova) {
    environment += ':CORDOVA';
    if (!!window.TFDetect && window.TFDetect.isTFXC) {
      environment += ':TFXC';
    } else if (utilityService.isAndroid()) {
      environment += ':ANDROID';
    } else {
      environment += ':APPSTORE';
    }
  } else {
    environment += ':WEB';
  }
  return environment;
};

export default getEnvironment;
