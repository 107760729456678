import httpService from '../../../../services/http.service';

import { getCacheBuster } from '../../../../services/utils/getCacheBuster';
import { getRadarApiUrlPrefix } from './getRadarApiUrlPrefix';

const getRasterMetaData = async () => {
  const rasterMetaData = await httpService.http_get(
    `${getRadarApiUrlPrefix()}/raster-meta-data.json${getCacheBuster()}`,
  );
  if (rasterMetaData) {
    delete rasterMetaData['rala-05'];
    delete rasterMetaData['rala-15'];
    delete rasterMetaData['rala-25'];
    delete rasterMetaData['rala-35'];
    delete rasterMetaData['rala-45'];
    delete rasterMetaData['rala-55'];
    let rasterMetaMap = Object.keys(rasterMetaData).map(
      (item) => ({
        key: item,
        refDate: rasterMetaData[item],
      }),
    );
    rasterMetaMap = rasterMetaMap.sort((a, b) => a.refDate - b.refDate);
    return rasterMetaMap;
  }
  return null;
};

export { getRasterMetaData };
