import { getPaypalSubscriptionDetails } from '../../paypal/getPaypalSubscriptionDetails';
import { getIsUserPaypalSubscriptionActive } from '../../user/getIsUserPaypalSubscriptionActive';

const processSubscriptionByT4 = async (t4Value) => {
  const subscriptionDetailByT4 = await getPaypalSubscriptionDetails(
    t4Value,
    true,
  );
  if (subscriptionDetailByT4) {
    const {
      id, plan_id, billing_info,
    } = subscriptionDetailByT4;
    const isActiveSubscription = getIsUserPaypalSubscriptionActive(subscriptionDetailByT4);
    if (isActiveSubscription && billing_info) {
      const t3DataForT4 = {
        bt: 'BA-05S70966ST4T4TEMP',
        oi: '6HN88583T4T4TEMP',
        si: id,
        lp: billing_info.last_payment.time,
        nb: billing_info.next_billing_time,
        pi: plan_id,
      };
      return t3DataForT4;
    }
  }
  return null;
};

export { processSubscriptionByT4 };
