<template>
  <div
    class="app-altitude-slider-container"
  >
    <div class="range-wrap">
      <div
        id="rangeV"
        class="range-value"
        :style="{ bottom: rangeValuePosition }"
      >
        {{ rangeValue }}
      </div>
      <input
        id="range"
        v-model="inputRange"
        type="range"
        min="0"
        class="app-altitude-slider"
        :max="maxRangeValue"
        step="1"
        @input="onAltitudeRangeChanging"
        @change="onAltitudeRangeChangingEnd"
      >
    </div>
  </div>
</template>
<script>
import d3 from '../globalexports/d3.export';
import imageryUtility from '../views/Imagery/imageryUtility.service';

export default {
  props: {
    slidesData: {
      type: Array,
      default: () => [],
    },
    currentVisibleImage: {
      type: Number,
      default: 0,
    },
    currentAltitudeSlideIndex: {
      type: Object,
      required: true,
    },
  },
  emits: ['altitude-range-changing', 'altitude-range-changing-end'],
  data() {
    return {
      inputRange: 0,
    };
  },
  computed: {
    maxRangeValue() {
      return this.slidesData.length - 1;
    },
    rangeValue() {
      return `Alt: ${this.slidesData[this.inputRange]}`;
    },
    rangeValuePosition() {
      const newValue = Number(
        ((this.inputRange - 0) * 100) / (this.slidesData.length - 1 - 0),
      );
      const newPosition = 0 - newValue * 0.4;
      return `calc(${newValue}% + (${newPosition}px))`;
    },
  },
  watch: {
    slidesData: {
      immediate: true,
      handler() {
        this.initializeRangeSlider();
      },
    },
    currentAltitudeSlideIndex: {
      handler() {
        if (this.currentAltitudeSlideIndex.rangeIndex !== this.inputRange) {
          this.inputRange = this.currentAltitudeSlideIndex.rangeIndex;
        }
      },
    },
  },
  methods: {
    initializeRangeSlider() {
      this.inputRange = 0;
      const rangeData = {
        rangeValue: this.slidesData[0],
        rangeIndex: 0,
      };
      this.$emit('altitude-range-changing-end', rangeData);
    },
    onAltitudeRangeChangingEnd(e) {
      const vm = this;
      // this.inputRange = e.target.value;
      const rangeData = {
        rangeValue: this.slidesData[e.target.value],
        rangeIndex: Number(e.target.value),
      };
      this.$emit('altitude-range-changing-end', rangeData);
      if (vm.currentVisibleImage >= 0) {
        const imagesContainers = d3.selectAll('.accordImages');
        imagesContainers.each(function () {
          const imagesContainer = d3.select(this);
          const imagesContainerImage = imagesContainer.select(
            `#image-${rangeData.rangeValue}`,
          );
          if (!imagesContainerImage.empty()) {
            const dataHref = imagesContainerImage.attr('data-href');
            if (dataHref) {
              imagesContainerImage.attr('x-link:href', dataHref);
              imagesContainerImage.attr('data-href', null);
            }
          }
        });
      }
    },
    onAltitudeRangeChanging(e) {
      const vm = this;
      e.preventDefault();
      const rangeData = {
        rangeValue: this.slidesData[e.target.value],
        rangeIndex: Number(e.target.value),
      };
      // this.inputRange = e.target.value;
      this.$emit('altitude-range-changing', rangeData);
      if (vm.currentVisibleImage >= 0) {
        d3.selectAll('.accordImages').style('display', 'none');
        const imagesContainer = d3.select(
          `#${imageryUtility.getSVGContainerID()}-accordImg${
            vm.currentVisibleImage
          }`,
        );
        imagesContainer.style('display', 'inline');
        imagesContainer.selectAll('image').style('display', 'none');
        imagesContainer
          .select(`#image-${rangeData.rangeValue}`)
          .style('display', 'inline');
      }
    },
  },
};
</script>
<style scoped>
.app-altitude-slider-container {
  position: absolute;
  z-index: 10;
  right: 26px;
  top: 60px;
  display: inline-block;
  width: 20px;
  height: 160px;
  padding: 0;
}
.hideBottomContainer .app-altitude-slider-container{
  right: 100px;
  top: 20px;
}
.range-value {
  width: 80px;
  position: absolute;
  right: 40px;
  background: #fff;
  border-radius: 10px;
  padding: 4px 10px;
  color: #000;
  font-weight: bold;
  text-align: center;
  box-shadow: rgb(0 0 0 / 60%) 0px 4px 16px;
}
.app-altitude-slider-container input {
  width: 160px;
  margin: 0;
  transform-origin: 80px 80px;
  transform: rotate(-90deg);
}
.app-altitude-slider {
  -webkit-appearance: none;
  /* width: 100%; */
  height: 10px;
  border-radius: 20px;
  background: #fff;
  outline: none;
  box-shadow: rgb(0 0 0 / 60%) 0px 4px 20px;
}

.app-altitude-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #0075ff;
  cursor: pointer;
}

.app-altitude-slider::-moz-range-thumb {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #0075ff;
  cursor: pointer;
}
</style>
