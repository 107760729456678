import {
  MRMS_ACTIONS_ENUM,
  MRMS_MUTATIONS_ENUM,
} from './mrms.enum';
import { getRasterMetaData } from './utils/getRasterMetaData';
import { loadMRMSImageCollection } from './utils/mrmsImageCollection';

export const mrmsActions = {
  async [MRMS_ACTIONS_ENUM.GET_MRMS_METADATA_REMOTE]({ commit }) {
    const data = await getRasterMetaData();
    if (data) {
      commit(MRMS_MUTATIONS_ENUM.SET_MRMS_METADATA, data);
      loadMRMSImageCollection(data);
    }
  },
};
