import ConfigDeviceSettings from '../../config/device.config';
import AccountConst from '../../constants/account.constant';
import AppConst from '../../constants/app.constant';
import { openEulaModalHandle } from '../../modal/eula.modal';

const userNeedToAcceptEula = (userDetails) => {
  let eularVersionToUse;
  if (ConfigDeviceSettings.settings.enableLoginFlow) {
    if (userDetails) {
      eularVersionToUse = userDetails[AccountConst.customAttribute.eulaVersionAgreed];
    }
    eularVersionToUse = parseInt(eularVersionToUse || 0, 10);
    if (eularVersionToUse < AppConst.eulaVersion) {
      openEulaModalHandle();
    }
  }
};
export { userNeedToAcceptEula };
