import { store } from '../../store/store';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { SUBSCRIPTION_ACTIONS_ENUM } from '../../store/modules/subscription/subscription.enum';

/**
 *
 * @param {Array} iapProductList
 */
const setIapProductList = (iapProductList) => {
  if (iapProductList) {
    store.dispatch(
      `${STORE_NAMESPACE.SUBSCRIPTION}/${SUBSCRIPTION_ACTIONS_ENUM.SET_ALL_IAP_PRODUCTS}`,
      iapProductList,
    );
  }
};
export { setIapProductList };
