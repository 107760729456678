import { ignoredApiRequest } from './ignoredErrors';

/**
 *
 * @param {*} errorPropertyConfig
 * @param {*} timeoutlag
 */
const checkIfReportAPIFailure = (errorPropertyConfig, timeoutlag) => {
  let shouldReport = true;
  ignoredApiRequest.forEach((item) => {
    if (errorPropertyConfig && errorPropertyConfig.indexOf(item) > -1) {
      shouldReport = false;
    }
  });
  if (timeoutlag > 0) {
    //  should not report any timeout error
    shouldReport = false;
  }
  return shouldReport;
};

export default checkIfReportAPIFailure;
