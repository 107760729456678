import { setPointRadius } from '../utils/setPointRadius';
import { drawStormDot } from './drawStormDot';
import globeviewUtilityService from '../../MapviewUtility.service';
import { drawStormCellTop } from './drawStormCellTop';
import { drawStormIcon } from './drawStormIcon';
import { drawStormPath } from './drawStormPath';
import { drawStormTiming } from './drawStormTiming';

const drawStorm = (
  radarSitesToBeDrawn,
  stormTrackCanvasConfig,
  selectedTooltip,
  projection,
  dateFormat,
) => {
  radarSitesToBeDrawn.storms.forEach((storm) => {
    if (radarSitesToBeDrawn.isFilterMode) {
      if (storm.icon) {
        drawStormIcon(
          projection,
          stormTrackCanvasConfig.ctx,
          storm,
        );
      } else {
        setPointRadius(stormTrackCanvasConfig.path, 8);
        drawStormDot(
          stormTrackCanvasConfig.ctx,
          stormTrackCanvasConfig.path,
          storm.stormStartPointGeoJson,
        );
      }
      drawStormCellTop(
        projection,
        stormTrackCanvasConfig.ctx,
        storm,
        !!storm.icon,
      );
    } else {
      if (storm.multiPointGeojson) {
        setPointRadius(stormTrackCanvasConfig.path, 4);
        drawStormDot(
          stormTrackCanvasConfig.ctx,
          stormTrackCanvasConfig.path,
          storm.multiPointGeojson,
        );
      }
      if (storm.stormStartPointGeoJson) {
        setPointRadius(
          stormTrackCanvasConfig.path,
          globeviewUtilityService.getFlightDotsSizes().flightDotMetar - 1,
        );
        if (storm.icon) {
          drawStormIcon(
            projection,
            stormTrackCanvasConfig.ctx,
            storm,
          );
        } else {
          drawStormDot(
            stormTrackCanvasConfig.ctx,
            stormTrackCanvasConfig.path,
            storm.stormStartPointGeoJson,
          );
        }
        drawStormCellTop(
          projection,
          stormTrackCanvasConfig.ctx,
          storm,
          !!storm.icon,
        );
      }
      if (storm.lineStringPoints) {
        drawStormPath(
          stormTrackCanvasConfig.ctx,
          stormTrackCanvasConfig.path,
          storm.lineStringPoints,
        );
      }
      if (selectedTooltip.stormSelected && selectedTooltip.stormSelected.id === storm.id) {
        drawStormTiming(
          projection,
          stormTrackCanvasConfig.ctx,
          storm,
          dateFormat,
        );
      }
    }
  });
};

export { drawStorm };
