import differenceInMinutes from 'date-fns/differenceInMinutes';
import GlobeConst from '../../../../constants/globe.constant';
import { getMRMSImageConfig } from './getMRMSImageConfig';
import EventConst from '../../../../constants/event.constant';
import Emitter from '../../../../bus';
import { setLayerToFailState, setLayerToPendingState, setTimeForLayer } from '../../../../services/layerUpdateTime/changeLayerUpdateTime';

const radarImagesCollection = [];
const cacheImages = {};

const resetRadarImagesCollection = () => {
  radarImagesCollection.splice(0, radarImagesCollection.length);
};

const loadOneOfTheImageNotLoaded = () => {
  const foundNotLoaded = radarImagesCollection.find((item) => {
    if (item.radarDrawingLow.tempSource) {
      return true;
    }
    return false;
  });
  if (foundNotLoaded) {
    foundNotLoaded.radarDrawingLow.src = foundNotLoaded.radarDrawingLow.tempSource;
    delete foundNotLoaded.radarDrawingLow.tempSource;
  }
};
const loadMRMSImageCollection = (rasterMetaData) => {
  resetRadarImagesCollection();
  const { radarSlidesCount: slidesCount } = GlobeConst;
  for (let i = 0; i < slidesCount; i += 1) {
    const mrmsImageConfig = getMRMSImageConfig(i);
    const { time, ...restRadarImageData } = cacheImages[mrmsImageConfig.key] || {};
    const downloadTime = time ? new Date(time) : new Date();
    const diff = differenceInMinutes(downloadTime, new Date());
    if (diff < 5 && restRadarImageData?.key
      && restRadarImageData?.radarDrawingLow?.isRadarImageLoadSuccess) {
      radarImagesCollection.push({ ...restRadarImageData });
    } else {
      const radarDrawingLow = new Image();
      radarDrawingLow.key = mrmsImageConfig.key;
      if (radarDrawingLow.key === rasterMetaData[slidesCount - 1].key) {
        radarDrawingLow.src = mrmsImageConfig.src;
      } else {
        radarDrawingLow.tempSource = mrmsImageConfig.src;
      }
      setLayerToPendingState(mrmsImageConfig.src);
      radarDrawingLow.onload = function () {
        setTimeForLayer(mrmsImageConfig.src);
        loadOneOfTheImageNotLoaded();
        this.isRadarImageLoaded = true;
        this.isRadarImageLoadSuccess = true;
        Emitter.emit(EventConst.radarImageLoaded, this);
      };
      radarDrawingLow.onerror = function () {
        loadOneOfTheImageNotLoaded();
        setLayerToFailState(mrmsImageConfig.src);
        this.isRadarImageLoaded = true;
        this.isRadarImageLoadSuccess = false;
      };
      const radarImage = {
        radarDrawingLow,
        key: mrmsImageConfig.key,
        refDate: rasterMetaData.find(
          (item) => item.key === mrmsImageConfig.key,
        ).refDate,
      };
      cacheImages[mrmsImageConfig.key] = { ...radarImage, time: Date.now() };
      console.log(cacheImages);
      radarImagesCollection.push(radarImage);
    }
  }
  radarImagesCollection.sort((a, b) => a.refDate - b.refDate);
};

export { loadMRMSImageCollection, radarImagesCollection, resetRadarImagesCollection };
