import utilityService from './utility.service';
import registerFontAwesomeIcons from './fontawesome-register.service';

const sideEffects = async () => {
  registerFontAwesomeIcons();
  utilityService.loadBanner();
  utilityService.setAppInstallDate();
  window.addEventListener('keyboardWillHide', () => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
};

export default sideEffects;
