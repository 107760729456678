import AppConst from '../../constants/app.constant';
import ChartConst from '../../constants/chart.constant';

const getTempLabelValue = (
  preferredTempratureFormat,
  tmp_celsius,
  tmp_fahrenheit,
  onlyPreferred = false,
) => {
  if (tmp_celsius) {
    const tmpC = `${tmp_celsius.toFixed(0)}${ChartConst.ChartLabelSpecs.tmp.tooltip.unit}${
      AppConst.temperatureUnits.celsius.key
    }`;
    const tmpF = `${tmp_fahrenheit.toFixed(0)}${ChartConst.ChartLabelSpecs.tmp.tooltip.unit}${
      AppConst.temperatureUnits.fahrenheit.key
    }`;
    if (onlyPreferred) {
      return preferredTempratureFormat === AppConst.temperatureUnits.fahrenheit.key ? tmpF : tmpC;
    }
    if (preferredTempratureFormat === AppConst.temperatureUnits.fahrenheit.key) {
      return `${tmpF}/${tmpC}`;
    }
    return `${tmpC}/${tmpF}`;
  }
  return ChartConst.noneTooltip;
};

export { getTempLabelValue };
