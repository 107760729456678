import {
  PERSONAL_MINIMUMS_ACTIONS_ENUM,
  PERSONAL_MINIMUMS_MUTATIONS_ENUM,
} from './personal-minimums.enum';

export const personalMinimumsActions = {
  [PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_FILTER]({ commit }, payload) {
    commit(PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_FILTER, payload);
  },
  [PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_ENABELD]({ commit }, payload) {
    commit(PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_ENABELD, payload);
  },
  [PERSONAL_MINIMUMS_ACTIONS_ENUM.SET_PERSONAL_MINIMUMS_VALUE]({ commit }, payload) {
    commit(PERSONAL_MINIMUMS_MUTATIONS_ENUM.SET_PERSONAL_MINIMUMS_VALUE, payload);
  },
};
