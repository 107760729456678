<template>
  <IonItem>
    <ion-label>
      <h2 class="makebold">
        {{ product.title }}
      </h2>
    </ion-label>
    <ion-button
      slot="end"
      fill="solid"
      color="primary"
      @click="onBuyProduct(product)"
    >
      {{ product.price }}
    </ion-button>
  </IonItem>
</template>
<script>
import { IonItem, IonLabel, IonButton } from '@ionic/vue';

export default {
  name: 'Subscription',
  components: {
    IonItem,
    IonLabel,
    IonButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  emits: ['buy-product'],
  methods: {
    onBuyProduct(product) {
      this.$emit('buy-product', product);
    },
  },
};
</script>
