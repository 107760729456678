import AccountConst from '../../../../constants/account.constant';
import { getCognitoUserAttribute } from '../../core/getCognitoUserAttribute';
import { updateUserAttributes } from '../../core/updateUserAttributes';

const setT4Cognito = async (t4Data) => {
  const attributeList = [
    getCognitoUserAttribute(AccountConst.customAttribute.t4, t4Data),
  ];
  return updateUserAttributes(attributeList);
};
export { setT4Cognito };
