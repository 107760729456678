const drawUSAStates = (ctx, path, projection, scaleThreshold, usaStates) => {
  // Drawing US states
  ctx.beginPath();
  path(usaStates);
  ctx.lineWidth = 1;
  ctx.fillStyle = '#1F3B59';
  ctx.fill();
  if (projection.scale() > scaleThreshold) {
    ctx.strokeStyle = '#fff';
    ctx.stroke();
  }
};

export { drawUSAStates };
