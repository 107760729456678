import AppConst from '../../constants/app.constant';
import ConfigDeviceSettings from '../../config/device.config';
import { getVisAndCigMapped } from './getVisAndCigMapped';

const rename_nbh_cig_vis = (airport) => {
  const versionToUse = ConfigDeviceSettings.settings.apiVersionKey;
  if (airport.forecast && versionToUse === 3) {
    airport.forecast.forEach((forecast) => {
      if (forecast) {
        forecast.cig = forecast.nbh_cig === null ? 120 : forecast.nbh_cig;
        forecast.vis = forecast.nbh_vis;
        const { cig_mapped, vis_mapped, vis } = getVisAndCigMapped(forecast.cig, forecast.vis);
        forecast.cig = cig_mapped;
        forecast.cig_mapped = cig_mapped;
        forecast.vis_mapped = vis_mapped;
        forecast.vis = vis;
      }
    });
  }
};

const rename_nbs_cig_vis = (airport) => {
  const versionToUse = ConfigDeviceSettings.settings.apiVersionKey;
  if (airport.forecast && versionToUse === 3) {
    airport.forecast.forEach((forecast) => {
      if (forecast) {
        forecast.cig = forecast.nbs_cig === null ? 120 : forecast.nbs_cig;
        forecast.vis = forecast.nbs_vis;
        const { cig_mapped, vis_mapped, vis } = getVisAndCigMapped(forecast.cig, forecast.vis);
        forecast.cig = cig_mapped;
        forecast.cig_mapped = cig_mapped;
        forecast.vis_mapped = vis_mapped;
        forecast.vis = vis;
      }
    });
  }
};

const doRenamingCigVis = (data, datamode) => {
  if (datamode === AppConst.dataMode.meteogram) {
    rename_nbh_cig_vis(data.lamp);
    rename_nbs_cig_vis(data.mav);
  } else {
    data.lamp.forEach((airport) => {
      rename_nbh_cig_vis(airport);
    });
    data.mav.forEach((airport) => {
      rename_nbs_cig_vis(airport);
    });
  }
};

export { doRenamingCigVis };
