import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  USER_ACTIONS_ENUM,
} from '../../store/modules/user/user.enum';
/**
 *
 * @returns Promise
 */
const setUserAttributeInStore = (attributeData) => {
  store.dispatch(
    `${STORE_NAMESPACE.USER}/${USER_ACTIONS_ENUM.SET_USER_ATTRIBUTE}`,
    attributeData,
  );
};
export {
  setUserAttributeInStore,
};
