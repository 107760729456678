import {
  toastController,
} from '@ionic/vue';

function presentNetworkToast() {
  toastController.create({
    header: 'No Internet Connection',
    message: 'Make sure your device is connected to the internet.',
    position: 'middle',
    buttons: [{
      text: 'Ok',
      role: 'cancel',
    }],
  }).then((toast) => {
    toast.present();
  });
}

export { presentNetworkToast };
