import { checkUserAuthenticated } from '../aws/user/checkUserAuthenticated';
import { reLoginAws } from '../aws/login/reLoginAws';
import { userAuthFailed } from './userAuthFailed';
import { userAuthSuccess } from './userAuthSuccess';

const asyncAuthFlow = async () => {
  const resStatus = await checkUserAuthenticated();
  if (resStatus.status === true) {
    userAuthSuccess();
  } else {
    const resRedo = await reLoginAws();
    if (resRedo !== undefined && resRedo !== null && !resRedo.message) {
      userAuthSuccess();
    } else {
      userAuthFailed();
    }
  }
};

export { asyncAuthFlow, userAuthSuccess };
