const refreshUserSession = (cognitoUser, refreshToken) => new Promise((resolve) => {
  cognitoUser.refreshSession(refreshToken, (err, refreshedSession) => {
    if (!err) {
      resolve(refreshedSession);
    } else {
      resolve(null);
    }
  });
});

export { refreshUserSession };
