import { lineString as turfLineString, point as turfPoint, multiPoint as turfMultiPoint } from '@turf/turf';
import { getStormDirection } from './getStormDirection';
import { getStormIconName } from '../getStormIconName';
import { isValidStorm } from './isValidStorm';
import utilityService from '../../../../../services/utility.service';

const processStorm = (stormDetected, radarSite, startStormUTC) => {
  const storm = {
    ...stormDetected,
    radarId: radarSite.id,
    ddhhmm: radarSite.ddhhmm,
    utcTimeStamp: startStormUTC,
  };
  const stormDots = [];
  const lineStringPoints = [];
  const multiPoints = [];
  const stormTimings = [];
  if (isValidStorm(storm)) {
    storm.fcst.forEach((fcst, idx) => {
      lineStringPoints.push([fcst[1], fcst[0]]);
      if (idx > 0) {
        multiPoints.push([fcst[1], fcst[0]]);
      }
      stormDots.push(turfPoint([fcst[1], fcst[0]]));
      const d = utilityService.addMinutes(startStormUTC, (15 * (idx)));
      stormTimings.push(d);
    });
    if (storm.si && storm.si.ct) {
      /**
       * Increase CT value by 25%
       */
      storm.extra = {
        ct25: Math.floor(storm.si.ct + ((storm.si.ct * 25) / 100)),
      };
    }
    storm.stormDirection = getStormDirection(storm.fcst[0], storm.fcst[storm.fcst.length - 1]);
    storm.stormStartPointGeoJson = turfPoint([storm.fcst[0][1], storm.fcst[0][0]]);
    storm.multiPointGeojson = turfMultiPoint(multiPoints);
    storm.lineStringPoints = turfLineString(lineStringPoints);
    storm.stormDots = stormDots;
    storm.stormTimings = stormTimings;
    const icon = getStormIconName(storm);
    if (icon) {
      storm.icon = icon;
    }
    return storm;
  }
};

export { processStorm };
