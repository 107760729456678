import AppConst from '../../constants/app.constant';
import reportError from '../error-handler/reportError';

export const getSettingFromLocalstorage = (settingKey) => {
  try {
    return JSON.parse(localStorage.getItem(
      AppConst.localstorageConf.prefix + settingKey,
    ));
  } catch (e) {
    e.message = `Error parsing localstorage key : ${AppConst.localstorageConf.prefix}${settingKey} ${e.message}`;
    // JSON parse Error
    reportError(e);
    return null;
  }
};
