<template>
  <h1>Skew-T Unavailable</h1>
  <!-- <iframe
    height="100%"
    width="100%"
    :src="skewTSource"
  /> -->
</template>
<script>
import { getServiceHostPreference } from '../../services/utils/getServiceHostPreference';
import AppConst from '../../constants/app.constant';

// https://app.weatherspork.com/api/skew-t/?data_source=Op40&latest=latest&fcst_len=shortest&airport=KBTP
export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  computed: {
    skewTSource() {
      // return `https://rucsoundings.noaa.gov/gwt/?data_source=Op40&latest=latest&start_year=2021&start_month_name=Apr&start_mday=12&start_hour=19&start_min=0&n_hrs=1.0&fcst_len=shortest&airport=${this.settings.start}&gwt=Interactive%20plot&hydrometeors=false&start=latest`;
      return `${getServiceHostPreference()}/${AppConst.skewTTabsData}${this.settings.start}`;
    },
  },
};
</script>
