import reportError from '../../error-handler/reportError';
import { getCurrentUser } from './getCurrentUser';

const changeOldPassword = (data) => new Promise((resolve) => {
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser) {
      cognitoUser.changePassword(data.oldPassword, data.newPassword, (err) => {
        if (err) {
          resolve(err);
          reportError(err);
          return;
        }
        resolve(true);
      });
    }
  });
});

export { changeOldPassword };
