import _cloneDeep from 'lodash/cloneDeep';
import AppConst from '../../constants/app.constant';
import { saveToLocalStorage } from './saveToLocalStorage';

export const setSettingInLocalstorage = (settings, settingKey) => {
  if (settings) {
    if (settingKey === 'weatherSetting' && settings.airportDetails) {
      // No need to save tabs data for weather 7day screen
      settings = _cloneDeep(settings, true);
      delete settings.airportDetails.tabs;
    }
    saveToLocalStorage(AppConst.localstorageConf.prefix + settingKey, JSON.stringify(
      settings,
    ));
  }
};
