import globeviewUtilityService from '../MapviewUtility.service';

const getBoundingBox = (
  projection,
  width,
  height,
) => globeviewUtilityService.calculateBoundingBox(
  projection,
  width,
  height,
);

export { getBoundingBox };
