<template>
  <ion-menu-toggle auto-hide="false">
    <ion-item
      :class="[activeStateKey === menuItem.key ? 'active-sub-item' : '']"
      class="menu-airport-list-item sub-items"
      @click="onMenuSubItemClicked"
    >
      <font-awesome-icon
        v-if="menuItem.fontawesome"
        slot="start"
        :icon="menuItem.icon"
      />
      <ion-icon
        v-else
        slot="start"
        mode="md"
        :icon="menuItem.icon"
      />
      <ion-label>
        {{ menuItem.label }}
      </ion-label>
    </ion-item>
  </ion-menu-toggle>
</template>
<script>
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonMenuToggle,
} from '@ionic/vue';

export default {
  components: {
    IonItem,
    IonIcon,
    IonLabel,
    IonMenuToggle,
  },
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
    activeStateKey: {
      type: String,
      default: '',
    },
  },
  emits: ['sub-item-clicked'],
  methods: {
    onMenuSubItemClicked() {
      this.$emit('sub-item-clicked', this.menuItem);
    },
  },
};
</script>

<style scoped>
.menu-airport-list-item {
  transition: 0.09s all linear;
  font-size: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  --background: transparent;
}
.menu-airport-list-item svg,
.menu-airport-list-item ion-icon {
  font-size: 18px;
  margin-right: 0;
}
.menu-airport-list-item {
  --min-height: 16px;
}
.menu-airport-list-item ion-label {
  margin: 0px 8px 0px 0;
}
.active-sub-item,
html:not(.plt-hybrid) .menu-airport-list-item:hover {
  border-left: 4px solid #F28B18 !important;
}
.active-sub-item {
    background: #363941 !important;
    --background: #363941 !important;
    color: #ffffff;
}
</style>
