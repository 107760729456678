import { messageLoaderState } from './message-loader.state';
import { messageLoaderActions } from './message-loader.action';
import { messageLoaderMutations } from './message-loader.mutation';
import { messageLoaderGetters } from './message-loader.getter';

export const messageLoader = {
  namespaced: true,
  state: messageLoaderState,
  mutations: messageLoaderMutations,
  actions: messageLoaderActions,
  getters: messageLoaderGetters,
};
