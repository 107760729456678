<template>
  <ion-label
    v-if="showUtcClock"
    slot="start"
    class="utc-clock"
  >
    <div class="nowText">
      Now
    </div>
    {{ utcTime }}
  </ion-label>
</template>
<script>
import { IonLabel } from '@ionic/vue';
import ChartConst from '../constants/chart.constant';
import d3 from '../globalexports/d3.export';

export default {
  name: 'UTCClock',
  components: {
    IonLabel,
  },
  data() {
    return {
      utcTime: '',
      showUtcClock: window.innerWidth > 330,
    };
  },
  computed: {
    utcDateFormat() {
      return d3.utcFormat(ChartConst.xAxis.format + ChartConst.GraphConfig.xAxis.timePostFix.zulu);
    },
  },
  created() {
    this.getUtcTime();
  },
  methods: {
    getUtcTime() {
      const now = new Date();
      const timeoutAfter = (60 - parseFloat(`${now.getSeconds().toString()}.${now.getMilliseconds()}`)) * 1000;
      this.utcTime = this.utcDateFormat(now);
      setTimeout(this.getUtcTime, timeoutAfter);
    },
  },
};
</script>
<style scoped>
.utc-clock{
  padding: 6px;
  position: absolute;
  font-weight: bold;
  font-size: 13px;
  color: #fff;
  left: 0;
  margin: 0;
  text-align: center;
}

.nowText {
  font-size: 12px;
}
</style>
