import AWS from 'aws-sdk';
import {
  clearStoreOnLogout,
} from '../../clearStoreOnLogout';
import { getCurrentUser } from './getCurrentUser';
import { setIsLoggedIn } from '../../user/setIsLoggedIn';
import { clearLocalStorage } from '../../localstorage/clearLocalStorage';
import { stopRefreshTokenTimer } from './refreshSessionTimer';

const signOut = () => {
  stopRefreshTokenTimer();
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      cognitoUser.signOut();
    }
  });
  clearStoreOnLogout();
  setIsLoggedIn(false);
  AWS.config.credentials.clearCachedId();
  clearLocalStorage();
  // On signout make sure remove forgotpwd state also
  localStorage.removeItem('bn_forgotPwdState');
  return true;
};

export { signOut };
