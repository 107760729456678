<template>
  <ion-list>
    <div>
      <div class="custom-divider">
        Membership
      </div>
      <div>
        <AccountActiveMembershipProduct
          v-if="activePaypalPlan"
          :product="activePaypalPlan"
        />
        <ion-item>
          <ion-label>{{ paypalNextBillingLabel }}</ion-label>
          <ion-label
            slot="end"
            class="right-align expiryinfo"
          >
            {{ nextLatestBillingDate }}
          </ion-label>
        </ion-item>
        <ion-item
          v-if="isPaypalMembershipActive"
          @click="onshowManageMemebership()"
        >
          <ion-label>Manage membership</ion-label>
          <font-awesome-icon
            v-if="!showManageMemebership"
            slot="end"
            icon="angle-down"
            class="floatright"
          />
          <font-awesome-icon
            v-if="showManageMemebership"
            slot="end"
            icon="angle-up"
            class="floatright"
          />
        </ion-item>
        <div v-if="isPaypalMembershipActive && showManageMemebership">
          <ion-item
            @click="onCancelPaypalMembership()"
          >
            <ion-label>Cancel Membership</ion-label>
            <ion-icon
              slot="end"
              :icon="arrowForward"
              mode="ios"
            />
          </ion-item>
        </div>
      </div>
    </div>
  </ion-list>
</template>
<script>
import {
  IonList, IonItem, IonLabel, IonIcon,
  alertController,
} from '@ionic/vue';
import addDays from 'date-fns/addDays';
import { arrowForward } from 'ionicons/icons';
import { mapGetters, mapActions } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM, SUBSCRIPTION_ACTIONS_ENUM } from '../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AppConst from '../constants/app.constant';
import PaypalConst from '../constants/paypal.constant';
import { getUserPaypalNextBillingDate, getUsersExtraDays, getNextLatestBillingDateBetweenPaypalAndFS } from '../services/user/getUserPaypalNextBillingDate';
import { getIsUserPaypalSubscriptionActive } from '../services/user/getIsUserPaypalSubscriptionActive';
import { getIsUserPaypalSubscriptionCancelled } from '../services/user/getIsUserPaypalSubscriptionCancelled';
import AccountActiveMembershipProduct from './AccountActiveMembershipProduct.vue';

export default {
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    AccountActiveMembershipProduct,
  },
  data() {
    return {
      arrowForward,
      showManageMemebership: false,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      user: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
      activePaypalPlan: SUBSCRIPTION_GETTERS_ENUM.GET_ACTIVE_PAYPAL_PLAN,
    }),
    paypalNextBillingDate() {
      const nextBillingDate = getUserPaypalNextBillingDate(this.user);
      if (!nextBillingDate) {
        return;
      }
      const extraDays = getUsersExtraDays(this.user);
      const d = addDays(new Date(nextBillingDate), extraDays);
      return d.toLocaleDateString(AppConst.buserPreferredDatedFormatLocale, { dateStyle: 'medium' });
    },
    nextLatestBillingDate() {
      const nextBillingDate = getNextLatestBillingDateBetweenPaypalAndFS(this.user);
      if (!nextBillingDate) {
        return;
      }
      const extraDays = getUsersExtraDays(this.user);
      const d = addDays(new Date(nextBillingDate), extraDays);
      return d.toLocaleDateString(AppConst.buserPreferredDatedFormatLocale, { dateStyle: 'medium' });
    },
    paypalNextBillingLabel() {
      return this.isPaypalMembershipActive ? 'Next billing date' : 'Expiration date';
    },
    isPaypalMembershipCancelled() {
      return getIsUserPaypalSubscriptionCancelled(this.userPaypalSubscription);
    },
    isPaypalMembershipActive() {
      return getIsUserPaypalSubscriptionActive(this.userPaypalSubscription);
    },
  },
  methods: {
    ...mapActions(STORE_NAMESPACE.SUBSCRIPTION, {
      cancelPaypalMembership: SUBSCRIPTION_ACTIONS_ENUM.CANCEL_USER_PAYPAL_SUBSCRIPTION,
    }),
    onshowManageMemebership() {
      this.showManageMemebership = !this.showManageMemebership;
    },
    sendSupportEmail(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
    onCancelPaypalMembership() {
      const vm = this;
      alertController
        .create({
          header: 'Feedback',
          message: PaypalConst.alert.cancelPaypalMembership.messageBeforeYouGo,
          buttons: [
            {
              text: 'No',
              handler() {
                vm.confirmCancel();
              },
            },
            {
              text: 'Yes',
              handler() {
                vm.sendSupportEmail();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    confirmCancel() {
      const vm = this;
      alertController
        .create({
          header: 'Confirm',
          message: PaypalConst.alert.cancelPaypalMembership.message.replace('{EXPIRY_DATE}', this.paypalNextBillingDate),
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Yes',
              handler() {
                vm.cancelPaypalMembership();
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
  },
};
</script>
