import AppConst from '../../../constants/app.constant';
import chartDataService from '../../../services/chartData.service';
import { processWeatherData } from './processWeatherData';

const primaryAPI = AppConst.possibleWeatherApi.govWeather;
const secondary = AppConst.possibleWeatherApi.mapClick;

/**
 *  try api.weather 2x then try api.forecast 2x
 * @param {*} settings
 * @returns
 */
const fetchDataWithFallBack = async (settings) => {
  let apiToUse = primaryAPI;
  let data = await chartDataService.getWeatherData(
    settings,
    primaryAPI,
  );
  if (!data || data.success === false || data.serviceUnavailable) {
    data = await chartDataService.getWeatherData(settings, primaryAPI);
  }
  if (!data || data.success === false || data.serviceUnavailable) {
    data = await chartDataService.getWeatherData(settings, secondary);
    apiToUse = secondary;
  }
  if (!data || data.success === false || data.serviceUnavailable) {
    data = await chartDataService.getWeatherData(settings, secondary);
  }
  return { data, apiToUse };
};

const getWeatherData = async (settings) => {
  let responseData;
  const { data, apiToUse } = await fetchDataWithFallBack(settings);
  settings.apiToUse = apiToUse;
  if (data && data.success !== false && !data.serviceUnavailable) {
    responseData = processWeatherData(data, settings);
  } else {
    responseData = data;
  }
  return responseData;
};

export { getWeatherData };
