import {
  AEROPLANE_LOADER_ACTIONS_ENUM,
  AEROPLANE_LOADER_MUTATIONS_ENUM,
} from './aeroplane-loader.eum';

export const aeroplaneLoaderActions = {
  [AEROPLANE_LOADER_ACTIONS_ENUM.SET_SHOW_AEROPLANE_TRUE]({ commit }) {
    commit(AEROPLANE_LOADER_MUTATIONS_ENUM.SET_SHOW_AEROPLANE_TRUE);
  },
  [AEROPLANE_LOADER_ACTIONS_ENUM.SET_SHOW_AEROPLANE_FALSE]({ commit }) {
    commit(AEROPLANE_LOADER_MUTATIONS_ENUM.SET_SHOW_AEROPLANE_FALSE);
  },
};
