import globeConstant from '../../../constants/globe.constant';
import ConfigDeviceSettings from '../../../config/device.config';

const getScaleMultiplierForZoomExtent = () => {
  const { stormTrackZoomExtentRange } = ConfigDeviceSettings.settings;
  const valueConfig = stormTrackZoomExtentRange.split(',').map((item) => Number(item));
  if (valueConfig && valueConfig.length === 3) {
    return valueConfig;
  }
  return globeConstant.scaleMultiplierForZoomExtent;
};

const getZoomLevel = (scale, projection) => {
  const thresholdMap = getScaleMultiplierForZoomExtent().map((value) => scale * value);
  const currentScale = projection.scale();
  for (let i = 0; i < thresholdMap.length; i += 1) {
    if (currentScale < thresholdMap[i]) {
      return i + 1;
    }
  }
  return thresholdMap.length + 1;
};

export { getZoomLevel };
