const PERSONAL_MINIMUMS_ACTIONS_ENUM = {
  SET_PERSONAL_MINIMUMS_FILTER: 'SET_PERSONAL_MINIMUMS_FILTER',
  SET_PERSONAL_MINIMUMS_ENABELD: 'SET_PERSONAL_MINIMUMS_ENABELD',
  SET_PERSONAL_MINIMUMS_VALUE: 'SET_PERSONAL_MINIMUMS_VALUE',
};

Object.freeze(PERSONAL_MINIMUMS_ACTIONS_ENUM);

const PERSONAL_MINIMUMS_GETTERS_ENUM = {
  GET_FILTERED_PERSONAL_MINIMUMS: 'GET_FILTERED_PERSONAL_MINIMUMS',
  GET_CURRENT_PERSONAL_MINIMUMS_FILTER: 'GET_CURRENT_PERSONAL_MINIMUMS_FILTER',
};

Object.freeze(PERSONAL_MINIMUMS_GETTERS_ENUM);

const PERSONAL_MINIMUMS_MUTATIONS_ENUM = {
  SET_PERSONAL_MINIMUMS_FILTER: 'SET_PERSONAL_MINIMUMS_FILTER',
  SET_PERSONAL_MINIMUMS_ENABELD: 'SET_PERSONAL_MINIMUMS_ENABELD',
  SET_PERSONAL_MINIMUMS_VALUE: 'SET_PERSONAL_MINIMUMS_VALUE',
};

Object.freeze(PERSONAL_MINIMUMS_MUTATIONS_ENUM);

export {
  PERSONAL_MINIMUMS_ACTIONS_ENUM,
  PERSONAL_MINIMUMS_MUTATIONS_ENUM,
  PERSONAL_MINIMUMS_GETTERS_ENUM,
};
