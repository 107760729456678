import utilityService from '../utility.service';
import DefConst from '../../constants/def.constant';
import ChartConst from '../../constants/chart.constant';

const processAirportMetar = (data, airportMetar) => {
  if (!data || !data.length) {
    return [{
      icao_id: airportMetar.icao_id,
      isMetarObj: true,
    }];
  }
  return data?.map((forecast) => {
    const airport = { ...forecast, raw_text: forecast.code };
    airport.vis_mapped = ChartConst.mavDefsMap.vis[airport.vis];
    airport.icao_id = forecast.station_id;
    airport.AirportObjJson = utilityService.getMatchedAirportObject(
      airport.icao_id,
    );
    if (airport.AirportObjJson) {
      airport.faa_facility_name = airport.AirportObjJson.faa_facility_name;
    }
    airport.isMetarObj = true;
    if (airport.AirportObjJson) {
      airport.lat = parseFloat(airport.AirportObjJson.lat);
      airport.lng = parseFloat(airport.AirportObjJson.lng);
    }
    if (airport.wind_speed) {
      airport.wsp = parseFloat(airport.wind_speed);
      // round of wind speed
      airport.wsp = parseInt(airport.wsp.toFixed(0), 10);
      airport.wdr = parseFloat(airport.wind_dir) / 10;
    }

    if (airport.wind_gust) {
      airport.wgs = parseFloat(airport.wind_gust);
    }
    airport.sky_condition = [];
    if (airport.sky) {
      airport.sky_condition = [{
        _sky_cover: airport.sky,
      }];
    }
    let indexLevelCld;
    let cloudToUse = null;
    let i = 0;
    while (i < airport.sky_condition.length) {
      // cloudToUse
      if (airport.sky_condition[i]._sky_cover === DefConst.skcMetar) {
        airport.sky_condition[i]._sky_cover = DefConst.clrMetar;
      }
      indexLevelCld = DefConst.cloudPriorityMetar.indexOf(
        airport.sky_condition[i]._sky_cover,
      );
      if (
        airport.sky_condition[i]._sky_cover === DefConst.ovcMetar
          || airport.sky_condition[i]._sky_cover === DefConst.bknMetar
      ) {
        // OVC or BKN has same priority, which is found first use that
        indexLevelCld = 0;
      }
      if (i === 0) {
        const [skyCondition] = airport.sky_condition;
        cloudToUse = skyCondition;
        cloudToUse.indexLevel = indexLevelCld;
      } else if (indexLevelCld > -1 && indexLevelCld < cloudToUse.indexLevel) {
        cloudToUse = airport.sky_condition[i];
        cloudToUse.indexLevel = indexLevelCld;
      }
      i += 1;
    }

    if (cloudToUse) {
      airport.cld = DefConst.metarToBnMapping[cloudToUse._sky_cover];
      if (
        cloudToUse._sky_cover === DefConst.bknMetar
          || cloudToUse._sky_cover === DefConst.ovcMetar
      ) {
        airport.cig_mapped = cloudToUse._cloud_base_ft_agl;
      }
    }
    if (airport.obs_temp) {
      airport.tmp = parseFloat(airport.obs_temp);
      airport.tmp_celsius = airport.tmp;
      airport.tmp_fahrenheit = utilityService.celsiusToFahrenheit(airport.tmp);
    }

    if (airport.dewpt) {
      airport.dew = parseFloat(airport.dewpt);
      airport.dew_celsius = airport.dew;
      airport.dew_fahrenheit = utilityService.celsiusToFahrenheit(airport.dew);
    }
    if (
      utilityService.isValueValid(airport.tmp)
        && utilityService.isValueValid(airport.dew)
    ) {
      if (airport.tmp >= airport.dew) {
        airport.rlh = utilityService.calcRlh(airport.tmp, airport.dew);
      } else {
        airport.rlh = 100;
      }
    }

    if (airport.vis) {
      airport.vis_mapped = parseFloat(airport.vis);
      if (airport.vis_mapped % 0.25 === 0) {
        airport.vis_display = airport.vis_mapped
          + ChartConst.ChartLabelSpecs.vis.tooltip.unit;
      } else {
        airport.vis_display = Math.round(
          utilityService.convertSMToMeter(airport.vis_mapped) / 100,
        ) * 100;
        if (airport.vis_display >= 10000) {
          airport.vis_display = '>10 km';
        } else {
          const paddingZeros = '0000';
          airport.vis_display = paddingZeros + airport.vis_display;
          airport.vis_display = airport.vis_display.substr(airport.vis_display.length - 4)
              + ChartConst.ChartLabelSpecs.vis.tooltip.unitMeter;
        }
      }
    }

    airport.observation_time_local = new Date(forecast.obs_time);
    return airport;
  });
};

export { processAirportMetar };
