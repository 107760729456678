import {
  writeText,
} from '../../../../utils/canvas';

const drawStormTiming = (projection, ctx, storm, dateFormat) => {
  if (storm && storm.stormTimings && !storm.isFilterMode) {
    const { si } = storm;
    if (si) {
      storm.stormTimings.forEach((stormTiming, idx) => {
        const latLongCord = [storm.fcst[idx][1], storm.fcst[idx][0]];
        const latLongProj = projection(latLongCord);
        writeText(ctx, dateFormat(stormTiming), latLongProj[0] - 10, latLongProj[1] - 10);
      });
    }
  }
};

export { drawStormTiming };
