<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <ion-button fill="solid" color="secondary" @click="closeModal()">
            Close
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button fill="solid" color="secondary" @click="copytoclipboard()">
            Copy
          </ion-button>
          <ion-button fill="solid" color="secondary" @click="emailSupport()">
            Email Support
          </ion-button>
        </ion-buttons>
        <ion-title>Data</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <p class="paddingcontent">
        {{ reportdata }}
      </p>
      <!-- <div class="paddingcontent">
        <ul v-for="(report, $index) in reportdata"  v-bind:key="$index">
          <li>
            <div>Request: {{$index + 1}}</div>
            <br />
            <p><b>Request URL:</b> {{report.key}}</p>
            <p><b>Response Data:</b> {{report.value}}</p>
            <hr/>
          </li>
          <hr/>
        </ul>
    </div> -->
    </ion-content>
  </div>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  toastController,
} from '@ionic/vue';
import _forEach from 'lodash/forEach';
import AppConst from '../../constants/app.constant';
import reportService from '../../services/report.service';

export default {
  name: 'Report',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  data() {
    return {
      reportdata: AppConst.jsonBlobLoadingMessage,
    };
  },
  mounted() {
    const vm = this;
    reportService
      .postJsonToBlob()
      .then((data) => {
        vm.reportdata = data;
      });
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    getMailBody() {
      const vm = this;
      let emailBody = '';
      _forEach(vm.reportdata, (item) => {
        emailBody += `${item.value}\n`;
      });
      let returnData = '\n Full Report: \n';
      returnData = emailBody
        + returnData
        + encodeURIComponent(JSON.stringify(vm.reportdata));
      return returnData;
    },
    emailSupport() {
      const vm = this;
      if (vm.reportdata === AppConst.jsonBlobLoadingMessage) {
        return;
      }
      window.open(
        `mailto:${AppConst.errorReporting.mailto}?subject=${AppConst.jsonBlobReporting.subject}&body=${vm.reportdata}`,
        '_self',
      );
      vm.closeModal();
    },
    async copytoclipboard() {
      await navigator.clipboard.writeText(this.reportdata);
      const toast = await toastController
        .create({
          message: 'Copied to clipboard',
          duration: 1500,
        });
      toast.present();
    },
  },
};
</script>
<style scoped>
.paddingcontent {
  padding: 20px;
}
</style>
