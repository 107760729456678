import EventConst from '../../constants/event.constant';
import Emitter from '../../bus';
import { setUserDetails } from './setUserDetails';
import { signOut } from '../aws/user/signOut';

const doSignoutTask = function () {
  signOut();
  setUserDetails({});
  Emitter.emit(EventConst.favorite.cleanFavorite);
};

export { doSignoutTask };
