<template>
  <div class="app-fcst">
    <div
      v-if="data && data.error"
      v-html="data.error"
    />
    <div
      v-else
      class="airport-tab-content"
    >
      <pre
        v-html="data"
      />
    </div>
  </div>
</template>
<script>
import * as LayerUpdateTimeService from '../../services/layerUpdateTime/layerUpdateTime.service';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        LayerUpdateTimeService.setCurrentScreenRenderTime();
      },
    },
  },
};
</script>
<style scoped>
pre {
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace !important;
}
</style>
