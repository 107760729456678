import { setUserDetails } from './setUserDetails';
import { userNeedSubscription } from './userNeedSubscription';
import { userNeedToAcceptEula } from './userNeedToAcceptEula';
import { doSignoutTask } from './doSignoutTask';
import { getUserAttributes } from '../aws/user/getUserAttributes';
import { startLiveUpdateSync } from '../capacitorLiveUdate.service';
import { setLoginAttributes } from '../aws/user/setAttributes/setLoginAttributes';
import { setUserIdForMatomo } from './setUserIdForMatomo';

const performSideEffect = (userDetails) => {
  setUserDetails(userDetails);
  setLoginAttributes();
  userNeedToAcceptEula(userDetails);
  userNeedSubscription(userDetails);
  setUserIdForMatomo(userDetails);
};

const loadUserFromCognito = async () => {
  const userDetails = await getUserAttributes();
  // const userDetails = { code: 'NetworkError' };
  if (userDetails.statusCode === 400) {
    doSignoutTask();
    return { success: false };
  }
  if (userDetails && userDetails.email) {
    startLiveUpdateSync(userDetails);
    performSideEffect(userDetails);
    return { success: true };
  }
};

export { loadUserFromCognito };
