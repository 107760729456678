<template>
  <div>
    <AccountPaypalMembership
      v-if="showPaypalMembership"
    />
    <AccountFsMembership
      v-else-if="showFsMembership"
    />
    <AccountAppleIapMembership
      v-else-if="showAppleIapMembership"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { SUBSCRIPTION_GETTERS_ENUM } from '../store/modules/subscription/subscription.enum';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AccountPaypalMembership from './AccountPaypalMembership.vue';
import AccountAppleIapMembership from './AccountAppleIapMembership.vue';
import AccountFsMembership from './AccountFsMembership.vue';
import { getIsActiveFsUser } from '../services/user/getIsActiveFsUser';
import { getIsActiveApplePayUser } from '../services/user/getIsActiveApplePayUser';

export default {
  components: {
    AccountAppleIapMembership,
    AccountPaypalMembership,
    AccountFsMembership,
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.SUBSCRIPTION, {
      userPaypalSubscription:
        SUBSCRIPTION_GETTERS_ENUM.GET_USER_PAYPAL_SUBSCRIPTION,
    }),
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
    showPaypalMembership() {
      return this.userPaypalSubscription && this.userPaypalSubscription.id;
    },
    showFsMembership() {
      return getIsActiveFsUser(this.userDetails);
    },
    showAppleIapMembership() {
      return getIsActiveApplePayUser(this.userDetails);
    },
  },
};
</script>
