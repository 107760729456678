import { getAppInfo } from '../../capacitor/getApp.info';
import Sentry from './sentry.import';
import appInfo from '../../../app.info.json';
import capacitorService from '../../capacitor.service';
import AppConst from '../../../constants/app.constant';
import getEnvironment from '../utils/getEnvironment';
import checkIfWeShouldReport from '../utils/checkIfWeShouldReport';
import getLocalStorageData from '../utils/getLocalstorage';
// import { doomScreenCheck, reloadDefaultScreenToHandleDoomScreen } from './doomScreenCheck';
// import simulateError from '../utils/simulateError';
import getUserName from '../utils/getUserName';
import { setUserContext } from './setUserContext';

const testingSentry = false;
let numberOfErrorsReported = 0;
let versions = {};
export const registerVueSentry = (app) => {
  app.config.errorHandler = (error) => {
    console.log(error);
    Sentry.captureException(error);
  };
};

export const initializeSentry = async () => {
  if (!capacitorService.isCapacitorMobileApp && window.location.hostname === 'localhost' && testingSentry === false) {
    return;
  }
  getAppInfo().then(({ appBuild, appVersion, appName }) => {
    versions = JSON.stringify({ appBuild, appVersion, appName });
  });
  Sentry.init({
    logErrors: true,
    release: `WSVue-${AppConst.application.version}-${AppConst.application.bundleVersion}`,
    dsn: 'https://8a92a9adff9742038db1eff5da42760e@sentry.io/1436176',
    // transport: Sentry.Transports.FetchTransport,
    // integrations: [new Sentry.Integrations.Vue()],
    environment: getEnvironment(),
    beforeSend(event) {
      /// Setting user context Start
      setUserContext();
      /// Setting user context End
      const exceptionValue = event
        && event.exception
        && event.exception.values
        && event.exception.values[0];
        // Approach taken from Sentry's React Native SDK.
        // See https://github.com/getsentry/sentry/issues/4719#issuecomment-333836573
      const stacktrace = (event && event.stacktrace)
        || (exceptionValue && exceptionValue.stacktrace);
      if (stacktrace) {
        stacktrace.frames.forEach((frame) => {
          if (frame.filename !== '[native code]') {
            // The iOS webview uses a custom URL scheme for serving the web app.
            // See https://github.com/ionic-team/cordova-plugin-ionic-webview#iosscheme
            // The full URL with that scheme will be used for filenames in the stacktrace.
            // The URL begins with "ionic://localhost/".
            // The custom "ionic" scheme in the URL creates issues
            // for source-mapped stacktraces.
            // Replacing "ionic" with "http" in the stackframe filename
            // will enable source-mapped stacktraces.
            frame.filename = frame.filename.replace(/^ionic/, 'http');
            frame.filename = frame.filename.replace(/^capacitor/, 'http');
          }
        });
      }
      // const doomScreenLogs = [];
      // const isDoomScreen = doomScreenCheck(event);
      // if (isDoomScreen) {
      //   doomScreenLogs.push(`Stringifiedvalue:${JSON.stringify(event.exception)}`);
      //   if (!!window.TFDetect && window.TFDetect.isTFXC) {
      //     doomScreenLogs.push('Only try this solution if TestFlight user');
      //     reloadDefaultScreenToHandleDoomScreen();
      //   }
      // }
      if (checkIfWeShouldReport(event)) {
        event.user = { id: getUserName() };
        if (!event.extra) {
          event.extra = {};
        }
        if (event.extra) {
          event.extra.VERSIONS = versions;
        }
        if (event.extra) {
          // event.extra.isDoomScreen = JSON.stringify({
          //   isDoomScreen,
          // });
        }
        if (!event.tags) {
          event.tags = {};
        }
        // if (isDoomScreen) {
        //   event.message = 'DOOM Screen detected';
        //   event.tags.isDoomScreen = isDoomScreen;
        //   event.extra.DOOMSCREENLOG = JSON.stringify(doomScreenLogs);
        // }
        if (event.extra['ErrorProperty:custom_http']) {
          exceptionValue.value = event.extra['ErrorProperty:custom_http'];
          event.fingerprint = [
            event.extra['ErrorProperty:custom_http'],
          ];
        }
        event.extra.localStorageData = getLocalStorageData();
        if (numberOfErrorsReported === 10) {
          // If more then 10 errors are logged for user
          // Then stop logging anymore errors
          // And after 20 seconds reduce numberOfErrorsReported by 5
          setTimeout(() => {
            numberOfErrorsReported -= 5;
          }, 20000);
          return null;
        }
        numberOfErrorsReported += 1;
        return event;
      }
      return null;
    },
  });
  Sentry.configureScope((scope) => {
    scope.setTag('appversion', appInfo.version);
  });
};
