/**
 *
 * @param {Canvas.context} ctx
 * @param {Canvas.PATH} path
 * @param {GeoJSON POINT} stormPoint
 * @param {Boolean} isStartPoint
 */
const drawStormDot = (ctx, path, stormPoint, color) => {
  ctx.beginPath();
  ctx.lineWidth = 3;
  path(stormPoint);
  ctx.strokeStyle = color || '#fff';
  ctx.stroke();
};

export { drawStormDot };
