<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <ion-page>
    <SentinelElement />
    <ion-content force-overscroll="false">
      <div class="swiper-container account-swiper-container">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <!-- Account Detail Slide -->
            <AccountDetails
              :user="userDetails"
            />
            <AccountSubscriptionOptions
              v-if="userDetails"
              @buyProduct="buyProduct"
            />
            <Logout v-if="userDetails" />
          </div>
          <div class="swiper-slide center-align">
            <!-- Product to buy Slide -->
            <SubscriptionProduct
              :product-to-buy="productToBuy"
              @goBackToAccount="goBackToAccount"
            />
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonPage,
} from '@ionic/vue';
import _debounce from 'lodash/debounce';
import Swiper from 'swiper';
import { mapGetters } from 'vuex';
import { STORE_NAMESPACE } from '../store/modules/store.namespace';
import { USER_GETTERS_ENUM } from '../store/modules/user/user.enum';
import AccountDetails from '../components/AccountDetails.vue';
import Logout from '../components/Logout.vue';
import SubscriptionProduct from '../components/SubscriptionProduct.vue';
import AccountSubscriptionOptions from '../components/AccountSubscriptionOptions.vue';

export default {
  name: 'Account',
  components: {
    IonContent,
    IonPage,
    Logout,
    AccountDetails,
    AccountSubscriptionOptions,
    SubscriptionProduct,
  },
  data() {
    return {
      productToBuy: {},
      accountSwiper: null,
    };
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.USER, {
      userDetails: USER_GETTERS_ENUM.GET_USER_DETAILS,
    }),
  },
  methods: {
    handleResizeEvent: _debounce(function () {
      if (this.accountSwiper && this.accountSwiper.update) {
        this.accountSwiper.update();
      }
    }, 100),
    initializeSwiper() {
      this.accountSwiper = new Swiper('.account-swiper-container', {
        allowTouchMove: false,
      });
      setTimeout(() => {
        if (this.accountSwiper && this.accountSwiper.update) {
          this.accountSwiper.update();
        }
      }, 1000);
    },
    ionViewDidEnter() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
      this.initializeSwiper();
    },
    buyProduct(product) {
      this.accountSwiper.slideNext();
      this.productToBuy = product;
    },
    goBackToAccount() {
      this.accountSwiper.slidePrev();
    },
  },
};
</script>
<style scoped>
.center-align {
  text-align: center;
}
</style>
