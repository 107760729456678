<template>
  <ion-footer>
    <ion-toolbar color="dark">
      <utc-clock />
      <IonTitle>
        <ion-segment
          mode="ios"
          class="mapview-segments"
          :value="activeSlider"
          @ionChange="setActiveSlider"
        >
          <ion-segment-button
            class="mapview-etd-tab"
            value="etd"
            layout="icon-start"
          >
            <span class="tab-plane-departure">
              <font-awesome-icon
                slot="start"
                icon="plane-departure"
              />
            </span>
            <ion-label class="segmentLabel">
              ETD
            </ion-label>
          </ion-segment-button>
          <ion-segment-button
            v-if="showRadarSlider"
            layout="icon-start"
            class="mapview-radar-tab"
            value="radar"
          >
            <ion-icon :icon="radioSharp" />
            <ion-label class="segmentLabel">
              Radar
            </ion-label>
          </ion-segment-button>
          <ion-segment-button
            class="mapview-flight-tab"
            layout="icon-start"
            value="flight"
          >
            <ion-icon :icon="airplaneSharp" />
            <ion-label class="segmentLabel">
              Flight
            </ion-label>
          </ion-segment-button>
        </ion-segment>
      </IonTitle>
      <layer-update-time-trigger-button :layer-for="getViewKeyName" />
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import {
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonFooter,
  IonToolbar,
  IonTitle,
} from '@ionic/vue';
import { radioSharp, airplaneSharp } from 'ionicons/icons';
import EventConst from '../constants/event.constant';
import Emitter from '../bus';
import ChartConst from '../constants/chart.constant';
import utilityService from '../services/utility.service';
import ConfigDeviceSettings from '../config/device.config';
import LayerUpdateTimeTriggerButton from './layerUpdateTime/layerUpdateTimeTriggerButton.vue';
import UtcClock from './UTCClock.vue';
import { ViewKeysMap } from '../constants/layerUpdateTime.constant';

export default {
  name: 'MapTabs',
  components: {
    IonIcon,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonFooter,
    IonToolbar,
    IonTitle,
    LayerUpdateTimeTriggerButton,
    UtcClock,
  },
  props: {
    activeSlider: {
      type: String,
      required: true,
    },
  },
  emits: ['set-active-slider'],
  data() {
    return {
      showRadarSlider: true,
      radioSharp,
      airplaneSharp,
      enableRadarFeature: ConfigDeviceSettings.settings.enableRadarFeature,
    };
  },
  computed: {
    getViewKeyName() {
      return ViewKeysMap.MAPVIEW;
    },
  },
  mounted() {
    Emitter.on(EventConst.metchanged, this.setShowRadarSlider);
    this.setShowRadarSlider();
  },
  methods: {
    setActiveSlider(event) {
      this.$emit('set-active-slider', event.detail.value);
    },
    setShowRadarSlider() {
      if (!ConfigDeviceSettings.settings.enableRadarFeature) {
        this.showRadarSlider = false;
        return;
      }
      const filter = utilityService.getSuppressedMetFromLocalStorage();
      this.showRadarSlider = filter.indexOf(ChartConst.RadarFilterKey) === -1;
      if (this.activeSlider === 'radar') {
        this.setActiveSlider({
          detail: {
            value: 'etd',
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.mapview-segments{
  width: 240px;
}
</style>
