<template>
  <ion-header>
    <ion-toolbar color="dark">
      <ion-title>Download progress</ion-title>
      <ion-buttons slot="end">
        <ion-button
          fill="solid"
          color="secondary"
          @click="closeModal"
        >
          Close
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list>
      <div>
        <ion-item
          v-for="item in getLayerData()"
          :key="item.url"
        >
          <layer-update-time-item :item="item" />
        </ion-item>
      </div>
    </ion-list>
  </ion-content>
</template>
<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonContent,
  IonList,
  IonItem,
  modalController,
} from '@ionic/vue';
import { mapGetters } from 'vuex';
import { ViewKeysMap } from '../../constants/layerUpdateTime.constant';
import { STORE_NAMESPACE } from '../../store/modules/store.namespace';
import { LAYER_UPDATE_TIME_GETTER_ENUM } from '../../store/modules/layer-update-time/layer-update-time.enum';
import LayerUpdateTimeItem from './layerUpdateTimeItem.vue';

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonContent,
    IonList,
    IonItem,
    LayerUpdateTimeItem,
  },
  props: {
    layerFor: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(STORE_NAMESPACE.LAYER_UPDATE_TIME, {
      getMapViewLayerUpdateTime: LAYER_UPDATE_TIME_GETTER_ENUM.GET_MAP_VIEW_LAYER_UPDATE_TIME,
      getMetogramLayerUpdateTime: LAYER_UPDATE_TIME_GETTER_ENUM.GET_METEOGRAM_LAYER_UPDATE_TIME,
      getTafLayerUpdateTime: LAYER_UPDATE_TIME_GETTER_ENUM.GET_TAF_LAYER_UPDATE_TIME,
      getFcstdiscussionLayerUpdateTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_FCST_DISCUSSION_LAYER_UPDATE_TIME,
      getMetarsLayerUpdateTime: LAYER_UPDATE_TIME_GETTER_ENUM.GET_METARS_LAYER_UPDATE_TIME,
      getGridViewLayerUpdateTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_GRID_VIEW_LAYER_UPDATE_TIME,
      getRouteProfileLayerUpdateTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_ROUTE_PROFILE_LAYER_UPDATE_TIME,
      getWeatherLayerUpdateTime:
        LAYER_UPDATE_TIME_GETTER_ENUM.GET_WEATHER_LAYER_UPDATE_TIME,
    }),
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    getLayerData() {
      const map = {
        [ViewKeysMap.MAPVIEW]: this.getMapViewLayerUpdateTime,
        [ViewKeysMap.FCSTDISCUSSION]: this.getFcstdiscussionLayerUpdateTime,
        [ViewKeysMap.TAFS]: this.getTafLayerUpdateTime,
        [ViewKeysMap.METARS]: this.getMetarsLayerUpdateTime,
        [ViewKeysMap.METEOGRAM]: this.getMetogramLayerUpdateTime,
        [ViewKeysMap.GRIDVIEW]: this.getGridViewLayerUpdateTime,
        [ViewKeysMap.ROUTEPROFILE]: this.getRouteProfileLayerUpdateTime,
        [ViewKeysMap.WEATHER]: this.getWeatherLayerUpdateTime,
      };
      return map[this.layerFor];
    },
  },
};
</script>
