<template>
  <ion-header>
    <ion-toolbar color="dark">
      <ion-buttons slot="start">
        <menu-button />
        <ion-button
          :id="'btn-fav-' + id"
          fill="solid"
          color="secondary"
          @click="markItemAsFav()"
        >
          <ion-icon
            v-show="isMarkedFavorite"
            class="markedfavorite"
            :icon="star"
          />
          <ion-icon
            v-show="!isMarkedFavorite"
            :icon="star"
          />
        </ion-button>
      </ion-buttons>
      <ion-title id="viewtitle">
        <div
          :id="id"
          class="routeSelectorContainer"
          @click="openRouteSelectModal()"
        >
          <route-selector-button
            id="routeSelector"
            :settings="settings"
          />
        </div>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button
          v-if="showLayerSelector"
          :id="'btn-layer-' + id"
          fill="solid"
          color="secondary"
          @click="openQuickChangesPopover"
        >
          <ion-icon
            mode="ios"
            :icon="logoBuffer"
          />
        </ion-button>
        <ion-button
          fill="solid"
          color="secondary"
          class="app-tour-button"
          @click="showtour()"
        >
          <ion-icon :icon="helpCircle" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>
<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/vue';
import {
  star,
  logoBuffer,
  helpCircle,
  chevronDownOutline,
  chevronUpOutline,
  arrowForward,
  arrowBack,
} from 'ionicons/icons';
import FavoritesService from './Favorites/Favorites.service';
import RouteSelectorButton from './RouteSelectorButton.vue';
import routeControllerService from '../services/routeController.service';

export default {
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    RouteSelectorButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    isMarkedFavorite: {
      type: Boolean,
      default: false,
    },
    showLayerSelector: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['show-tour', 'open-route-select-modal'],
  data() {
    return {
      star,
      logoBuffer,
      helpCircle,
      chevronDownOutline,
      chevronUpOutline,
      arrowForward,
      arrowBack,
    };
  },
  methods: {
    showtour() {
      this.$emit('show-tour');
    },
    openRouteSelectModal() {
      this.$emit('open-route-select-modal');
    },
    openQuickChangesPopover(event) {
      routeControllerService.openQuickChangesPopover(this, event);
    },
    markItemAsFav() {
      FavoritesService.markItemAsFav(this.settings);
    },
  },
};
</script>
