import getAllExtraData from './utils/getAllExtraData';
import reportToSentry from './sentry/reportToSentry';

/**
 *
 * @param {ERROR} errorData
 * @param {Object} extraTag
 */
const reportError = (errorData, extraTag) => {
  const extraData = getAllExtraData(errorData);
  reportToSentry(errorData, extraData, extraTag);
};

export default reportError;
