import { layerUpdateTimeState as state } from './layer-update-time.state';
import { layerUpdateTimeMutation as mutations } from './layer-update-time.mutation';
import { layerUpdateTimeGetter as getters } from './layer-update-time.getter';

export const layerUpdateTime = {
  namespaced: true,
  state,
  mutations,
  getters,
};
