<template>
  <div>
    <div class="agreement-heading">
      <b>WeatherSpork
        <br />
        <br />
        END USER LICENSE AGREEMENT AND TERMS OF USE</b>
    </div>
    <p>
      <b>WeatherSpork</b> is not a substitute for a standard weather briefing as described in
      <a target="_blank" rel="noopener noreferrer"
        href="https://www.faa.gov/sites/faa.gov/files/FAA-H-8083-28_FAA_Web.pdf">FAA Aviation Weather Handbook </a>.
    </p>
    <p>
      Your use of WeatherSpork requires that you accept all liability for using WeatherSpork. You
      hereby disclaim, release, indemnify and hold BuserNet Consulting LLC and its members harmless
      from any and all liability, damages, attorneys fees, costs, expenses, verdicts, settlements
      and judgments that may arise or result from your use of WeatherSpork. In no event will
      BuserNet Consulting LLC and/or its members be liable to you or your heirs or assigns for any
      damages.
    </p>
  </div>
</template>
<style scoped>
.agreement-heading {
  text-align: center;
  padding: 20px;
  font-size: 16px !important;
}
</style>
