import { getUserDetails } from './getUserDetails';
import {
  getUserFsValue,
} from './getUserFsValue';
import { getFsExpiresInDays } from './getFsExpiresInDays';

const getExtraDaysForEarlyPurchase = () => {
  const userDetails = getUserDetails();
  const fsValue = getUserFsValue(userDetails);
  if (fsValue) {
    const extraDays = getFsExpiresInDays(fsValue);
    return extraDays > 0 ? extraDays : 0;
  }
  return 0;
};

export { getExtraDaysForEarlyPurchase };
