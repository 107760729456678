import Sentry from './sentry.import';

/**
 *
 * @param {VueAppInstance} app
 */
export const registerVueSentry = (app) => {
  app.config.errorHandler = (error) => {
    console.error('RegisterVueSentry: ', error);
    Sentry.captureException(error);
  };
};
