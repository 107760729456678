const isDateExpired = (dateToCheck) => {
  const current = new Date();
  current.setHours(0, 0, 0, 0);
  // Set expiry date hours to start of day next to expiry day
  dateToCheck.setHours(24, 0, 0, 0);
  if (current > dateToCheck) {
    return true;
  }
  return false;
};

export { isDateExpired };
