/**
 * For given minutes it calculate minutes and hours. Ex: 65 = 1 hour, 5 minute
 * @param {Number} num This is number of minutes
 * @returns
 */
const minutesToHHmm = (num) => {
  const hours = Math.floor(num / 60);
  const minutes = Math.round(num % 60);
  return { hours, minutes };
};

export { minutesToHHmm };
