import { menuState } from './menu.state';
import { menuActions } from './menu.action';
import { menuMutations } from './menu.mutation';
import { menuGetters } from './menu.getter';

export const menu = {
  namespaced: true,
  state: menuState,
  mutations: menuMutations,
  actions: menuActions,
  getters: menuGetters,
};
