const getIsUserFsExpired = (expiryDate) => {
  const validExpDate = '0000-00-00';
  const currentDate = new Date();
  // Set current date hours to start of todays date
  currentDate.setHours(0, 0, 0, 0);
  let userExpired = false;
  const expiryDateObj = new Date(expiryDate);
  // Set expiry date hours to start of day next to expiry day
  expiryDateObj.setHours(24, 0, 0, 0);
  // User is expired if  currentDate > expiryDate or expiryDate is equal to 0000-00-00
  if (currentDate > expiryDateObj && expiryDate !== validExpDate) {
    userExpired = true;
  }
  return userExpired;
};

export { getIsUserFsExpired };
