import DefConst from '../../constants/def.constant';
import WeatherConst from '../../constants/weather.constant';
import { isDayOrNightAtThisTime } from './isDayOrNightAtThisTime';

function processWeatherValue(item, objectKey) {
  let weatherValue;
  let timeOfDay;
  if (item) {
    if (item.sunMoonPositionData) {
      timeOfDay = isDayOrNightAtThisTime(item, objectKey);
    }
    if (item.t06 > 20 || item.st6 > 10 || item.t01 > 20) {
      weatherValue = WeatherConst.weatherMapping.lightning;
    } else if (item.fzp >= 10) {
      weatherValue = WeatherConst.weatherMapping.freezing;
    } else if (item.sno > 50 && (item.ppo - item.sno > 20 || item.p06 - item.sno > 20)) {
      weatherValue = WeatherConst.weatherMapping.highSnowHighPrecipitation;
    } else if (item.sno > 25 && (item.ppo - item.sno < 5 || item.p06 - item.sno < 5)) {
      weatherValue = WeatherConst.weatherMapping.highSnow;
    } else if (item.sno > 25 && (item.ppo - item.sno < 20 || item.p06 - item.sno < 20)) {
      weatherValue = WeatherConst.weatherMapping.lowPrecipMediumSnow;
    } else if (item.sno > 25 && (item.ppo - item.sno >= 20 || item.p06 - item.sno >= 20)) {
      weatherValue = WeatherConst.weatherMapping.mediumSnowHighPrecipitation;
    } else if (item.sno <= 25 && (item.ppo > 25 || item.p06 > 25)) {
      if (item.cig < 300 || item.vis < 3) {
        weatherValue = WeatherConst.weatherMapping.rainFog;
      } else {
        weatherValue = WeatherConst.weatherMapping.lowSnowMediumPrecipitation;
      }
    } else if (item.cig < 300 || item.vis < 3) {
      weatherValue = timeOfDay === DefConst.timeOfDay.day
        ? WeatherConst.weatherMapping.lowCeilingLowVisibilityDay
        : WeatherConst.weatherMapping.lowCeilingLowVisibilityNight;
    } else if (item.wsp > 20 || item.wgs > 20) {
      weatherValue = WeatherConst.weatherMapping.highWind;
    } else if (item.cld === DefConst.clouds.overcast || item.cld === DefConst.clouds.broken) {
      weatherValue = WeatherConst.weatherMapping.cloudy;
    } else if (item.cld === DefConst.clouds.few || item.cld === DefConst.clouds.scattered) {
      weatherValue = timeOfDay === DefConst.timeOfDay.day
        ? WeatherConst.weatherMapping.scatteredCloudDay
        : WeatherConst.weatherMapping.scatteredCloudNight;
    } else {
      weatherValue = timeOfDay === DefConst.timeOfDay.day
        ? WeatherConst.weatherMapping.sunny
        : WeatherConst.weatherMapping.night;
    }
    if (item.wsp === null || item.frl === null) {
      // if wsp and frl is null then dont show any icon for weather
      weatherValue = null;
    }
    item.timeOfDay = timeOfDay;
    item.weatherRepresentation = weatherValue;
  }
  return item;
}

export { processWeatherValue };
