const getStormFiltered = (stormTrackData, valueAbove) => {
  const stormsFiltered = [];
  stormTrackData.allStorms.forEach((storm) => {
    const { si, extra } = storm;
    if (si) {
      if (storm.hail) {
        const { probHail, probSevere } = storm.hail;
        if (!storm.si) {
          storm.si = {};
        }
        storm.si.ph = probHail;
        storm.si.psh = probSevere;
      }
      if (extra.ct25 && extra.ct25 > valueAbove) {
        stormsFiltered.push(storm);
      }
    }
  });
  return stormsFiltered;
};

export { getStormFiltered };
