import AppConst from '../../constants/app.constant';
import utilityService from '../utility.service';

const getServiceHostPreference = () => {
  let serviceHostPreference = localStorage.getItem(AppConst.localstorageConf.prefix
    + AppConst.localstorageConf.serviceHost);
  if (!serviceHostPreference) {
    if (utilityService.isCordovaApplication() || window.location.host === 'localhost:8100') {
      serviceHostPreference = AppConst.environments.prod.url;
    } else {
      serviceHostPreference = `${window.location.protocol}//${window.location.host}`;
    }
  }
  return serviceHostPreference;
};

export { getServiceHostPreference };
