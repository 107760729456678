import { ignoreErrorsList } from './ignoredErrors';
import checkIfReportAPIFailure from './checkIfReportAPIFailure';

const checkIfWeShouldReport = (ex) => {
  let shouldReport = true;
  ignoreErrorsList.forEach((item) => {
    if (ex && JSON.stringify(ex.exception).indexOf(item) > -1) {
      shouldReport = false;
    }
  });
  if (shouldReport && ex.extra) {
    shouldReport = checkIfReportAPIFailure(ex.extra['ErrorProperty:config'], ex.extra.timeoutlag);
  }
  return shouldReport;
};

export default checkIfWeShouldReport;
