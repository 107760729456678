<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <ion-header>
    <ion-toolbar color="dark">
      <IonTitle>
        Need Subscription
      </IonTitle>
      <ion-buttons
        v-show="currentSlide"
        slot="start"
      >
        <ion-button
          class="back-button"
          fill="solid"
          color="secondary"
          @click="onBack"
        >
          Back
          <ion-icon
            slot="start"
            mode="ios"
            :icon="arrowBack"
          />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>
<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonButtons,
} from '@ionic/vue';
import {
  arrowBack,
} from 'ionicons/icons';

export default {
  name: 'NeedSubscription',
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonButtons,
  },
  props: {
    currentSlide: {
      type: Number,
      default: 0,
    },
  },
  emits: ['slide-prev'],
  data() {
    return {
      arrowBack,
    };
  },
  methods: {
    onBack() {
      this.$emit('slide-prev');
    },
  },
};
</script>
