<template>
  <div
    :class="showClass"
    class="app-backgroud-refresh"
  >
    <ion-button
      @click="doRefreshNavigation"
    >
      <ion-icon
        slot="start"
        :icon="refreshCircle"
      />  Please click here to refresh
    </ion-button>
  </div>
</template>
<script>
import { refreshCircle } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/vue';
import reportError from '../services/error-handler/reportError';
import { navigateToRouteDifferentFromNow } from '../services/sentinal/navigateToRouteDifferentFromNow';
import router from '../router';

export default {
  components: {
    IonButton,
    IonIcon,
  },
  data() {
    return {
      refreshCircle,
      showClass: '',
    };
  },
  created() {
    setTimeout(() => {
      this.showClass = 'allowShowing';
    }, 1000);
  },
  methods: {
    doRefreshNavigation() {
      reportError({
        message: 'Background refresh due to gray screen',
        code: 'GSOD Screen',
        routeCurrentName: router.currentRoute.value.name,
        name: 'Doom screen issue detected',
      });
      navigateToRouteDifferentFromNow();
    },
  },
};
</script>
<style scoped>
.app-backgroud-refresh {
  display: none;
}
.ion-page-invisible .app-backgroud-refresh.allowShowing {
  display: flex;
  transition-property: display;
  transition-duration: 5s;
  transition-delay: 2s;
}
.app-backgroud-refresh{
  width: 100%;
  position: absolute;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
