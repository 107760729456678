import {
  personalMinimumsState,
} from './personal-minimums.state';
import {
  personalMinimumsActions,
} from './personal-minimums.action';
import {
  personalMinimumsMutations,
} from './personal-minimums.mutation';
import {
  personalMinimumsGetters,
} from './personal-minimums.getter';

export const personalMinimums = {
  namespaced: true,
  state: personalMinimumsState,
  mutations: personalMinimumsMutations,
  actions: personalMinimumsActions,
  getters: personalMinimumsGetters,
};
