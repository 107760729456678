import AppConst from '../../../constants/app.constant';
import { getGovWeatherDayNightObject } from './getGovWeatherDayNightObject';
import { getMapClickDayNightObject } from './getMapClickDayNightObject';

function getDayNightObject(weatherData, i, settings, isDayObj) {
  switch (settings.apiToUse) {
    case AppConst.possibleWeatherApi.govWeather:
      return getGovWeatherDayNightObject(weatherData, (isDayObj
        ? i
        : i + 1), settings, isDayObj);
    case AppConst.possibleWeatherApi.mapClick:
      return getMapClickDayNightObject(weatherData, (isDayObj
        ? i
        : i + 1), settings, isDayObj);
    default:
  }
  return null;
}

export { getDayNightObject };
