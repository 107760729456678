import { getCognitoSyncDataset } from './getCognitoSyncDataset';
import { fillDatasetMap } from './fillDatasetMap';
import { getIsConnectedToInternet } from '../../connectivity/getIsConnectedToInternet';

const synchronizeDataset = () => {
  getCognitoSyncDataset().then((cognitoSyncDataset) => {
    if (cognitoSyncDataset && getIsConnectedToInternet()) {
      cognitoSyncDataset.synchronize({
        onSuccess() {
          fillDatasetMap(cognitoSyncDataset);
        },
        onConflict(dataset, conflicts, callback) {
          const resolved = [];
          let i = 0;
          while (i < conflicts.length) {
            // Take remote version.
            if (conflicts[i].localRecord.deviceLastModifiedDate > conflicts[i].remoteRecord
              .deviceLastModifiedDate) {
              conflicts[i].remoteRecord.value = conflicts[i].localRecord.value;
            }
            resolved.push(conflicts[i].resolveWithRemoteRecord());
            i += 1;
          }
          if (resolved.length) {
            dataset.resolve(resolved, (err) => {
              if (err) {
                console.log(err);
                return callback(false);
              }
              return callback(true);
            });
          }
        },
        onFailure(err) {
          console.log(err);
        },
        onDatasetDeleted() {
          console.log('onDatasetDeleted');
        },
        onDatasetsMerged() {
          console.log('onDatasetsMerged');
        },
      });
    }
  });
};

export { synchronizeDataset };
