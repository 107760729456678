<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <div>
    <div
      id="routeprofileNoDataMessage"
      class="no-data"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <span />
    </div>
    <div
      id="routeProfileChartContainer"
      class="svg-container"
    />
  </div>
</template>
<script>
import _debounce from 'lodash/debounce';
import RouteProfileChartService from './service/routeProfileChart.service';
import ChartConst from '../../constants/chart.constant';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import routeProfileService from '../../views/Routeprofile/Routeprofile.service';

export default {
  name: 'RouteprofileChart',
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      travelRoute: null,
      fullData: null,
      softDraw: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleResizeEvent);
      window.addEventListener('resize', this.handleResizeEventSecondary);
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent);
    window.removeEventListener('resize', this.handleResizeEventSecondary);
  },
  methods: {
    handleResizeEvent: _debounce(function () {
      this.initDrawing();
    }, ChartConst.resizeDebounce),
    handleResizeEventSecondary() {
      showAeroplane();
      routeProfileService.resetTravelAloftMap();
    },
    initDrawing() {
      RouteProfileChartService.chartApi.refresh(
        this.travelRoute,
        this.fullData,
        this.softDraw,
        this.settings,
      );
      hideAeroplane();
    },
    refresh(travelRoute, fullData, softDraw) {
      this.travelRoute = travelRoute;
      this.fullData = fullData;
      this.softDraw = softDraw;
      this.initDrawing();
    },
  },
};
</script>
