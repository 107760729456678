<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <ion-page>
    <SentinelElement />
    <ion-header>
      <ion-toolbar color="dark">
        <ion-buttons slot="start">
          <menu-button />
          <ion-button
            id="btn-fav-weather"
            fill="solid"
            color="secondary"
            @click="markItemAsFav(settings)"
          >
            <ion-icon
              v-show="isMarkedFavorite"
              class="markedfavorite"
              :icon="star"
            />
            <ion-icon
              v-show="!isMarkedFavorite"
              :icon="star"
            />
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button
            fill="solid"
            color="secondary"
            class="app-tour-button"
            @click="showtour()"
          >
            <ion-icon :icon="helpCircle" />
          </ion-button>
        </ion-buttons>
        <ion-title id="viewtitle">
          <ion-button
            id="btn-weather-selector"
            class="custom-button"
            fill="solid"
            color="secondary"
            @click="openAirportSelectModal"
          >
            {{ settings.start }}
            <ion-icon
              slot="end"
              mode="ios"
              :icon="chevronDown"
            />
          </ion-button>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content
      v-if="isActiveRoute"
      class="has-header"
    >
      <AppLoader />
      <div
        v-show="showNoData"
        class="no-data-weather"
      >
        {{ noDataFoundAirportMessage }}
      </div>
      <div
        v-for="(weatherItem, $index) in weatherData.weeklyData"
        :key="$index"
        class="group"
        :class="{
          expanded:
            isIphone() && weatherItem.showSecondary && weatherItem.day.hasSlider
        }"
      >
        <div
          class="row weather-item-primary vertical-flex weather-item"
          :style="{ minHeight: getRowHeight() }"
          @click="expandItem(weatherItem, weatherItem.showSecondary)"
        >
          <div
            :id="'col-1-' + $index"
            class="col col-1"
          >
            <span class="weather-day">{{ weatherItem.day.displayDay }}</span>
            <span class="display-block weather-date">{{
              weatherItem.day.displayDate
            }}</span>
          </div>
          <div
            :id="'col-2-' + $index"
            class="col col-2"
          >
            <div>
              <h5 class="hi-lo">
                <span
                  id="temp-hi"
                ><span
                  v-text="
                    (weatherItem.day && weatherItem.day.temperature) || '--'
                  "
                />&deg;</span>
                <span
                  id="temp-lo"
                ><span
                  v-text="
                    (weatherItem.night && weatherItem.night.temperature) ||
                      '--'
                  "
                />&deg;</span>
              </h5>
              <span
                v-if="weatherItem.day.windIcon"
                class="display-block vertical-flex wind-icon"
              ><i
                 class="wi wi-wind"
                 :class="weatherItem.day.windIcon"
               />
                <span class="wind-value">
                  {{ weatherItem.day.windDisplay }}
                </span>
                <span class="wind-unit"> kts</span></span>
            </div>
          </div>
          <div
            :id="'col-3-' + $index"
            class="col col-3 textCenter"
          >
            <div class="weather-icon">
              <i
                class="wi"
                :class="weatherItem.day.weatherType.icon"
              />
            </div>
            <span
              id="precip-val"
              :style="weatherItem.day.weatherType.customStyle"
            ><span
              v-if="weatherItem.day.pop != '0'"
              v-text="weatherItem.day.pop"
            /><span v-if="weatherItem.day.pop != '0'">%</span></span>
          </div>
          <div
            :id="'col-4-' + $index"
            class="col col-4"
            :class="{
              'col-4-full': isIphone() && weatherItem.day.hasSlider
            }"
          >
            {{ weatherItem.day.weather }}
          </div>
          <span
            v-if="isIphone() && weatherItem.day.hasSlider"
            class="down-accord"
          >
            <font-awesome-icon
              v-if="!weatherItem.showSecondary"
              slot="start"
              icon="angle-down"
            />
            <font-awesome-icon
              v-if="weatherItem.showSecondary"
              slot="start"
              icon="angle-up"
            />
          </span>
          <div
            v-if="!isIphone() && weatherItem.day.hasSlider"
            :id="'col-5-' + $index"
            class="col col-4 weather-text"
          >
            <div v-if="weatherItem.day && weatherItem.day.text">
              <span>{{ weatherItem.day.displayKey + ":" }}</span>
              {{ weatherItem.day.text }}
            </div>
            <div v-if="weatherItem.night && weatherItem.night.text">
              <span>{{ weatherItem.night.displayKey + ":" }}</span>
              {{ weatherItem.night.text }}
            </div>
          </div>
        </div>
        <div
          v-if="
            (!isIphone() && weatherItem.day.hasSlider) ||
              !weatherItem.day.hasSlider
          "
          class="sunrisesunset"
        >
          <font-awesome-icon
            slot="start"
            icon="sun"
            class="sunicon sunrise"
          /><span> {{ weatherItem.day.sunrise }}</span>
          <font-awesome-icon
            slot="start"
            icon="moon"
            class="sunicon sunset"
          /><span>{{ weatherItem.day.sunset }}</span>
        </div>
        <div
          v-if="
            isIphone() && weatherItem.showSecondary && weatherItem.day.hasSlider
          "
          :id="'col-6-' + $index"
          class="row weather-item weather-text weather-item-secondary"
        >
          <div class="sunrisesunset">
            <font-awesome-icon
              slot="start"
              icon="sun"
              class="sunicon sunrise"
            /><span> {{ weatherItem.day.sunrise }}</span>
            <font-awesome-icon
              slot="start"
              icon="moon"
              class="sunicon sunset"
            /><span>{{ weatherItem.day.sunset }}</span>
          </div>
          <div v-if="weatherItem.day.text">
            <span>{{ weatherItem.day.displayKey + ":" }}</span>
            {{ weatherItem.day.text }}
          </div>
          <div v-if="weatherItem.night && weatherItem.night.text">
            <span>{{ weatherItem.night.displayKey + ":" }}</span>
            {{ weatherItem.night.text }}
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar color="dark">
        <UTCClock />
        <!-- <layer-update-time-trigger-button :layer-for="getViewKeyName" /> -->
      </ion-toolbar>
    </ion-footer>
    <v-tour
      v-if="isActiveRoute"
      name="viewTour"
      :steps="toursteps"
      :callbacks="tourCallbacks"
    />
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
  IonFooter,
} from '@ionic/vue';
import { helpCircle, chevronDown, star } from 'ionicons/icons';
import _forEach from 'lodash/forEach';
import _debounce from 'lodash/debounce';
import ChartConst from '../../constants/chart.constant';
import AppConst from '../../constants/app.constant';
import EventConst from '../../constants/event.constant';
import WeatherConst from '../../constants/weather.constant';
import { getSettingFromLocalstorage } from '../../services/localstorage/getSettingFromLocalstorage';
import { is_iphone } from '../../services/utils/is_iphone';
import utilityService from '../../services/utility.service';
import AirportSelector from '../../components/AirportSelector.vue';
import Emitter from '../../bus';
import HelpBoxConfig from '../../components/HelpBox/HelpBox.config';
import dashboardDataSource from '../Airport/Airport.data';
import ConfigApplicationSettings from '../../config/application.config';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import { getWeatherData } from './services/getWeatherData';
import { getIconSize } from './utils/getIconSize';
// eslint-disable-next-line import/no-cycle
import FavoritesService from '../../components/Favorites/Favorites.service';
// eslint-disable-next-line import/no-cycle
import reportService from '../../services/report.service';
import { routerPushInterface } from '../../services/router.interface';
import UTCClock from '../../components/UTCClock.vue';
import { ViewKeysMap } from '../../constants/layerUpdateTime.constant';
import { setCurrentScreenRenderTime } from '../../services/layerUpdateTime/layerUpdateTime.service';

export default {
  name: 'Weather',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonFooter,
    UTCClock,
  },
  data() {
    return {
      helpCircle,
      chevronDown,
      star,
      settings: {
        start: 'KBTP',
      },
      lockModal: false,
      showNoData: false,
      refreshData: {},
      weatherData: {},
      noDataFoundAirportMessage: '',
      isMarkedFavorite: false,
      toursteps: HelpBoxConfig['app.weather'].steps,
    };
  },
  computed: {
    isActiveRoute() {
      return this.$router.currentRoute.value.name === 'app.weather';
    },
    getViewKeyName() {
      return ViewKeysMap.WEATHER;
    },
  },
  methods: {
    refreshView() {
      this.renderWeeklyReport();
    },
    handleResizeEvent: _debounce(function () {
      this.refreshView();
    }, ChartConst.resizeDebounce),
    ionViewDidEnter() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
      this.refreshView();
      this.initializeView();
      Emitter.on(
        EventConst.refreshViewRootscope + AppConst.stateMap.weather.key,
        this.initializeView,
      );
      reportService.bindReportMethod(AppConst.dataMode.weekly);
      utilityService.changeTourElementPosition();
      Emitter.on(
        EventConst.favorite.listRefreshed,
        this.isMarkedFavoriteCheckForStar,
      );
    },
    ionViewWillLeave() {
      utilityService.stopTour();
      window.removeEventListener('resize', this.handleResizeEvent);
      Emitter.off(
        EventConst.refreshViewRootscope + AppConst.stateMap.weather.key,
      );
      Emitter.off(
        EventConst.favorite.listRefreshed,
        this.isMarkedFavoriteCheckForStar,
      );
      reportService.unbindReportMethod();
    },
    expandItem(weatherItem, currentState) {
      _forEach(this.weatherData.weeklyData, (item) => {
        item.showSecondary = false;
      });
      weatherItem.showSecondary = !currentState;
    },
    day7SyncWithAirport() {
      return ConfigApplicationSettings.settings.synch7DayAirport;
    },
    updateAirportDetails() {
      this.settings.airportDetails = utilityService.getMatchedAirportObject(
        this.settings.start,
      );
    },
    loadViewSettings() {
      if (ConfigApplicationSettings.settings) {
        const myPreviousSettings = getSettingFromLocalstorage(
          AppConst.localstorageConf.weatherSettingKey,
        );
        let sharedStartAirport = null;
        if (ConfigApplicationSettings.settings.synch7DayAirport) {
          sharedStartAirport = dashboardDataSource.sharedStartAirport;
        }
        this.settings = {
          start:
            sharedStartAirport
            || this.$route.query.start
            || (myPreviousSettings
              ? myPreviousSettings.start
              : ChartConst.defaultStart),
          timeZone: ConfigApplicationSettings.settings.timeZone,
          temperatureUnit: ConfigApplicationSettings.settings.temperatureUnit,
        };
      }
    },
    loadViewVairables() {
      const queryParam = {
        start: this.$route.query.start,
      };
      if (this.$route.query.start) {
        this.settings.start = this.$route.query.start;
      } else if (!this.$route.query.start) {
        queryParam.start = this.settings.start;
      }
      routerPushInterface({ query: queryParam });
    },
    renderWeeklyReport() {
      if (this.refreshData) {
        this.weatherData = utilityService.clone(this.refreshData);
        this.getRowHeight();
      }
    },
    markItemAsFav(item) {
      FavoritesService.markItemAsFav(item);
    },
    init() {
      showAeroplane();
      this.updateAirportDetails();
      this.isMarkedFavorite = FavoritesService.isMarkedFavorite(this.settings);
      getWeatherData(this.settings)
        .then((weatherData) => {
          if (weatherData) {
            if (!weatherData.serviceUnavailable) {
              this.showNoData = false;
              this.refreshData = utilityService.clone(weatherData);
              this.renderWeeklyReport();
            } else {
              if (weatherData.serviceUnavailable) {
                this.noDataFoundAirportMessage = ChartConst.serviceNotAvailableMessage;
              } else {
                this.noDataFoundAirportMessage = ChartConst.noDataFoundAirportMessage;
              }
              this.weatherData = {};
              this.showNoData = true;
            }
          } else {
            this.noDataFoundAirportMessage = ChartConst.noDataFoundAirportMessage;
            this.weatherData = {};
            this.showNoData = true;
          }
          setCurrentScreenRenderTime();
          hideAeroplane();
        });
    },
    getRowHeight() {
      if (
        this.weatherData
        && this.weatherData.weeklyData
        && this.weatherData.weeklyData.length
      ) {
        return `${Math.max(
          getIconSize(this.weatherData.weeklyData.length),
          WeatherConst.rowMinHeight,
        )}px`;
      }
      return '';
    },
    isIphone() {
      return is_iphone();
    },
    syncRouteData() {
      const queryParam = {
        start: this.settings.start.toUpperCase(),
      };
      routerPushInterface({ query: queryParam });
      utilityService.setSettingInLocalstorage(
        this.settings,
        AppConst.localstorageConf.weatherSettingKey,
      );
      if (this.day7SyncWithAirport()) {
        dashboardDataSource.sharedStartAirport = this.settings.start;
      }
    },
    openAirportSelectModal() {
      if (this.lockModal) {
        return;
      }
      this.lockModal = true;
      modalController
        .create({
          component: AirportSelector,
          backdropDismiss: false,
          componentProps: {
            selectorInput: this.settings.start,
          },
        })
        .then((modalElement) => {
          modalElement.present();
          modalElement.onDidDismiss().then((res) => {
            this.lockModal = false;
            const formData = res.data;
            if (formData) {
              this.settings.start = formData.icao_id.toUpperCase();
              this.init();
              this.syncRouteData();
            }
          });
        });
    },
    initializeView() {
      this.loadViewSettings();
      this.loadViewVairables();
      this.init();
      this.syncRouteData();
    },
    showtour() {
      if (!this.showNoData) {
        this.$tours.viewTour.start();
      }
    },
    isMarkedFavoriteCheckForStar() {
      FavoritesService.isMarkedFavoriteCheckForStar(this, this.settings);
    },
  },
};
</script>
