import axios from 'axios';
import _debounce from 'lodash/debounce';
import { getAppInfo } from './capacitor/getApp.info';
import ApiConst from '../constants/api.constant';

const expTime = 30000; // 30-seconds
const logsGroupHistory = {};

const isMessageGroupExpired = (dateToCheck) => {
  const current = new Date().getTime();
  if (current > dateToCheck) {
    return true;
  }
  return false;
};

const getMessageKey = (dataToSend) => {
  if (typeof dataToSend === 'string') {
    return dataToSend;
  }
  if (dataToSend instanceof Error) {
    return dataToSend.message;
  }
  if (typeof dataToSend === 'object') {
    if (dataToSend.sentryLogData?.message) {
      return dataToSend.sentryLogData?.message;
    }
  }
  return JSON.stringify(dataToSend);
};

const sendSlackNotification = _debounce(async (dataToSend) => {
  const appInfo = await getAppInfo();
  const messageKey = getMessageKey(dataToSend);
  if (logsGroupHistory[messageKey] && !isMessageGroupExpired(logsGroupHistory[messageKey])) {
    return;
  }
  logsGroupHistory[messageKey] = new Date().getTime() + expTime;
  const data = {
    blocks: [
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `:apple: Apple IAP WeatherSpork - ${appInfo.appBuild} - ${appInfo.appVersion} :apple: `,
          },
        ],
      },
      {
        type: 'section',
        block_id: 'section567',
        text: {
          type: 'mrkdwn',
          text: `\`\`\`json\n${JSON.stringify(dataToSend, null, 2)}\n\`\`\``,
        },
      },
    ],
  };
  if (dataToSend instanceof Error) {
    data.blocks.push({
      type: 'section',
      block_id: 'section567',
      text: {
        type: 'mrkdwn',
        text: `*Exception Message:* \n\`\`\`${dataToSend.message}\`\`\``,
      },
    });
    data.blocks.push({
      type: 'section',
      block_id: 'section567',
      text: {
        type: 'mrkdwn',
        text: `*Exception Stack:* \n\`\`\`${dataToSend.stack}\`\`\``,
      },
    });
  }

  axios
    .post(
      `https://hooks.slack.com/services/${ApiConst.sl.part1}/${ApiConst.sl.part2}/${ApiConst.sl.part3}`,
      JSON.stringify(data),
      // Bypass CORS here
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .then((res) => {
      console.log(`Status: ${res.status}`);
      console.log('Student Info: ', res.data);
    })
    .catch((err) => {
      console.error(err);
    });
}, 300);

// curl -X POST -H 'Content-type: application/json' --data '{"text":"Hello, World!"}' https://hooks.slack.com/services/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
export { sendSlackNotification };
