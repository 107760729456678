import { getFreeTrialExpiresOn } from './getFreeTrialExpiresOn';
import {
  isDateExpired,
} from '../utils/isDateExpired';

const checkIsFreeTrialExpired = (user) => {
  if (user) {
    const freeTrialExpOn = getFreeTrialExpiresOn(user);
    return isDateExpired(freeTrialExpOn);
  }
  return true;
};

export { checkIsFreeTrialExpired };
