/**
 *
 * @param {String} t2 Date in form YYYY-MM-DD
 * @returns
 */
const getIsUserT2Expired = (t2) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let userExpired = false;
  const expiryDateObj = new Date(t2);
  expiryDateObj.setHours(24, 0, 0, 0);
  if (currentDate > expiryDateObj) {
    userExpired = true;
  }
  return userExpired;
};

export { getIsUserT2Expired };
