<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <ion-button
    fill="solid"
    color="secondary"
    class="custom-button"
  >
    {{ settings.start }}
    <div
      v-if="!isIphone"
      class="align-center-items"
    >
      <font-awesome-icon
        v-if="settings.mid1 || settings.mid2 || settings.end"
        icon="arrow-right"
        class="arrow-icon"
      />
      <span v-if="settings.mid1">{{ settings.mid1 }}</span>
      <font-awesome-icon
        v-if="settings.mid1"
        icon="arrow-right"
        class="arrow-icon"
      />
      <span v-if="settings.mid2">{{ settings.mid2 }}</span>
      <font-awesome-icon
        v-if="settings.mid2"
        icon="arrow-right"
        class="arrow-icon"
      />
    </div>
    <font-awesome-icon
      v-if="isIphone && (!settings.mid1 && !settings.mid2)"
      icon="arrow-right"
      class="arrow-icon"
    />
    <font-awesome-icon
      v-if="isIphone && (settings.mid1 || settings.mid2)"
      icon="ellipsis-h"
      class="moredots"
    />
    {{ settings.end }}
    <ion-icon
      slot="end"
      mode="ios"
      :icon="chevronDownOutline"
    />
  </ion-button>
</template>
<script>
import { IonButton, IonIcon } from '@ionic/vue';
import { chevronDownOutline } from 'ionicons/icons';
import utilityService from '../services/utility.service';

export default {
  name: 'RouteSelectorButton',
  components: {
    IonButton,
    IonIcon,
  },
  props: {
    settings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      chevronDownOutline,
      isIphone: utilityService.useShortAirportSelector(),
    };
  },
  mounted() {
    this.isIphone = utilityService.useShortAirportSelector();
    window.addEventListener('resize', this.handleResizeEvent);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResizeEvent);
  },
  methods: {
    handleResizeEvent() {
      this.isIphone = utilityService.useShortAirportSelector();
    },
  },
};
</script>
<style scoped>
.arrow-icon,
.moredots {
  font-size: 14px;
  margin: 0 4px;
}
.align-center-items{
  align-items: center;
  display: flex;
}
</style>
