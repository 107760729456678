import {
  store,
} from '../store/store';
import {
  STORE_NAMESPACE,
} from '../store/modules/store.namespace';
import {
  USER_ACTIONS_ENUM,
} from '../store/modules/user/user.enum';
import {
  SUBSCRIPTION_ACTIONS_ENUM,
} from '../store/modules/subscription/subscription.enum';

const clearStoreOnLogout = (status) => {
  store.dispatch(
    `${STORE_NAMESPACE.USER}/${USER_ACTIONS_ENUM.CLEAR_USER_STORE_ON_LOGOUT}`,
    status,
  );
  store.dispatch(
    `${STORE_NAMESPACE.SUBSCRIPTION}/${SUBSCRIPTION_ACTIONS_ENUM.CLEAR_SUBSCRIPTION_ON_LOGOUT}`,
    status,
  );
};
export {
  clearStoreOnLogout,
};
