const STORE_NAMESPACE = {
  AEROPLANE_LOADER: 'aeroplaneLoader',
  MESSAGE_LOADER: 'messageLoader',
  PERSONAL_MINIMUMS: 'personalMinimums',
  SUBSCRIPTION: 'subscription',
  MENU: 'menu',
  USER: 'user',
  MRMS: 'mrms',
  LAYER_UPDATE_TIME: 'layerUpdateTime',
  CONNECTIVITY: 'connectivity',
};

Object.freeze(STORE_NAMESPACE);

export { STORE_NAMESPACE };
