import _forEach from 'lodash/forEach';
import AccountConst from '../../../constants/account.constant';
import EventConst from '../../../constants/event.constant';
import Emitter from '../../../bus';

const fillDatasetMap = (dataset) => new Promise((resolve) => {
  if (dataset) {
    dataset.getAllRecords((err, value) => {
      if (!err) {
        _forEach(value, (item) => {
          if (item) {
            if (AccountConst.allowedCognitoSyncKeys.indexOf(item.key) > -1) {
              localStorage.setItem(item.key, item.value);
            }
          }
        });
        Emitter.emit(EventConst.datasetSynced);
        resolve(true);
      } else {
        resolve(false);
      }
    });
  } else {
    resolve(false);
  }
});

export { fillDatasetMap };
