import utilityService from '../utility.service';
import {
  getIsFsUser,
} from '../user/getIsFsUser';
import { getIsUserPaypalSubscriptionActive } from '../user/getIsUserPaypalSubscriptionActive';
// userPaypalSubscription || !getIsFsUser(user)

const showPaypalSubscriptionOptions = (user, userPaypalSubscription) => {
  let showPaypalSubscription = true;
  if (
    utilityService.isCordovaApplication()
    && utilityService.isIOS()
    && !getIsFsUser(user)
    && !userPaypalSubscription
  ) {
    showPaypalSubscription = false;
  }
  if (userPaypalSubscription && getIsUserPaypalSubscriptionActive(userPaypalSubscription)) {
    // If user is already subscribed to active paypal then we dont show this section
    showPaypalSubscription = false;
  }
  return showPaypalSubscription;
};

export {
  showPaypalSubscriptionOptions,
};
