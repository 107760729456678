import DefConst from '../../constants/def.constant';
import utilityService from '../utility.service';

const isDayOrNightAtThisTime = function (item, objectKey) {
  let itIs;
  const prevDay = new Date(new Date(item[objectKey]).setDate(item[objectKey].getDate() - 1));
  const prevDayObject = {
    sunMoonPositionData: utilityService.getSunMoonCalculation(prevDay, item.lat, item.lng),
  };
  const nextDay = new Date(new Date(item[objectKey]).setDate(item[objectKey].getDate() + 1));
  const nextDayObject = {
    sunMoonPositionData: utilityService.getSunMoonCalculation(nextDay, item.lat, item.lng),
  };
  if (item[objectKey].getTime() >= prevDayObject.sunMoonPositionData.todaysSunlightTime
    .sunrise.getTime() && item[objectKey].getTime() <= prevDayObject.sunMoonPositionData
    .todaysSunlightTime.sunset.getTime()) {
    itIs = DefConst.timeOfDay.day;
  } else if (item[objectKey].getTime() >= prevDayObject.sunMoonPositionData.todaysSunlightTime
    .sunset.getTime() && item[objectKey].getTime() <= item.sunMoonPositionData
    .todaysSunlightTime.sunrise.getTime()) {
    itIs = DefConst.timeOfDay.night;
  } else if (item[objectKey].getTime() >= item.sunMoonPositionData.todaysSunlightTime.sunrise
    .getTime() && item[objectKey].getTime() <= item.sunMoonPositionData.todaysSunlightTime
    .sunset.getTime()) {
    itIs = DefConst.timeOfDay.day;
  } else if (item[objectKey].getTime() >= item.sunMoonPositionData.todaysSunlightTime.sunset
    .getTime() && item[objectKey].getTime() <= nextDayObject.sunMoonPositionData
    .todaysSunlightTime.sunrise.getTime()) {
    itIs = DefConst.timeOfDay.night;
  }
  return itIs;
};

export { isDayOrNightAtThisTime };
