<!--
  All rights reserved © 2012, 2020 BuserNet Consulting LLC
-->
<template>
  <IonPage>
    <SentinelElement />
    <ion-content
      id="contents"
      class="account login-content"
    >
      <!-- <div v-show="visibleViewIndex === 0" class="welcome-container">
      <div class="account-primary-form">
        <img src="static/img/horizontal-logo.svg" class="side-nav-logo">
        <div class="clear-login-div"></div>
        <div>Welcome to WeatherSpork</div>
        <div class="clear-login-div"></div>
        <div>The all-purpose strategic weather planner for aviators,
          //a complement to the real-time weather product
          //you normally use in the cockpit.</div>
        <div class="clear-login-div"></div>
        <div class="clear-login-div"></div>
        <div class="clear-login-div"></div>
        <div class="clear-login-div"></div>
        <ion-button shape="round" v-on:click="changeView('register')" color="light" expand="full">
            Need an account? Register here.
        </ion-button>
        <ion-button shape="round" v-on:click="changeView('login')" color="light" expand="full">
            Already have an account? Login here.
        </ion-button>
      </div>
    </div> -->
      <ion-card class="welcome-account account-theme accountmodal-spacing">
        <ion-card-content>
          <img
            src="static/img/horizontal-logo.svg"
            class="side-nav-logo"
          >
          <div class="account-primary-form">
            <form
              id="loginregisterformprimary"
              name="loginregisterformprimary"
              novalidate
            >
              <div v-if="!isIphone5 && visibleViewIndex === 1">
                <h2>You'll use this email and password to login to</h2>
                <h2>WeatherSpork on your other devices.</h2>
                <h2>Register for 14 day free trial.</h2>
              </div>
              <div v-if="isIphone5 && visibleViewIndex === 1">
                <h2>You'll use this email and password to</h2>
                <h2>login to WeatherSpork on your other devices.</h2>
                <h2>Register for 14 day free trial.</h2>
              </div>
              <br>
              <p
                v-show="
                  validation.message &&
                    validation.message !== 'ContactSupport' &&
                    validation.message !== 'UserAlreadyRegistered' &&
                    validation.message !== 'Support' &&
                    validation.message !== 'Subexpire' &&
                    validation.message !== 'Trialexpire' &&
                    validation.message.indexOf('Trialexpire:IAP') < 0
                "
                class="validationmessage"
              >
                {{ validation.message }}
              </p>
              <p
                v-show="validation.message === 'UserAlreadyRegistered'"
                class="validationmessage"
              >
                User already registered. Please login.
                <br>Please contact
                <a
                  href
                  @click="sendSupportEmail"
                >support@weatherspork.com</a>
                if you need any assistance..
              </p>
              <p
                v-show="validation.message === 'ContactSupport'"
                class="validationmessage"
              >
                There's a problem with your account. Please contact
                <a
                  href
                  @click="sendSupportEmail"
                >support@weatherspork.com</a>
                for assistance.
              </p>
              <p
                v-show="validation.message === 'Support'"
                class="validationmessage"
              >
                Please enter the verification code <br>
                we sent to your email address.
              </p>
              <p
                v-show="validation.message === 'Subexpire'"
                class="validationmessage"
              >
                Your subscription has expired. Please contact
                <a
                  @click="sendSupportEmail"
                >support@weatherspork.com</a>
                for assistance.
              </p>
              <!-- <p v-show="validation.message === 'Trialexpire'" class="validationmessage">
                Your trial membership has expired. Please contact <a href
                 v-on:click="sendSupportEmail()">support@weatherspork.com</a>
              for assistance.</p> -->
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>First Name</ion-label>
                <ion-input
                  v-model.trim="accountDetail.firstname"
                  name="firstname"
                  placeholder="Enter Your First Name"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>Last Name</ion-label>
                <ion-input
                  v-model.trim="accountDetail.lastname"
                  name="lastname"
                  placeholder="Enter Your Last Name"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item>
                <ion-label>Email</ion-label>
                <ion-input
                  id="emailTextField"
                  v-model.trim="accountDetail.useremail"
                  autocomplete="on"
                  type="email"
                  name="username"
                  placeholder="Enter Your Email"
                  @keyup.enter="accountFormSubmit"
                  @ionInput="
                    accountDetail.useremail = $event.target.value
                      .toLowerCase()
                      .trim()
                  "
                />
              </ion-item>
              <ion-item>
                <ion-label>Password</ion-label>
                <ion-input
                  id="passwordTextField"
                  v-model="accountDetail.userpassword"
                  autocomplete="on"
                  name="password"
                  type="password"
                  placeholder="Enter Your Password"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-item v-if="visibleViewIndex === 1">
                <ion-label>Confirm</ion-label>
                <ion-input
                  v-model="accountDetail.confirmpassword"
                  name="confirmpassword"
                  type="password"
                  placeholder="Confirm Your Password"
                  @keyup.enter="accountFormSubmit"
                />
              </ion-item>
              <ion-button v-show="false">
                Dummy button
              </ion-button>
              <ion-button
                v-if="visibleViewIndex == 1"
                shape="round"
                color="warning"
                expand="full"
                @click="registerNow()"
              >
                Create Account
              </ion-button>
              <!-- v-bind:disabled="errors.items.length || accountDetail.userpassword.length == 0
                || accountDetail.useremail.length == 0"  -->
              <ion-button
                v-if="visibleViewIndex == 2"
                id="loginbutton"
                shape="round"
                color="warning"
                expand="full"
                @click="loginNow()"
              >
                Login
              </ion-button>
              <div class="clear-login-div" />
              <ion-button
                v-if="visibleViewIndex == 2"
                shape="round"
                color="light"
                expand="full"
                @click="openForgotPasswordModal()"
              >
                Forgot Password
              </ion-button>
              <ion-button
                shape="round"
                color="light"
                class="button-account-secondary"
                expand="full"
                @click="sendSupportEmail"
              >
                Contact Support
              </ion-button>
              <div class="clear-login-div" />
              <div v-show="visibleViewIndex === 2">
                <ion-button
                  shape="round"
                  color="warning"
                  expand="full"
                  @click="changeView('register')"
                >
                  Need an account? Register here.
                </ion-button>
              </div>
              <div v-show="visibleViewIndex === 1">
                <ion-button
                  shape="round"
                  color="light"
                  expand="full"
                  @click="changeView('login')"
                >
                  Already have an account? Login here.
                </ion-button>
              </div>

              <div class="clear-login-div" />
              <div class="clear-login-div" />
            </form>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
    <AppVersion />
  </IonPage>
</template>
<script>
import {
  IonContent,
  IonPage,
  IonButton,
  IonCard,
  IonCardContent,
  IonItem,
  IonInput,
  IonLabel,
} from '@ionic/vue';
import AppConst from '../constants/app.constant';
import AccountConst from '../constants/account.constant';
import utilityService from '../services/utility.service';
import {
  hideIonicLoading,
  showIonicLoading,
} from '../services/loading.service';
import ConfigDeviceSettings from '../config/device.config';
import menuService from '../services/menu.service';
import { routerPushInterface } from '../services/router.interface';
import AppVersion from '../components/AppVersion.vue';
import { checkIfPasswordInvalid } from '../services/aws/utils/checkIfPasswordInvalid';
import { forgotPasswordFormModal } from '../modal/forgotPasswordForm.modal';
import { doSignoutTask } from '../services/user/doSignoutTask';
import { loadUserFromCognito } from '../services/user/loadUserFromCognito';
import { navigateToLastStateOrDefault } from '../services/navigateToLastStateOrDefault';
import { loginUser } from '../services/aws/login/loginUser';
import { registerUser } from '../services/aws/register/registerUser';
import { sendSlackNotification } from '../services/sendSlackNotifications';

const LoginContentHandle = {
  registerLocked: false,
  loginLocked: false,
};
export default {
  name: 'LoginForm',
  components: {
    IonContent,
    IonCard,
    IonCardContent,
    IonPage,
    IonButton,
    IonItem,
    IonInput,
    IonLabel,
    AppVersion,
  },
  data() {
    return {
      accountDetail: {
        firstname: '',
        lastname: '',
        useremail: '',
        userpassword: '',
        confirmpassword: '',
        verificationCode: '',
      },
      changePasswordForm: {
        oldPassword: '',
        newPassword: '',
      },
      visibleViewIndex: 2,
      isIphone5: false,
      validation: {},
    };
  },
  methods: {
    ionViewDidEnter() {
      const vm = this;
      if (!ConfigDeviceSettings.settings.enableLoginFlow) {
        // if login is not enabled but trying to open login screen
        routerPushInterface({ name: AppConst.defaultHomeViewState });
      }
      vm.disableEnableMenuSwipe(false);
      routerPushInterface({ query: {} });
      vm.visibleViewIndex = 2;
      vm.prePopulateLoginModel();
      setTimeout(() => {
        vm.prePopulateLoginModel();
      }, 2000);
    },
    ionViewWillLeave() {
      // show split pane for other screen
      this.disableEnableMenuSwipe(true);
    },
    /**
     * This method is useful if user ar pre-filled rememberd userdetail in browser
     * Then model was not getting updated
     */
    prePopulateLoginModel() {
      const vm = this;
      const emailTextField = document.querySelector('#emailTextField input');
      const passwordTextField = document.querySelector('#passwordTextField input');
      if (!vm.accountDetail.useremail && emailTextField && emailTextField.value) {
        vm.accountDetail.useremail = emailTextField.value;
      }
      if (!vm.accountDetail.userpassword && passwordTextField && passwordTextField.value) {
        vm.accountDetail.userpassword = passwordTextField.value;
      }
    },
    resetChangePasswordForm() {
      this.changePasswordForm = {
        oldPassword: '',
        newPassword: '',
      };
    },
    resetAccountForm() {
      this.accountDetail = {
        firstname: '',
        lastname: '',
        useremail: '',
        userpassword: '',
        confirmpassword: '',
        verificationCode: '',
      };
    },
    cognitoLogin(email, password) {
      const vm = this;
      const emailForLogin = email ? email.toLowerCase() : '';
      loginUser(emailForLogin, password).then((data) => {
        if (!data.message) {
          loadUserFromCognito().then((res) => {
            hideIonicLoading();
            if (res.success) {
              navigateToLastStateOrDefault();
            }
          });
        } else {
          hideIonicLoading();
          vm.validation = {
            message: AppConst.loginFailedMessage,
          };
        }
      });
    },
    registerNow() {
      const vm = this;
      if (this.checkRegisterFormValid()) {
        if (!LoginContentHandle.registerLocked) {
          LoginContentHandle.registerLocked = true;
          if (vm.accountDetail && vm.accountDetail.useremail && vm.accountDetail.userpassword) {
            showIonicLoading(AccountConst.processingText.signup);
            vm.validation = {};
            vm.accountDetail.useremail = vm.accountDetail.useremail.toLowerCase();
            registerUser(
              vm.accountDetail.useremail,
              vm.accountDetail.useremail,
              vm.accountDetail.userpassword,
              vm.accountDetail.registrationcode,
              // false -$$$
              vm.accountDetail.firstname,
              vm.accountDetail.lastname,
            )
              .then((data) => {
                if (!data.message) {
                  sendSlackNotification({
                    firstName: vm.accountDetail.firstname,
                    lastName: vm.accountDetail.lastname,
                    email: vm.accountDetail.useremail,
                    message: 'New user registered successfully',
                  });
                  vm.cognitoLogin(
                    vm.accountDetail.useremail,
                    vm.accountDetail.userpassword,
                  );
                  // vm.accountDetail.lastname, vm.accountDetail.useremail);
                } else if (
                  data.code.match(
                    new RegExp(AppConst.exceptionsCodes.UsernameExistsException),
                  )
                ) {
                  hideIonicLoading();
                  vm.validation = {
                    message: AppConst.userAlreadyRegistered,
                  };
                } else if (checkIfPasswordInvalid(data)) {
                  hideIonicLoading();
                  vm.validation = {
                    message: AppConst.passwordConstraintNotMet,
                  };
                } else {
                  hideIonicLoading();
                  vm.validation = {
                    message: AppConst.contactSupport,
                  };
                }
              });
          }
        }
        setTimeout(() => {
        // unlock login button after 2 seconds
        // Till then anyways overlay will be opened
          LoginContentHandle.registerLocked = false;
        }, 2000);
      }
    },
    openForgotPasswordModal() {
      forgotPasswordFormModal(this.accountDetail.useremail, this);
    },
    loginNow() {
      const vm = this;
      if (vm.checkLoginFormValid()) {
        if (!LoginContentHandle.loginLocked) {
        // lock login button on click... so that user do not click it twice
          LoginContentHandle.loginLocked = true;
          setTimeout(() => {
          // Timeout to make sure the model is updated
            if (
              vm.accountDetail
            && vm.accountDetail.useremail
            && vm.accountDetail.userpassword
            ) {
            // Before login task clean old user info if any
              doSignoutTask(true);
              showIonicLoading(AccountConst.processingText.login);
              vm.validation = {};
              vm.cognitoLogin(
                vm.accountDetail.useremail,
                vm.accountDetail.userpassword,
              );
            }
          });
        }
        setTimeout(() => {
        // unlock login button after 2 seconds
        // Till then anyways overlay will be opened
          LoginContentHandle.loginLocked = false;
        }, 2000);
      }
    },
    sendSupportEmail(event) {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      window.open(`mailto:${AppConst.errorReporting.mailto}`, '_self');
    },
    clearValidationMessage() {
      this.validation = {};
    },
    changeView(view) {
      switch (view) {
        case 'register':
          this.visibleViewIndex = 1;
          break;
        case 'login':
          this.visibleViewIndex = 2;
          break;
        default:
      }
      this.resetAccountForm();
      this.clearValidationMessage();
    },
    checkLoginFormValid() {
      const vm = this;
      vm.prePopulateLoginModel();
      if (
        vm.accountDetail.useremail.length > 0
        && vm.accountDetail.userpassword.length > 0
        && utilityService.validateEmail(vm.accountDetail.useremail)
      ) {
        return true;
      }
      if (vm.accountDetail.useremail.length === 0
       || vm.accountDetail.userpassword.length === 0) {
        vm.validation = {
          message: AccountConst.emailAndPasswordRequired,
        };
      } else if (!utilityService.validateEmail(vm.accountDetail.useremail)) {
        vm.validation = {
          message: AccountConst.emailValidRequired,
        };
      }
      return false;
    },
    checkRegisterFormValid() {
      const vm = this;
      vm.validation = {};
      if (
        !vm.accountDetail.firstname.length
        || !vm.accountDetail.lastname.length
        || !vm.accountDetail.useremail.length
        || !vm.accountDetail.userpassword.length
        || !vm.accountDetail.confirmpassword.length
      ) {
        vm.validation = {
          message: AccountConst.allRequiredField,
        };
        return false;
      }
      if (
        !utilityService.validateEmail(vm.accountDetail.useremail)
      ) {
        vm.validation = {
          message: AccountConst.emailValidRequired,
        };
        return false;
      }
      if (vm.accountDetail.userpassword.length < 6) {
        vm.validation = {
          message: AccountConst.passwordMinLengthRequired,
        };
        return false;
      }
      if (vm.accountDetail.userpassword !== vm.accountDetail.confirmpassword) {
        vm.validation = {
          message: AccountConst.registerPasswordNotMatch,
        };
        return false;
      }
      return true;
    },
    accountFormSubmit() {
      const vm = this;
      if (vm.visibleViewIndex === 1 && vm.checkRegisterFormValid()) {
        vm.registerNow();
      } else if (vm.visibleViewIndex === 2 && vm.checkLoginFormValid()) {
        vm.loginNow();
      }
    },
    disableEnableMenuSwipe(status) {
      menuService.disableEnableMenuSwipe(status);
    },
  },
};
</script>
<style scoped>
ion-content {
  height: 100%;
}
.account-primary-form ion-item {
  background: #fff;
}
ion-button.button-account-primary {
  --background: #f28b18;
}
ion-button.button-account-secondary {
  --background: #f8f8f8;
  --color: #444;
}
ion-content {
  --background: #20232C;
}
/* .isDarkContent ion-content{
  --background: #1C1C1C
} */
ion-item ion-label {
  flex: 1 !important;
}
ion-item ion-input {
  caret-color: #fff;
  flex: 2;
  caret-color: #fff;
}
ion-item {
  --highlight-background: transparent;
}
ion-card-content {
  padding: 0;
}
.skip-auth {
  color: lightgray;
  cursor: pointer;
}
.welcome-container {
  display: flex;
  text-align: center;
  color: #fff;
}
.welcome-container .side-nav-logo {
  position: relative;
}
.welcome-container .account-primary-form {
  margin: auto;
  margin-top: 60px;
}
.header-md::after {
  background-image: none;
}
.header-ios ion-toolbar:last-child {
  --border-width: 0;
}
.required-field{
  color: white;
}
</style>
