const getWorseStorm = (stormA, stormB) => {
  const stormAHail = stormA.hail ? stormA.hail.probHail : 0;
  const stormBHail = stormB.hail ? stormB.hail.probHail : 0;
  const stormACellTop = stormA && stormA.si ? stormA.si.ct : 0;
  const stormBCellTop = stormB && stormB.si ? stormB.si.ct : 0;
  if (stormAHail === stormBHail) {
    if (stormACellTop > stormBCellTop) {
      return 1;
    }
    return 2;
  } if (stormAHail > stormBHail) {
    return 1;
  }
  return 2;
};

export { getWorseStorm };
