<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->

<template>
  <ion-header>
    <ion-toolbar color="dark">
      <IonTitle>
        Subscription Expires Soon
      </IonTitle>
      <ion-buttons slot="end">
        <ion-button
          fill="solid"
          color="secondary"
          @click="closeModal()"
        >
          Close
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>
<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
} from '@ionic/vue';

export default {
  name: 'SubscriptionExpireSoonHeader',
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
};
</script>
