import { getPaypalClientId } from './getPaypalClientId';

const loadPaypalSdk = () => new Promise((resolve) => {
  const PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?client-id=${getPaypalClientId()}&vault=true&intent=subscription`;
  const script = document.createElement('script');
  script.addEventListener('load', () => {
    resolve();
  });
  script.setAttribute('src', PAYPAL_SCRIPT);
  document.head.appendChild(script);
});

export { loadPaypalSdk };
