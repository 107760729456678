import AccountConst from '../../../constants/account.constant';
import utilityService from '../../utility.service';

/**
 *
 * @returns {UserPoolId, ClientId}
 */
export const getPoolConfig = () => ({
  UserPoolId: AccountConst.awsCognito.userPoolId,
  ClientId: utilityService.isCordovaApplication()
    ? AccountConst.awsCognito.clientIdMobile
    : AccountConst.awsCognito.clientIdWeb,
});
