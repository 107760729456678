import GlobeConst from '../../../../constants/globe.constant';
import { getCacheBuster } from '../../../../services/utils/getCacheBuster';
import { getRadarApiUrlPrefix } from './getRadarApiUrlPrefix';

const getMRMSImageConfig = (slideIndex) => {
  let interval = '';
  if (slideIndex === 0) {
    interval = '00';
  } else {
    interval = slideIndex * (60 / GlobeConst.radarSlidesCount);
  }
  return {
    key: `rala-${interval}`,
    src: `${getRadarApiUrlPrefix()}/low/rala_${interval}.png${getCacheBuster()}`,
  };
};

export { getMRMSImageConfig };
