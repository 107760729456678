import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _find from 'lodash/find';
import routeProfileService from '../../views/Routeprofile/Routeprofile.service';
import bnChartDataService from '../chartData.service';

const getMapMosCurrentSlice = (
  routeRefObj,
  mapMosData,
  sourceData,
  settings,
  lastDraggedPoint,
) => {
  const mosObjects = [];
  if (routeRefObj && !routeRefObj.flightMode) {
    const lampOrMavObj = routeProfileService.getLampOrMavObj(sourceData, settings);
    if (lampOrMavObj) {
      routeRefObj.flightMode = lampOrMavObj.flightMode;
    }
  }
  if (routeRefObj && routeRefObj.flightMode) {
    let flightMode = routeRefObj.flightMode.toLowerCase();
    flightMode = flightMode === 'na' ? 'mav' : flightMode;
    const data = mapMosData[flightMode];

    // consdering lamp or mav have same set of record (timestamp based) for each airport
    // lamp data is for every 1 hr for 1 day that means there will be 25 records
    const firstColletionWithData = _find(data, 'length');
    const dates = _map(firstColletionWithData, 'forecast_ts_local');
    const { index: matchedIdx } = bnChartDataService.findClosestDate(settings.departureTime, dates);
    _forEach(data, (airport) => {
      if (!airport) {
        // for null record
        return;
      }
      const tempObj = airport[matchedIdx];
      tempObj.isMosObject = true;
      if (lastDraggedPoint && lastDraggedPoint.arrivalTime) {
        tempObj.arrival_time = lastDraggedPoint.arrivalTime;
      } else {
        tempObj.arrival_time = routeRefObj.arrival_time;
      }
      tempObj.closestIndex = matchedIdx;
      tempObj.flightMode = flightMode;
      mosObjects.push(tempObj);
    });
  }
  return mosObjects;
};

export { getMapMosCurrentSlice };
