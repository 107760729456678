// import stormTrackConst from '../../../../../constants/stormTrack.const';
import d3 from '../../../../../globalexports/d3.export';
import ConfigDeviceSettings from '../../../../../config/device.config';
import { getWorseStorm } from './getWorseStorm';

const filterStormDupes = (storms) => {
  const distanceThreshold = ConfigDeviceSettings.settings.stormTrackDuplicateRange;
  for (let i = 0; i < storms.length; i += 1) {
    for (let j = i + 1; j < storms.length; j += 1) {
      const stormToCompare = storms[i];
      const stormToCompareWith = storms[j];
      const distanceBetweenStorm = d3.geoDistance(
        stormToCompare.fcst[0],
        stormToCompareWith.fcst[0],
      );
      const distanceInMeter = distanceBetweenStorm * 6371 * 1000;
      if (distanceInMeter < distanceThreshold) {
        const worseStorm = getWorseStorm(stormToCompare, stormToCompareWith);
        switch (worseStorm) {
          case 1:
            stormToCompareWith.ignore = true;
            //   // console.log(`
            //   // ${distanceInMeter} :
            //   // Storm :${stormToCompare.id} was
            //   // selected over ${stormToCompareWith.id}`);
            break;
          case 2:
            stormToCompare.ignore = true;
            //   // console.log(`${distanceInMeter} :
            //   // Storm :${stormToCompareWith.id} was
            //   // selected over ${stormToCompare.id}`);
            break;
          default:
        }
      }
    }
  }
  const filteredStorm = storms.filter((storm) => !storm.ignore);
  return filteredStorm;
};

export { filterStormDupes };
