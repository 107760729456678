import _debounce from 'lodash/debounce';
import AccountConst from '../../../constants/account.constant';
import { getCurrentUser } from './getCurrentUser';
import { initCognitoSync } from '../cognitoSync/initCognitoSync';

let refreshTokenTimer = null;

const refreshCognito = _debounce(() => {
  getCurrentUser().then((cognitoUser) => {
    if (cognitoUser !== null) {
      cognitoUser.getSession((err1, session) => {
        initCognitoSync(session.idToken.jwtToken);
      });
    }
  });
}, 1000);

const startRefreshTokenTimer = () => {
  if (!refreshTokenTimer) {
    refreshTokenTimer = setInterval(() => {
      refreshCognito();
    }, AccountConst.refreshTokenDuration);
  }
};

const stopRefreshTokenTimer = () => {
  if (refreshTokenTimer) {
    // clear refresh token timer on signout
    clearInterval(refreshTokenTimer);
    refreshTokenTimer = null;
  }
};

export {
  startRefreshTokenTimer,
  stopRefreshTokenTimer,
};
