<!--
All rights reserved © 2012, 2020 BuserNet Consulting LLC
 -->
<template>
  <IonPage>
    <SentinelElement />
    <RouteHeader
      id="btn-route-selector"
      :settings="settings"
      :is-marked-favorite="isMarkedFavorite"
      :show-layer-selector="true"
      @showTour="showtour"
      @openRouteSelectModal="openRouteSelectModal"
    />
    <ion-content
      v-if="isActiveRoute"
      class="hasfooter"
      force-overscroll="false"
    >
      <AppLoader />
      <div
        v-show="noDataFound"
        id="routeprofileNoDataMessage"
        class="no-data no-data-display"
      >
        <font-awesome-icon icon="exclamation-circle" />
        <span>{{ noDataFoundRouteMessage }}</span>
      </div>
      <div
        v-show="progressObj.progressPercent && progressObj.progressPercent < 100"
        id="progress-radial-route"
        class="progress-demo"
        hasfooter="true"
      />
      <route-profile-chart
        ref="chartApi"
        :settings="settings"
      />
      <etd-slider
        id="bn-etd-slider-routeprofile"
        ref="etdSliderApi"
        :no-pin="true"
        :class="{
          unpinned: hideBottomAxis,
          pinned: !hideBottomAxis
        }"
        :style="{
          opacity:
            departTime != 'ETD:  NaN  NaN:NaN' &&
            departTime != 'ETD:  NaN  NaNNaNZ' &&
            (showSlider || settings.sliderPinned)
              ? '1'
              : '0',
          'pointer-events': showSlider || settings.sliderPinned ? 'all' : 'none'
        }"
        @pinToggled="togglePin"
        @changing="sliderChanging"
      />
      <div
        v-if="enableAlternateSlider || hideBottomAxis"
        id="floating-etd-button"
        :style="floatingEtdStyle"
        class="floating-etd"
      >
        <FooterSliderLabelButton
          :label="departTime"
          :show-icon="false"
        />
      </div>
    </ion-content>
    <ion-footer v-show="!hideBottomAxis && !enableAlternateSlider">
      <ion-toolbar color="dark">
        <UTCClock />
        <ion-title>
          <FooterSliderLabelButton
            id="footer-slider-label-button-route"
            :label="departTime"
          />
        </ion-title>
        <layer-update-time-trigger-button
          :layer-for="getViewKeyName"
        />
      </ion-toolbar>
    </ion-footer>
    <v-tour
      name="viewTour"
      :steps="toursteps"
      :callbacks="tourCallbacks"
    />
  </IonPage>
</template>
<script>
import {
  IonContent,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {
  arrowForward,
  arrowBack,
} from 'ionicons/icons';
import _debounce from 'lodash/debounce';
import ChartConst from '../../constants/chart.constant';
import AppConst from '../../constants/app.constant';
import EventConst from '../../constants/event.constant';
import reportService from '../../services/report.service';
import routeControllerService from '../../services/routeController.service';
import searchHistoryService from '../../services/searchHistory.service';
import ConfigDeviceSettings from '../../config/device.config';
import etdService from '../../services/etd.service';
import routeProfileService from './Routeprofile.service';
import UTCClock from '../../components/UTCClock.vue';
import FooterSliderLabelButton from '../../components/FooterSliderLabelButton.vue';
import RouteProfileChart from '../../components/RouteProfileChart/RouteProfileChart.vue';
import EtdSlider from '../../components/EtdSlider.vue';
import FavoritesService from '../../components/Favorites/Favorites.service';
import RouteHeader from '../../components/RouteHeader.vue';
import utilityService from '../../services/utility.service';
import Emitter from '../../bus';
import { showAeroplane, hideAeroplane } from '../../services/aeroplane.service';
import HelpBoxConfig from '../../components/HelpBox/HelpBox.config';
import LayerUpdateTimeTriggerButton from '../../components/layerUpdateTime/layerUpdateTimeTriggerButton.vue';
import { ViewKeysMap } from '../../constants/layerUpdateTime.constant';

export default {
  name: 'Routeprofile',
  components: {
    IonContent,
    IonFooter,
    IonPage,
    IonTitle,
    IonToolbar,
    FooterSliderLabelButton,
    RouteProfileChart,
    EtdSlider,
    RouteHeader,
    UTCClock,
    LayerUpdateTimeTriggerButton,
  },
  data() {
    return {
      arrowForward,
      arrowBack,
      settings: {},
      showAdvanced: false,
      dateFormat: null,
      sliderMainObj: null,
      departTime: null,
      routeProfileData: null,
      noDataFound: false,
      noDataFoundRouteMessage: ChartConst.noDataFoundRouteMessage,
      progressObj: {
        progressPercent: 0,
      },
      showSlider: true,
      allDataForInterval: [],
      currentSliderIndex: null,
      attachedLoader: null,
      isMarkedFavorite: false,
      floatingEtdStyle: {
        visibility: 'hidden',
        top: 0,
      },
      enableAlternateSlider:
        ConfigDeviceSettings.settings.enableAlternateSlider,
      toursteps: HelpBoxConfig['app.routeprofile'].steps,
      hideBottomAxis: utilityService.hideBottomAxis(),
    };
  },
  computed: {
    isActiveRoute() {
      return this.$router.currentRoute.value.name === 'app.routeprofile';
    },
    getViewKeyName() {
      return ViewKeysMap.ROUTEPROFILE;
    },
  },
  methods: {
    refreshView() {
      const vm = this;
      vm.hideBottomAxis = utilityService.hideBottomAxis();
      if (vm.settings) {
        if (vm.settings.sliderPinnedOldState) {
          vm.settings.sliderPinned = vm.settings.sliderPinnedOldState;
        } else if (vm.settings.sliderPinnedOldState === false) {
          vm.settings.sliderPinned = vm.settings.sliderPinnedOldState;
        }
        if (vm.hideBottomAxis) {
          vm.settings.sliderPinned = true;
        }
      }
      vm.setTourSteps();
    },
    handleResizeEvent: _debounce(function () {
      this.refreshView();
    }, ChartConst.resizeDebounce),
    ionViewDidEnter() {
      const vm = this;
      // This timeout makes sure that these is smooth transition of view
      vm.initializeView();
      Emitter.on(
        EventConst.refreshViewRootscope + AppConst.stateMap.routeprofile.key,
        vm.initializeView,
      );
      reportService.bindReportMethod(AppConst.dataMode.routeprofile);
      utilityService.changeTourElementPosition();
      vm.setTourSteps();
      Emitter.on(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      vm.$nextTick(() => {
        window.addEventListener('resize', this.handleResizeEvent);
      });
    },
    ionViewWillLeave() {
      const vm = this;
      utilityService.stopTour();
      Emitter.off(
        EventConst.refreshViewRootscope + AppConst.stateMap.routeprofile.key,
      );
      Emitter.off(EventConst.promiseProgress);
      Emitter.off(EventConst.tapped_outside);
      Emitter.off(
        EventConst.favorite.listRefreshed,
        vm.isMarkedFavoriteCheckForStar,
      );
      Emitter.off(EventConst.tourEvent);
      reportService.unbindReportMethod();
      window.removeEventListener('resize', this.handleResizeEvent);
    },
    showtour() {
      const vm = this;
      if (!vm.noDataFound) {
        vm.$tours.viewTour.start();
      }
    },
    setDateFormat() {
      routeControllerService.setDateFormatRoute(this);
    },
    syncRouteData() {
      routeControllerService.syncRouteData(this.settings);
    },
    loadViewVairables() {
      routeControllerService.loadViewVairables(this);
    },
    loadRouteprofileSetting() {
      routeControllerService.loadRouteprofileSetting(this);
    },
    cleanUpSpace() {
      routeControllerService.cleanUpSpace(this);
    },
    togglePin(state) {
      routeControllerService.togglePin(state, this);
    },
    loadSliderDatesDependency(data) {
      routeControllerService.loadSliderDatesDependency(data, this);
    },
    loadRouteProfileData(data, isSoftDraw) {
      this.routeProfileData = data;
      this.refreshChart(
        this.routeProfileData.travelRoute,
        this.routeProfileData,
        isSoftDraw,
      );
    },
    loadSliderDataAndProfileData(data) {
      routeControllerService.loadSliderDataAndProfileData(data, this);
    },
    getAllIntervalData() {
      routeControllerService.getAllIntervalDataRoute(this);
    },
    openRouteSelectModal() {
      routeControllerService.openRouteSelectModal(this);
    },
    refreshChart(travelRoute, fullData, softDraw) {
      etdService.setDepartureTime(this.settings.departureTime);
      if (this.$refs.chartApi && this.$refs.chartApi.refresh) {
        this.$refs.chartApi.refresh(travelRoute, fullData, softDraw);
      }
    },
    initRouteProfile() {
      const vm = this;
      showAeroplane();
      utilityService.clearAuditFactory();
      vm.isMarkedFavorite = FavoritesService.isMarkedFavorite(vm.settings);
      vm.noDataFound = false;
      routeProfileService
        .getRouteProfileData(
          vm.settings,
          AppConst.dataMode.routeprofile,
          routeControllerService,
          vm.openRouteSelectModal,
        )
        .then((data) => {
          if (data && data.isValidRoute !== false) {
            vm.loadSliderDataAndProfileData(data);
            searchHistoryService.loadAirportHistorySearch();
          } else {
            const formattedDepartureTime = vm.dateFormat
              ? vm.dateFormat(vm.settings.departureTime) : '--';
            vm.departTime = ChartConst.departurePrefix + formattedDepartureTime;
            vm.sliderMainObj = [];
          }
          if (data === null) {
            vm.noDataFound = true;
          }
          hideAeroplane();
        });
    },
    updateTravelRouteLive() {
      const vm = this;
      vm.refreshChart(
        routeProfileService.getTravelRoute(
          vm.settings.departureTime,
          vm.settings,
        ),
        vm.routeProfileData,
        true,
      );
    },
    updateTravelRoute() {
      const vm = this;
      vm.refreshChart(
        routeProfileService.getTravelRoute(
          vm.settings.departureTime,
          vm.settings,
        ),
        vm.routeProfileData,
      );
      etdService.saveDepartureTimeToLs();
    },
    sliderChanging(sliderEventData) {
      const vm = this;
      vm.departTime = sliderEventData.departTimeDisplay;
      vm.settings.departureTime = sliderEventData.departTime;
      if (!sliderEventData.isInitializing) {
        routeControllerService.shiftEtdSliderLabel(vm, sliderEventData);
        if (sliderEventData.isDragEnd) {
          vm.updateTravelRoute();
          routeControllerService.hideFloatingSlider(vm);
        } else {
          vm.updateTravelRouteLive();
        }
      }
    },
    initializeView() {
      const vm = this;
      routeControllerService.initializeView(vm);
    },
    isMarkedFavoriteCheckForStar() {
      const vm = this;
      FavoritesService.isMarkedFavoriteCheckForStar(vm, vm.settings);
    },
    setTourSteps() {
      const vm = this;
      utilityService.setTourSteps(vm, 'app.routeprofile');
    },
  },
};
</script>
