<template>
  <PreloadImage
    v-slot="preload"
    :url="radarImage"
  >
    <ScaleToFit
      v-slot="scaleToFit"
      :image-height="preload.imageNaturalHeight"
      :image-width="preload.imageNaturalWidth"
    >
      <ImageWithZoomPan
        :url="radarImage"
        :image-height="scaleToFit.height"
        :image-width="scaleToFit.width"
      />
    </ScaleToFit>
  </PreloadImage>
</template>
<script setup>
import { computed } from 'vue';
import utilityService from '../../services/utility.service';
import ImageWithZoomPan from '../ImagerySlider/ImageWithZoomPan.vue';
import PreloadImage from '../ImagerySlider/PreloadImage.vue';
import ScaleToFit from '../ImagerySlider/ScaleToFit.vue';

const props = defineProps({
  settings: {
    type: Object,
    required: true,
  },
});

const radarImage = computed(() => {
  const { settings } = props;
  const airportDetails = utilityService.getMatchedAirportObject(settings.start);
  if (airportDetails) {
    return `https://radar.weather.gov/ridge/standard/K${airportDetails.nexrad_site
    }_loop.gif?bncachebuster=${Date.now()}`;
  }
  return null;
});
</script>
