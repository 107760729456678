import {
  store,
} from '../store/store';
import {
  STORE_NAMESPACE,
} from '../store/modules/store.namespace';
import {
  AEROPLANE_LOADER_ACTIONS_ENUM,
} from '../store/modules/aeroplane-loader/aeroplane-loader.eum';

const showAeroplane = () => {
  store.dispatch(
    `${STORE_NAMESPACE.AEROPLANE_LOADER}/${AEROPLANE_LOADER_ACTIONS_ENUM.SET_SHOW_AEROPLANE_TRUE}`,
  );
};

const hideAeroplane = () => {
  store.dispatch(
    `${STORE_NAMESPACE.AEROPLANE_LOADER}/${AEROPLANE_LOADER_ACTIONS_ENUM.SET_SHOW_AEROPLANE_FALSE}`,
  );
};

export { showAeroplane, hideAeroplane };
