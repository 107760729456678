import httpService from '../../../services/http.service';
import { getServiceHostPreference } from '../../../services/utils/getServiceHostPreference';
import AppConst from '../../../constants/app.constant';

const getTafsTabData = async (icao_id) => {
  const url = `${getServiceHostPreference()}/${AppConst.tafsTabData}/${icao_id}`;
  const errorResponse = {
    errorMessage: `TAF not available for ${icao_id}`,
  };
  const { data } = await httpService.http_get_content(url);
  if (data) {
    return '<pre><code>' + data + '</code></pre>';
  }
  return errorResponse;
};

export {
  getTafsTabData,
};
