import AccountConst from '../../../../constants/account.constant';
import { getCognitoUserAttribute } from '../../core/getCognitoUserAttribute';
import { updateUserAttributes } from '../../core/updateUserAttributes';

const setPayPalOrderRecord = async (paypalOrderData) => {
  const attributeList = [
    getCognitoUserAttribute(AccountConst.customAttribute.t3, JSON.stringify(paypalOrderData)),
  ];
  return updateUserAttributes(attributeList);
};
export { setPayPalOrderRecord };
