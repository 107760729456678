import { CognitoUser } from 'amazon-cognito-identity-js';
import AppConst from '../../../constants/app.constant';
import reportError from '../../error-handler/reportError';
import { getCognitoUserPool } from '../core/getCognitoUserPool';
import { appendUserNameToMessage } from '../utils/appendUserNameToMessage';

const forgotPassword = (username) => new Promise((resolve) => {
  const userData = {
    Username: username,
    Pool: getCognitoUserPool(),
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess() {
      resolve(true);
    },
    onFailure(err) {
      err.message = appendUserNameToMessage(username, err.message);
      err.emailTried = username;
      resolve(err);
      if (
        !err.code
          || (err.code && !err.code.match(
            new RegExp(AppConst.exceptionsCodes.UserNotFoundException),
          ))
      ) {
        reportError(err);
      }
    },
  });
});

export { forgotPassword };
