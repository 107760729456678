<template>
  <div
    v-if="!mobileSelector && filteredResults.length"
    class="filteredResults"
  >
    <ion-list>
      <ion-item
        v-for="(airport, $index) in filteredResults"
        :key="$index"
        @click="selectedAirportItem(airport)"
      >
        <ion-icon
          v-if="airport.logDate"
          slot="start"
          :icon="time"
        />
        <font-awesome-icon
          v-else
          slot="start"
          icon="map-marker-alt"
        />
        <ion-label v-html="highlight(airport)" />
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import {
  IonLabel, IonIcon, IonList, IonItem,
} from '@ionic/vue';
import { time } from 'ionicons/icons';
import utilityService from '../services/utility.service';

export default {
  components: {
    IonLabel,
    IonIcon,
    IonList,
    IonItem,
  },
  props: {
    filteredResults: {
      type: Array,
      default: () => [],
    },
    searchStr: {
      type: String,
      default: '',
    },
  },
  emits: ['selected-airport-item'],
  data() {
    return {
      time,
    };
  },
  computed: {
    mobileSelector() {
      return utilityService.isModalFullScreen();
    },
  },
  methods: {
    highlight(airport) {
      const text = `${airport.icao_id} ${airport.faa_facility_name}`;
      if (!this.searchStr) {
        return text;
      }
      let highlightText = utilityService.sanitizeSearchString(
        this.searchStr,
      );
      highlightText = highlightText.replace(/\[/g, '');
      return text.replace(
        new RegExp(highlightText, 'gi'),
        '<span class="boldedText">$&</span>',
      );
    },
    selectedAirportItem(airport) {
      this.$emit('selected-airport-item', airport);
    },
  },
};
</script>
