import sub from 'date-fns/sub';
import addHours from 'date-fns/addHours';
import getCycleTimeFromAdjustedtime from './utils/getCycleTimeFromAdjustedTime';
import { replaceKeysByConfig } from './utils/replaceKeysByConfig';
import { minutesToHHmm } from './utils/minutesToHHmm';
import hoursToHHmm from './utils/hoursToHHmm';
import { processImageUrl } from './processImageUrl';
import { getUTCDateParts } from './utils/getDateParts';

const processSubtab = (subtab, cacheBuster) => {
  const imageLocations = [];
  const {
    valid, s: subtractHours, cycles, 'valid-range': validRange, altitudes,
  } = subtab;
  const subtractHHmm = hoursToHHmm(subtractHours);
  let imageUrlPlaceholder;
  if (subtab.imageUrlPlaceholder) {
    imageUrlPlaceholder = subtab.imageUrlPlaceholder;
  } else {
    [imageUrlPlaceholder] = subtab.i;
  }
  const adjustedTime = sub(new Date(), {
    hours: subtractHHmm.hours,
    minutes: subtractHHmm.minutes,
  });

  const adjustedCycleTime = getCycleTimeFromAdjustedtime(adjustedTime, cycles);
  let validTimestamps = null;
  let subtabImages = [];
  if (valid && subtractHours !== undefined) {
    validTimestamps = [];
    valid.forEach((validHour) => {
      const VHVM = minutesToHHmm(validHour * 60);
      let validTimestampItem = addHours(new Date(adjustedCycleTime), validHour);
      if (validRange !== undefined) {
        validTimestampItem = addHours(validTimestampItem, validRange);
      }
      validTimestamps.push(validTimestampItem);
      const {
        month, day, year, hour, minute,
      } = getUTCDateParts(validTimestampItem);
      const replacebleConfig = {
        Vmin: `00${VHVM.minutes}`.slice(-2),
        VH: `00${VHVM.hours}`.slice(-2),
        TH: hour,
        TM: month,
        Tmin: minute,
        TD: day,
        TYYY: year,
      };
      const imageUrl = replaceKeysByConfig(imageUrlPlaceholder.u, replacebleConfig);
      subtabImages.push({
        u: imageUrl,
      });
    });
  }
  if (subtabImages.length === 0) {
    subtabImages = subtab.i;
  }
  if (altitudes !== undefined) {
    const altitudesImages = [];
    subtabImages.forEach((img) => {
      altitudesImages.push([]);
      altitudes.forEach((altitude) => {
        const replacebleConfig = {
          ALT: altitude,
        };
        altitudesImages[altitudesImages.length - 1].push({
          alt: altitude,
          u: replaceKeysByConfig(img.u, replacebleConfig),
        });
      });
    });
    subtabImages = altitudesImages;
  }

  subtabImages.forEach((image, imageIdx) => {
    if (!Array.isArray(image)) {
      const imageUrl = processImageUrl(image.u, adjustedTime, adjustedCycleTime);
      const subtabImage = `${imageUrl}${cacheBuster}&alt=${image.alt}`;
      imageLocations.push(subtabImage);
    } else {
      imageLocations.push([]);
      image.forEach((img) => {
        const imageUrl = processImageUrl(img.u, adjustedTime, adjustedCycleTime);
        const subtabImage = `${imageUrl}${cacheBuster}&alt=${img.alt}&imageIdx=${imageIdx}`;
        imageLocations[imageLocations.length - 1].push(subtabImage);
      });
    }
  });
  return {
    subtabImages,
    imageLocations,
    validTimestamps,
    imageUrlPlaceholder,
  };
};

export { processSubtab };
