import {
  store,
} from '../../store/store';

/**
 *
 * @returns Promise
 */
const getIsLoggedIn = () => store.state.user.isLoggedIn;

export {
  getIsLoggedIn,
};
