import _debounce from 'lodash/debounce';
import router from '../../router';
import {
  store,
} from '../../store/store';
import {
  STORE_NAMESPACE,
} from '../../store/modules/store.namespace';
import {
  MRMS_ACTIONS_ENUM,
} from '../../store/modules/mrms/mrms.enum';
import ConfigDeviceSettings from '../../config/device.config';
import AppConst from '../../constants/app.constant';
import Emitter from '../../bus';
import EventConst from '../../constants/event.constant';

let timeoutValue = null;
let startTimer;

const clearTimer = () => {
  if (timeoutValue !== null) {
    clearTimeout(timeoutValue);
    timeoutValue = null;
  }
};

const loadMrmsDataRemote = _debounce(async () => {
  clearTimer();
  if (ConfigDeviceSettings.settings.enableRadarFeature) {
    if (router.currentRoute.value.name === 'app.mapview') {
      store.dispatch(
        `${STORE_NAMESPACE.MRMS}/${MRMS_ACTIONS_ENUM.GET_MRMS_METADATA_REMOTE}`,
        true,
      );
      startTimer();
    }
  }
}, 2000);

startTimer = () => {
  timeoutValue = setTimeout(() => {
    loadMrmsDataRemote();
  }, AppConst.mrmsDataAge);
};

const appPauseResumeStatusChanged = (status) => {
  if (status === 'paused') {
    clearTimer();
  } else if (status === 'resume') {
    loadMrmsDataRemote();
  }
};
Emitter.on(EventConst.appPaused, appPauseResumeStatusChanged);
Emitter.on(EventConst.appPaused, appPauseResumeStatusChanged);

export {
  loadMrmsDataRemote,
  clearTimer,
};
