import utilityService from '../../services/utility.service';
import capacitorService from '../../services/capacitor.service';

const getPixelRatio = () => {
  const ctxForConfig = document.createElement('canvas').getContext('2d');
  const dpr = window.devicePixelRatio || 1;
  const bsr = ctxForConfig.webkitBackingStorePixelRatio
    || ctxForConfig.mozBackingStorePixelRatio
    || ctxForConfig.msBackingStorePixelRatio
    || ctxForConfig.oBackingStorePixelRatio
    || ctxForConfig.backingStorePixelRatio
    || 1;
  return dpr / bsr;
};

/**
 * Safari has limit of max size of canvas to 224MB
 */
const checkIfIpadPro12Inch = () => {
  if (utilityService.isCordovaApplication()) {
    if (
      (window.innerHeight === 1024 && window.innerWidth === 1366)
      || (window.innerWidth === 1024 && window.innerHeight)
    ) {
      return true;
    }
  }
  return false;
};

/**
 *
 * @param {Number} w
 * @param {Number} h
 */
const getHiDPICanvasConfig = (w, h) => {
  let ratio = getPixelRatio();
  if (capacitorService.isCapacitorMobileApp) {
    if (checkIfIpadPro12Inch()) {
      ratio = 1.1;
    } else {
      ratio = 1.6;
    }
  }
  const config = {
    style: {},
  };
  config.ratio = ratio;
  config.width = w * ratio;
  config.height = h * ratio;
  config.style.width = `${w}px`;
  config.style.height = `${h}px`;
  return config;
};

export default getHiDPICanvasConfig;
