import { getUTCDateParts } from './getDateParts';

const getUTCConfig = (adjustedTime, adjustedCycleTime) => {
  const {
    month, day, year, hour, minute,
  } = getUTCDateParts(adjustedTime);

  const adjustedCycleTimeParts = getUTCDateParts(adjustedCycleTime);
  // This is kinda hacky but Shashwat says it's fine
  if (adjustedCycleTimeParts.hour === '24') {
    adjustedCycleTimeParts.hour = '00';
  }

  return {
    replacebleConfig: {
      YYYY: year,
      YY: adjustedTime.toLocaleDateString(undefined, { year: '2-digit', timeZone: 'UTC' }),
      DD: day,
      MM: month,
      HH: hour,
      min: minute,
      Cmin: 0,
      CH: adjustedCycleTimeParts.hour,
      CYYY: adjustedCycleTimeParts.year,
      CM: adjustedCycleTimeParts.month,
      CD: adjustedCycleTimeParts.day,
    },
  };
};
export { getUTCConfig };
