import { getUserPaypalNextBillingDate, getUsersExtraDays } from './getUserPaypalNextBillingDate';
import { isDateExpired } from '../utils/isDateExpired';
import utilityService from '../utility.service';
import { getIsUserPaypalSubscriptionActive } from './getIsUserPaypalSubscriptionActive';

/**
 * Checks if Paypal subscription is expired.
 * If Paypal returns that status is active then return TRUE
 * We allow extra 1 day in
 * calculating Expiry to make sure that paypal has enough time to update next billing date
 * of subscription
 * @param {Object} user
 * @returns
 */
const checkUserPaypalSubscriptionExpired = (user, userPayPalSubscription) => {
  const nextBillingDate = getUserPaypalNextBillingDate(user);
  /**
   * So if paypal says it is ACTIVE then we consider him active subscription
   */
  if (getIsUserPaypalSubscriptionActive(userPayPalSubscription)) {
    return false;
  }
  if (nextBillingDate) {
    try {
      const nextBillingDateObj = new Date(nextBillingDate);
      const ed = getUsersExtraDays(user) || 0;
      const gracePeriodDays = 1 + ed;
      const nextBillingDateWithGrace = utilityService.addMinutes(
        nextBillingDateObj,
        gracePeriodDays * 24 * 60,
      );
      return isDateExpired(nextBillingDateWithGrace);
    } catch (ex) {
      return true;
    }
  }
  return true;
};

export { checkUserPaypalSubscriptionExpired };
